import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import REQUEST_PARAMETER_TYPE from 'app/types/RequestParameterType';
import _ from 'lodash';
import MACHINE_TYPE from 'app/types/MachineType';
import CommandGatewayStatusType, { COMMAND_GATEWAY_STATUS_TYPE } from 'app/types/CommandGatewayStatusType';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getCurrentStDeepscanSettings = createAsyncThunk('main/cockpit/stDeepscanSettings/getCurrentStDeepscanSettings', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/currentstdeepscansettings`, { params: { } });
  const data = await response.data;
  if (data && data.stdeepscansettings && data.stdeepscansettings.length > 0)
    return {...data.stdeepscansettings[0], details: data.stdeepscansettings[0].stDeepScanSettingsDetails};

  return;
});

export const getCurrentStDeepscanSettingsToCreateNew = createAsyncThunk('main/cockpit/stDeepscanSettings/getCurrentStDeepscanSettingsToCreateNew', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/currentstdeepscansettings`, { params: { } });
  const data = await response.data;
  if (data && data.stdeepscansettings && data.stdeepscansettings.length > 0)
    return {...data.stdeepscansettings[0], details: data.stdeepscansettings[0].stDeepScanSettingsDetails};

  return;
});

export const patchStDeepscanSettingsRequest = createAsyncThunk('main/cockpit/stDeepscanSettings/patchStDeepscanSettingsRequest', async (stDeepscanSettingsRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(stDeepscanSettingsRequest);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'stdeepscansettingsrequest',clone);

  const response = await axios.patch(`api/v1/stdeepscansettingsrequests/${stDeepscanSettingsRequest.id}`, payload);

  const data = await response.data;

  return data;
});

export const postStDeepscanSettingsRequest = createAsyncThunk('main/cockpit/stDeepscanSettings/postStDeepscanSettingsRequest', async (stDeepscanSettingsRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(stDeepscanSettingsRequest);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'stdeepscansettingsrequest', clone);

  const response = await axios.post(`api/v1/stdeepscansettingsrequests`, payload);

  const data = await response.data;

  return data;
});

export const changeStDeepscanSettingsRequestStatus = createAsyncThunk('main/cockpit/stDeepscanSettings/changeStDeepscanSettingsRequestStatus', async (stDeepscanSettingsRequest, { getState }) => {

  const response = await axios.patch(`api/v1/stdeepscansettingsrequests/${stDeepscanSettingsRequest.id}/changestatus`);
  const data = await response.data;

  return data;
});

export const deleteStDeepscanSettingsRequest = createAsyncThunk('main/cockpit/stDeepscanSettings/deleteStDeepscanSettingsRequest', async (stDeepscanSettingsRequest, { getState }) => {

  const response = await axios.delete(`api/v1/stdeepscansettingsrequests/${stDeepscanSettingsRequest.id}`);
  const data = await response.data;

  return data;
});

export const patchInquiryBodyParameterStatus = createAsyncThunk('main/cockpit/stDeepscanSettings/patchInquiryBodyParameterStatus', async (stDeepscanSettingsRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${stDeepscanSettingsRequest.id}/inquirystatus`);
  const data = await response.data;

  return data;
});

export const patchCancelStDeepscanSettingsRequest = createAsyncThunk('main/cockpit/stDeepscanSettings/patchCancelStDeepscanSettingsRequest', async (stDeepscanSettingsRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${stDeepscanSettingsRequest.id}/cancel`);
  const data = await response.data;

  return data;
});


export const getStDeepscanSettingsHistory = createAsyncThunk('main/cockpit/stDeepscanSettings/getStDeepscanSettingsHistory', async (params, { getState }) => {
  const response = await axios.get(`api/v1/units/${params.id}/stdeepscansettings`, { params: { offset: params.offset, limit: params.limit } });
  const data = await response.data;
  if (!data)
    return;

  return { stdeepscansettings: data.stdeepscansettings, historyTotalCount: data.totalcount };
});

export const getStDeepscanSettingsWriteRequests = createAsyncThunk('main/cockpit/stDeepscanSettings/getStDeepscanSettingsWriteRequests', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/stdeepscansettingsrequests`, { params: { } });
  const data = await response.data;
  if (!data || !data.stdeepscansettingsrequests)
    return [];

    return data.stdeepscansettingsrequests.map(st => {return {...st, details: st.stDeepScanSettingsRequestDetails}});
});

const stDeepscanSettingsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectStDeepscanSettings, selectById: selectStDeepscanSettingById, selectIds: selectStDeepscanSettingIds } =
  stDeepscanSettingsAdapter.getSelectors((state) => { return state.main.cockpit.stDeepscanSettings.stDeepscanSettingsHistory; });

  const stDeepscanSettingRequestsAdapter = createEntityAdapter({ selectId: (model) => model.id });
  export const { selectAll: selectStDeepscanSettingRequests, selectById: selectStDeepscanSettingRequestById, selectIds: selectStDeepscanSettingRequestIds } =
  stDeepscanSettingRequestsAdapter.getSelectors((state) => { return state.main.cockpit.stDeepscanSettings.stDeepscanSettingRequests; });

const StDeepscanSettingsSlice = createSlice({
  name: 'main/cockpit/stDeepscanSettings',
  initialState: {
    searchText: '',
    loadingStDeepscanSettingsHistory: false,
    loadingCurrentStDeepscanSettings: false,
    loadingStDeepscanSettingRequests: false,
    savingCurrentStDeepscanSettings: false,
    stDeepscanSettingsHistory: stDeepscanSettingsAdapter.getInitialState({}),
    historyTotalCount: 0,
    stDeepscanSettingRequests: stDeepscanSettingRequestsAdapter.getInitialState({}),
    currentStDeepscanSettings: 
    {
      isLeftDeepscanOn: false,
      isRightDeepscanOn: false,
      thresholdsFirst : 20,
      thresholdsSecond : 30,
      isSettingViaDisplayAllowed: false,
      isLifterStopInactive: false,
      isAlarmEnabled: false,
    },
    currentStDeepScanSettingsRequest: null,
  },
  reducers: {
    resetStDeepScanSettings: {
      reducer: (state, action) => {
        state.loadingStDeepscanSettingsHistory = false;
        state.loadingCurrentStDeepscanSettings = false;
        state.loadingStDeepscanSettingRequests = false;
        state.savingCurrentStDeepscanSettings = false;
        state.historyTotalCount = 0;
        stDeepscanSettingsAdapter.removeAll(state.stDeepscanSettingsHistory);
        stDeepscanSettingRequestsAdapter.removeAll(state.stDeepscanSettingRequests);

        state.currentStDeepscanSettings = 
        {
          isLeftDeepscanOn: false,
          isRightDeepscanOn: false,
          thresholdsFirst : 20,
          thresholdsSecond : 30,
          isSettingViaDisplayAllowed: false,
          isLifterStopInactive: false,
          isAlarmEnabled: false,
        };
        state.currentStDeepScanSettingsRequest = null;
      },
    },
    setStDeepscanSettingsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCurrentStDeepscanSettngs: {
      reducer: (state, action) => {
        state.currentStDeepScanSettingsRequest = action.payload ;
      },
    },
    updateStDeepscanSettingRequest: {
      reducer: (state, action) => {
        stDeepscanSettingRequestsAdapter.updateOne(state.stDeepscanSettingRequests, action.payload);
      },
    },
  },

  extraReducers: {
    [getCurrentStDeepscanSettings.fulfilled](state, { payload }) {
      state.currentStDeepscanSettings = payload;
      state.loadingCurrentStDeepscanSettings = false;
    },
    [getCurrentStDeepscanSettings.pending](state, { payload }) {
      state.currentStDeepscanSettings =
      {
        details:
        {
          isLeftDeepscanOn: false,
          isRightDeepscanOn: false,
          thresholdsFirst : 0,
          thresholdsSecond : 0,
          isSettingViaDisplayAllowed: false,
          isLifterStopInactive: false,
          isAlarmEnabled: false,
        }
      }
      state.loadingCurrentStDeepscanSettings = true;
    },
    [getCurrentStDeepscanSettings.rejected](state, { payload }) {
      state.loadingCurrentStDeepscanSettings = false;
    },

    [getCurrentStDeepscanSettingsToCreateNew.fulfilled](state, action) {

      const unit = action.meta.arg;
      const payload = action.payload;

      state.currentStDeepScanSettingsRequest =
      {
        createdAt: new Date(),
        updatedAt: new Date(),
        machineType: MACHINE_TYPE.BODY,
        type: REQUEST_PARAMETER_TYPE.WRITE_ST_DEEP_SCAN_SETTINS,
        commandGatewayStatus: COMMAND_GATEWAY_STATUS_TYPE.NEW_REQUEST,
        isExpanded: true,
        vehicle: unit,
        details:
        {
          description: '',
          isLeftDeepscanOn: (payload && payload.details) ? payload.details.isLeftDeepscanOn : false,
          isRightDeepscanOn: (payload && payload.details) ? payload.details.isRightDeepscanOn : false,
          thresholdsFirst : (payload && payload.details) ? payload.details.thresholdsFirst : 20,
          thresholdsSecond : (payload && payload.details) ? payload.details.thresholdsSecond : 26,
          isSettingViaDisplayAllowed: (payload && payload.details) ? payload.details.isSettingViaDisplayAllowed : true,
          isLifterStopInactive: (payload && payload.details) ? payload.details.isLifterStopInactive : false,
          alarmIsEnabled: (payload && payload.details) ? payload.details.alarmIsEnabled : true,
        }
      }
      state.loadingCurrentStDeepscanSettingsToCreateNew = false;
    },
    [getCurrentStDeepscanSettingsToCreateNew.pending](state, { payload }) {
      state.currentStDeepscanSettings = { details: {}};
      
      state.loadingCurrentStDeepscanSettingsToCreateNew = true;
    },
    [getCurrentStDeepscanSettingsToCreateNew.rejected](state, { payload }) {
      state.loadingCurrentStDeepscanSettingsToCreateNew = false;
    },

    [patchStDeepscanSettingsRequest.fulfilled](state, { payload }) {
      state.savingCurrentStDeepscanSettings = false;
    },
    [patchStDeepscanSettingsRequest.pending](state, { payload }) {
      state.savingCurrentStDeepscanSettings = true;
    },
    [patchStDeepscanSettingsRequest.rejected](state, { payload }) {
      state.savingCurrentStDeepscanSettings = false;
    },

    [postStDeepscanSettingsRequest.fulfilled](state, { payload }) {
      state.savingCurrentStDeepscanSettings = false;
      state.currentStDeepScanSettingsRequest = null;
    },
    [postStDeepscanSettingsRequest.pending](state, { payload }) {
      state.savingCurrentStDeepscanSettings = true;
    },
    [postStDeepscanSettingsRequest.rejected](state, { payload }) {
      state.savingCurrentStDeepscanSettings = false;
    },

    [changeStDeepscanSettingsRequestStatus.fulfilled](state, { payload }) {
      // TODO
      state.savingCurrentStDeepscanSettings = false;
    },
    [changeStDeepscanSettingsRequestStatus.pending](state, { payload }) {
      // TODO
      state.savingCurrentStDeepscanSettings = true;
    },
    [changeStDeepscanSettingsRequestStatus.rejected](state, { payload }) {
      // TODO
      state.savingCurrentStDeepscanSettings = false;
    },

    [deleteStDeepscanSettingsRequest.fulfilled](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = false;
    },
    [deleteStDeepscanSettingsRequest.pending](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = true;
    },
    [deleteStDeepscanSettingsRequest.rejected](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = false;
    },   
    
    [patchInquiryBodyParameterStatus.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },
    [patchInquiryBodyParameterStatus.pending](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = true;
    },
    [patchInquiryBodyParameterStatus.rejected](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },  
    
    [patchCancelStDeepscanSettingsRequest.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },
    [patchCancelStDeepscanSettingsRequest.pending](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = true;
    },
    [patchCancelStDeepscanSettingsRequest.rejected](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },  

    [getStDeepscanSettingsHistory.fulfilled](state, { payload }) {
      stDeepscanSettingsAdapter.setAll(state.stDeepscanSettingsHistory, payload.stdeepscansettings);
      state.loadingStDeepscanSettingsHistory = false;
      state.historyTotalCount = payload.historyTotalCount;
    },
    [getStDeepscanSettingsHistory.pending](state, { payload }) {
      stDeepscanSettingsAdapter.removeAll(state.stDeepscanSettingsHistory);
      state.loadingStDeepscanSettingsHistory = true;
      state.historyTotalCount = 0;
    },
    [getStDeepscanSettingsHistory.rejected](state, { payload }) {
      state.loadingStDeepscanSettingsHistory = false;
    },

    [getStDeepscanSettingsWriteRequests.fulfilled](state, { payload }) {
      console.log('write requets', payload);
      stDeepscanSettingRequestsAdapter.setAll(state.stDeepscanSettingRequests, payload);
      state.loadingStDeepscanSettingRequests = false;
    },
    [getStDeepscanSettingsWriteRequests.pending](state, { payload }) {
      stDeepscanSettingRequestsAdapter.removeAll(state.stDeepscanSettingRequests);
      state.loadingStDeepscanSettingRequests = true;
    },
    [getStDeepscanSettingsWriteRequests.rejected](state, { payload }) {
      state.loadingStDeepscanSettingRequests = false;
    },

  },
});

export const { setStDeepscanSettingsSearchText, setCurrentStDeepscanSettngs, updateStDeepscanSettingRequest, resetStDeepScanSettings } = StDeepscanSettingsSlice.actions;

export default StDeepscanSettingsSlice.reducer;
