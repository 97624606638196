import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import MACHINE_TYPE from 'app/types/MachineType';
import REQUEST_PARAMETER_TYPE from 'app/types/RequestParameterType';
import _ from 'lodash';
import ParameterCategoriesLifter from 'app/types/ParameterCategoriesLifter';

export const getLifterReadParameterRequests = createAsyncThunk('main/cockpit/lifterParameterRead/getLifterReadParameterRequests', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/types/${REQUEST_PARAMETER_TYPE.READ_LIFTER_PARAMETERS}/parameterrequests`, { params: { inprogress: false } });
  const data = await response.data;
  if (!data)
    return;

  return data.parameterrequests;
});

export const getLifterReadParameterHistories = createAsyncThunk('main/cockpit/lifterParameterRead/getLifterReadParameterHistories', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.LIFTER}/parameterhistory`);
  const data = await response.data;
  if (!data)
    return;

  return data.dates;
});

export const sendLifterReadParameterRequest = createAsyncThunk('main/cockpit/lifterParameterRead/sendLifterReadParameterRequest', async (unit, { getState }) => {
  const response = await axios.post(`api/v1/parameterrequests`,
    {
      parameter_request: {
        vehicle: unit.id,
        type: REQUEST_PARAMETER_TYPE.READ_LIFTER_PARAMETERS,
        machineType: MACHINE_TYPE.LIFTER,
        parameterRequestDetails: []
      }
    });
  const data = await response.data;

  return data;
});

export const getLifterHistoryChanges = createAsyncThunk('main/cockpit/lifterParameterRead/getLifterHistoryChanges', async (lifterHistory, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const responseParamVehicle = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.LIFTER}/parameterfilechange`, { params: { 'date': lifterHistory.date.date } });
  const responseParam = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.LIFTER}/parameterfilechangeinfo`, { params: { 'date': lifterHistory.date.date } });

  let data = await responseParamVehicle.data;
  let dataParam = await responseParam.data;
  if (!data)
    return;

  let parameterVehicleValues = data.parametervehiclevalues;
  let parameters = dataParam.parameters;

  if (parameterVehicleValues && parameterVehicleValues.length > 0 && parameters && parameters.length > 0) {

    parameterVehicleValues = parameterVehicleValues.map(paramVehicle => {
      let parameter = parameters.find(param => param.parameterNumber == paramVehicle.number);
      paramVehicle.parameter = parameter;
      return paramVehicle;
    });
  }

  return {
    id: lifterHistory.id,
    changes: {
      loadingChanges: false,
      parameterVehicleValues: parameterVehicleValues,
    }
  }
});

export const getInUseLifterParameterCategories = createAsyncThunk('main/cockpit/lifterParameterRead/getInUseLifterParameterCategories', async (unit, { getState }) => {

  const response = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.LIFTER}/parametercategory`);

  let data = await response.data;
  if (!data)
    return;

  return data.categories.map(category => {
    return {
      id: category.category, 
      changes: {
        loading: false,
        count: category.count,
      }
    }
  });
});

export const getLifterParameterVehicles = createAsyncThunk('main/cockpit/lifterParameterRead/getLifterParameterVehicles', async (paramCategory, { getState }) => {
  const { main } = getState();
  const { unit, lifterInformation } = main.cockpit.unitDetail;
  const responseParamVehicle = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.LIFTER}/parametervehicles`, { params: { 'category': paramCategory.value } });
  const responseParam = await axios.get(`api/v1/parameters`,
    {
      params: {
        vehicletype: lifterInformation.lifterType,
        category: paramCategory.value,
        limit: 1000,
        machinetype: MACHINE_TYPE.LIFTER
      },
      headers: {
        'X-UI-State': 'unit.parameters.overview'
      }
    });

  let data = await responseParamVehicle.data;
  let dataParam = await responseParam.data;
  if (!data)
    return;

  let parametervehicles = data.parametervehicles;
  let parameters = dataParam.parameters;

  if (parametervehicles && parametervehicles.length > 0 && parameters && parameters.length > 0) {

    parametervehicles = parametervehicles.map(paramVehicle => {
      let parameter = parameters.find(param => param.parameterNumber == paramVehicle.number);
      paramVehicle.parameter = parameter;
      return paramVehicle;
    });
  }

  return {
    id: paramCategory.value,
    changes: {
      loading: false,
      parameterVehicles: parametervehicles,
    }
  }
});

const lifterReadParameterRequestsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectLifterReadParameterRequests, selectById: selectLifterReadParameterRequestById, selectIds: selectLifterReadParameterRequestIds } =
  lifterReadParameterRequestsAdapter.getSelectors((state) => { return state.main.cockpit.lifterParameterRead.lifterReadParameterRequests; });

const lifterReadParameterHistoriesAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectLifterReadParameterHistories, selectById: selectLifterReadParameterHistoryById, selectIds: selectLifterReadParameterHistoryIds } =
  lifterReadParameterHistoriesAdapter.getSelectors((state) => { return state.main.cockpit.lifterParameterRead.lifterReadParameterHistories; });

const lifterReadParameterCategoriesAdapter = createEntityAdapter({ selectId: (model) => model.value });
export const { selectAll: selectLifterReadParameterCategories, selectById: selectLifterReadParameterCategoryById, selectIds: selectLifterReadParameterCategoryIds } =
  lifterReadParameterCategoriesAdapter.getSelectors((state) => { return state.main.cockpit.lifterParameterRead.lifterReadParameterCategories; });

const initializeParameterCategoriesLifter = (unit) => {
  return _.cloneDeep(ParameterCategoriesLifter);
};

const LifterParameterReadSlice = createSlice({
  name: 'main/cockpit/lifterParameterRead',
  initialState: {
    searchText: '',
    loadingLifterReadParameterRequests: false,
    loadingLifterReadParameterHistories: false,
    loadingInUseLifterParameterCategories: false,
    sendingLifterReadParameterRequest: false,
    lifterReadParameterRequests: lifterReadParameterRequestsAdapter.getInitialState({}),
    lifterReadParameterHistories: lifterReadParameterHistoriesAdapter.getInitialState({}),
    lifterReadParameterCategories: lifterReadParameterCategoriesAdapter.getInitialState({}),
  },
  reducers: {
    setReadParameterSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: {
    [getLifterReadParameterRequests.fulfilled](state, { payload }) {
      lifterReadParameterRequestsAdapter.setAll(state.lifterReadParameterRequests, payload);
      state.loadingLifterReadParameterRequests = false;
    },
    [getLifterReadParameterRequests.pending](state, { payload }) {
      lifterReadParameterRequestsAdapter.removeAll(state.lifterReadParameterRequests);
      state.loadingLifterReadParameterRequests = true;
    },
    [getLifterReadParameterRequests.rejected](state, { payload }) {
      state.loadingLifterReadParameterRequests = false;
    },

    [sendLifterReadParameterRequest.fulfilled](state, { payload }) {
      state.sendingLifterReadParameterRequest = false;
    },
    [sendLifterReadParameterRequest.pending](state, { payload }) {
      state.sendingLifterReadParameterRequest = true;
    },
    [sendLifterReadParameterRequest.rejected](state, { payload }) {
      state.sendingLifterReadParameterRequest = false;
    },

    [getLifterReadParameterHistories.fulfilled](state, { payload }) {
      lifterReadParameterRequestsAdapter.setAll(state.lifterReadParameterHistories, payload);
      state.loadingLifterReadParameterHistories = false;
    },
    [getLifterReadParameterHistories.pending](state, { payload }) {
      lifterReadParameterRequestsAdapter.removeAll(state.lifterReadParameterHistories);
      state.loadingLifterReadParameterHistories = true;
    },
    [getLifterReadParameterHistories.rejected](state, { payload }) {
      state.loadingLifterReadParameterHistories = false;
    },

    [getLifterHistoryChanges.fulfilled](state, { meta, payload }) {
      lifterReadParameterHistoriesAdapter.updateOne(state.lifterReadParameterHistories, payload);
    },
    [getLifterHistoryChanges.pending](state, { meta, payload }) {
      let lifterHistory = meta.arg;
      let changes = {
        id: lifterHistory.id,
        changes: {
          loadingChanges: true,
          parameterVehicleValues: null,
        }
      }
      lifterReadParameterHistoriesAdapter.updateOne(state.lifterReadParameterHistories, changes);
    },
    [getLifterHistoryChanges.rejected](state, { meta, payload }) {
      let lifterHistory = meta.arg;
      let changes = {
        id: lifterHistory.id,
        changes: {
          loadingChanges: false,
        }
      }
      lifterReadParameterHistoriesAdapter.updateOne(state.lifterReadParameterHistories, changes);
    },

    [getInUseLifterParameterCategories.fulfilled](state, { meta, payload }) {
      lifterReadParameterCategoriesAdapter.updateMany(state.lifterReadParameterCategories, payload);
      state.loadingInUseLifterParameterCategories = false;
    },
    [getInUseLifterParameterCategories.pending](state, { meta, payload }) {
      let unit = meta.arg;
      lifterReadParameterCategoriesAdapter.setAll(state.lifterReadParameterCategories, initializeParameterCategoriesLifter(unit));
      state.loadingInUseLifterParameterCategories = true;
    },
    [getInUseLifterParameterCategories.rejected](state, { meta, payload }) {
      state.loadingInUseLifterParameterCategories = false
    },

    [getLifterParameterVehicles.fulfilled](state, { meta, payload }) {
      lifterReadParameterCategoriesAdapter.updateOne(state.lifterReadParameterCategories, payload);
    },
    [getLifterParameterVehicles.pending](state, { meta, payload }) {
      let category = meta.arg;
      let changes = {
        id: category.value,
        changes: {
          loading: true,
          parameterVehicles: null,
        }
      }
      lifterReadParameterCategoriesAdapter.updateOne(state.lifterReadParameterCategories, changes);
    },
    [getLifterParameterVehicles.rejected](state, { meta, payload }) {
      let category = meta.arg;
      let changes = {
        id: category.value,
        changes: {
          loading: false,
        }
      }
      lifterReadParameterCategoriesAdapter.updateOne(state.lifterReadParameterCategories, changes);
    },
  },
});

export const { setReadParameterSearchText } = LifterParameterReadSlice.actions;

export default LifterParameterReadSlice.reducer;
