import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";
import { pourcentageScantecSlice } from "../../scantec/store/graphPositionScantec";

const initialState = {
  loadingMileage: false,
  mileageData: {},
};

export const getMileageData = createAsyncThunk(
  "fleetTracking/units/mileageData",
  async (id) => {
    const response = await axios.get(
      `${apiUrlConfig.GET_UNITS}/${id}/mileagedata`,
      { params: { limit: 0 }, headers: { "X-UI-State": "kocobox.overview" } }
    );
    console.log("mileage data in slice is ", response.data.data);
    const data = await response.data;
    return data.data;
  }
);

export const fleetMileageDataSlice = createSlice({
  name: "fleetTracking/mileageData",
  initialState,
  reducers: {
    resetMileage: {
      reducer: (state, action) => {
        state.mileageData = {};
      },
    },
  },
  extraReducers: {
    [getMileageData.pending]: (state) => {
      state.loadingMileage = true;
      state.mileageData = {};
    },
    [getMileageData.fulfilled]: (state, { payload }) => {
      state.loadingMileage = false;
      state.mileageData = payload;
    },
    [getMileageData.rejected]: (state) => {
      state.loadingMileage = false;
      state.mileageData = {};
    },
  },
});

export const postReducer = fleetMileageDataSlice.reducer;
export const { resetMileage } = fleetMileageDataSlice.actions;

export default fleetMileageDataSlice.reducer;
