import {default as React, useEffect, useRef, useState} from "react";
import {MapContainer, Polyline, TileLayer, useMap} from "react-leaflet";
import AirplaneMarker from "./AirplanWidget";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import 'leaflet/dist/leaflet.css';
import "../javascript/easy-button/easy-button.css";
import "../javascript/easy-button/easy-button.js";
import "font-awesome/css/font-awesome.min.css";
import "../javascript/movingMarker.js";
import "../javascript/slider";
import Control from 'react-leaflet-custom-control'
import { Button } from '@mui/material'
import { Replay, Stop,Pause,PlayArrow,SkipNext ,SkipPrevious,Close  } from '@mui/icons-material'
import moment from 'moment';
import {floor,round} from "lodash/math";
import Legend from "./Legend";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import {useTranslation} from "react-i18next";
let cursor = 0;
export default function App(props) {
    moment.locale('de');
    const { t } = useTranslation('common');
    const intervalRef = useRef();
    const cursorRef = useRef();
    const [map, setMap] = useState(null);
    const sliderRef = useRef();
    const limitRef = useRef();
    const currentDate = useRef();
    const  [isStopped, setIsStopped] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [currentTrack, setCurrentTrack] = useState({lat:53.22376666666667, lng: 50.745841666666664});
    const {load,lines} = useSelector(({ main }) => main.fleetTracking.fleetLines);
    const [polylines, setPolylines] = useState({});
    var polyUtil = require('polyline-encoded');
    let index = 0;
    let interval = null;
    let polylineObjectArray = [];
    useEffect(() => {
        //setCurrentTrack(dataStory[cursor]);
        if (lines && lines.length > 0) {
            props.setDataFetched(1);
            let polylinesArray = [];
            let polylineObjectArray = [];
            let polyliness = [];
            let polylinesss = [];
            let timess = [];
            index = 0;
            let latlngs = {};
            let cursor = 0;
            _.each(lines, function (line) {

                let latlngs = polyUtil.decode(line.line, 5);
                let polyline = new L.Polyline(polyUtil.decode(line.line, 5), {
                    //color: '#008c96',
                    color: '#008c96',
                    weight: 2
                    // offset :0
                });
                if (index == 0) {
                    setCurrentTrack(polyline.getLatLngs()[0]);
                }
                polyliness.push(latlngs);
                let diff = 0;
                if (index == lines.length-1) {
                    diff = 0;
                } else {
                    diff = moment(lines[index + 1].date).unix() - moment(lines[index].date).unix();
                }
                let diffByInde = round(diff/latlngs.length);
                let time = moment(lines[index].date).unix();

                _.each(polyline.getLatLngs(), function (latlng) {
                    time = time+diffByInde;
                    polylineObjectArray.push(latlng);
                    polylinesArray.push({lat: latlng[0], lng: latlng[1]});
                    timess.push(time);
                });
                //polylinesss(polyline.getLatLngs())
                index++;
            });
            setPolylines(polyliness);
            limitRef.current = polylineObjectArray.length - 1;
            if (sliderRef.current && polylineObjectArray && polylineObjectArray.length > 0) {
                sliderRef.current.options.max = (polylineObjectArray.length - 1);
            }
            cursorRef.current = cursor;
            if (!document.getElementById('slider'))
            {sliderRef.current = null};

            interval = setInterval(() => {
                if (cursor === polylineObjectArray.length - 1) {
                    clearInterval(interval);
                    setIsFinished(true);
                    return;
                }
                if (cursorRef.current == cursor) {
                    cursor += 1;
                    cursorRef.current = cursor;
                } else {
                    cursor = cursorRef.current;
                }
                setCurrentTrack(polylineObjectArray[cursor]);
                intervalRef.current = interval;
                currentDate.current = timess[cursor];
                if (polylineObjectArray && polylineObjectArray.length > 0) {
                    sliderRef.current.setValue(cursor);
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
            // intervalRef.current = interval;
        } else{
            if (load == false) {
                props.setDataFetched(2);
                closeMenu();
            }
        }
    }, [lines]);

    if (load) return <FuseLoading />;
    if (lines && lines.length == 0) return <FuseLoading />;
    return (
        <div>
            <MapContainer
                style={{ height: "510px", width: "100%" }}
                center={[53.49573, 8.62839]}
                zoom={12}
                minZoom={1}
                whenCreated={setMap}
            ><Fullscreen/>
                <Control position='bottomleft'>
                    <Button color='inherit' onClick={() => jumpBack()}>
                        <SkipPrevious />
                    </Button>
                    {isStopped=== false &&  <Button color='inherit' onClick={() => pauseAndStop(true)}>
                        <Pause/></Button>
                        }
                        {isStopped &&  <Button color='inherit' onClick={() => resumeAndPlay(true)}>
                            <PlayArrow/>
                    </Button>}
                    {isFinished=== false &&  <Button color='inherit' onClick={() => pauseAndStop(false)}>
                        <Stop/></Button>
                    }
                    {isFinished &&  <Button color='inherit' onClick={() => resumeAndPlay(false)}>
                        <Replay/>
                    </Button>}
                    <Button color='inherit' onClick={() => jumpUp()}>
                        <SkipNext />
                    </Button>
                </Control>
                <Control position='topright'>
                    <Button color='inherit' onClick={() => closeMenu()}>
                        <Close />
                    </Button>
                </Control>
                <MyComponent/><Legend map={map} />
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {polylines && polylines.length > 0 &&<AirplaneMarker data={currentTrack ?? {}} />}
                {polylines.length > 0 && polylines.map((polyline,index) => (
                    <Polyline key={index} positions={polyline} color="blue"/>
                ))}
            </MapContainer>
        </div>
    );

    function MyComponent() {
        if (sliderRef.current == null) {
            var slider2 = L.control.slider(function (value) {
                cursorRef.current = parseInt(value);
            }, {
                position: 'bottomleft',
                max: limitRef.current,
                value: 0,
                step: 1,
                size: '230px',
                orientation: 'horizontal',
                id: 'slider',
                syncSlider: true,
                logo: '',
                getValue: function (value) {
                    return t('fleet_tracking_mileage_date')+' :'+ moment.unix(currentDate.current).format("YYYY-MM-DD HH:mm:ss") ;
                }
            });
            if (map) {
                slider2.addTo(useMap());
                sliderRef.current = slider2;
            }
        }
        return null;
    }

    function pauseAndStop(isStop) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        if (isStop) {
            setIsStopped(true);
        } else {
            setIsFinished(true);
            cursorRef.current = 0;
        }
        _.each(lines, function (line) {

            let latlngs = polyUtil.decode(line.line, 5);
            let polyline = new L.Polyline(polyUtil.decode(line.line, 5), {
                //color: '#008c96',
                color: '#008c96',
                weight: 2
                // offset :0
            });
            if (isIndexSet) {
                if (index == cursorRef.current) {
                    setCurrentTrack(polyline.getLatLngs()[0]);
                }
            } else {
                if (index == 0) {
                    setCurrentTrack(polyline.getLatLngs()[0]);
                }
            }
        });
    }

    function closeMenu() {
        props.setDisplayMapFleet(false);
    }

    function resumeAndPlay(isIndexSet) {
        setIsStopped(false);
        setIsFinished(false);
        if (lines && lines.length > 0) {
            let polylinesArray = [];
            let polylineObjectArray = [];
            let polyliness = [];
            let polylinesss = [];
            let timess = [];
            index = 0;
            let latlngs = {};

            let cursor = 0;
            if (isIndexSet) {
                cursor = cursorRef.current;
            }
            _.each(lines, function (line) {

                let latlngs = polyUtil.decode(line.line, 5);
                let polyline = new L.Polyline(polyUtil.decode(line.line, 5), {
                    //color: '#008c96',
                    color: '#008c96',
                    weight: 2
                    // offset :0
                });
                if (isIndexSet) {
                    if (index == cursorRef.current) {
                        setCurrentTrack(polyline.getLatLngs()[0]);
                    }
                } else {
                    if (index == 0) {
                        setCurrentTrack(polyline.getLatLngs()[0]);
                    }
                }
                polyliness.push(latlngs);
                let diff = 0;
                if (index == lines.length-1) {
                    diff = 0;
                } else {
                    diff = moment(lines[index + 1].date).unix() - moment(lines[index].date).unix();
                }
                let diffByInde = round(diff/latlngs.length);
                let time = moment(lines[index].date).unix();

                _.each(polyline.getLatLngs(), function (latlng) {
                    time = time+diffByInde;
                    //console.log("new time" + time);
                    polylineObjectArray.push(latlng);
                    polylinesArray.push({lat: latlng[0], lng: latlng[1]});
                    timess.push(time);
                });
                //polylinesss(polyline.getLatLngs())
                index++;
            });
            setPolylines(polyliness);
            limitRef.current = polylineObjectArray.length - 1;
            if (sliderRef.current && polylineObjectArray && polylineObjectArray.length > 0) {
                sliderRef.current.options.max = (polylineObjectArray.length - 1);
            }
            cursorRef.current = cursor;
            if (!document.getElementById('slider'))
            {sliderRef.current = null};
            interval = setInterval(() => {
                if (cursor === polylineObjectArray.length - 1) {
                    clearInterval(interval);
                    setIsFinished(true);
                    return;
                }
                if (cursorRef.current == cursor) {
                    cursor += 1;
                    cursorRef.current = cursor;
                } else {
                    cursor = cursorRef.current;
                }
                setCurrentTrack(polylineObjectArray[cursor]);
                intervalRef.current = interval;
                currentDate.current = timess[cursor];
                if (polylineObjectArray && polylineObjectArray.length > 0) {
                    sliderRef.current.setValue(cursor);
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
            // intervalRef.current = interval;
        }
    }

    function jumpUp() {
        if (cursorRef.current +10 < limitRef.current) {
            cursorRef.current += 10;
        }
    }

    function jumpBack() {
        if (cursorRef.current -10 > 0) {
            cursorRef.current -= 10;
        }
    }
    function distance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }


}
