import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProfilePictureUrl,
} from "../../main/settings-profile/store/UserSlice";
import { useEffect, useState } from "react";
import apiUrlConfig from "../../apiUrlConfig";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    background: theme.palette.background.default,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));
function UserNavbarHeader(props) {
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  const profilePicture = useSelector(selectProfilePictureUrl);

  return (
    <StyledAppBar
      position="static"
      color="primary"
      className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
    >
      <Typography
        className="username text-18 whitespace-nowrap font-semibold mb-4"
        color="inherit"
      >
        {user.data.displayName}
      </Typography>
      <Typography
        className="email text-13 opacity-50 whitespace-nowrap font-medium"
        color="inherit"
      >
        {user.data.email}
      </Typography>
      <div className="flex items-center justify-center absolute bottom-0 -mb-44">
        <Avatar
          className="avatar w-96 h-92 box-content"
          alt="user photo"
          src={
            profilePicture
              ? `${apiUrlConfig.USER_PHOTO}/${profilePicture}`
              : user.profilePicture && user.profilePicture != ""
              ? `${apiUrlConfig.USER_PHOTO}/${user.profilePicture}`
              : "assets/images/avatars/noImage.jpg"
          }
        />
      </div>
    </StyledAppBar>
  );
}

export default UserNavbarHeader;
