import _ from "lodash";
import moment from "moment";
import KocoUtils from "./KocoUtils";

export const COLUMN_TYPES = {

    REMAIN_BODY_WORKING_HOURS: 1,
    ERROR_CODES: 2,
    BODY_FILL_LEVEL: 3,
    BODY_PUMP_FILL_LEVEL: 4,
    LEFT_FOOTSTEP_ACTIVE: 5,
    RIGHT_FOOTSTEP_ACTIVE: 6,
    LIFTER_REMAIN_WORKING_HOURS: 7,
    CHASSIS_DIESEL: 8,
    CHASSIS_AD_BLUE: 9,
    ENGINE_COOLANT_TEMP_WARNING: 10,
    ENGINE_OIL_PRESSURE_WARNING: 11,
    HS_MEETING_GAS_MEETING: 12,
    TACHO_MEETING: 13,
    SECURITY_MEEETING: 14,
    UVV_MEETING: 15,
    FEHLER_MELDUNGEN: 16,
    LAST_RECEIVED_DATA: 17,

    REMAIN_BODY_WORKING_HOURS_SWEEPER: 21,
    WATER_TANK_LEVEL_SWEEPER: 22,
    TIME_TO_NEXT_SERVICE_AUX_ENGINE_SWEEPER: 23,
    MOTOR_ERROR_SWEEPER: 24,
    BODY_MOTOR_DIESEL_SWEEPER: 25,
    DIESEL_SWEEPER: 26,
    AD_BLUE_SWEEPER: 27,
    CHASSIS_ERROR_SWEEPER: 28,
    CHASSIS_REMAIN_WORKING_HOURS_SWEEPER: 29,
};

const OFFSETS = {
    HS_MEETING_GAS_MEETING_OFFSET: 12,
    TACHO_MEETING_OFFSET: 24,
    SECURITY_MEEETING_OFFSET: 30,
    UVV_MEETING_OFFSET: 12,
};

const logicRemainWorkingHours = (a) => {
    var icon = 'grey';
    if (a <= 0) {
        icon = 'red';
    } else if (a <= 150) {
        icon = 'yellow';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicRemainWorkingHoursSweeper = (a) => {
    var icon = 'grey';
    if (a <= 0) {
        icon = 'red';
    } else if (a <= 50) {
        icon = 'yellow';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicDataBoolean = (a) => {
    var icon = 'grey';
    if (a > 1) {
        icon = 'red';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicBodyValueFullPump = (a) => {
    var icon = 'grey';
    if (a == 0) {
        icon = 'green';
    }else if (a == 1) {
        icon = 'red';
    } 
    // else if (a == 2) {
    //     icon = 'red';
    // } 
    else {
        icon = 'grey';
    }
    return icon;
};

const logicBodyValueFullstand = a => {

    var icon = 'grey';
    if (a == 0) {
        icon = 'green';
    }else if (a == 1) {
        icon = 'red';
    } 
    // else if (a == 2) {
    //     icon = 'red';
    // } 
    else {
        icon = 'grey';
    }
    return icon;
};

const logicForData = (a) => {
    var icon = 'grey';
    if (a === 255) {
        return icon;
    }
    if (a < 10) {
        icon = 'red';
    } else if (a < 40) {
        icon = 'yellow';
    } else {
        icon = 'green';
    }
    return icon;
}
const logicForChassisDiesel = (a, b) => {
    var icon = 'grey';
    if (b === 255) {
        return icon;
    }
    if (a === 0) {
        icon = 'green';
    } else if (a === 1) {
        icon = 'yellow';
    } else if (a === 2) {
        icon = 'red';
    }
    return icon;
};

const logicEngineCoolantTempWarning = (a, b) => {
    var icon = 'grey';
    if (b === 65280) {
        return icon;
    }
    if (a === 0) {
        icon = 'green';
    } else if (a === 1) {
        icon = 'yellow';
    } else if (a === 2) {
        icon = 'red';
    }
    return icon;
};

const logicEngineOilPressureWarning = (a, b) => {
    var icon = 'yellow';
    if (b === 255) {
        icon = 'grey';
        return icon;
    }
    if (a === 0) {
        icon = 'green';
    } else if (a === 1) {
        icon = 'red';
    } else if (a === 2 || a === 3) {
        icon = 'grey';
    }
    return icon;
};

const logicLeftFootstepActive = (a) => {
    var icon = 'yellow';
    if (a === 0 || a === 1) {
        icon = 'green';
    } else if (a === 2) {
        icon = 'red';
    } else if (a === 3) {
        icon = 'grey';
    }
    return icon;
};

const logicRightFootstepActive = (a) => {
    var icon = 'yellow';
    if (a === 0 || a === 1) {
        icon = 'green';
    } else if (a === 2) {
        icon = 'red';
    } else if (a === 3) {
        icon = 'grey';
    }
    return icon;
};

const logicFirstInstallation = (a, offset) => {
    var icon = 'grey';
    var diffInMonths = moment(a, 'DD.MM.YYYY').add(offset, 'months').diff(moment(), 'month');
    if (diffInMonths > 1) {
        icon = 'green';
        return icon;
    } else if (diffInMonths <= 1 && diffInMonths > 0) {
        icon = 'yellow';
        return icon;
    } else if (diffInMonths <= 0) {
        icon = 'red';
        return icon;
    }
    return icon;
};

const logicErrorCode = (a) => {
    var icon = 'grey';

    if (a == 0) {
        icon = 'green';
    } else if (a >= 1) {
        icon = 'red';
    }
    return icon;
};



const lastUpdate = (a) => {
    var icon = 'grey';
    if (!a)
        return icon;

    var ms = moment().diff(moment(a));

    if (ms < 1000 * 60 * 5) {
        icon = 'green';
    } else if (ms < 1000 * 60 * 10) {
        icon = 'yellow';
    }

    return icon;
};

const logicBodyWaterTankLevelSweeper = (a) => {
    var icon = 'grey';
    if (a <= 5) {
        icon = 'red';
    } else if (a <= 10) {
        icon = 'yellow';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicTimeToNextServiceAuxEngineSweeper = (a) => {
    var icon = 'grey';
    if (a <= 0) {
        icon = 'red';
    } else if (a <= 50) {
        icon = 'yellow';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicBodyMotorDieselSweeper = (a) => {
    var icon = 'grey';
    if (a <= 10) {
        icon = 'red';
    } else if (a <= 40) {
        icon = 'yellow';
    } else if (a == 255) {
        icon = 'grey';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicDieselSweeper = (a) => {
    var icon = 'grey';
    if (a <= 10) {
        icon = 'red';
    } else if (a <= 40) {
        icon = 'yellow';
    } else if (a == 255) {
        icon = 'grey';
    } else {
        icon = 'green';
    }
    return icon;
};

const logicAdBlueSweeper = (a) => {
    var icon = 'grey';
    if (a <= 10) {
        icon = 'red';
    } else if (a <= 40) {
        icon = 'yellow';
    } else if (a == 255) {
        icon = 'grey';
    } else {
        icon = 'green';
    }
    return icon;
};

const remainWorkingHoursTooltip = (color) => {
    return 'service_dashboard_rcv_body_maintenance';
};
// const remainWorkingHoursSweeperTooltip = (color) => {
//     return 'service_dashboard_rcv_body_maintenance';
// };

const errorCodesTooltip = (icon) => {
    if (color === 'grey')
        return 'overview_menu_hint_error_message_green';
    else if (color === 'yellow')
        return ''; // t('overview_menu_hint_error_message_green');
    else if (color === 'green')
        return 'overview_menu_hint_error_message_green';
    else if (color === 'red')
        return 'overview_menu_hint_error_message_red';
    else
        return 'overview_menu_hint_error_message_green';
};

const bodyFillLevelTooltip = (icon) => {
    return 'service_dashboard_rcv_body_fill_level';
};
const bodyPumpFillLevelTooltip = (icon) => {
    return 'service_dashboard_rcv_body_pump';
};
const leftFootstepActiveTooltip = (icon) => {
    return 'service_dashboard_rcv_body_warning_left';
};
const rightFootstepActiveTooltip = (icon) => {
    return 'service_dashboard_rcv_body_warning_right';
};
const lifterRemainWorkingHoursTooltip = (icon) => {
    return 'service_dashboard_rcv_lifter_warning';
};
const chassisDieselTooltip = (icon) => {
    return 'service_dashboard_rcv_chassis_diesel';
};
const chassisAdBlueTooltip = (icon) => {
    return 'service_dashboard_rcv_chassis_adblue';
};
const engineCooltantTempWarningTooltip = (icon) => {
    return 'service_dashboard_rcv_chassis_motor_temperature';
};
const engineOilPressureWarningTooltip = (icon) => {
    return 'service_dashboard_rcv_chassis_motor_pressure';
};
const hsMeetingGasMeetingTooltip = (icon) => {
    return 'service_dashboard_rcv_examination_general';
};
const tachoMeetingTooltip = (icon) => {
    return 'service_dashboard_rcv_examination_tacho';
};
const securityMeetingTooltip = (icon) => {
    return 'service_dashboard_rcv_examination_security';
};
const uvvMeetingTooltip = (icon) => {
    return 'service_dashboard_rcv_examination_uvv';
};
const felhlerMeldungenTooltip = (icon) => {
    if (icon === 'grey')
        return 'overview_menu_hint_error_message_green';
    else if (icon === 'yellow')
        return ''; // t('overview_menu_hint_error_message_green');
    else if (icon === 'green')
        return 'overview_menu_hint_error_message_green';
    else if (icon === 'red')
        return 'overview_menu_hint_error_message_red';
    else
        return 'overview_menu_hint_error_message_green';
};
const lastreceivedTooltip = (icon) => {
    return 'last received data';
};
const remainBodyWorkingHoursSweeeperTooltip = (icon) => {
    return 'service_dashboard_rcv_body_maintenance';
};
const WaterTankLevelSweeperTooltip = (unit, icon) => {

    if (icon == 'grey')
        return 'cockpit_tab_default_not_available';
    else if (icon == 'red')
        return '<= 5%';
    else if (icon == 'yellow')
        return '<= 10%';
    else if (icon == 'green')
        return '> 10%';
};
const timeToNextServiceAuxEngineSweeperTooltip = (icon) => {

    if (icon == 'grey')
        return 'cockpit_tab_default_not_available';
    else if (icon == 'red')
        return '<= 0%';
    else if (icon == 'yellow')
        return '<= 50%';
    else if (icon == 'green')
        return '> 50%';
};
const motorErrorSweeperTooltip = (icon) => {
    return 'service_dashboard_sweeper_body_error_motor';
};
const bodyMotorDieselSweeperTooltip = (icon) => {
    return 'service_dashboard_sweeper_body_fill_level_diesel';
};
const dieselSweeperTooltip = (icon) => {
    return 'service_dashboard_sweeper_chassis_maintenance';
};
const adBlueSweeperTooltip = (icon) => {
    return 'service_dashboard_rcv_chassis_adblue';
};
const chassisErrorSweeperTooltip = (icon) => {
    return 'service_dashboard_rcv_body_error';
};
const chassisRemainWorkingHoursSweeperTooltip = (icon) => {
    return 'service_dashboard_sweeper_chassis_maintenance';
};

class ServiceDashboardUtils {

    static getTooltip = (unit, type, icon) => {

        if (!unit || !type)
            return '';

        switch (type) {
            case COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS:
                return remainWorkingHoursTooltip(icon);
            case COLUMN_TYPES.ERROR_CODES:
                return errorCodesTooltip(icon);
            case COLUMN_TYPES.BODY_FILL_LEVEL:
                return bodyFillLevelTooltip(icon);
            case COLUMN_TYPES.BODY_PUMP_FILL_LEVEL:
                return bodyPumpFillLevelTooltip(icon);
            case COLUMN_TYPES.LEFT_FOOTSTEP_ACTIVE:
                return leftFootstepActiveTooltip(icon);
            case COLUMN_TYPES.RIGHT_FOOTSTEP_ACTIVE:
                return rightFootstepActiveTooltip(icon);
            case COLUMN_TYPES.LIFTER_REMAIN_WORKING_HOURS:
                return lifterRemainWorkingHoursTooltip(icon);
            case COLUMN_TYPES.CHASSIS_DIESEL:
                return chassisDieselTooltip(icon);
            case COLUMN_TYPES.CHASSIS_AD_BLUE:
                return chassisAdBlueTooltip(icon);
            case COLUMN_TYPES.ENGINE_COOLANT_TEMP_WARNING:
                return engineCooltantTempWarningTooltip(icon);
            case COLUMN_TYPES.ENGINE_OIL_PRESSURE_WARNING:
                return engineOilPressureWarningTooltip(icon);
            case COLUMN_TYPES.HS_MEETING_GAS_MEETING:
                return hsMeetingGasMeetingTooltip(icon);
            case COLUMN_TYPES.TACHO_MEETING:
                return tachoMeetingTooltip(icon);
            case COLUMN_TYPES.SECURITY_MEEETING:
                return securityMeetingTooltip(icon);
            case COLUMN_TYPES.UVV_MEETING:
                return uvvMeetingTooltip(icon);
            case COLUMN_TYPES.FEHLER_MELDUNGEN:
                return felhlerMeldungenTooltip(icon);
            case COLUMN_TYPES.LAST_RECEIVED_DATA:
                return lastreceivedTooltip(icon);
            case COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS_SWEEPER:
                return remainBodyWorkingHoursSweeeperTooltip(icon);
            case COLUMN_TYPES.WATER_TANK_LEVEL_SWEEPER:
                return WaterTankLevelSweeperTooltip(unit, icon);
            case COLUMN_TYPES.TIME_TO_NEXT_SERVICE_AUX_ENGINE_SWEEPER:
                return timeToNextServiceAuxEngineSweeperTooltip(icon);
            case COLUMN_TYPES.MOTOR_ERROR_SWEEPER:
                return motorErrorSweeperTooltip(icon);
            case COLUMN_TYPES.BODY_MOTOR_DIESEL_SWEEPER:
                return bodyMotorDieselSweeperTooltip(icon);
            case COLUMN_TYPES.DIESEL_SWEEPER:
                return dieselSweeperTooltip(icon);
            case COLUMN_TYPES.AD_BLUE_SWEEPER:
                return adBlueSweeperTooltip(icon);
            case COLUMN_TYPES.CHASSIS_ERROR_SWEEPER:
                return chassisErrorSweeperTooltip(icon);
            case COLUMN_TYPES.CHASSIS_REMAIN_WORKING_HOURS_SWEEPER:
                return chassisRemainWorkingHoursSweeperTooltip(icon);
        };

    };

    static getSummaryIndicatorColor = (unit) => {

        if (unit.isRcv == true) {
            return this.getIndicatorColor(unit,
                [COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS,
                COLUMN_TYPES.FEHLER_MELDUNGEN,
                COLUMN_TYPES.BODY_FILL_LEVEL,
                COLUMN_TYPES.BODY_PUMP_FILL_LEVEL,
                COLUMN_TYPES.LEFT_FOOTSTEP_ACTIVE,
                COLUMN_TYPES.RIGHT_FOOTSTEP_ACTIVE,
                COLUMN_TYPES.LIFTER_REMAIN_WORKING_HOURS,
                COLUMN_TYPES.CHASSIS_DIESEL,
                COLUMN_TYPES.CHASSIS_AD_BLUE,
                COLUMN_TYPES.ENGINE_COOLANT_TEMP_WARNING,
                COLUMN_TYPES.ENGINE_OIL_PRESSURE_WARNING,
                COLUMN_TYPES.HS_MEETING_GAS_MEETING,
                COLUMN_TYPES.TACHO_MEETING,
                COLUMN_TYPES.SECURITY_MEEETING,
                COLUMN_TYPES.UVV_MEETING], true
            );
        }
        else {
            return this.getIndicatorColor(unit,
                [COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS_SWEEPER,
                COLUMN_TYPES.FEHLER_MELDUNGEN,
                COLUMN_TYPES.WATER_TANK_LEVEL_SWEEPER,
                COLUMN_TYPES.TIME_TO_NEXT_SERVICE_AUX_ENGINE_SWEEPER,
                COLUMN_TYPES.MOTOR_ERROR_SWEEPER,
                COLUMN_TYPES.BODY_MOTOR_DIESEL_SWEEPER,
                COLUMN_TYPES.CHASSIS_REMAIN_WORKING_HOURS_SWEEPER,
                COLUMN_TYPES.CHASSIS_ERROR_SWEEPER,
                COLUMN_TYPES.CHASSIS_DIESEL,
                COLUMN_TYPES.CHASSIS_AD_BLUE,
                COLUMN_TYPES.HS_MEETING_GAS_MEETING,
                COLUMN_TYPES.TACHO_MEETING], true
            );
        }
    };

    static getIndicatorColor = (unit, types, withCount) => {
        if (!unit || !types || types.length == 0)
            return 'grey';

        let isRedFound = 0;
        let isYellowFound = 0;
        let isGreenFound = 0;

        let icons = [];
        let icon = 'grey';
        let ic = 'grey';
        let a, b, val;

        _.forEach(types, type => {
            switch (type) {

                case COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS:
                    ic = 'grey';
                    if (unit.bodyRemainWorkingHours) {
                        a = parseFloat(unit.bodyRemainWorkingHours);
                        ic = logicRemainWorkingHours(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS_SWEEPER:
                    ic = 'grey';
                    if (unit.bodyRemainWorkingHoursSweeper) {
                        a = parseFloat(unit.bodyRemainWorkingHoursSweeper);
                        ic = logicRemainWorkingHoursSweeper(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.BODY_FILL_LEVEL:
                    ic = 'grey';
                    if (unit.bodyValueFullStand) {
                        a = parseFloat(unit.bodyValueFullStand);
                        ic = logicBodyValueFullstand(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.BODY_PUMP_FILL_LEVEL:
                    ic = 'grey';
                    if (unit.bodyValueFullPump) {
                        a = parseFloat(unit.bodyValueFullPump);
                        ic = logicBodyValueFullPump(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.LIFTER_REMAIN_WORKING_HOURS:
                    ic = 'grey';
                    if (unit.lifterRemainWorkingHours) {
                        a = parseInt(unit.lifterRemainWorkingHours);
                        if (a > 2147483647) {
                            var hex = (a.toString(16));
                            a = KocoUtils.hexToSignedInt(hex);
                        } else {
                            a = a - 1;
                        }
                        let y = a / 3600;
                        ic = logicRemainWorkingHours(y);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.CHASSIS_AD_BLUE:
                    ic = 'grey';
                    if (unit.chassisAdBlueLevel) {
                        a = parseFloat(unit.chassisAdBlueLevel);
                        if (a === 255) {
                            val = "";
                        }
                        ic = logicForData(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.CHASSIS_DIESEL:
                    ic = 'grey';
                    if (unit.chassisDiesel) {
                        a = parseFloat(unit.chassisDieselWarning);
                        b = parseFloat(unit.chassisDiesel);
                        if (b === 255) {
                            val = "";
                        }
                        ic = logicForChassisDiesel(a, b);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.SECURITY_MEEETING:
                    ic = 'grey';
                    if (unit.firstInstallation) {
                        ic = logicFirstInstallation(unit.firstInstallation, OFFSETS.SECURITY_MEEETING_OFFSET);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.HS_MEETING_GAS_MEETING:
                    ic = 'grey';
                    if (unit.firstInstallation) {
                        ic = logicFirstInstallation(unit.firstInstallation, OFFSETS.HS_MEETING_GAS_MEETING_OFFSET);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.TACHO_MEETING:
                    ic = 'grey';
                    if (unit.firstInstallation) {
                        ic = logicFirstInstallation(unit.firstInstallation, OFFSETS.TACHO_MEETING_OFFSET);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.UVV_MEETING:
                    ic = 'grey';
                    if (unit.firstInstallation) {
                        ic = logicFirstInstallation(unit.firstInstallation, OFFSETS.UVV_MEETING_OFFSET);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.ERROR_CODES:
                    ic = 'grey';
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.ENGINE_COOLANT_TEMP_WARNING:
                    ic = 'grey';
                    if (unit.engineCoolantTempWarning) {
                        a = parseFloat(unit.engineCoolantTempWarning);
                        b = parseFloat(unit.engineCoolantTemp);
                        if (b === 65280) {
                            val = "";
                        }
                        ic = logicEngineCoolantTempWarning(a, b);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.ENGINE_OIL_PRESSURE_WARNING:
                    ic = 'grey';
                    if (unit.engineOilPressureWarning) {
                        a = parseFloat(unit.engineOilPressureWarning);
                        b = parseFloat(unit.engineOilPressure);
                        if (b === 255) {
                            val = "";
                        }
                        ic = logicEngineOilPressureWarning(a, b);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.LEFT_FOOTSTEP_ACTIVE:
                    ic = 'grey';
                    if (unit.leftFootstepActive) {
                        a = parseFloat(unit.leftFootstepActive);
                        ic = logicLeftFootstepActive(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.RIGHT_FOOTSTEP_ACTIVE:
                    ic = 'grey';
                    if (unit.rightFootstepActive) {
                        a = parseFloat(unit.rightFootstepActive);
                        ic = logicRightFootstepActive(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.FEHLER_MELDUNGEN:
                    ic = 'grey';
                    ic = logicErrorCode(unit.activeErrorsCount);
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.LAST_RECEIVED_DATA:
                    ic = 'grey';

                    // attributes.greentooltip = unit.lastUpdateString;
                    // attributes.redtooltip = unit.lastUpdateString
                    // attributes.yellowtooltip = unit.lastUpdateString
                    // attributes.greytooltip = unit.lastUpdateString

                    ic = lastUpdate(unit.updateDate);
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.WATER_TANK_LEVEL_SWEEPER:
                    ic = 'grey';
                    if (unit.bodyWaterTankLevelSweeper) {
                        a = parseFloat(unit.bodyWaterTankLevelSweeper);
                        ic = logicBodyWaterTankLevelSweeper(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.TIME_TO_NEXT_SERVICE_AUX_ENGINE_SWEEPER:
                    ic = 'grey';
                    if (unit.timeToNextServiceAuxEngineSweeper) {
                        a = parseFloat(unit.timeToNextServiceAuxEngineSweeper);
                        ic = logicTimeToNextServiceAuxEngineSweeper(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.MOTOR_ERROR_SWEEPER:
                    ic = 'grey';
                    // if (unit.) {
                    //     a = parseFloat(unit.);
                    //     ic = logicMotorErrorSweeper(a);
                    // }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.BODY_MOTOR_DIESEL_SWEEPER:
                    ic = 'grey';
                    if (unit.bodyMotorDieselSweeper) {
                        a = parseFloat(unit.bodyMotorDieselSweeper);
                        ic = logicBodyMotorDieselSweeper(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.DIESEL_SWEEPER:
                    ic = 'grey';
                    if (unit.dieselSweeper) {
                        a = parseFloat(unit.dieselSweeper);
                        ic = logicDieselSweeper(a);
                    }
                    icons.push(ic);
                    break;
                case COLUMN_TYPES.AD_BLUE_SWEEPER:
                    ic = 'grey';
                    if (unit.adBlueSweeper) {
                        a = parseFloat(unit.adBlueSweeper);
                        ic = logicAdBlueSweeper(a);
                    }
                    icons.push(ic);
                    break;
                default:
                    ic = 'grey';
                    icons.push(ic);
                    break;
            }
        });

        icons.forEach(function (icon) {
            if (icon == 'red') {
                isRedFound++;
            }
            else if (icon == 'yellow') {
                isYellowFound++;
            }
            else if (icon == 'green') {
                isGreenFound++;
            }
        });

        if (isRedFound > 0) {
            icon = 'red';
        } else if (isYellowFound > 0) {
            icon = 'yellow';
        } else if (isGreenFound > 0) {
            icon = 'green';
        } else {
            icon = 'grey';
        }

        if(withCount != true)
          return icon;
        
        return {icon: icon, red: isRedFound, yellow: isYellowFound, green: isGreenFound};

    }


}

export default ServiceDashboardUtils;