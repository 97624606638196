import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Paper from "@mui/material/Paper";
function SensorWidget(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    let {load, markerSet } = useSelector(({ main }) => main.scantec.positionScantec);
    const { t } = useTranslation('common');
    let isSet = false;
    let color ="#228B22";

    if (load) return <FuseLoading />;
    return <Paper className="w-full rounded-20 shadow flex flex-col justify-between">
        <div className="flex items-center justify-between px-4 pt-8">
            <Typography className="text-16 px-16 font-medium" color="textSecondary">
                {t('scantec_sensor_data')}
            </Typography>
            {/* <IconButton aria-label="more" size="large">
          <Icon>more_vert</Icon>
        </IconButton> */}
        </div>
        <div className="text-center py-12">
            <Typography
                className={
                        'text-72 font-semibold leading-none tracking-tighter'}>
                {markerSet.measurement ? markerSet.measurement: 0 }%
            </Typography>
        </div>
        <Typography
            className="p-20 pt-0 h-56 flex justify-center items-end text-13 font-medium"
            color="textSecondary"
        >
            {markerSet && markerSet.date&& <span className="truncate"><Moment unix local={"de"} format="DD.MM.YYYY HH:mm">{markerSet.date.sec}</Moment></span>}
            {/* <b className="px-8">{props.widget.data.extra.count}</b> */}
        </Typography>
    </Paper>
}

export default SensorWidget;
