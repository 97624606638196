import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { memo, default as React } from "react";
import ReactApexChart from "react-apexcharts";
import _ from "@lodash";
import Moment from "react-moment";
import { floor } from "lodash/math";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../css/graphs.css";
import { Skeleton } from "@mui/material";

function MileageWidget(props) {
  const theme = useTheme();
  const data = _.merge({}, props.data);
  _.setWith(data, "options.colors", [theme.palette.secondary.main]);
  const { t } = useTranslation("common");
  let mileage = _.find(props.data, (element) => {
    // You can put the required conditions here.
    return element.signalId == "30201" ||
      element.signalId == "110202" ||
      element.signalId == "20401"
      ? true
      : false;
  });
  let data2 = {};
  if (mileage) {
    let data = generateDayWiseTimeSeries(
      mileage.array,
      mileage.lastValue,
      mileage.signalId
    );

    data2 = {
      series: [
        {
          name: t("overview_menu_km_total_widget"),
          data: data.series,
        },
      ],
      options: {
        chart: {
          stacked: true,
          events: {
            selection: function (chart, e) {
            },
            mouseMove: function (event, chartContext, config) {},
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
        },
        colors: ["#666d78", "#00E396", "#CED4DC"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            left: -20,
            right: -2,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          show: false,
        },
        yaxis: {
          show: false,
          floating: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: "datetime",
          show: false,
          labels: {
            show: false,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("HH:mm"); // formats to hours:minutes
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          crosshairs: [true],
          formatter: function () {
            return (
              "Datetime: " +
              Moment.utc(Moment.unix(this.x / 1000)).format("HH:mm") +
              "<br> Ad-Blue: " +
              this.y
            );
          },
        },
      },
    };
  }
  if (props.loading) {
    mileage = null;
    return (
      <Card className="w-full rounded-20 shadow">
        <div className="w-full isolate ">
          <Skeleton className="w-1/3 h-36 m-10" variant="recangular" />
          <Skeleton className="h-96 m-10" variant="rectangular" />
        </div>
      </Card>
    );
  }
  if (!mileage) {
    return (
      <Card className="w-full rounded-20 shadow">
        <div className="p-20 pb-0">
          <Typography className="h3 font-medium">
            {t("overview_menu_km_total_widget")}
          </Typography>

          <div className="flex flex-row flex-wrap items-center mt-12">
            <Typography className="text-36 font-semibold leading-none tracking-tighter">
              {t("cockpit_tab_default_not_available")}
            </Typography>
          </div>
        </div>
        <div className="h-96 w-100-p empty-chart"></div>
      </Card>
    );
  }
  return (
    <Card className="w-full rounded-20 shadow">
      <div className="p-20 pb-0">
        <Typography className="h3 font-medium">
          {t("overview_menu_km_total_widget")}
        </Typography>

        <div className="flex flex-row flex-wrap items-center mt-12">
          {(mileage.signalId == "30201" || mileage.signalId == "20401") &&
            mileage.diff > 1000 && (
              <Typography className="text-44 font-semibold leading-none tracking-tighter ">
                {floor(mileage.diff / 1000)}km
              </Typography>
            )}
          {(mileage.signalId == "30201" || mileage.signalId == "20401") &&
            mileage.diff < 1000 && (
              <Typography className="text-44 font-semibold leading-none tracking-tighter ">
                {Math.round(mileage.diff / 1000).toFixed(1)}km
              </Typography>
            )}
          {mileage.signalId == "110202" && (
            <Typography className="text-44 font-semibold leading-none tracking-tighter ">
              {Math.round(mileage.diff)}km
            </Typography>
          )}

          <div className="flex flex-col mx-5">
            <div className="flex items-center">
              <Typography className="font-semibold" color="textSecondary">
                <Moment unix local={"de"} format="DD.MM.YYYY HH:mm">
                  {mileage.date}
                </Moment>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="h-96 w-100-p pt-10">
        <ReactApexChart
          options={data2.options}
          series={data2.series}
          type="area"
          height={"100px"}
        />
      </div>
    </Card>
  );
}
export default MileageWidget;

function generateDayWiseTimeSeries(data, lastValue, signalId) {
  let i = 0;
  var series = [];
  var times = [];
  let firstValue = 0;
  let y = 0;
  let x = 0;
  const myOrderedArray = _.sortBy(data, function (o) {
    return o.date;
  });

  _.each(myOrderedArray, function (e) {
    if (firstValue == 0) {
      firstValue = e.value;
    }
    y =
      signalId == "110202"
        ? floor(e.value - firstValue)
        : floor((e.value - firstValue) / 1000);
    if (y > 0) {
      if (times.indexOf(e.date * 1000) == -1) {
        x = e.date * 1000;
        times.push(x);
        series.push([x, y]);
      }
    }
  });
  return { series: series, firstValue: firstValue, lastValue: y, lastDate: x };
}
