import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import FosService from 'app/services/fosService';

export const getUnitFleetTracking = createAsyncThunk('fleetTracking/units/getUnits', async () => {
  const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: params, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  FosService.setLoggedIn(false);
  return data.units.map(val => { val.title= (val.nameKoco ? val.nameKoco: "test");
    val.value = val.id;val.isVisible= true;val.isSelected = 0;val.isDriving = 0; return val;});
});

export const getPassiveVehicleGroups = createAsyncThunk('fleetTracking/units/getPassiveVehicleGroups', async () => {
  const response = await axios.get(`api/v1/passivevehiclegroups`, { params: { limit: 0 }});
  const data = await response.data;

  return data.passivevehiclegroups;
});

const passiveVehicleGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectPassiveVehicleGroups, selectById: selectPassiveVehicleGroupById } =
    passiveVehicleGroupsAdapter.getSelectors((state) => state.main.fleetTracking.unitFleetTracking.passiveVehicleGroups);

const initialState = {
  entities: [],
  loading: false,
  search: '',
  searchEntities:[],
  ids:[],
  markers:[],
  selectedMarker: {},
  passiveVehicleGroups: passiveVehicleGroupsAdapter.getInitialState({ }),
  loadingPassiveVehicleGroups: false,
  selectedVehicleGroups: [],
  isMap: false
}

const applySearch = (state) => {
  let result;
  if (state.selectedVehicleGroups && state.selectedVehicleGroups.length > 0) {
    let ids = state.selectedVehicleGroups.map(grp => grp.passiveVehicles).flat().map(unit => unit.id);

    result = state.entities.filter(unit => ids.indexOf(unit.id) !== -1);
  }
  else {
    result = state.entities;
  }

  if (state.searchText && state.searchText.length > 0)
    state.searchEntities = FuseUtils.filterArrayByString(result, state.searchText)
  else
    state.searchEntities = result;
}

export const unitFleetTrackingSlice = createSlice({
  name: 'fleetTracking/units',
  initialState,
  reducers: {
    setSearch: {
      reducer: (state, action) => {
        state.searchText = action.payload;
        applySearch(state);
        state.isMap = false;
      },
    },
    setSelectedVehicleGroups: {
      reducer: (state, action) => {
        state.selectedVehicleGroups = action.payload;
        applySearch(state);
        state.isMap = false;
      },
    },
    setIsDriving: {
      reducer: (state, action) => {
        state.searchText = action.payload;
        state.searchEntities.map(function(el) {
          if (el.id == action.payload) {
            el.isDriving = 1;
          } else {
            el.isDriving = 0;
          }
        })
      },
    },setNotDriving: {
      reducer: (state, action) => {
        state.searchText = action.payload;
        state.searchEntities.map(function(el) {
          el.isDriving = 0;
        });
      },
    },setIds: {
      reducer: (state, action) => {
        state.ids = action.payload;
        if (action.payload && action.payload.length > 0) {
          state.searchEntities = _.intersectionWith(state.entities, action.payload, (o, id) => o.id === id);
        } else {
          state.searchEntities = state.entities;
        }
        state.isMap = true;
        state.searchEntities.map(function(el) {
          el.isSelected = 0;
          el.isDriving = 0;
        })
      },
    },
    setElement: {
      reducer: (state, action) => {
        //state.ids = action.payload;
        if (action.payload) {
          state.searchEntities = _.filter(state.entities, {id: action.payload});
          state.searchEntities.map(function(el) {
            el.isSelected = 1;
            el.isDriving = 0;
          })
        } else {
          state.searchEntities = state.entities;
        }
        state.isMap = true;
      }
    },

  },
  extraReducers: {
    [getUnitFleetTracking.pending]: (state) => {
      state.loading = true
    },
    [getUnitFleetTracking.fulfilled]: (state, { payload }) => {
      state.loading = false
      if (state.entities && state.entities.length > 0) {
      } else {
        state.entities = payload
      }
      state.searchEntities = payload
      state.isMap = false;
      //state.markers = _.map(payload, 'latestPosition');
    },
    [getUnitFleetTracking.rejected]: (state) => {
      state.loading = false
    },

    [getPassiveVehicleGroups.fulfilled](state, { payload }) {
      passiveVehicleGroupsAdapter.setAll(state.passiveVehicleGroups, payload);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.pending]: (state) => {
      state.loadingPassiveVehicleGroups = true;
      passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.rejected]: (state) => {
      state.loadingPassiveVehicleGroups = false;
    },
  },
});
export const { setIds, setSearch, setElement, setIsDriving, setNotDriving, setSelectedVehicleGroups} = unitFleetTrackingSlice.actions;

export const postReducer = unitFleetTrackingSlice.reducer;
export default unitFleetTrackingSlice.reducer;