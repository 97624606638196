import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
function InfoWidget(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    let {load, markerSet } = useSelector(({ main }) => main.scantec.positionScantec);
    const { t } = useTranslation('common');
    let isSet = false;
    let color ="#228B22";

    if (load) return <FuseLoading />;
    return <Paper className="w-full rounded-20 shadow flex flex-col justify-between">
            <div className="flex items-center justify-between px-4 pt-8">
                <Typography className="text-16 px-16 font-medium" color="textSecondary">
                    {t('scantec_single_bin_identification')}
                </Typography>
            </div>
            <div className="flex flex-row flex-wrap items-center mt-12 bin-ident ">
                <Stack spacing={1}>
                <Stack  direction="row" spacing={2}>
                    <div className="mb-24">
                        <Typography className="font-semibold mb-4 text-15">{t('scantec_single_bin_identificatier')}</Typography>
                        <Typography>{markerSet.binId ? markerSet.binId  : "XXX-XXX"}</Typography>
                    </div>
                    <div className="mb-24 right">
                        <Typography className="font-semibold mb-4 text-15">{t('scantec_single_bin_username')}</Typography>
                        <Typography>YYY YYY</Typography>
                    </div>
                </Stack>
                <Stack  direction="row" spacing={2}>
                    <div className="mb-24">
                        <Typography className="font-semibold mb-4 text-15">{t('scantec_email')}</Typography>
                        <Typography>john@user.com</Typography>
                    </div>
                    <div className="mb-24 right">
                        <Typography className="font-semibold mb-4 text-15">{t('scantec_phone')}</Typography>
                        <Typography>+491211Z1211</Typography>
                    </div>
                </Stack>
                </Stack>
            </div>
    </Paper>
}

export default InfoWidget;
