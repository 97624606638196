import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPassiveVehicleGroups = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroups/getPassiveVehicleGroups', async () => {
  const response = await axios.get(`api/v1/passivevehiclegroups`, { params: { limit: 0 }});
  const data = await response.data;

  return data.passivevehiclegroups;
});

const passiveVehicleGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectPassiveVehicleGroups, selectById: selectPassiveVehicleGroupById } =
  passiveVehicleGroupsAdapter.getSelectors((state) => state.main.settingsPassiveVehicleGroup.passiveVehicleGroups.passiveVehicleGroups);

const passiveVehicleGroupsSlice = createSlice({
  name: 'main/settingsPassiveVehicleGroup/passiveVehicleGroups',
  initialState: {
    searchText: '',
    loadingpassiveVehicleGroups: false,
    passiveVehicleGroups: passiveVehicleGroupsAdapter.getInitialState({ }),
  },
  reducers: {
    setPassiveVehicleGroupsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getPassiveVehicleGroups.fulfilled](state, { payload }) {
      passiveVehicleGroupsAdapter.setAll(state.passiveVehicleGroups, payload);
      state.loadingpassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.pending]: (state) => {
      state.loadingpassiveVehicleGroups = true;
      passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
      state.loadingpassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.rejected]: (state) => {
      state.loadingpassiveVehicleGroups = false;
    },
  },
});

export const { setPassiveVehicleGroupsSearchText } = passiveVehicleGroupsSlice.actions;

export default passiveVehicleGroupsSlice.reducer;
