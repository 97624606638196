import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getErrorGroups = createAsyncThunk('main/settingsErrorGroup/errorGroups/getErrorGroups', async () => {
  const response = await axios.get(`api/v1/errorgroups`, { params: { limit: 0 }});
  const data = response.data;
  return data.errorgroup;
});

const errorGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectErrorGroups, selectById: selectErrorGroupById } =
  errorGroupsAdapter.getSelectors((state) => state.main.settingsErrorGroup.errorGroups.errorGroups);

const errorGroupsSlice = createSlice({
  name: 'main/settingsErrorGroup/errorGroups',
  initialState: {
    searchText: '',
    loadingErrorGroups: false,
    errorGroups: errorGroupsAdapter.getInitialState({}),
  },
  reducers: {
    setErrorGroupsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getErrorGroups.fulfilled](state, { payload }) {
      if (payload) {
        errorGroupsAdapter.setAll(state.errorGroups, payload);
      } else {
        console.error('Payload is undefined or null:', payload);
      }
      state.loadingErrorGroups = false;
    },
    [getErrorGroups.pending]: (state) => {
      state.loadingErrorGroups = true;
      errorGroupsAdapter.removeAll(state.errorGroups);
      state.loadingErrorGroups = false;
    },
    [getErrorGroups.rejected]: (state) => {
      state.loadingErrorGroups = false;
    },
  },
});

export const { setErrorGroupsSearchText } = errorGroupsSlice.actions;

export default errorGroupsSlice.reducer;