export const initialSweeperColumnsValue = [
    {
        id: 'information',
        value: '100',
        colSpan: 2,
        align: 'left',
        disablePadding: false,
        translate: 'Information',
        translateTooltip: 'Information',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                // {
                //   id: 'icon',
                //   value: '101',
                //   align: 'left',
                //   disablePadding: false,
                //   translate: '',
                //   sort: false,
                // },
                {
                    id: 'licencePlatColor',
                    value: '102',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_license_plate', //'Kennenzeichen',
                    translateTooltip: 'service_dashboard_license_plate', 
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'internalFleetManagerColor',
                    value: '103',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_internal_fleet_number', //'Interne Fuhrparknummer',
                    translateTooltip: 'service_dashboard_internal_fleet_number', 
                    sort: true,
                },
            ]
    },
    {
        id: 'bodyIndicators',
        value: '200',
        colSpan: 6,
        align: 'left',
        disablePadding: false,
        translate: 'service_dashboard_menu_body', // 'Aufbau',
        translateTooltip: 'service_dashboard_menu_body',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                {
                    id: 'remainBodyWorkingHoursSweeperColor', // Aufbauwartung
                    value: '21',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_maintenance_short', // 'AW',
                    translateTooltip: 'service_dashboard_rcv_body_maintenance', 
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'fehlerMeldungenColor', // Fehlermeldungen
                    value: '16',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_error_short', // 'FM',
                    translateTooltip: 'service_dashboard_rcv_body_error',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'waterTankLevelSweeperColor', // Füllstand Wassertank
                    value: '22',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_body_fill_level_water_short', // 'FW',
                    translateTooltip: 'service_dashboard_sweeper_body_fill_level_water',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'timeToNextServiceAuxEngineSweeperColor', // Aufbaumotorwartung
                    value: '23',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_body_motor_maintenance_short', // 'AMW',
                    translateTooltip: 'service_dashboard_sweeper_body_motor_maintenance', 
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'motorErrorSweeperColor', // Fehldermeldungen Aufbaumotor
                    value: '24',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_body_error_motor_short', // 'FAM',
                    translateTooltip: 'service_dashboard_sweeper_body_error_motor',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'bodyMotorDieselSweeperColor', // Füllstand Dieseltank Aufbaumotor 
                    value: '25',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_body_fill_level_diesel_short', // 'FDA',
                    translateTooltip: 'service_dashboard_sweeper_body_fill_level_diesel',
                    sort: true,
                    borderLeft: 0,
                },
            ]
    },
    {
        id: 'chassisIndicators',
        value: '400',
        colSpan: 4,
        align: 'left',
        disablePadding: false,
        translate: 'service_dashboard_menu_chassis', // 'Fahrgestell',
        translateTooltip: 'service_dashboard_menu_chassis',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                {
                    id: 'chassisRemainWorkingHoursSweeperColor', // Fahrgestell Wartung
                    value: '29',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_chassis_maintenance_short', //'FW',
                    translateTooltip: 'service_dashboard_sweeper_chassis_maintenance',
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'chassisErrorSweeperColor', // Fehldermeldungen Fahrgestell
                    value: '28',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_sweeper_chassis_error_short', // 'FF',
                    translateTooltip: 'service_dashboard_sweeper_chassis_error', 
                    sort: true,
                },
                {
                    id: 'chassisDieselColor', // Füllstand Diesel
                    value: '8',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_diesel_short', // 'FD',
                    translateTooltip: 'service_dashboard_rcv_chassis_diesel',
                    sort: true,
                },
                {
                    id: 'chassisAdblueColor', // Füllstand AdBlue
                    value: '9',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_adblue_short', // 'FAB',
                    translateTooltip: 'service_dashboard_rcv_chassis_adblue',
                    sort: true,
                },
            ]
    },
    // {
    //     id: 'gesetzlichePrüfungen',
    //     value: '500',
    //     colSpan: 4,
    //     align: 'left',
    //     disablePadding: false,
    //     translate: 'service_dashboard_menu_examination', // 'Gesetzliche Prüfungen',
    //     translateTooltip: 'service_dashboard_menu_examination', 
    //     sort: false,
    //     css: 'border-l-1',
    //     columns:
    //         [
    //             {
    //                 id: 'hsMeetingGasMeetingColor', // Hauptuntersuchung
    //                 value: '12',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_general_short', // 'HU/AU',
    //                 translateTooltip: 'service_dashboard_rcv_examination_general', 
    //                 sort: true,
    //                 css: 'border-l-1',
    //             },
    //             {
    //                 id: 'tachoMeetingColor', // Tachographenprüfung
    //                 value: '13',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_tacho_short', // 'TP',
    //                 translateTooltip: 'service_dashboard_rcv_examination_tacho',
    //                 sort: true,
    //             }
    //         ]
    // },

];