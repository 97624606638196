import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const SettingsStTourDetails = lazy(() => import('./SettingsStTourDetails'));

const SettingsStTourDetailsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'settingssttourdetails',
      element: <SettingsStTourDetails />,
      auth: [authRoles.ROLE_ST_TOUR_DETAIL_READ], 
    },
  ],
};

export default SettingsStTourDetailsConfig;