import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourViews = createAsyncThunk('main/stAnalysis/getStTourViews', async (searchParams) => {
  console.log('search params', searchParams);
  const response = await axios.post(`api/v1/stvehicletoursummaries`, {

    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order,
      deviceType: searchParams.deviceType,

    }, headers: { 'X-UI-State': 'stAnalysis' }
  });
  let data = await response.data;
  if (data.stvehicletoursummaries) {
    data.stvehicletoursummaries =
      data.stvehicletoursummaries.map(item => {
        if (item.stTourCalculatedData) {
          item.stOtherMaterial = item.stTourCalculatedData.otherMaterial;
          item.stTotal = item.stTourCalculatedData.total;
          item.stOkCount = item.stTourCalculatedData.okCount;
          item.stMiddleCount = item.stTourCalculatedData.middleCount;
          item.stNotOkCount = item.stTourCalculatedData.notOkCount;
          item.stNotEmptied = item.stTourCalculatedData.notEmptied;
          item.stNotOkPercentage = item.stTourCalculatedData.notOkPercentage;
        } else {
          item.stOtherMaterial = 0;
          item.stTotal = 1;
          item.stOkCount = 0;
          item.stMiddleCount = 0;
          item.stNotOkCount = 0;
          item.stNotEmptied = 0;
          item.stNotOkPercentage = 0;
        }
        if (item.scTourCalculatedData) {
          item.scOtherMaterial = item.scTourCalculatedData.otherMaterial;
          item.scTotal = item.scTourCalculatedData.total;
          item.scOkCount = item.scTourCalculatedData.okCount;
          item.scMiddleCount = item.scTourCalculatedData.middleCount;
          item.scNotOkCount = item.scTourCalculatedData.notOkCount;
          item.scNotEmptied = item.scTourCalculatedData.notEmptied;
          item.scNotOkPercentage = item.scTourCalculatedData.notOkPercentage;
        } else {
          item.scOtherMaterial = 0;
          item.scTotal = 0;
          item.scOkCount = 0;
          item.scMiddleCount = 0;
          item.scNotOkCount = 0;
          item.scNotEmptied = 0;
          item.scNotOkPercentage = 0;
        }
        return item;
      });
  }

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getStApiIngsByVehicleDate = createAsyncThunk('main/stAnalysis/getStApiIngsByVehicleDate', async (searchParams) => {
  console.log('search params', searchParams);
  const response = await axios.post(`api/v1/stapiingpervehicledates`, {

    params:
    {
      conditions: searchParams,

    }, headers: { 'X-UI-State': 'stAnalysis' }
  });
  let data = await response.data;
  
  return data?.stapiings || [];
});

export const getStTourViewFilters = createAsyncThunk('main/stAnalysis/getStTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/stvehicletoursummaries`, {
    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

    }, headers: { 'X-UI-State': 'filter' }
  });
  let data = await response.data;
  if (data.stvehicletoursummaries) {
    data.stvehicletoursummaries =
      data.stvehicletoursummaries.map(item => {
        if (item.stTourCalculatedData) {
          item.otherMaterial = item.stTourCalculatedData.otherMaterial;
          item.total = item.stTourCalculatedData.total;
          item.okCount = item.stTourCalculatedData.okCount;
          item.middleCount = item.stTourCalculatedData.middleCount;
          item.notOkCount = item.stTourCalculatedData.notOkCount;
          item.notEmptied = item.stTourCalculatedData.notEmptied;
          item.notOkPercentage = item.stTourCalculatedData.notOkPercentage;
        } else if (item.scTourCalculatedData) {
          item.otherMaterial = item.scTourCalculatedData.otherMaterial;
          item.total = item.scTourCalculatedData.total;
          item.okCount = item.scTourCalculatedData.okCount;
          item.middleCount = item.scTourCalculatedData.middleCount;
          item.notOkCount = item.scTourCalculatedData.notOkCount;
          item.notEmptied = item.scTourCalculatedData.notEmptied;
          item.notOkPercentage = item.scTourCalculatedData.notOkPercentage;
        } else {
          item.otherMaterial = 0;
          item.total = 1;
          item.okCount = 0;
          item.middleCount = 0;
          item.notOkCount = 0;
          item.notEmptied = 0;
          item.notOkPercentage = 0;
        }
        return item;
      });
  }

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/stAnalysis/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/stAnalysis/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stAnalysis' } });
  const data = await response.data;

  return data.st_tours;
});


const stTourViewAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViews, selectById: selectStTourViewById } =
  stTourViewAdapter.getSelectors((state) => state.main.stAnalysis.stTourViews);

const stApiIngAdapter = createEntityAdapter({});

export const { selectAll: selectStApiIngs, selectById: selectStApiIngById } =
  stApiIngAdapter.getSelectors((state) => state.main.stAnalysis.stApiIngs);

const stTourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViewFilters, selectById: selectStTourViewFilterById } =
  stTourViewFiltersAdapter.getSelectors((state) => state.main.stAnalysis.stTourViewFilters);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.stAnalysis.stTours);
  

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.stAnalysis.units);

const stAnalysisSlice = createSlice({
  name: 'main/stAnalysis',
  initialState: {

    selectedUnit: [],
    rangeDate: [null, null],
    selectedTour: [],
    selectedUnitPrevCount: [],
    selectedTourPrevCount: [],
    selectedDatePrevValue: [],
    unitsCombo: [],
    stToursCombo: [],
    rangeDateCombo: [],
    searchText: '',
    stTourViewsCount: 0,
    loadingStTourViews: false,
    loadingUnits: false,
    loadingStTours: false,
    stAnalysisCount: 0,
    stTourViewFiltersCount: 0,
    loadingStTourViewFilters: false,
    stTourViews: stTourViewAdapter.getInitialState({ }),
    stApiIngs: stApiIngAdapter.getInitialState({ }),
    stTourViewFilters: stTourViewFiltersAdapter.getInitialState({ }),
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
  },
  reducers: {
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedUnitPrevCount: {
      reducer: (state, action) => {
        state.selectedUnitPrevCount = action.payload;
      },
    },
    setSelectedTourPrevCount: {
      reducer: (state, action) => {
        state.selectedTourPrevCount = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setStToursCombo: {
      reducer: (state, action) => {
        state.stToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
    setstAnalysisSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetStTourViewFilters: {
      reducer: (state, action) => {
        stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
      },
    },
  },
  extraReducers: {
    [getStTourViews.fulfilled](state, { payload }) {
      stTourViewAdapter.setAll(state.stTourViews, payload.stAnalysis);
      state.stTourViewsCount = payload.totalcount;
      state.loadingStTourViews = false;
    },
    [getStTourViews.pending]: (state) => {
      state.loadingStTourViews = true;
      stTourViewAdapter.removeAll(state.stTourViews);
    },
    [getStTourViews.rejected]: (state) => {
      state.loadingStTourViews = false;
    },
    [getStApiIngsByVehicleDate.fulfilled](state, { payload }) {
      stApiIngAdapter.setAll(state.stApiIngs, payload);
      state.loadingStApiIngs = false;
    },
    [getStApiIngsByVehicleDate.pending]: (state) => {
      state.loadingStApiIngs = true;
      stApiIngAdapter.removeAll(state.stApiIngs);
    },
    [getStApiIngsByVehicleDate.rejected]: (state) => {
      state.loadingStApiIngs = false;
    },
    
    [getStTourViewFilters.fulfilled](state, { payload }) {
      stTourViewFiltersAdapter.setAll(state.stTourViewFilters, payload.stAnalysis);
      state.stTourViewFiltersCount = payload.totalcount;
      state.loadingStTourViewFilters = false;
    },
    [getStTourViewFilters.pending]: (state) => {
      state.loadingStTourViewFilters = true;
      stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
    },
    [getStTourViewFilters.rejected]: (state) => {
      state.loadingStTourViewFilters = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const { 
  setstAnalysisSearchText, 
  resetStTourViewFilters,
  setSelectedUnit,
  setRangeDate,
  setSelectedTour,
  setSelectedUnitPrevCount,
  setSelectedTourPrevCount,
  setSelectedDatePrevValue,
  setUnitsCombo,
  setStToursCombo,
  setRangeDateCombo,
} = stAnalysisSlice.actions;

export default stAnalysisSlice.reducer;
