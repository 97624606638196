import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getEventInfos = createAsyncThunk('main/settingsEventInfo/eventInfos/getEventInfos', async () => {
  const response = await axios.get(`api/v1/eventinfos`, { params: { limit: 0 }, headers: {'X-UI-State': 'settings.eventInfo.list'} });
  const data = await response.data;

  return data.eventinfos;
});

const eventInfosAdapter = createEntityAdapter({});

export const { selectAll: selectEventInfos, selectById: selectEventInfoById } =
  eventInfosAdapter.getSelectors((state) => state.main.settingsEventInfo.eventInfos.eventInfos);

const eventInfosSlice = createSlice({
  name: 'main/settingsEventInfo/eventInfos',
  initialState: {
    searchText: '',
    loadingEventInfos: false,
    eventInfos: eventInfosAdapter.getInitialState({ }),
  },
  reducers: {
    setEventInfosSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getEventInfos.fulfilled](state, { payload }) {
      eventInfosAdapter.setAll(state.eventInfos, payload);
      state.loadingEventInfos = false;
    },
    [getEventInfos.pending]: (state) => {
      state.loadingEventInfos = true;
      eventInfosAdapter.removeAll(state.eventInfos);
    },
    [getEventInfos.rejected]: (state) => {
      state.loadingEventInfos = false;
    },

  },
});

export const { setEventInfosSearchText } = eventInfosSlice.actions;

export default eventInfosSlice.reducer;
