import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosInterceptorUtils from '../../../utils/AxiosInterceptorUtils';

/* get all service inspections */
export const getServiceInspections = createAsyncThunk('main/cockpit/unitDetail/getServiceInspections', async (id) => {
  //console.log('idfromgetServiceInspections', id);
  const response = await axios.get(`api/v1/allserviceinspections/${id}`, { headers: { 'X-UI-State': 'settings.inspections.detail' } });
  const data = response.data;
  return data.inspections;
});

/* patch */
export const patchServiceInspections = createAsyncThunk('main/cockpit/unitDetail/patchServiceInspections', async (serviceInspectionBody, { getState, rejectWithValue }) => {
  //console.log("serviceInspectionBodypatch", serviceInspectionBody);
  
  let clone = _.cloneDeep(serviceInspectionBody.filter(item => item.updated));
  //console.log("clonedataconee", clone);

  let payload = clone.map((row) => {
    //console.log('rowdatauishere', row);
    return (
      AxiosInterceptorUtils.preparePayload('patch', 'serviceInspections', row)
    );
  });
  //console.log('payloadfrompatchServiceInspections', payload);
  
  try {
    const response = await axios.patch(`api/v1/batchserviceinspection`, { inspections: payload });
    const data = await response.data;
    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data } });
    }
    return data;
  } catch (err) {
    return rejectWithValue({ error: err.response.data })
  }
});

const serviceInspectionsAdapter = createEntityAdapter({});

export const { selectAll: selectServiceInspections, selectById: selectServiceInspectionsById } =
  serviceInspectionsAdapter.getSelectors((state) => state.main.cockpit.unitDetail.serviceInspections);

const serviceInspectionsSlice = createSlice({
  name: 'main/cockpit/serviceInspections',
  initialState: {
    searchText: '',
    loadingServiceInspections: false,
    serviceInspections: serviceInspectionsAdapter.getInitialState({}),
    savingServiceInspections: false,
    isDataDirty: false,
  },
  reducers: {
    setServiceInspectionsAdapterSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
          state.isDataDirty = action.payload;
      },
  },
  },
  extraReducers: {
    [getServiceInspections.fulfilled](state, { payload }) {
      if (payload) {
        serviceInspectionsAdapter.setAll(state.serviceInspections, payload);
      } else {
        console.error('Payload is undefined or null:', payload);
      }
      state.loadingServiceInspections = false;
      state.isDataDirty = false;
    },
    [getServiceInspections.pending]: (state) => {
      state.loadingServiceInspections = true;
      serviceInspectionsAdapter.removeAll(state.serviceInspections);
      state.isDataDirty = false;

    },
    [getServiceInspections.rejected]: (state) => {
      state.loadingServiceInspections = false;
      state.isDataDirty = false;

    },
    [patchServiceInspections.fulfilled](state, { payload }) {
      state.savingServiceInspections = false;
    },
    [patchServiceInspections.pending]: (state) => {
      state.savingServiceInspections = true;
    },
    [patchServiceInspections.rejected]: (state) => {
      state.savingServiceInspections = false;
    },
  },
});

export const { setServiceInspectionsAdapterSearchText, setIsDataDirty } = serviceInspectionsSlice.actions;
export default serviceInspectionsSlice.reducer;