export const COMMAND_GATEWAY_STATUS_TYPE = {
    NEW_REQUEST: -1,
    To_BE_QUEUED: 0,
    QUEUED: 1,
    SENT: 2,
    RECEIVED: 3,
    EXECUTED_SUCCUSSFULLy: 4,
    EXECUTED_WITH_ERROR: 5,
    UNDER_MODIFICATION: 6
};

class CommandGatewayStatusType {
    static getLabel = (status) => {

        switch (status) {
            case -1:
                return 'New request';
            case 0:
                return 'To be queued';
            case 1:
                return 'Queued';
            case 2:
                return 'Sent';
            case 3:
                return 'Received';
            case 4:
                return 'Executed successfully';
            case 5:
                return 'Executed with error';
            case 6:
                return 'Under modification';
            default:
                return '-';
        }
    };

    static getTranslation = (status) => {

        switch (status) {
            case -1:
                return 'command_gateway_status_new';
            case 0:
                return 'command_gateway_status_to_be_queued';
            case 1:
                return 'command_gateway_status_queued';
            case 2:
                return 'command_gateway_status_sent';
            case 3:
                return 'command_gateway_status_received';
            case 4:
                return 'command_gateway_status_success';
            case 5:
                return 'command_gateway_status_errors';
            case 6:
                return 'command_gateway_under_modification';
            default:
                return '-';
        }

    };
}

export default CommandGatewayStatusType;

