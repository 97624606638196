import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import getChassisManufacturer from 'app/types/chassisManufacturerType';
import ServiceDashboardUtils, { COLUMN_TYPES } from 'app/utils/ServiceDashboardUtils';
import { initialRcvColumnsValue } from '../tabs/rcv/RcvColumns';
import { initialSweeperColumnsValue } from '../tabs/sweeper/SweeperColumns';
import FosService from 'app/services/fosService';


export const getUnits = createAsyncThunk('main/serviceDashboard/getUnits', async () => {
  const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: params, headers: { 'X-UI-State': 'kocobox.list' } });
  const data = await response.data;

  data.units = data.units.map(unit => {

    if (unit.isRcv == true) {
      unit.remainBodyWorkingHoursColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS]);
      unit.bodyFillLevelColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.BODY_FILL_LEVEL]);
      unit.bodyPumpFillLevelColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.BODY_PUMP_FILL_LEVEL]);
      unit.leftFootstepActiveColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.LEFT_FOOTSTEP_ACTIVE]);
      unit.rightFootstepActiveColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.RIGHT_FOOTSTEP_ACTIVE]);
      unit.lifterRemainWorkingHoursColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.LIFTER_REMAIN_WORKING_HOURS]);
      unit.chassisDieselColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_DIESEL]);
      unit.chassisAdblueColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_AD_BLUE]);
      unit.engineCoolantTempWarningColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.ENGINE_COOLANT_TEMP_WARNING]);
      unit.engineOilPressureWarningColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.ENGINE_OIL_PRESSURE_WARNING]);
      unit.hsMeetingGasMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.HS_MEETING_GAS_MEETING]);
      unit.tachoMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.TACHO_MEETING]);
      unit.securityMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.SECURITY_MEEETING]);
      unit.uvvMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.UVV_MEETING]);
    } else {
      unit.remainBodyWorkingHoursSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.REMAIN_BODY_WORKING_HOURS_SWEEPER]);
      unit.waterTankLevelSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.WATER_TANK_LEVEL_SWEEPER]);
      unit.timeToNextServiceAuxEngineSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.TIME_TO_NEXT_SERVICE_AUX_ENGINE_SWEEPER]);
      unit.motorErrorSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.MOTOR_ERROR_SWEEPER]);
      unit.bodyMotorDieselSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.BODY_MOTOR_DIESEL_SWEEPER]);
      unit.chassisRemainWorkingHoursSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_REMAIN_WORKING_HOURS_SWEEPER]);
      unit.chassisErrorSweeperColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_ERROR_SWEEPER]);
      unit.chassisDieselColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_DIESEL]);
      unit.chassisAdblueColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.CHASSIS_AD_BLUE]);
      unit.hsMeetingGasMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.HS_MEETING_GAS_MEETING]);
      unit.tachoMeetingColor = ServiceDashboardUtils.getIndicatorColor(unit, [COLUMN_TYPES.TACHO_MEETING]);
    }
    
    return unit;
  });
  FosService.setLoggedIn(false);
  return data.units;
});

export const getActiveErrorsCount = createAsyncThunk('main/serviceDashboard/units/getActiveErrorsCount', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_PASSIVE_VEHICLE_ACTIVE_ERRORS, { ids: ids });
  const data = await response.data;

  if (!data)
    return;

  let changeData = data.value.map(val => {
    return {
      id: val.vehicle, changes:
      {
        activeErrorsCount: val.count,
        fehlerMeldungenColor: ServiceDashboardUtils.getIndicatorColor({ activeErrorsCount: val.count }, [COLUMN_TYPES.FEHLER_MELDUNGEN])
      }
    }
  });
  return changeData;
});

export const getPassiveVehicleGroups = createAsyncThunk('main/serviceDashboard/getPassiveVehicleGroups', async () => {
  const response = await axios.get(`api/v1/passivevehiclegroups`, { params: { limit: 0 }});
  const data = await response.data;

  return data.passivevehiclegroups;
});

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById, selectIds: selectUnitIds } =
unitsAdapter.getSelectors((state) => state.main.serviceDashboard.units);

const passiveVehicleGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectPassiveVehicleGroups, selectById: selectPassiveVehicleGroupById } =
  passiveVehicleGroupsAdapter.getSelectors((state) => state.main.serviceDashboard.passiveVehicleGroups);

const serviceDashboardSlice = createSlice({
  name: 'main/serviceDashboard',
  initialState: {
    searchText: '',
    tabValue: 0,
    rcvColumns: initialRcvColumnsValue,
    sweeperColumns: initialSweeperColumnsValue,
    csvResult: [],
    units: unitsAdapter.getInitialState({}),
    loading: false,
    passiveVehicleGroups: passiveVehicleGroupsAdapter.getInitialState({ }),
    loadingPassiveVehicleGroups: false,
    selectedVehicleGroups: [],
  },
  reducers: {
    setUnitsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setSelectedVehicleGroups: {
      reducer: (state, action) => {
        state.selectedVehicleGroups = action.payload;   
      },
    },
    setTabValue: {
      reducer: (state, action) => {
        state.tabValue = action.payload;   
      },
    },
    setRcvColumns: {
      reducer: (state, action) => {
        state.rcvColumns = action.payload;   
      },
    },
    setSweeperColumns: {
      reducer: (state, action) => {
        state.sweeperColumns = action.payload;   
      },
    },
    setCsvResult: {
      reducer: (state, action) => {
        state.csvResult = action.payload;
      },
    },
  },
  extraReducers: {
    [getUnits.pending](state, { payload }) {
      state.loading = true;
    }, 
    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload)
      state.loading = false;
    },  
    [getActiveErrorsCount.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
    },

    [getPassiveVehicleGroups.fulfilled](state, { payload }) {
      passiveVehicleGroupsAdapter.setAll(state.passiveVehicleGroups, payload);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.pending]: (state) => {
      state.loadingPassiveVehicleGroups = true;
      passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.rejected]: (state) => {
      state.loadingPassiveVehicleGroups = false;
    },
  },
});

export const { setUnitsSearchText, setRcvColumns, setSweeperColumns, setCsvResult, setTabValue, setSelectedVehicleGroups } = serviceDashboardSlice.actions;

export default serviceDashboardSlice.reducer;
