import { combineReducers } from '@reduxjs/toolkit';
import unit from './UnitSlice';
import units from './UnitsSlice';

const reducer = combineReducers({
  units,
  unit,
});

export default reducer;
