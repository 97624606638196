import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCompanies = createAsyncThunk('main/settingsCompany/companies/getCompanies', async () => {
  const response = await axios.get(`api/v1/companies`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.companies' } });
  const data = await response.data;

  return data.companies;
});

const CompaniesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectCompanyById } =
  CompaniesAdapter.getSelectors((state) => state.main.settingsCompany.companies.companies);

const companiesSlice = createSlice({
  name: 'main/settingsCompany/companies',
  initialState: {
    searchText: '',
    loadingCompanies: false,
    companies: CompaniesAdapter.getInitialState({ }),
  },
  reducers: {
    setCompaniesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getCompanies.fulfilled](state, { payload }) {
      CompaniesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      CompaniesAdapter.removeAll(state.companies);
      state.loadingCompanies = false;
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },
  },
});

export const { setCompaniesSearchText } = companiesSlice.actions;

export default companiesSlice.reducer;
