import { combineReducers } from '@reduxjs/toolkit';
import stTourPlanNew from './StTourPlanNewSlice';
import stTourPlans from './StTourPlansSlice';

const reducer = combineReducers({
  stTourPlans,
  stTourPlanNew,
});

export default reducer;
