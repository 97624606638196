
const REQUEST_PARAMETER_TYPE = {
     READ_BODY_PARAMETERS: 3,
     READ_LIFTER_PARAMETERS: 4,
     WRITE_BODY_PARAMETERS: 6,
     WRITE_LIFTER_PARAMETERS: 7,

     WRITE_ST_DEEP_SCAN_SETTINS: 11,
};

export default REQUEST_PARAMETER_TYPE;