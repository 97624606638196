import {combineReducers} from '@reduxjs/toolkit';
import fleetOverview from '../fleet-overview/store/FleetOverviewSlice';
import serviceDashboard from '../service-dashboard/store/ServiceDashboardSlice';
import cockpit from '../unit-detail/store/index';
import user from './userSlice';
import fleetTracking from '../fleetTracking/store/index';
import pathTrackerForMiniMap from '../unit-detail/store/index';
import scantec from "../scantec/store/index";
import tourAnalysis from "../tour-analysis/store/TourAnalysisSlice";
import reports from "../reports/store/index";
import newReports from "../reports_2/store/index";
import settingsCompany from "../settings-company/store/index";
import settingsProfile from "../settings-profile/store/index";
import settingsUser from "../settings-user/store/index";
import settingsDepot from "../settings-depot/store/index";
import settingsRole from "../settings-role/store/index";
import settingsUnit from "../settings-unit/store/index";
import settingsPassiveVehicleGroup from "../settings-passive-vehicle-group/store/index";
import settingsSubscription from "../settings-subscription/store/index";
import settingsEventInfo from "../settings-event-info/store/index";
import stTourOverview from "../st-tour-overview/store/StTourOverviewSlice";
import settingsStTourPlan from "../settings-st-tour-plan/store/index";
import settingsStTour from "../settings-st-tour/store/index";
import settingsStTourCollector from "../settings-st-tour-collector/store/index";
import stTourMap from "../st-tour-map/store/index";
import stAnalysis from "../st-analysis/store/StAnalysisSlice";
import stTourDetails from "../st-tour-details/store/StTourDetailsSlice";
import settingsStTourDetails from "../settings-st-tour-details/store/SettingsStTourDetailsSlice";
import settingsStRegion from "../settings-st-region/store/index";
import settingsStClassifier from '../settings_st_classifier/store/index';
import settingsErrorGroup from "../settings-error-code-group/store/index";
import tourAnalyseOverview from "../tour-analyse-overview/store/TourOverviewSlice";
import tourAnalyseTour from "../tour-analyse-tour/store/index";
import taTourDetails from "../tour-analyse-details/store/TaTourDetailsSlice";
import tourMap from "../tour-analyse-map/store/index"
import settingsTourPlan from "../tour-analyse-plan/store/index";
import performanceReports from "../performance-reports/store/index";

const mainReducer = combineReducers({
  serviceDashboard,
  fleetOverview,
  user,
  cockpit,
  fleetTracking,
  pathTrackerForMiniMap,
  scantec,
  newReports, 
  tourAnalysis,
  reports,
  settingsCompany,
  settingsProfile,
  settingsDepot,
  settingsUser,
  settingsRole,
  settingsUnit,
  settingsPassiveVehicleGroup,
  settingsSubscription,
  settingsEventInfo,
  stTourOverview,
  settingsStTourPlan,
  settingsStTour,
  settingsStTourCollector,
  stTourMap,
  stAnalysis,
  stTourDetails,
  settingsStTourDetails,
  settingsStRegion,
  settingsStClassifier,
  settingsErrorGroup,
  tourAnalyseOverview,
  tourAnalyseTour,
  taTourDetails,
  tourMap,
  settingsTourPlan,
  performanceReports
});

export default mainReducer;
