import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import StRegionType from "app/types/StRegionType";

export const getStRegion = createAsyncThunk(
  "main/settingsStRegion/stRegion/getStRegion",
  async (id) => {
    const response = await axios.get(`api/v1/stregions/${id}`, {
      headers: { "X-UI-State": "settings.stregion.detail" },
    });
    let data = await response.data;

    if (data === undefined) return null;

    if (data && data.type)
      data.type = { id: data.type, label: '' };

    return data;
  }
);

export const patchStRegion = createAsyncThunk(
  "main/settingsStRegion/stRegion/patchStRegion",
  async (stRegion, { getState, rejectWithValue }) => {
    let clone = _.cloneDeep(stRegion);

    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "stRegion",
      clone
    );

    try {
      const response = await axios.patch(
        `api/v1/stregions/${stRegion.id}`,
        payload
      );
      const data = await response.data;
      console.log("here");
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postStRegion = createAsyncThunk(
  "main/settingsStRegion/stRegion/postStRegion",
  async (stRegion, { getState, rejectWithValue }) => {
    let clone = _.cloneDeep(stRegion);

    let payload = AxiosInterceptorUtils.preparePayload(
      "post",
      "stRegion",
      clone
    );

    try {
      const response = await axios.post(`api/v1/stregions`, payload);
      const data = await response.data;
      console.log("here");
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStToursByCompany = createAsyncThunk(
  "main/settingsStRegion/stRegion/getStToursByCompany",
  async (company) => {
    const response = await axios.get(
      `api/v1/companies/${company.id}/sttours`,
      {
        params: { limit: 0 },
        headers: { "X-UI-State": "kocobox.fleetoverview" },
      }
    );

    const dataUnits = await response.data;

    return dataUnits.st_tours;
  }
);

export const activateStRegion = createAsyncThunk("main/settingsStRegion/stRegion/activateStRegion", async (stRegion, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/stregions/${stRegion.id}/enable`);
  const data = await response.data;

  return data;
});

export const deleteStRegion = createAsyncThunk("main/settingsStRegion/stRegion/deleteStRegion", async (stRegion, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/stregions/${stRegion.id}`);
  const data = await response.data;

  return data;
});

const stToursAdapter = createEntityAdapter({});

export const {
  selectAll: selectStTours,
  selectById: selectStTourById,
  selectIds: selectStTourIds,
} = stToursAdapter.getSelectors(
  (state) => state.main.settingsStRegion.stRegion.stTours
);

export const selectTourStRegion = (state) =>
  state.main.settingsStRegion.stRegion.tourStRegion;

const generateNewStRegion = () => {
  return {
    id: "new",
    name: "",
    description: "",
    stTours: [],
  };
};

const resetState = (state) => {
  state.entity = null;
  state.loadingStRegion = false;
  state.tabValue = 0;
  stToursAdapter.removeAll(state.stTours);
  state.savingStRegion = false;
  state.stToursSearchText = "";
  state.loadingStTours = false;
  state.error = null;
};

const StRegionSlice = createSlice({
  name: "main/settingsStRegion/stRegion",
  initialState: {
    stToursSearchText: "",  
    loadingStRegion: false,
    tabValue: 0,
    entity: null,
    loadingStTours: false,
    savingStRegion: false,
    stTours: stToursAdapter.getInitialState({}),
    error: null,
    page: 0,
    pageSize: 10,
    rowsPerPage: 10,
  },
  reducers: {
    setStToursSearchText: {
      reducer: (state, action) => {
        state.stToursSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setTabValue: {
      reducer: (state, action) => {
        state.tabValue = action.payload;
      },
    },
    setStTours: {
      reducer: (state, action) => {
        stToursAdapter.setAll(state.stTours, action.payload);
      },
    },
    setStRegion: {
      reducer: (state, action) => {
        state.stRegion = action.payload;
      },
    },
    resetStRegion: {
      reducer: (state, action) => {
        resetState(state);
      },
    },
    newStRegion: {
      reducer: (state, action) => {
        resetState(state);
        state.entity = generateNewStRegion();
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
  },
  extraReducers: {
    [getStRegion.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingStRegion = false;
    },
    [getStRegion.pending]: (state) => {
      resetState(state);
      state.loadingStRegion = true;
    },
    [getStRegion.rejected]: (state) => {
      state.loadingStRegion = false;
    },

    [patchStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [patchStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [patchStRegion.rejected](state, { payload }) {
      state.error = payload;
      state.savingStRegion = false;
    },

    [postStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [postStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [postStRegion.rejected](state, { payload }) {
      state.error = payload;
      state.savingStRegion = false;
    },

    [getStToursByCompany.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStToursByCompany.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStToursByCompany.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [activateStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [activateStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [activateStRegion.rejected](state, { payload }) {
      state.savingStRegion = false;
    },

    [deleteStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [deleteStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [deleteStRegion.rejected](state, { payload }) {
      state.savingStRegion = false;
    },

  },
});

export const {
  setTabValue,
  resetStRegion,
  newStRegion,
  setStTours,
  setStToursSearchText,
  setStRegion,
  setRowsPerPage,
  setPageSize
} = StRegionSlice.actions;

export default StRegionSlice.reducer;
