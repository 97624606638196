import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "./FleetTrackingSlice";
import moment from 'moment';
const initialState = {
  load: false,
  consumption:[],
  allConsumption:[]
}

export const getConsumption = createAsyncThunk('fleetTracking/units/consumptionData', async (obj) => {
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+obj+ apiUrlConfig.GET_CONSUMPTION, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const fleetConsumptionSlice = createSlice({
  name: 'fleetTracking/consumptionData',
  initialState,
  reducers: {
    setFilterDate: {
      reducer: (state, action) => {
        //state.ids = action.payload;
        if (action.payload) {
          console.log(action.payload);
          let startDate =  moment().subtract(1, 'weeks').unix();
          let endDate = moment().unix();
          switch (action.payload) {
            case 1:
            default:
              startDate =  moment().subtract(1, 'weeks').unix();
              break;
            case 2:
              startDate =  moment().subtract(2, 'weeks').unix();
              break;
            case 3:
              startDate =  moment().subtract(3, 'weeks').unix();
              break;
            case 4:
              startDate =  moment().subtract(4, 'weeks').unix();
              break;
          }

          state.consumption = _.filter(state.allConsumption, function(e){
              if (e.timestamp < endDate && e.timestamp> startDate) {
                return true;
              }
              return false;
          });
        }
      }
    }
  },
  extraReducers: {
    [getConsumption.pending]: (state) => {
      state.load = true
    },
    [getConsumption.fulfilled]: (state, { payload }) => {
      let endDate =  moment().unix();
      let startDate =  moment().subtract(1, 'weeks').unix();
      state.load = false;
      state.consumption = _.filter(payload, function(e){
        if (e.timestamp <= endDate && e.timestamp> startDate) {
          return true;
        }
        return false;
      });
      state.allConsumption= payload;
    },
    [getConsumption.rejected]: (state) => {
      state.load = false
    },
  },
});
export const {setFilterDate} = fleetConsumptionSlice.actions;

export const postReducer = fleetConsumptionSlice.reducer;
export default fleetConsumptionSlice.reducer;