import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {GeoJSON, MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import Card from "@mui/material/Card";
import React, {memo, useEffect, useRef, useState} from "react";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import Fullscreen from "react-leaflet-fullscreen-plugin";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Legend from "../widgets/Legend";
import Moment from "react-moment";
import "../css/graphs.css";
import {setMarker, setMarkers} from "../store/positionScantec";
import {getTrand} from "../store/trendPositionScantec";
function Markers(props)  {
    const dispatch = useDispatch();
    let { entities, load,filter,vehicles,markerSet } = useSelector(({ main }) => main.scantec.positionScantec);

    if (load) {
        //setMap(null);
        return <FuseLoading/>
    }

    function handleClick(row) {
        dispatch(setMarker(row));
        props.setDetails(row);
        dispatch(getTrand({binid : row.binId, date :props.date}));
    }
    function funca(cluster) {
        console.log('i am here');
        console.log(cluster);
        let arrayIds = []
       // dispatch(setMarkers(cluster.layer.getAllChildMarkers()));
    }
    console.log(filter);
    if (filter && filter.length >0) {
        return (
            <MarkerClusterGroup
                                  showCoverageOnHover={false}
            >
                {filter.length > 0 && filter.map((marker, index) => (
                    <Marker key={index} data={marker}
                            icon={getIcon(marker)}
                            eventHandlers={{
                                click: (e) => {
                                    handleClick(marker);
                                }
                            }}
                            position={[marker.location.coordinates[1], marker.location.coordinates[0]]}>
                    </Marker>
                ))}
            </MarkerClusterGroup>);
    } else {
        return null;
    }
}

function getIcon(el) {
    let iconUrl = "/assets/images/gray-circle.png";
    if (el.result == 0) {
        iconUrl = "/assets/images/green-circle.png";
    } else if ( el.result == 1) {
        iconUrl = "/assets/images/yellow-circle.png";
    } else if (el.result == 2) {
        iconUrl = "/assets/images/red-circle.png";
    }
    return new L.icon({
        iconUrl: iconUrl,
        iconSize: [20, 20],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40]
    });
}
export default memo(Markers);
