import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import getChassisManufacturer from 'app/types/chassisManufacturerType';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';


export const getErrorGroup = createAsyncThunk('main/settingsErrorGroup/errorGroup/getErrorGroup', async (id) => {
    const response = await axios.get(`api/v1/errorgroups/${id}`, { headers: { 'X-UI-State': 'settings.errorGroup.detail' } });
    let data = await response.data;

    if (data === undefined)
        return null;

    /*   if (data && data.errors)
        data.errors = data.errors.map(vehicle => vehicle.id); */
    return data;
});

export const getErrorGroupResult = createAsyncThunk('main/settingsErrorGroup/errorGroup/getErrorGroupResult', async (id) => {
    const response = await axios.get(`api/v1/errorgroupresults/${id}`);
    let data = await response.data;

    if (data === undefined)
        return null;

    return data;
});

export const removeProduct = createAsyncThunk(
    'eCommerceApp/product/removeProduct',
    async (val, { dispatch, getState }) => {
        const { id } = getState().eCommerceApp.product;
        await axios.post('/api/e-commerce-app/remove-product', { id });

        return id;
    }
);

export const patchErrorGroup = createAsyncThunk('main/settingsErrorGroup/errorGroup/patchErrorGroup', async (errorGroup, { rejectWithValue }) => {

    const errorGroupData = {
        bodyType: errorGroup.bodyType ? errorGroup.bodyType.name : errorGroup.bodyType,
        groupName: errorGroup.groupName,
        description: errorGroup.description,
        company: errorGroup.company.id, // We need only the ID of the company
        errors: errorGroup.errors.map((error, index, self) => {

            const isDuplicate = self.some( // Here I am checking for duplicate errors (based on number, passiveVehicleType, bodyType, and machineType)
                (otherError) =>
                    otherError.number === error.number &&
                    otherError.passiveVehicleType === error.passiveVehicleType &&
                    otherError.bodyType === error.bodyType &&
                    otherError.machineType === error.machineType
            );

            const errorCopy = { ...error };       // Cloning the error object to avoid mutation
            if (errorCopy.hasOwnProperty('id') && isDuplicate) {       // This line removes the "id" property only if it exists and there's a duplicate
                delete errorCopy.id;
            }

            return errorCopy;
        }),
    };
    try {
        const response = await axios.patch(`api/v1/errorgroups/${errorGroup.id}`, { error_group: errorGroupData });
        const data = await response.data;
        return data;
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const postErrorGroup = createAsyncThunk(
    'errorGroups/postErrorGroup',
    async (errorGroup, { rejectWithValue }) => {
        try {
            const response = await axios.post(`api/v1/errorgroups`, { error_group: errorGroup });
            const data = await response.data;
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
        /*     try {
              const response = await axios.post('api/v1/errorgroups', { error_group: errorGroupData });
              return response.data;
            } catch (error) {
              return rejectWithValue(error.response.data);
            } */
    }
);

export const deleteErrorGroup = createAsyncThunk('main/settingsErrorGroup/errorGroup/deleteErrorGroup', async (errorGroup, { getState }) => {
    const { main } = getState();

    const response = await axios.delete(`api/v1/errorgroups/${errorGroup.id}`);
    const data = await response.data;

    return data;
});

// This gets all the bodyTypes for a company 
export const fetchBodyTypes = createAsyncThunk('main/settingsErrorGroup/errorGroup/fetchBodyTypes', async (companyId) => {
    const response = await axios.get(`api/v1/companies/${companyId}/bodytypes`, { params: { limit: 0 } });
    const data = await response.data;

    if (data) {
        // constructing object like structure with id field and name here
        const bodyTypeOptions = data.map((bodyType, index) => ({
            id: index + 1, // Assign a unique identifier to each body type
            name: bodyType
        }));
        const elementsToRemove = ["Variopress", "SIDE LOADER", "FAUN SIDEPRESS", "FAUN FRONTPRESS"]; // Types that should be removed 
        const filteredBodyTypes = bodyTypeOptions.filter(body => !elementsToRemove.includes(body.name));
        return filteredBodyTypes;
    }
    // If no data, return an empty array
    return [];
});

// This gets all the Errors for the bodyType assigned to the company
export const getErrorByCompany = createAsyncThunk('main/settingsErrorGroup/errorGroup/getErrorByCompany', async (bodyType) => {

    let selectedBodyTypeNumber = null;
    let selectedCategory = null;

    const formattedValue = KocoUtils.formatBodyType(bodyType);   // Format the selected value
    const bodyTypeInfo = KocoUtils.getBodyTypeInfo(formattedValue);   // Get the body type info using the formatted value

    if (bodyTypeInfo) {
        selectedBodyTypeNumber = bodyTypeInfo.bodyTypeNumber;
        selectedCategory = bodyTypeInfo.category;
    }

    const response = await axios.get('api/v1/errorinfos', {
        params: {
            vehicleType: selectedCategory,
            machineType: 1,
            bodyType: selectedBodyTypeNumber,
            limit: 0
        }
    });

    const data = await response.data;
    if (!data || !data.errorinfos)
        return [];
    return data.errorinfos;
});

/* export const getErrorInfos = createAsyncThunk('main/settingsErrorGroup/errorGroup/getErrorInfos', async (errorNumbers) => {
  const response = await axios.get(`api/v1/errorinfos`, { params: { limit: 0 }});
  const data = await response.data;

  return data.companies;
}); */

export const getErrorInfos = createAsyncThunk('main/settingsErrorGroup/errorGroup/getErrorInfos', async (errorNumbers) => {

    const response = await axios.post(apiUrlConfig.POST_ERROR_INFOS, { errorNumbers: errorNumbers });
    const data = await response.data;

    // Transform data to extract numbers from errors array
    const extractedNumbers = data.errors.map(error => error.number);
    return extractedNumbers;

});

export const patchErrorGroupPublic = createAsyncThunk('main/settingsErrorGroup/errorGroup/patchErrorGroupPublic', async (errorGroup, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(`api/v1/errorgroups/${errorGroup.id}/public`);
    const data = await response.data;

    return data;
});

export const patchErrorGroupPrivate = createAsyncThunk('main/settingsErrorGroup/errorGroup/patchErrorGroupPrivate', async (errorGroup, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(`api/v1/errorgroups/${errorGroup.id}/private`);
    const data = await response.data;

    return data;
});

export const activateErrorGroup = createAsyncThunk('main/settingsErrorGroup/errorGroup/activateErrorGroup', async (errorGroup, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(`api/v1/errorgroups/${errorGroup.id}/enable`);
    const data = await response.data;

    return data;
});

const ErrorsAdapter = createEntityAdapter({});

export const { selectAll: selectErrors } =
    ErrorsAdapter.getSelectors((state) => state.main.settingsErrorGroup.errorGroup.fetchedErrors);


const generateNewErrorGroup = () => {
    return {
        id: 'new',
        bodyType: '',
        name: '',
        description: '',
        errors: [],
    };
};

const resetState = (state) => {
    state.entity = null;
    state.loadingErrorGroup = false;
    state.tabValue = 0;
    ErrorsAdapter.removeAll(state.fetchedErrors);
    state.loadingErrors = false;
    state.savingErrorGroup = false;
    state.unitsSearchText = '';
    state.errorsSearchText = '';
    state.loadingErrorInfos = false;
};

const errorGroupSlice = createSlice({
    name: 'main/settingsErrorGroup/errorGroup',
    initialState: {
        selectedErrorslist: false,
        selectedCheckbox: false,
        bodyTypes: [{}],
        load: false,
        errorsSearchText: '',
        loadingErrorGroup: false,
        tabValue: 0,
        entity: null,
        savingErrorGroup: false,
        fetchedErrors: ErrorsAdapter.getInitialState({}),
        loadingErrors: false,
        isDataDirty: false,
        loadingErrorInfos: false,
        bodyTypesFetched: false, // New state property
        loadingErrorGroupResult: false,
        result: { status: false },
    },
    reducers: {
        setErrorsSearchText: {
            reducer: (state, action) => {
                state.errorsSearchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setIsDataDirty: {
            reducer: (state, action) => {
                state.isDataDirty = action.payload;
            },
        },
        setSelectedErrorslist: {
            reducer: (state, action) => {
                state.selectedErrorslist = action.payload;
            }
        },

        setTabValue: {
            reducer: (state, action) => {
                state.tabValue = action.payload;
            },
        },
        setFetchedErrors: {
            reducer: (state, action) => {
                ErrorsAdapter.setAll(state.fetchedErrors, action.payload);
            },
        },
        resetErrorGroup: {
            reducer: (state, action) => {
                resetState(state);
                state.isDataDirty = false;
            },
        },
        newErrorGroup: {
            reducer: (state, action) => {
                resetState(state);
                state.entity = generateNewErrorGroup();
            },
        },
    },
    extraReducers: {
        [getErrorGroupResult.fulfilled](state, { payload }) {
            state.result = payload;
            state.loadingErrorGroupResult = false;
        },
        [getErrorGroupResult.pending]: (state) => {
            state.loadingErrorGroupResult = true;
        },
        [getErrorGroupResult.rejected]: (state) => {
            state.loadingErrorGroupResult = false;
        },
        [getErrorGroup.fulfilled](state, { payload }) {
            state.entity = payload;
            state.loadingErrorGroup = false;
        },
        [getErrorGroup.pending]: (state) => {
            resetState(state);
            state.loadingErrorGroup = true;
        },
        [getErrorGroup.rejected]: (state) => {
            state.loadingErrorGroup = false;
        },
        [patchErrorGroup.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [patchErrorGroup.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [patchErrorGroup.rejected](state, { payload }) {
            state.error = payload;
            state.savingErrorGroup = false;
        },
        [postErrorGroup.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [postErrorGroup.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [postErrorGroup.rejected](state, { payload }) {
            state.error = payload;
            state.savingErrorGroup = false;
        },
        [deleteErrorGroup.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [deleteErrorGroup.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [deleteErrorGroup.rejected](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [fetchBodyTypes.pending]: (state) => {
            state.load = true;
            state.bodyTypes = [{}];
            state.bodyTypesFetched = false;
        },
        [fetchBodyTypes.fulfilled]: (state, { payload }) => {

            state.bodyTypes = payload;
            state.load = false;
            state.bodyTypesFetched = true; // Mark as fetched
        },
        [fetchBodyTypes.rejected]: (state) => {
            state.load = false;
            state.bodyTypesFetched = false; // Mark as not fetched
        },
        [getErrorByCompany.fulfilled](state, { payload }) {
            ErrorsAdapter.setAll(state.fetchedErrors, payload);
            state.loadingErrors = false;
        },
        [getErrorByCompany.pending]: (state) => {
            state.loadingErrors = true;
            ErrorsAdapter.removeAll(state.fetchedErrors);
        },
        [getErrorByCompany.rejected]: (state) => {
            state.loadingErrors = false;
        },
        [patchErrorGroupPublic.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [patchErrorGroupPublic.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [patchErrorGroupPublic.rejected](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [activateErrorGroup.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [activateErrorGroup.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [activateErrorGroup.rejected](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [patchErrorGroupPrivate.fulfilled](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [patchErrorGroupPrivate.pending](state, { payload }) {
            state.savingErrorGroup = true;
        },
        [patchErrorGroupPrivate.rejected](state, { payload }) {
            state.savingErrorGroup = false;
        },
        [getErrorInfos.pending](state, { payload }) {
            state.loadingErrorInfos = true;
        },
        [getErrorInfos.rejected](state, { payload }) {
            state.loadingErrorInfos = false;
        },
        [getErrorInfos.fulfilled](state, { payload }) {
            ErrorsAdapter.updateMany(state.fetchedErrors, payload);
            state.loadingErrorInfos = false;
        },
    },
});

export const { setTabValue, resetErrorGroup, newErrorGroup, setUnits, setSelectedErrorslist, setFetchedErrors, setErrorsSearchText, setgraphData, setIsDataDirty } = errorGroupSlice.actions;

export default errorGroupSlice.reducer;