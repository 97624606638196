import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";
import { pourcentageScantecSlice } from "../../scantec/store/graphPositionScantec";

const initialState = {
  loadingBlue: false,
  blueData: [],
};

export const getBlueData = createAsyncThunk(
  "fleetTracking/units/blueData",
  async (id) => {
    const response = await axios.get(
      `${apiUrlConfig.GET_UNITS}/${id}/bluedata`,
      { params: { limit: 0 }, headers: { "X-UI-State": "kocobox.overview" } }
    );
    const data = await response.data;
    return data.data;
  }
);

export const fleetBlueDataSlice = createSlice({
  name: "fleetTracking/blueData",
  initialState,
  reducers: {
    resetBlue: {
      reducer: (state, action) => {
        state.loadingBlue = true;
        state.blueData = [];
      },
    },
  },
  extraReducers: {
    [getBlueData.pending]: (state) => {
      state.loadingBlue = true;
      state.blueData = [];
    },
    [getBlueData.fulfilled]: (state, { payload }) => {
      state.loadingBlue = false;
      state.blueData = payload;
    },
    [getBlueData.rejected]: (state) => {
      state.loadingBlue = false;
      state.blueData = [];
    },
  },
});

export const postReducer = fleetBlueDataSlice.reducer;
export const { resetBlue } = fleetBlueDataSlice.actions;

export default fleetBlueDataSlice.reducer;
