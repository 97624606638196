import { default as React, useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import "../javascript/movingMarker.js";
import "../javascript/slider";
import "leaflet-control-geocoder";

import Control from "react-leaflet-custom-control";
import { Button } from "@mui/material";
import { GpsFixed, Refresh, Close } from "@mui/icons-material";
import moment from "moment";
import { floor, round } from "lodash/math";
import Legend from "./Legend";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import { useTranslation } from "react-i18next";
import L from "leaflet";
import HeatmapLayer from "react-leaflet-heatmap-layer-v3/lib/HeatmapLayer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import getVehicleIcon from "../../../types/kocoIconType";
import Moment from "react-moment";
import IconButton from "@mui/material/IconButton";
import LegendFleet from "./LegendFleetTracking";
import { Reference } from "react-popper";
import { getLines } from "../store/FleetTrackingLines";
import {
  getUnitFleetTracking,
  setIsDriving,
} from "../store/FleetTrackingSlice";
import { getCalculation } from "../store/FleetTrackingCalculation";
import { showMessage } from "../../../store/fuse/messageSlice";
import { getUnit } from "../../settings-unit/store/UnitSlice";
import History from "@history";

export default function App(props) {
  const dispatch = useDispatch();

  moment.locale("de");
  const geocoder = L.Control.Geocoder.nominatim();
  const { t } = useTranslation("common");
  const [map, setMap] = useState(null);
  const blueMark = props.setSelected.latestPosition.latitude;

  const { loadingLines, lines } = useSelector(
    ({ main }) => main.fleetTracking.fleetTrackingLines
  );
  const { loadingGps, positions } = useSelector(
    ({ main }) => main.fleetTracking.fleetTrackingGps
  );
  const [polylines, setPolylines] = useState({});
  var polyUtil = require("polyline-encoded");
  const [colors, setColors] = useState([
    "blue",
    "red",
    "yellow",
    "green",
    "black",
    "cyan",
    "darkorange",
    "gray",
    "indigo",
    "lightcoral",
    "magenta",
    "blue",
    "red",
    "yellow",
    "green",
    "black",
    "cyan",
    "darkorange",
    "gray",
    "indigo",
    "lightcoral",
    "magenta",
    "blue",
    "red",
    "yellow",
    "green",
    "black",
    "cyan",
    "darkorange",
    "gray",
    "indigo",
    "lightcoral",
    "magenta",
  ]);
  const [date, setDates] = useState([]);
  const [item, setItem] = useState({});
  const [pos, setPos] = useState({});
  //const [center, setCenter] = useState({});
  const [text, setText] = useState("");
  const [bounds, setBounds] = useState([]);
  const popupElRef = useRef(null);
  const markerRef = useRef(null);
  let { load, data } = useSelector(
    ({ main }) => main.fleetTracking.fleetCalculation
  );
  const { entity: unit } = useSelector(({ main }) => main.settingsUnit.unit);
  console.log("new unit is ", unit);

  const icon = L.icon({
    iconSize: [30, 30],
    popupAnchor: [2, -20],
    iconUrl: "/assets/gps.png",
  });

  const blueIcon = new L.Icon({
    iconUrl:
      "https://github.com/pointhi/leaflet-color-markers/blob/master/img/marker-icon-2x-blue.png?raw=true",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    if (data && data.vehicle && bounds) {
      refresh();
      clearInterval();
    } else if (data && data.success) {
      console.log("come here");
      dispatch(getCalculation({ id: props.setSelected.id, type: 1 }));
    }
  }, [data]);

  useEffect(() => {
    // map.flyTo([unit.latestPosition.latitude,unit.latestPosition.longitude],18);
    if (map && unit && unit.latestPosition) {
      map.setView(
        [unit.latestPosition.latitude, unit.latestPosition.longitude],
        18
      );
    }
  }, [unit]);

  useEffect(() => {
    let polyliness = [];
    let dates = [];
    var bound = null;
    bound = L.latLngBounds();
    if (positions && positions.length > 0 && lines && lines.length > 0) {
      _.each(lines, function (line, index) {
        _.each(line.elements2, function (line2) {
          let latlngs = polyUtil.decode(line2.line, 5);
          polyliness.push({ polyline: latlngs, index: index });
          bound.extend(latlngs);
        });
        let date = moment(line.date.sec * 1000);
        dates.push(date.format("DD.MM.YYYY"));
      });
      setPolylines(polyliness);
      setDates(dates);
      setBounds(bound);
      setItem({});
      if (map) {
        map.fitBounds(bound);
      }
      //  setColors(setColor(lines.length));
    } else if (
      loadingGps == false &&
      loadingLines == false &&
      positions.length == 0 &&
      lines.length == 0
    ) {
      setPolylines(polyliness);
      dispatch(showMessage({ message: t("layout_default_no_data") }));
    }
  }, [lines, positions]);

  const greenIcon = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  // const latestPositionofUnit = [
  //   props.setSelected.latestPosition.latitude,
  //   props.setSelected.latestPosition.longitude,
  // ];

  function MyComponent() {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        handleData(e.latlng);
      },
    });
    return null;
  }

  return (
    <div>
      <MapContainer
        style={{ height: "510px", width: "100%" }}
        center={[53.49573, 8.62839]}
        zoom={6}
        minZoom={1}
        whenCreated={setMap}
        bounds={map ? map.getBounds() : null}
      >
        <MyComponent />
        {positions && positions.length > 0 && (
          <LegendFleet map={map} dates={date} />
        )}
        <Fullscreen />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {polylines.length > 0 &&
          polylines.map((polyline, index) => (
            <Polyline
              key={index}
              positions={polyline.polyline}
              color={colors[polyline.index]}
            ></Polyline>
          ))}
        {/* {props && props.setSelected.latestPosition.latitude && (
          <Marker
            position={latestPositionofUnit}
            icon={blueIcon}
            eventHandlers={{
              popupopen: (e) => {
                e.popup._closeButton.removeAttribute("href");
                e.popup._closeButton.style.cursor = "pointer";
              },
            }}
          >
            <Popup>
              <Card sx={{ maxWidth: 150 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="10"
                    image={getVehicleIcon(props.setSelected.constructionType)}
                    alt="blue iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      component="div"
                    >
                      {props.setSelected.nameKoco}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Date :{" "}
                      <Moment local={"de"} format="DD.MM.YYYY HH:mm">
                        {props.setSelected.updateDate}
                      </Moment>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Popup>
          </Marker>
        )} */}
        {unit && unit.id && (
          <Marker
            position={[
              unit.latestPosition.latitude,
              unit.latestPosition.longitude,
            ]}
            icon={icon}
          ></Marker>
        )}
        {item && item.latitude && (
          <Marker
            ref={markerRef}
            position={[item.latitude, item.longitude]}
            icon={greenIcon}
            eventHandlers={{
              click: () => {
                console.log("marker clicked");
              },
            }}
          >
            <Popup ref={popupElRef} closeButton={false}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="50"
                    image={getVehicleIcon(props.setSelected.constructionType)}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {props.setSelected.nameKoco}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Coordinates: {item.latitude}, {item.longitude},{" "}
                      {item.altitude}
                    </Typography>
                    {item.speed && item.speed > -1 && (
                      <Typography variant="body2" color="text.secondary">
                        Speed: {item.speed} Km/h
                      </Typography>
                    )}
                    <Typography variant="body2" color="text.secondary">
                      Date :{" "}
                      <Moment local={"de"} unix format="DD.MM.YYYY HH:mm">
                        {item.date.sec}
                      </Moment>
                    </Typography>
                    <Typography variant="body2">{text}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Popup>
          </Marker>
        )}
        {positions &&
          positions.length > 0 &&
          positions.map((markerGroup, index2) => (
            <HeatmapLayer
              key={index2}
              points={markerGroup.elements2}
              longitudeExtractor={(m) => m.longitude}
              latitudeExtractor={(m) => m.latitude}
              gradient={{ 0: colors[index2], 1: colors[index2] }}
              blur={1}
              minOpacity={1}
              radius={1}
              intensityExtractor={(m) => m.value}
            />
          ))}
        <Control position="topright">
          <Button color="inherit" onClick={() => closeMenu()}>
            <Close />
          </Button>
          <Button color="inherit" onClick={() => refresh()}>
            <Refresh />
          </Button>
          <Button color="inherit" onClick={() => truckPosition()}>
            <GpsFixed />
          </Button>
        </Control>
      </MapContainer>
    </div>
  );
  function handleData(latlng) {
    let selectedItem = null;
    let selectedItem2 = {};
    let shortDistance = -1;
    let canpopUp = false;
    for (let j = 0; j < positions.length; j++) {
      let pos = positions[j].elements2;
      for (let i = 0; i < pos.length; i++) {
        // if this location is within 0.1KM of the user, add it to the list
        let dis = distance(
          latlng.lat,
          latlng.lng,
          pos[i].latitude,
          pos[i].longitude,
          "K"
        );
        if (dis <= 0.1) {
          if (dis < shortDistance) {
            selectedItem = pos[i];
            shortDistance = dis;
            canpopUp = true;
          } else if (shortDistance == -1) {
            shortDistance = dis;
            selectedItem = pos[i];
            canpopUp = true;
          }
          //break;
        } else if (dis < 5 && shortDistance == -1) {
          shortDistance = dis;
          selectedItem2 = pos[i];
        } else if (dis < 5 && dis < shortDistance) {
          shortDistance = dis;
          selectedItem2 = pos[i];
        }
      }
      if (!selectedItem) {
        selectedItem = selectedItem2;
      }
    }
    selectedItem && geocoder.reverse(
      { lat: selectedItem.latitude, lng: selectedItem.longitude },
      1,
      (results) => {
        var r = results[0];
        if (r) {
          console.log(r);
        
          setText("Address :" + (r.name ? r.name : r.html));
        }
      }
    );
    // map.flyTo([selectedItem.latitude, selectedItem.longitude], 18);
    // map.setView([selectedItem.latitude, selectedItem.longitude], 18);
    setItem(selectedItem);
    const marker = markerRef.current;
    if (marker && canpopUp) {
      // marker.openPopup()
    }
  }

  function distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }

  function closeMenu() {
    props.setDisplayMapFleet(false);
    props.setNewUnit("");
    History.replace("/fleetTracking");
  }

  function refresh() {
    props.sendRequest(false);
  }

  function truckPosition() {
    dispatch(getUnit(props.setSelected.id));
  }
}
