import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import deLocale from 'date-fns/locale/de';
import moment from "moment";
import de from 'date-fns/locale/de';
import {getLines} from "../store/FleetTrackingLines";
import {getLiveData} from "../store/FleetTrackingLiveData";
import {useTranslation} from "react-i18next";
import {setIsDriving} from "../store/FleetTrackingSlice";
import {getCalculation} from "../store/FleetTrackingCalculation";
import {showMessage} from "../../../store/fuse/messageSlice";
import FuseLoading from "../../../../@fuse/core/FuseLoading";

function CustomDialogDate(props) {
    const [open, setOpen] = useState(props.open);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const success = useRef();
    const handleClose = () => {
        props.setOpen(false);
        setOpen(false);
    };
    let { load,data } = useSelector(({ main }) => main.fleetTracking.fleetCalculation);
    success.current = data;
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [value, setValue] = useState(new Date());
    return (
        <div>
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('chose_a_date')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                    <DatePicker
                        value={value}
                        onChange={(newValue) => {
                           onChangeEvent(newValue,props.id);
                        }}
                        format="DD-MM-YYYY"
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>{t('layout_default_cancel')}</Button>
                <Button onClick={() => handleData(props.id,value,props.row)}>
                    {t('layout_default_save')}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );

    function onChangeEvent(newValue) {
        setValue(newValue);
    }
    function handleData(id,value,row) {
        let dateFormat =moment(value).format("YYYY-MM-DD");
        let today = moment().format("YYYY-MM-DD");
        if (today == dateFormat) {
            if (row.isActivToday && row.isStillRunning == false) {
                dispatch(getCalculation({id: id, type: 0}));
                dispatch(showMessage({ message: t('fleet_tracking_notification_gps_calculation') }));
                props.setAnchorEl(null);
                handleClose();
                let interval = setInterval(function () {
                    if (success.current && success.current.vehicle) {
                        dispatch(getLines({id: id, date: dateFormat}));
                        dispatch(setIsDriving(id));
                        props.setDisplayMapFleet(true);
                        clearInterval(interval);
                    }
                    dispatch(getCalculation({id: id, type: 1}));
                }, 3000);
            } else if (row.isActivToday ==false) {
                props.setAnchorEl(null);
                handleClose();
                dispatch(showMessage({message : t('truck_not_on_tour_today')}));
            } else {
                dispatch(getLines({id: id, date: dateFormat}));
                dispatch(setIsDriving(id));
                props.setDisplayMapFleet(true);
                props.setAnchorEl(null);
                handleClose();
            }
        }  else {
            dispatch(getLines({id: id, date: dateFormat}));
            dispatch(setIsDriving(id));
            props.setDisplayMapFleet(true);
            props.setAnchorEl(null);
            handleClose();
        }
    }
}

export default CustomDialogDate;
