import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  lines: [],
  loadingLines: false
}

export const getAllLines = createAsyncThunk('fleetTracking/units/lines', async (obj) => {
  const {id,date,date2} = obj;
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+id+ apiUrlConfig.GET_LINE_AREA, { params: { date: date, date2:date2 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const fleetTrackingLinesForMiniMapSlice = createSlice({
  name: 'fleetTracking/linesAll',
  initialState,
  reducers: {
    setClearLines: {
      reducer: (state, action) => {
        state.lines = []
      }
    }
  },
  extraReducers: {
    [getAllLines.pending]: (state) => {
      state.loadingLines = true
      state.lines = []
    },
    [getAllLines.fulfilled]: (state, { payload }) => {
      state.loadingLines = false
      state.lines = payload
    },
    [getAllLines.rejected]: (state) => {
      state.loadingLines = false
      state.lines = []
    },
  },
});
export const postReducer = fleetTrackingLinesForMiniMapSlice.reducer;
export const { setClearLines } = fleetTrackingLinesForMiniMapSlice.actions;

export default fleetTrackingLinesForMiniMapSlice.reducer;