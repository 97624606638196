import { combineReducers } from '@reduxjs/toolkit';

import unitsReports from "./unitsReports";
import signalsReports from "./signalsReports";
import oldData from "./oldDataReports";
const reducer = combineReducers({
   unitsReports,
    signalsReports,
    oldData
});

export default reducer;
