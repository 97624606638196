import L from "leaflet";
import { useEffect } from "react";
import "../css/legend.css";
import {useTranslation} from "react-i18next";


function Legend({ map }) {
    console.log(map);
    const { t } = useTranslation('common');
    useEffect(() => {
        if (map) {
            const legend = L.control({ position: "bottomright" });
            if (document.getElementsByClassName('legend').length == 0) {
                legend.onAdd = function () {

                    var div = L.DomUtil.create('div', 'info legend'),
                        grades = [t('fleet_tracking_legend_running'), t('fleet_tracking_legend_stop')],
                        labels = ["/assets/images/vehicles/avariopress.png", "/assets/images/loading.png"];

                    // loop through our density intervals and generate a label with a colored square for each interval
                    for (var i = 0; i < grades.length; i++) {
                        div.innerHTML +=
                            (" <img src=" + labels[i] + " height='25' width='25'>")+ grades[i]+ '<br>';
                    }

                    return div;
                };

                legend.addTo(map);
                map.dragging.enable();
            }
        }
    }, [map]); //here add map
    return null;
}

export default Legend;