import { combineReducers } from "@reduxjs/toolkit";
import serviceTickets from "./ServiceTicketsSlice";
import serviceTicket from "./ServiceTicketSlice";

const reducer = combineReducers({
  serviceTickets,
  serviceTicket,
});

export default reducer;
