import {combineReducers} from '@reduxjs/toolkit';

import unitsReports from "./unitsReports";
import signalsReports from "./signalsReports";
import newData from "./newReportsData";

const reducer = combineReducers({
    unitsReports,
    signalsReports,
    newData
});

export default reducer;
