import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getUnit = createAsyncThunk('main/settingsUnit/Unit/getUnit', async (id) => {
  const response = await axios.get(`api/v1/units/${id}`, { headers: { 'X-UI-State': 'unit.settings.units' }});
  const responseCompanies = await axios.get(`api/v1/passivevehicles/${id}/company`);
  let data = await response.data;
  if(!data || data === undefined)  
    return null;

  const dataCompanies = await responseCompanies.data;

  if(data && dataCompanies && dataCompanies.passivevehiclecompanyaccesses){
    data.companyAccesses = dataCompanies.passivevehiclecompanyaccesses.map(access => access.company);
  }

  return data;

});

export const patchUnit = createAsyncThunk('main/settingsUnit/Unit/patchUnit', async (unit, { getState }) => {
  const { main } = getState();
  // const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(unit);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'passivevehicle',clone);
  console.log('unit to patch', payload);
  
  const response = await axios.patch(`api/v1/passivevehicles/${unit.id}`, payload);

  const data = await response.data;

  return data;
});

export const postUnit = createAsyncThunk('main/settingsUnit/Unit/postUnit', async (unit, { getState }) => {
  const { main } = getState();
  // const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(unit);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'passivevehicle', clone);

  const response = await axios.post(`api/v1/units`, payload);

  const data = await response.data;

  return data;
});

export const activateUnit = createAsyncThunk('main/settingsUnit/Unit/activateUnit', async (unit, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/units/${unit.id}/restore`);
  const data = await response.data;

  return data;
});

export const deleteUnit = createAsyncThunk('main/settingsUnit/Unit/deleteUnit', async (unit, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/units/${unit.id}`);
  const data = await response.data;

  return data;
});

const generateNewunit = () => {
  return {
    id: '',
    company: '',
    firstName: '',
    lastName: '',
    companyAccesses: [],
    email: '',
    groups: [],
    id: '',
    lastLogin: '',
    locale: '',
    mobileNumber: '',
    roleKocos: [],
    timezone: '',
    unitType: '',
    unitname: '',
  }
};

const productSlice = createSlice({
  name: 'main/settingsUnit/unit',
  initialState: {
    loadingUnit: false,
    entity: null,
  },
  reducers: {
    resetUnit: {
      reducer: (state, action) => {
        console.log("reset unit called.");
        state.entity = generateNewunit();
        state.tabValue = 0;
        state.loadingCompany = false;
      },
    },
    newUnit: {
      reducer: (state, action) => {
        state.entity = generateNewunit();
        state.entity.id = 'new';
      },
    },
  },
  extraReducers: {
    [getUnit.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingUnit = false;
    },
    [getUnit.pending]: (state) => {
      state.loadingUnit = true;
      state.entity = null;
    },
    [getUnit.rejected]: (state) => {
      state.loadingUnit = false;
    },

    [patchUnit.fulfilled](state, { payload }) {
      state.savingUnit = false;
    },
    [patchUnit.pending](state, { payload }) {
      state.savingUnit = true;
    },
    [patchUnit.rejected](state, { payload }) {
      state.savingUnit = false;
    },

    [postUnit.fulfilled](state, { payload }) {
      state.savingUnit = false;
    },
    [postUnit.pending](state, { payload }) {
      state.savingUnit = true;
    },
    [postUnit.rejected](state, { payload }) {
      state.savingUnit = false;
    },

    [activateUnit.fulfilled](state, { payload }) {
      state.savingUnit = false;
    },
    [activateUnit.pending](state, { payload }) {
      state.savingUnit = true;
    },
    [activateUnit.rejected](state, { payload }) {
      state.savingUnit = false;
    },

    [deleteUnit.fulfilled](state, { payload }) {
      state.savingUnit = false;
    },
    [deleteUnit.pending](state, { payload }) {
      state.savingUnit = true;
    },
    [deleteUnit.rejected](state, { payload }) {
      state.savingUnit = false;
    },
  },
});

export const { newUnit, resetUnit } = productSlice.actions;

export default productSlice.reducer;
