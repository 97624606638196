import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourPlans = createAsyncThunk('main/taTours/taTours/getStTourPlans2', async (searchParams) => {
  const response = await axios.get(`api/v1/sttourplans`, {
    params:
        {
          offset: searchParams.offset,
          limit: searchParams.limit,
          fromDateTime: searchParams.fromDateTime,
          toDateTime: searchParams.toDateTime,
          unit: searchParams.unit,
          classifier: searchParams.classifier,
          tour: searchParams.tour

        }, headers: { 'X-UI-State': 'stTourPlan.settings.taTourPlans' } });
  const data = await response.data;

  return { sttourplans: data.sttourplans, totalcount: data.totalcount };
});
export const postStToursManuel = createAsyncThunk('main/taTours/taTours/post2', async (stTourPlan) => {
  //const { main } = getState();

  let clone = _.cloneDeep(stTourPlan);
  let tours = {tours : clone};
  let payload = AxiosInterceptorUtils.preparePayload('post', 'sttours',tours);

  const response = await axios.post(`api/v1/sttours/multis`, tours);

  const data = await response.data;

  return data;
});
export const postDuplication = createAsyncThunk('main/taTours/taTours/post/duplication2', async (stTourPlan) => {
  //const { main } = getState();

  let clone = _.cloneDeep(stTourPlan);
  let tours = {name : clone};
  let payload = AxiosInterceptorUtils.preparePayload('post', 'sttours',tours);

  const response = await axios.post(`api/v1/sttours/names`, tours);

  const data = await response.data;

  return data;
});

export const getUnits = createAsyncThunk('main/tourAnalyseTour/taTours/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const patchStTourPlan = createAsyncThunk('main/tourAnalyseTour/taTours/patchTaTourPlan', async (stTourPlan, { getState }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlan);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'sttours',clone);
  console.log('stTourPlan to patch', payload);

  const response = await axios.patch(`api/v1/sttours/${stTourPlan.id}`, payload);

  const data = await response.data;

  return data;
});

export const postBatchTaTour = createAsyncThunk('main/tourAnalyseTour/taTourNew/postBatchTaTourPlan', async (taTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(taTourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'sttours', row)); });

  try {
    const response = await axios.post(`api/v1/newbatchsttours`, { st_tours: payload });
    console.log('response', response);
    const data = await response.data;
    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data is duplicated', data: data }});
    }
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const patchBatchStTour = createAsyncThunk('main/tourAnalyseTour/taTourNew/patchBatchTaTourPlan', async (taTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(taTourPlans.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'sttours', row)); });

  try {
    const response = await axios.patch(`api/v1/newbatchsttours`, { st_tours: payload });
    console.log('response', response);
    const data = await response.data;
    if (data && data.length > 0) {

      return rejectWithValue({ error: { message: 'data is duplicated', data: data }});
    }
    return data;
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const deleteStTourPlan = createAsyncThunk('main/tourAnalyseTour/taTours/patchTaTourPlan', async (stTourPlan, { getState }) => {
  const response = await axios.delete(`api/v1/sttours/${stTourPlan}`);
  return response;
});

export const getTaTours = createAsyncThunk('main/tourAnalyseTour/taTours/getStTours2', async (searchParams) => {
  const response = await axios.get(`api/v1/sttour`, { params: searchParams , headers: { 'X-UI-State': 'stTourPlan.settings.taTourPlans' } });
  const data = await response.data;

  return data;
});

export const getStTourPlansByCompany = createAsyncThunk('main/tourAnalyseTour/taTours/getTaTourPlansByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/taTourPlans`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.taTourPlans' } });
  const data = await response.data;

  return data.sttourplans;
});

export const getRoles = createAsyncThunk('main/tourAnalyseTour/taTours/getRoles', async () => {
  const response = await axios.get(`api/v1/rolekocos`, { params: { limit: 0 } });
  const data = await response.data;

  return data.rolekocos;
});

const taTourPlansAdapter = createEntityAdapter({});

export const { selectAll: selectTaTourPlans, selectById: selectTaTourPlanById } =
    taTourPlansAdapter.getSelectors((state) => state.main.tourAnalyseTour.taTour.taTourPlans);

const taToursAdapter = createEntityAdapter({});

export const { selectAll: selectTaTours, selectById: selectTaTourById } =
    taToursAdapter.getSelectors((state) => state.main.tourAnalyseTour.taTour.taTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
    unitsAdapter.getSelectors((state) => state.main.tourAnalyseTour.taTour.units);

const rolesAdapter = createEntityAdapter({});

export const { selectAll: selectRoles, selectById: selectRoleById } =
    rolesAdapter.getSelectors((state) => state.main.tourAnalyseTour.taTour.roles);

const taTourSlice = createSlice({
  name: 'main/tourAnalyseTour/taTour',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: 10,
    loadingTaTourPlans: false,
    loadingUnits: false,
    errorDuplicationName:  false,
    savingTaTourPlan: false,
    deletingTaTourPlan: false,
    loadingTaTours: false,
    taToursAdded:[{"id" : 1, "name" : "", "type":1}],
    taTourPlans: taTourPlansAdapter.getInitialState({ }),
    taTourPlansCount: 0,
    taTours: taToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    roles: rolesAdapter.getInitialState({ }),
    hasError: false,
    isDuplicate: false,
    hasErrorInData: 0,
    isDataDirty: false,
    isDataDirtyNew: false,
    errorOnSave: null,
  },
  reducers: {
    setStTourPlansSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
        //state.isDataDirty = true;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    addStTour: {
      reducer: (state, action) => {
        state.taToursAdded.push({"id" : (state.taToursAdded.length)+1, "name" : "","type":1});
      }
    },
    setStTour: {
      reducer: (state, action) => {
        state.taToursAdded = action.payload;
      }
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setIsDataDirtyNew: {
      reducer: (state, action) => {
        state.isDataDirtyNew = action.payload;
      },
    },
    setHasError: {
      reducer: (state, action) => {
        state.hasError = action.payload;
      },
    },
    setIsDuplicate: {
      reducer: (state, action) => {
        state.isDuplicate = action.payload;
      },
    },
    setHasErrorInData: {
      reducer: (state, action) => {
        state.hasErrorInData = action.payload;
      },
    },
    setDataTaTourPlans: {
      reducer: (state, action) => {
        state.dataTaTourPlans = action.payload;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    resetTaTours: {
      reducer: (state, action) => {
        state.isDataDirty = false;
        state.isDataDirtyNew = false;
        state.errorOnSave = null;
        state.dataTaTourPlans = [];
        taTourPlansAdapter.removeAll(state.taTourPlans);
        state.loadingTaTourPlans = false;
        state.savingTaTourPlan = false;
        state.hasError = false;
      },
    },
  },
  extraReducers: {
    [getStTourPlans.fulfilled](state, { payload }) {
      state.errorOnSave = null;
      taTourPlansAdapter.setAll(state.taTourPlans, payload.sttourplans);
      state.taTourPlansCount = payload.totalcount;
      state.loadingTaTourPlans = false;
    },
    [getStTourPlans.pending]: (state) => {
      state.loadingTaTourPlans = true;
      taTourPlansAdapter.removeAll(state.taTourPlans);
    },
    [getStTourPlans.rejected]: (state) => {
      state.loadingTaTourPlans = false;
    },

    [postBatchTaTour.fulfilled](state, { payload }) {
      state.savingTaTourPlan = false;
      state.isDataDirty = false;
      state.isDataDirtyNew = false;
    },
    [postBatchTaTour.pending]: (state) => {
      state.savingTaTourPlan = true;
      state.errorOnSave = null;
    },
    [postBatchTaTour.rejected]: (state, { payload }) => {
      state.savingTaTourPlan = false;
      state.errorOnSave = payload?.error;
    },
    [patchBatchStTour.fulfilled](state, { payload }) {
      state.savingTaTourPlan = false;
      //state.isDataDirty = true;
    //  taToursAdapter.removeAll(state.taTours);
    },
    [patchBatchStTour.pending]: (state) => {
      state.errorOnSave = null;
      state.savingTaTourPlan = true;
    },
    [patchBatchStTour.rejected]: (state, { payload }) => {
      state.isDataDirty = false;
      state.isDataDirtyNew = false;
      state.savingTaTourPlan = false;
      state.errorOnSave = payload?.error;
    },
    [patchStTourPlan.fulfilled](state, { payload }) {
      state.savingTaTourPlan = false;
    },
    [patchStTourPlan.pending](state, { payload }) {
      state.savingTaTourPlan = true;
    },
    [patchStTourPlan.rejected](state, { payload }) {
      //state.savingTaTourPlan = false;
    },

    [postDuplication.fulfilled](state, { payload }) {
      state.errorDuplicationName = false;
    },
    [postDuplication.pending](state, { payload }) {
      state.errorDuplicationName = payload.duplication;
    },
    [postDuplication.rejected](state, { payload }) {
      state.errorDuplicationName = false;
    },

    [deleteStTourPlan.fulfilled](state, { payload }) {
     // state.savingTaTourPlan = false;
    },
    [deleteStTourPlan.pending](state, { payload }) {
     // state.savingTaTourPlan = true;
    },
    [deleteStTourPlan.rejected](state, { payload }) {
      //state.savingTaTourPlan = false;
    },

    [getTaTours.fulfilled](state, { payload }) {
      taToursAdapter.setAll(state.taTours, payload.st_tours);
      state.loadingTaTours = false;
      //state.taToursAdded =  [{"id" : 1, "name" : "", "type":1}]
      state.taTourPlansCount = payload.count;
      state.isDataDirty =false;
      state.isDataDirtyNew =false;
    },
    [getTaTours.pending]: (state) => {
      state.loadingTaTours = true;
      taToursAdapter.removeAll(state.taTours);
      state.taToursAdded =  [{"id" : 1, "name" : "", "type":1}]
      state.taTourPlansCount = 0;
    },
    [getTaTours.rejected]: (state) => {
      state.loadingTaTours = false;
      //state.savingTaTourPlan = false;
      state.taTourPlansCount = 0;

    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getRoles.fulfilled](state, { payload }) {
      rolesAdapter.setAll(state.roles, payload);
      state.loadingRoles = false;
    },
    [getRoles.pending]: (state) => {
      state.loadingRoles = true;
      rolesAdapter.removeAll(state.roles);
    },
    [getRoles.rejected]: (state) => {
      state.loadingRoles = false;
    },

    [getStTourPlansByCompany.fulfilled](state, { payload }) {
      taTourPlansAdapter.setAll(state.taTourPlans, payload);
      state.loadingTaTourPlans = false;
    },
    [getStTourPlansByCompany.pending]: (state) => {
      state.loadingTaTourPlans = true;
      taTourPlansAdapter.removeAll(state.taTourPlans);
      state.loadingTaTourPlans = false;
    },
    [getStTourPlansByCompany.rejected]: (state) => {
      state.loadingTaTourPlans = false;
    },
  },
});

export const { setStTourPlansSearchText,addStTour,setStTour,setHasError,setIsDuplicate,setIsDataDirty, setIsDataDirtyNew, setDataTaTourPlans, setPage, setPageSize, setHasErrorInData, setRowsPerPage, resetTaTours} = taTourSlice.actions;

export default taTourSlice.reducer;
