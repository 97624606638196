import { motion } from "framer-motion";
import HeaderWidget from "./Widgets/HeaderWidget";

function FleetTrackingHeader(props) {
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <motion.div
        variants={item}
        className="widget w-full items-center justify-between"
      >
        <HeaderWidget />
      </motion.div>
    </div>
  );
}

export default FleetTrackingHeader;
