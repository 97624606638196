import React, { lazy } from 'react';
import authRoles from "../../auth/authRoles";

const TaTourNewTable = lazy(() => import('./inserTour/TaTourNew'));
const TaTour = lazy(() => import('./taTour/TaTour'));

const TaTourConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'tatours',
      element: <TaTour />,
      auth: [authRoles.ROLE_TA_TOUR_READ], 
    },
    {
      path: 'tainsertTour/new',
      element: <TaTourNewTable mode="new" />,
      auth: [authRoles.ROLE_TA_TOUR_WRITE],
    },
    {
      path: 'tainsertTour/import',
      element: <TaTourNewTable mode="import" />,
      auth: [authRoles.ROLE_TA_TOUR_WRITE],
    }
  ],
};

export default TaTourConfig;
