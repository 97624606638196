import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  entities: [],
  filter: [],
  load: false
}

export const getGeofences = createAsyncThunk('scantec/geofences', async () => {
  const response = await axios.get(apiUrlConfig.GET_GEO_FENCES, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.features;
});


export const geofencesScantecSlice = createSlice({
  name: 'scantec/geofences',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getGeofences.pending]: (state) => {
      state.load = true
    },
    [getGeofences.fulfilled]: (state, { payload }) => {
      state.load = false
      state.entities = payload;
      state.filter = payload.map(function(el) {
        return {"id" : el.id, "title" : el.properties.name};
      });
    },
    [getGeofences.rejected]: (state) => {
      state.load = false
    },
  },
});
export const postReducer = geofencesScantecSlice.reducer;
export default geofencesScantecSlice.reducer;