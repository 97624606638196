import { combineReducers } from '@reduxjs/toolkit';
import unitDetail from './unitDetailSlice';
import errorReport from './ErrorCodeSlice';
import faunErrorReport from './FaunErrorCodeSlice';
import bodyParameterRead from './BodyParameterReadSlice';
import lifterParameterRead from './LifterParameterReadSlice';
import bodyParameterUpdate from './BodyParameterUpdateSlice';
import lifterParameterUpdate from './LifterParameterUpdateSlice';
import serviceBook from './ServiceBookSlice';
import serviceInspections from './ServiceInspectionsSlice';
import signals from './SignalsSlice';
import fleetTrackingLinesForMiniMap from "./FleetTrackingLinesAllSlice";
import fleetTrackingGpsForMiniMap from "./FleetTrackingGpsAllSlice";
import fleetCalculationForMiniMap from "../../fleetTracking/store/FleetTrackingCalculation";
import stDeepscanSettings from "./StDeepscanSettingsSlice";
import handOvers from './HandOversSlice';

const reducer = combineReducers({
  unitDetail,
  errorReport,
  faunErrorReport,
  bodyParameterRead,
  lifterParameterRead,
  bodyParameterUpdate,
  fleetTrackingLinesForMiniMap,
  fleetTrackingGpsForMiniMap,
  fleetCalculationForMiniMap,
  lifterParameterUpdate,
  serviceBook,
  signals, 
  handOvers,
  stDeepscanSettings,
  serviceInspections
});

export default reducer;
