import { combineReducers } from '@reduxjs/toolkit';
import user from './UserSlice';
import users from './UsersSlice';

const reducer = combineReducers({
  users,
  user,
});

export default reducer;
