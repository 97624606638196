import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";

export const getStClassifier = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/getStClassifier",
  async (id) => {
    const response = await axios.get(`/api/v1/stclassifiers/${id}`, {
      headers: { "X-UI-State": "stClassifier.settings.stClassifier" },
    });
    const data = await response.data;
    return data === undefined ? null : data;
  }
);

export const getStInfoClassifiers = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/getStInfoClassifiers",
  async (id) => {
    const response = await axios.get(`/api/v1/stinfoclassifiers`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "stClassifier.settings.stInfoClassifiers" },
    });
    const data = await response.data;
    return data === undefined ? null : data;
  }
);

export const getStAiModels = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/getStAiModels",
  async (id) => {
    const response = await axios.get(`/api/v1/staimodels`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "stClassifier.settings.stAiModels" },
    });
    const data = await response.data;
    return data === undefined ? null : data;
  }
);

export const getDuplicateCheck = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/getDuplicateCheck",
  async (name) => {
    const response = await axios.get(
      `/api/v1/stclassifierduplicate?name=${name}
    `
    );
    const data = await response.data;
    return data === undefined ? null : data;
  }
);

export const postStClassifier = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/postStClassifier",
  async (stClassifier, { getState }) => {
    let clone = _.cloneDeep(stClassifier);
    let payload = AxiosInterceptorUtils.preparePayload(
      "post",
      "stClassifier",
      clone
    );
    const response = await axios.post(`/api/v1/stclassifiers`, {
      st_classifier: payload.stClassifier,
    });
    const data = await response.data;
    return data;
  }
);

export const disableStClassifier = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/disableStClassifier",
  async (id, { getState }) => {
    const response = await axios.delete(`/api/v1/stclassifiers/${id}`);
    const data = await response.data;
    return data;
  }
);

export const enableStClassifier = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/enableStClassifier",
  async (id, { getState }) => {
    const response = await axios.patch(`/api/v1/stclassifiers/${id}/enable`);
    const data = await response.data;
    return data;
  }
);

export const patchStClassifier = createAsyncThunk(
  "main/settingsStClassifier/stClassifier/patchStClassifier",
  async (stClassifier, { getState }) => {
    const { main } = getState();
    let clone = _.cloneDeep(stClassifier);
    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "stClassifier",
      clone
    );

    const response = await axios.patch(
      `/api/v1/stclassifiers/${stClassifier.id}`,
      {
        st_classifier: payload.stClassifier,
      }
    );

    const data = await response.data;
    // return data;
  }
);

const generateNewStClassifier = () => {
  return {
    id: "",
    // stClassifers: [],
    name: "",
    description: "",
    isDeleted: false,
    isPublic: true,
    allowedThresholds: {
      bad: 70,
      good: 90,
    },
    allowed: [],
    notAllowed: [],
    notAllowedThresholds: {
      bad: 30,
      good: 10,
    },
  };
};

const stClassifierSlice = createSlice({
  name: "main/settingsStClassifier/stClassifier",
  initialState: {
    loadingStClassifier: false,
    loadingStClassifiers: false,
    entity: null,
    loadingStInfoClassifiers: false,
    savingStClassifier: false,
    stInfoClassifiers: null,
    stAiModels: null,
    loadingStAiModels: false,
    hasError: false,
    isDuplicate: false,
  },
  reducers: {
    setHasError: {
      reducer: (state, action) => {
        state.hasError = action.payload;
      },
    },
    newStClassifier: {
      reducer: (state, action) => {
        // state.loadingDepot = false;
        state.entity = generateNewStClassifier();
        state.entity.id = "new";
      },
    },
    setIsDuplicate: {
      reducer: (state, action) => {
        state.isDuplicate = action.payload;
        // state.loadingStClassifier = false;
      },
    },
    resetStClassifier: {
      reducer: (state, action) => {
        state.entity = generateNewStClassifier();
        state.loadingStClassifier = false;
      },
    },
  },
  extraReducers: {
    [getStClassifier.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingStClassifier = false;
    },
    [getStClassifier.pending]: (state) => {
      state.loadingStClassifier = true;
    },
    [getStClassifier.rejected]: (state) => {
      state.loadingStClassifier = false;
    },
    [getStInfoClassifiers.fulfilled](state, { payload }) {
      state.stInfoClassifiers = payload;
      state.loadingStInfoClassifiers = false;
    },
    [getStInfoClassifiers.pending]: (state) => {
      state.loadingStInfoClassifiers = true;
    },
    [getStInfoClassifiers.rejected]: (state) => {
      state.loadingStInfoClassifiers = false;
    },
    [getStAiModels.fulfilled](state, { payload }) {
      state.stAiModels = payload;
      state.loadingStAiModels = false;
    },
    [getStAiModels.pending]: (state) => {
      state.loadingStAiModels = true;
    },
    [getStAiModels.rejected]: (state) => {
      state.loadingStAiModels = false;
    },

    [getDuplicateCheck.fulfilled](state, { payload }) {
      state.isDuplicate = payload?.duplicated;
    },
    [getDuplicateCheck.pending]: (state) => {
      state.isDuplicate = null;
    },
    [getDuplicateCheck.rejected]: (state) => {
      state.isDuplicate = null;
    },
    [patchStClassifier.fulfilled](state, { payload }) {
      state.savingStClassifier = false;
      // state.isDataDirty = false;
      // state.dataStTourPlans = [];
    },
    [patchStClassifier.pending](state, { payload }) {
      // state.errorOnSave = null;
      state.savingStClassifier = true;
    },
    [patchStClassifier.rejected](state, { payload }) {
      // state.errorOnSave = payload?.error;
      state.savingStClassifier = false;
    },
  },
});

export const {
  setHasError,
  newStClassifier,
  resetStClassifier,
  setIsDuplicate,
} = stClassifierSlice.actions;

export default stClassifierSlice.reducer;
