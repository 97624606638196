import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  signals: [],
  loading: false,
  position:{},
  unloading : {},
}

export const getSignals = createAsyncThunk('fleetTracking/units/signals', async (obj) => {
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+obj+ apiUrlConfig.GET_SIGNALS, { params: { limit: 0,type:4 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.signals;
});


export const fleetSignalsSlice = createSlice({
  name: 'fleetTracking/signals',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getSignals.pending]: (state) => {
      state.loading = true
    },
    [getSignals.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.signals = payload
      state.position=_.find(payload, (element) => {
        return (element._id.type == 8 || element._id.type == 31) ? true : false;
      });
      state.unloading=_.find(payload, (element) => {
        return (element._id.type == 7) ? true : false;
      });
    },
    [getSignals.rejected]: (state) => {
      state.loading = false
    },
  },
});
export const postReducer = fleetSignalsSlice.reducer;
export default fleetSignalsSlice.reducer;