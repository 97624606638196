import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StRegions = lazy(() => import('./st-regions/StRegions'));
const StRegionTour = lazy(() => import('./st-region-tour/StRegion'));

const SettingsStRegionConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'stregions',
      element: <StRegions />,
      auth: [authRoles.ROLE_ST_REGION_READ], 
    },
    {
      path: 'stregions/:stRegionId/*',
      element: <StRegionTour />,
    },
  ],
};

export default SettingsStRegionConfig;
