import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDepots = createAsyncThunk('main/settingsDepot/depots/getDepots', async () => {
    const response = await axios.get(`api/v1/depots`, { params: { limit: 0 },  headers: { 'X-UI-State': 'ecotecFleet' }});
    const data = await response.data;
    return data.depots;
  });

const depotsAdapter = createEntityAdapter({selectId: (model) => model.id});

export const { selectAll: selectDepots, selectById: selectDepotById } =
depotsAdapter.getSelectors((state) => { return state.main.settingsDepot.depots.depots });

const depotsSlice = createSlice({      
    name: 'main/settingsDepot/depots',
    initialState: {
      searchText: '',
      loadingDepots: false,
      depots: depotsAdapter.getInitialState({ }),
    //   roles: rolesAdapter.getInitialState({ }),
    },
    reducers: {
      setDepotsSearchText: {
        reducer: (state, action) => {
          state.searchText = action.payload;
        },
        prepare: (event) => ({ payload: event.target.value || '' }),
      },
      resetDepots: {
        reducer: (state, action) => {
          depotsAdapter.setAll(state.depots, "");
          state.loadingDepots = false;
        },
      },
    },
    extraReducers: {
      [getDepots.fulfilled](state, { payload }) {
        depotsAdapter.setAll(state.depots, payload);
        state.loadingDepots = false;
      },
      [getDepots.pending]: (state) => {
        state.loadingDepots = true;
        depotsAdapter.removeAll(state.depots);
      },
      [getDepots.rejected]: (state) => {
        state.loadingDepots = false;
      },
    },
  });
  
  export const { setDepotsSearchText, resetDepots } = depotsSlice.actions;
  
  export default depotsSlice.reducer;
