import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getTourViews = createAsyncThunk('main/tourAnalyseOverview/getTourViews', async (searchParams) => {
  const response = await axios.post(`api/v1/vehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      deviceType: searchParams.deviceType,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourOverview' } });
  let data = await response.data;
  if(data.vehicletoursummaries) {
    data.vehicletoursummaries = 
    data.vehicletoursummaries.map(item => { 
      if(item.tourCalculatedData){
        item.stOtherMaterial = item.tourCalculatedData.otherMaterial;
        item.stTotal = item.tourCalculatedData.total;
        item.stOkCount = item.tourCalculatedData.okCount;
        item.stMiddleCount = item.tourCalculatedData.middleCount;
        item.stNotOkCount = item.tourCalculatedData.notOkCount;
        item.stNotEmptied = item.tourCalculatedData.notEmptied;
        item.stNotOkPercentage = item.tourCalculatedData.notOkPercentage;
      }

      return item;
    } );
  }

  return { tourOverview: data.vehicletoursummaries, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/tourAnalyseOverview/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/tourAnalyseOverview/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourOverview' } });
  const data = await response.data;

  return data.st_tours;
});

export const getTourViewFilters = createAsyncThunk('main/tourAnalyseOverview/getTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/vehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'filter' } });
  let data = await response.data;

  return { stAnalysis: data.vehicletoursummaries, totalcount: data.totalcount };
});

export const patchBatchTourOverview = createAsyncThunk('main/tourAnalyseOverview/patchBatchTourOverview', async (stTourOverviews, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourOverviews.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'touroverview', row)); });

  try {
    const response = await axios.patch(`api/v1/batchvehicletoursummary`, { st_tour_overviews: payload });
    const data = await response.data;
    return data;
  } catch (err) {

    return rejectWithValue({ error: { message: 'error in saving data', data: err.response.data } });
  }

});

const tourViewAdapter = createEntityAdapter({});

export const { selectAll: selectTourViews, selectById: selectStTourViewById } =
  tourViewAdapter.getSelectors((state) => state.main.tourAnalyseOverview.tourViews);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.tourAnalyseOverview.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.tourAnalyseOverview.units);

const tourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectTourViewFilters, selectById: selectStTourViewFilterById } =
  tourViewFiltersAdapter.getSelectors((state) => state.main.tourAnalyseOverview.tourViewFilters);

const tourOverviewSlice = createSlice({
  name: 'main/tourAnalyseOverview',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    sortModel: [{ field: 'dateTime', sort: 'desc' }],
    rangeDate: [null, null],
    selectedUnit: null,
    selectedClassifier: [],
    selectedTour: null,
    selectedDeviceTypes: [],
    csvResult: [],
    selectedDatePrevValue: [],
    unitsCombo: [],
    stToursCombo: [],
    rangeDateCombo: [],
    loadingTourViews: false,
    loadingUnits: false,
    loadingStTours: false,
    stTourOverviewCount: 0,
    tourViews: tourViewAdapter.getInitialState({}),
    stTours: stToursAdapter.getInitialState({}),
    units: unitsAdapter.getInitialState({}),
    tourViewFilters: tourViewFiltersAdapter.getInitialState({}),
    loadingTourViewFilters: false,
    tourOverviewMode: 'view',
    isDataDirty: false,
    dataTourOverviews: [],
    errorOnSave: null,
  },
  reducers: {
    setstTourOverviewSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setDataTourOverviews: {
      reducer: (state, action) => {
        state.dataTourOverviews = action.payload;
      },
    },
    resetTourViewFilters: {
      reducer: (state, action) => {
        tourViewFiltersAdapter.removeAll(state.tourViewFilters);
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedClassifier: {
      reducer: (state, action) => {
        state.selectedClassifier = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedDeviceTypes: {
      reducer: (state, action) => {
        state.selectedDeviceTypes = action.payload;
      },
    },
    setCsvResult: {
      reducer: (state, action) => {
        state.csvResult = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setStToursCombo: {
      reducer: (state, action) => {
        state.stToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
    setTourOverviewMode: {
      reducer: (state, action) => {
        state.tourOverviewMode = action.payload;
      },
    },
    resetTourOverview: {
      reducer: (state, action) => {
        state.errorOnSave = null;
        state.isDataDirty = false;
        tourViews.removeAll(state.tourViews);
        state.loadingTourViews = false;
        state.loadingUnits = false;
        state.isDataDirty = false;
        state.tourViewFilters = tourViewFiltersAdapter.getInitialState({});
        state.loadingTourViewFilters = false;
      },
    },
  },

  extraReducers: {
    [getTourViews.fulfilled](state, { payload }) {
      tourViewAdapter.setAll(state.tourViews, payload.tourOverview);
      state.tourViewsCount = payload.totalcount;
      state.loadingTourViews = false;
    },
    [getTourViews.pending]: (state) => {
      state.loadingTourViews = true;
      tourViewAdapter.removeAll(state.tourViews);
    },
    [getTourViews.rejected]: (state) => {
      state.loadingTourViews = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [patchBatchTourOverview.fulfilled](state, { payload }) {
      state.savingTourOverviews = false;
    },
    [patchBatchTourOverview.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingTourOverviews = true;
    },
    [patchBatchTourOverview.rejected](state, { payload }) {
      state.errorOnSave = payload?.error;
      state.savingTourOverviews = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getTourViewFilters.fulfilled](state, { payload }) {
      tourViewFiltersAdapter.setAll(state.tourViewFilters, payload.stAnalysis);
      state.tourViewFiltersCount = payload.totalcount;
      state.loadingTourViewFilters = false;
    },
    [getTourViewFilters.pending]: (state) => {
      state.loadingTourViewFilters = true;
      tourViewFiltersAdapter.removeAll(state.tourViewFilters);
    },
    [getTourViewFilters.rejected]: (state) => {
      state.loadingTourViewFilters = false;
    },
  },
});

export const { 
  setstTourOverviewSearchText, 
  setIsDataDirty,
  setDataTourOverviews,
  resetTourViewFilters,
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifier,
  setSelectedTour,
  setSelectedDeviceTypes,
  setCsvResult,
  setSelectedDatePrevValue,
  setUnitsCombo,
  setStToursCombo,
  setRangeDateCombo,
  setTourOverviewMode,
  resetTourOverview

} = tourOverviewSlice.actions;

export default tourOverviewSlice.reducer;
