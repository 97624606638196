import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TourAnalyseOverview = lazy(() => import('./TourAnalyseOverview'));

const TourAnalyseOverviewConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'touranalyseoverview',
      element: <TourAnalyseOverview />,
      auth: [authRoles.ROLE_TOUR_OVERVIEW_READ],
    },
  ],
};

export default TourAnalyseOverviewConfig;
