const locale = {
    "login_welcome": "Willkommen",
    "login_welcome_to": "zum",
    "login_welcome_koco_portal": "KOCO-Fleet Portal!",
    "login_welcome_message": "Analyse, Tracking und Monitoring System für Abfallsammelfahrzeuge und Kehrmaschinen der Kirchhoff Gruppe.",
    "login_email_required": "Email ist ein Pflichtfeld",
    "login_email_not_valid": "Sie müssen eine gültige E-Mail-Adresse eingeben",
    "login_password_required": "Passwort ist ein Pflichtfeld",
    "login_password": "Passwort",
    "login_password_forgotten": "Passwort vergessen?",
    "login_button": "Einloggen",
    "login_dont_have_an_account": "Sie haben kein Konto?",
    "login_register": "registrieren",
    "login_pass_button": "Bestätigen",
    "login_pass_match": "Passwort stimmt nicht überein",
    "login_pass_text": "Bitte legen Sie ein Passwort für Ihr Konto fest",
    "login_reset_pass_text": "Bitte geben Sie unten Ihre E-Mail-Adresse an. Wenn Sie ein registrierter Benutzer bei uns sind, senden wir Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts",
    "login_form_link": "anmeldeseite",
    "login_pass_send_message": "Es wurde eine E-Mail an die angegebene E-Mail-Adresse gesendet",
    "login_pass_reset_button": "Reset-Link senden",
    "months": "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember",
    "monthsShort": "Jan._Febr._Mrz._Apr._Mai_Jun._Jul._Aug._Sept._Okt._Nov._Dez.",
    "weekdays": "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag",
    "weekdaysShort": "So._Mo._Di._Mi._Do._Fr._Sa.",
    "weekdaysMin": "So_Mo_Di_Mi_Do_Fr_Sa",
    "login_title": "Anmelden",
    "login.username": "Benutzername",
    "login.password": "Passwort",
    "login.password_forget": "Passwort vergessen",
    "login.contact": "Kontakt",
    "login.api": "API",
    "login.status": "Status",
    "login.impression": "Impressum",
    "login.notification_success": "Vielen Dank für die erfolgreiche Anmeldung im Webportal.Es wurde ein neues Fenster geöffnet. Sollten Sie dieses nicht sehen, klicken Sie",
    "login_notification_success_2": "hier",
    "login.notification_failure": "Benutzername oder Passwort falsch",
    "layout_user": "Anmelden",
    "layout_logout": "Benutzername",
    "layout_quick_search": "Schnellsuche",
    "layout_default_filter_all": "Alle",
    "layout_default_filter_disable": "Filter löschen",
    "layout_default_filter_undo": "Zurücksetzen",
    "layout_default_search": "Suche",
    "layout_default_reset_filter": "Filter löschen",
    "layout_signal_search": "Signale",
    "layout_default_filter": "Filtern",
    "layout_default_loading_data": "Daten werden geladen...",
    "layout_default_no_data": "Es sind keine Daten vorhanden",
    "layout_default_gps_no_data": "Es sind keine Gps Daten vorhanden",
    "profile_data_updated": "Daten wurden aktualisiert",
    "layout_default_creation_date": "Erstellungsdatum",
    "layout_default_last_update_date": "letztes Update",
    "layout_default_user": "Nutzerin/Nutzer",
    "layout_default_status": "Status",
    "layout_default_display_details": "Anzeigen",
    "layout_default_hide_details": "Schließen",
    "layout_default_confirmation": "Sind sie sicher?",
    "layout_default_field_required": "Dies ist ein Pflichtfeld",
    "layout_default_invalid_date_format": "ungültiges Datumsformat",
    "layout_default_save": "Speichern",
    "layout_default_cancel": "Abbrechen",
    "layout_default_delete": "Löschen",
    "layout_default_undo" : "rückgängig machen",
    "layout_default_yes": "Ja",
    "layout_default_no": "Nein",
    "layout_default_to": "bis",
    "layout_default_please_select": "Bitte Wählen",
    "layout_default_rows_per_page": "Zeilen pro Seite: ",
    "layout_default_of": "von",
    "layout_confirmation_title": "Bestätigung",
    "layout_default_action": "Aktionen",
    "layout_default_enabled": "Aktiviert",
    "layout_default_enable_button": "Aktivieren",
    "layout_default_disable_button": "Deaktivieren",
    "layout_default_navigate_away_message": "Ihre Änderungen wurden noch nciht gespeichert. Möchten Sie die Seite wirklich verlassen, ohne zu speichern?",
    "layout_default_not_selected": "nicht ausgewählt",
    "menu_overview": "Flottenübersicht",
    "menu_fleet_tracking": "Fleet Tracking",
    "menu_scantec": "SCANTEC",
    "menu_dashboard": "Service Dashboard",
    "menu_tour_analyse": "Tour Analyse",
    "menu_reports": "Reports",
    "reports_plot": "PLOT KONFIGURIEREN",
    "menu_settings": "Einstellungen",
    "overview_title": "Flottenübersicht",
    "overview_export": "Exportieren",
    "overview_columns": "Kolumne",
    "overview_hint_download": "Flottenübersicht herunterladen",
    "overview_save_column": "Ansicht speichern",
    "overview_save_notification_success": "Ansicht gespeichert",
    "overview_save_notification_failure": "Fehler beim Speichern der Ansicht",
    "overview_menu_construction_type": "Aufbau-\nTyp",
    "overview_menu_construction_number": "Aufbau-\nNummer",
    "overview_menu_chassis_manufacturer": "Fahrgestell-\nHersteller",
    "overview_menu_internal_fleet_manager": "Interne Flottennummer",
    "overview_menu_license_plate": "Kennzeichen",
    "overview_menu_first_registration": "Erst-\nRegistrierung",
    "overview_menu_km_total": "Kilometer-\nStand",
    "overview_menu_body_working_hours": "Aufbau\nBh",
    "overview_menu_lifter_working_hours": "Lifter\nBh",
    "overview_menu_SoC": "Ladestand",
    "overview_menu_diesel_h2": "Diesel/\nH2",
    "overview_menu_status_vehicle": "Status-\nFahrzeug",
    "overview_menu_error_messages": "Fehler-\nMeldungen",
    "overview_menu_kgu_status": "KGU-\nStatus",
    "overview_menu_last_date": "Letzter-\nDatenempfang",
    "overview_menu_status_service": "Status-\nService",
    "overview_menu_construction_type_desc": "Aufbau-Typ",
    "overview_menu_last_location": "Standort",
    "overview_menu_construction_number_desc": "Aufbau-Nummer",
    "overview_menu_chassis_manufacturer_desc": "Fahrgestell-Hersteller",
    "overview_menu_internal_fleet_manager_desc": "Interne Flottennummer",
    "overview_menu_license_plate_desc": "Kennzeichen",
    "overview_menu_first_registration_desc": "Erst-Registrierung",
    "overview_menu_km_total_desc": "Kilometer-Stand",
    "overview_menu_body_working_hours_desc": "Aufbau Betriebsstunden",
    "overview_menu_lifter_working_hours_desc": "Lifter Betriebsstunden",
    "overview_menu_status_vehicle_desc": "Status-Fahrzeug",
    "overview_menu_error_messages_desc": "Fehler-Meldungen",
    "overview_menu_kgu_status_desc": "KGU-Status",
    "overview_menu_last_date_desc": "Letzter-Datenempfang",
    "overview_menu_status_service_desc": "Status-Service",
    "overview_menu_hint_error_message_green": "Keine Fehlermeldungen vorhanden",
    "overview_menu_hint_error_message_red": "Fehlermeldungen vorhanden",
    "overview_menu_hint_status_service_red": "Service überfällig",
    "overview_menu_hint_status_service_yellow": "Service in Kürze fällig",
    "overview_menu_hint_status_service_green": "Kein Service fällig",
    "overview_menu_hint_status_service_grey": "Kein Service fällig",
    "overview_menu_hint_no_columns_selected": "Es ist keine Kolumne ausgewählt.",
    "fleet_tracking_title": "Kartenansicht",
    "fleet_tracking_menu_title": "Bezeichnung",
    "fleet_tracking_menu_construction_type": "Aufbau-Typ",
    "fleet_tracking_popup_construction_number": "Aufbau-Nummer",
    "fleet_tracking_popup_lifter_number": "Lifternummer",
    "fleet_tracking_popup_Lifter_type": "Lifter Typ",
    "fleet_tracking_popup_km_total": "Kilometerstand",
    "fleet_tracking_popup_last_emptying": "Letzter Empfang",
    "fleet_tracking_popup_neu_method": "neue Methode",
    "fleet_tracking_popup_date_fleet": "Datum Fahrspur",
    "fleet_tracking_popup_button_details": "Details",
    "fleet_tracking_popup_button_calculate": "Kalkulieren",
    "fleet_tracking_popup_button_display_route": "Fahrspur",
    "fleet_tracking_popup_button_display_mileage": "Distanz Tracker",
    "fleet_tracking_notification_gps_calculation": "Daten werden in wenigen Sekunden berechnet",
    "fleet_tracking_notification_no_gps": "Daten werden in wenigen Sekunden berechnet",
    "fleet_tracking_notification_failure_gps": "Beim Erstellen des Berichts ist ein Fehler aufgetreten",
    "fleet_tracking_mileage_title": "Im Bereich Kilometerstand können Sie den Kilometerstand für das ausgewählte Fahrzeug lesen",
    "fleet_tracking_mileage_truck": "Fahrzeug",
    "fleet_tracking_mileage_date_from": "vom",
    "fleet_tracking_mileage_date_to": "bis",
    "fleet_tracking_mileage_button_save": "Distanz Tracker laden",
    "fleet_tracking_mileage_button_cancel": "Abbrechen",
    "fleet_tracking_mileage_date": "Datum",
    "fleet_tracking_mileage_distance": "Distanz in Kilometer",
    "fleet_tracking_mileage_field_not_empty": "Feld muss eine Auswahl enthalten",
    "fleet_tracking_mileage_no_data": "Es sind keine Dateien vorhanden",
    "fleet_tracking_legend_running": "Bewegung",
    "fleet_tracking_legend_stop": "Stop",
    "fleet_tracking_menu_icon": "Icon",
    "fleet_tracking_menu_status": "Status",
    "fleet_tracking_menu_action": "Aktionen",
    "service_dashboard_title": "Service Dashboard",
    "service_dashboard_title_rcv": "ASF",
    "service_dashboard_title_sweeper": "Kehrmaschine",
    "service_dashboard_license_plate": "Kennzeichen",
    "service_dashboard_internal_fleet_number": "Interne Fuhrparknummer",
    "service_dashboard_menu_body": "Aufbau",
    "service_dashboard_menu_lifter": "Lifter",
    "service_dashboard_menu_chassis": "Fahrgestell",
    "service_dashboard_menu_examination": "Gesetzliche Prüfungen",
    "service_dashboard_rcv_body_maintenance": "Aufbauwartung",
    "service_dashboard_rcv_body_maintenance_short": "AW",
    "service_dashboard_rcv_body_error": "Fehlermeldungen",
    "service_dashboard_rcv_body_error_short": "FM",
    "service_dashboard_rcv_body_fill_level": "Füllstandslevel Zentralschmieranlage",
    "service_dashboard_rcv_body_fill_level_short": "FZ",
    "service_dashboard_rcv_body_pump": "Pumpe Zentralschmieranlage",
    "service_dashboard_rcv_body_pump_short": "PZ",
    "service_dashboard_rcv_body_warning_left": "Warnung linker Fußtritt",
    "service_dashboard_rcv_body_warning_left_short": "WLA",
    "service_dashboard_rcv_body_warning_right": "Warnung rechter Fußtritt",
    "service_dashboard_rcv_body_warning_right_short": "WRA",
    "service_dashboard_rcv_lifter_warning": "Lifterwartung",
    "service_dashboard_rcv_lifter_warning_short": "LW",
    "service_dashboard_rcv_chassis_diesel": "Füllstand Diesel",
    "service_dashboard_rcv_chassis_diesel_short": "FD",
    "service_dashboard_rcv_chassis_adblue": "Füllstand AdBlue",
    "service_dashboard_rcv_chassis_adblue_short": "FAB",
    "service_dashboard_rcv_chassis_motor_temperature": "Temperatur Motorkühlmittel",
    "service_dashboard_rcv_chassis_motor_temperature_short": "TM",
    "service_dashboard_rcv_chassis_motor_pressure": "Motoröldruck",
    "service_dashboard_rcv_chassis_motor_pressure_short": "M",
    "service_dashboard_rcv_examination_general": "Hauptuntersuchung/Abgasuntersuchung",
    "service_dashboard_rcv_examination_general_short": "HU/AU",
    "service_dashboard_rcv_examination_tacho": "Tachographenprüfung",
    "service_dashboard_rcv_examination_tacho_short": "TP",
    "service_dashboard_rcv_examination_security": "Sicherheitsprüfung",
    "service_dashboard_rcv_examination_security_short": "SP",
    "service_dashboard_rcv_examination_uvv": "UVV-Prüfung",
    "service_dashboard_rcv_examination_uvv_short": "UVV-P",
    "service_dashboard_rcv_examination_details": "Zur Detailansicht",
    "service_dashboard_sweeper_body_fill_level_water": "Füllstand Wassertank",
    "service_dashboard_sweeper_body_fill_level_water_short": "FW",
    "service_dashboard_sweeper_body_motor_maintenance": "Aufbaumotorwartung",
    "service_dashboard_sweeper_body_motor_maintenance_short": "AMW",
    "service_dashboard_sweeper_body_error_motor": "Fehlermeldungen Aufbaumotor",
    "service_dashboard_sweeper_body_error_motor_short": "FAM",
    "service_dashboard_sweeper_body_fill_level_diesel": "Füllstand Dieseltank Aufbaumotor",
    "service_dashboard_sweeper_body_fill_level_diesel_short": "FDA",
    "service_dashboard_sweeper_chassis_maintenance": "Fahrgestellwartung",
    "service_dashboard_sweeper_chassis_maintenance_short": "FW",
    "service_dashboard_sweeper_chassis_error": "Fehlermeldungen Fahrgestell",
    "service_dashboard_sweeper_chassis_error_short": "FF",
    "tour_analyse_truck_selection": "Fahrzeug auswählen *",
    "reports_time_range_selection_From": "Zeitspanne",
    "tour_analyse_no_truck": "Keine Fahrzeuge",
    "tour_analyse_truck_name": "Fahrzeug",
    "tour_analyse_truck_button_save": "Tour Report erstellen",
    "tour_analyse_truck_button_cancel": "Abbrechen",
    "tour_analyse_map_description": "Bildbeschreibung",
    "tour_analyse_map_rcv_direction": "Fahrtrichtung",
    "tour_analyse_map_rcv_unloading": "Abladestelle",
    "tour_analyse_map_rcv_bin": "Beladestelle",
    "tour_analyse_map_rcv_stop_point": "Haltepunkt",
    "tour_analyse_map_rcv_first_bin": "Erste Beladestelle",
    "tour_analyse_map_rcv_last_bin": "Letzte Beladestelle",
    "tour_analyse_map_rcv_depot": "Depot",
    "tour_analyse_map_sweeper_right": "Kehren rechts",
    "tour_analyse_map_sweeper_left": "Kehren links",
    "tour_analyse_map_sweeper_both": "Kehren beidseitig",
    "tour_analyse_map_unloading": "Abladestelle",
    "tour_analyse_map_report_title": "Tour Report",
    "tour_analyse_map_report_general": "Allgemein",
    "tour_analyse_map_report_details": "Details",
    "tour_analyse_popup_latitude": "Breitengrad",
    "tour_analyse_popup_longitude": "Längengrad",
    "tour_analyse_popup_part": "Teil",
    "tour_analyse_popup_date": "Datum",
    "tour_analyse_title_table_total": "Gesamt",
    "tour_analyse_title_table_transport": "Transport",
    "tour_analyse_title_table_collection": "Sammlung",
    "tour_analyse_start_time": "Startzeit",
    "tour_analyse_end_time": "Endzeit",
    "tour_analyse_duration": "Dauer",
    "tour_analyse_extra_duration": "Verweildauer",
    "tour_analyse_distance": "Fahrtstrecke",
    "tour_analyse_avg_vehicle_speed": "Durchschnittsgeschwindigkeit",
    "tour_analyse_axle_weights": "Abfallmenge",
    "tour_analyse_left_bins": "Anzahl 2-Rad Behälter links",
    "tour_analyse_right_bins": "Anzahl 2-Rad Behälter rechts",
    "tour_analyse_big_bins": "Anzahl 4-Rad Behälter (4-Fach)",
    "tour_analyse_bins_pro_hour": "Behälter pro Stunde (Sammlung)",
    "tour_analyse_bins_avg": "Durchschnittlicher",
    "tour_analyse_diesel_consumption": "Dieselverbrauch (Liter)",
    "fleet_tracking_diesel": "Dieselverbrauch",
    "tour_analyse_reverse": "Rückwärtsgang",
    "tour_analyse_kickdown": "Anzahl Kickdown",
    "tour_analyse_status": "Status",
    "tour_analyse_status_done": "Fertig",
    "tour_analyse_status_not_done": "Nicht Fertig",
    "tour_analyse_table_bins_title": "Übersicht der Beladestellen",
    "tour_analyse_table_bins_column_sum": "Summe",
    "tour_analyse_table_bins_column_date": "Datum",
    "tour_analyse_table_bins_column_collection": "Sammelpunkt",
    "tour_analyse_table_bins_column_collection_right": "Rechter Kippstuhl",
    "tour_analyse_table_bins_column_collection_left": "Linker Kippstuhl",
    "tour_analyse_table_bins_column_collection_big_right": "4-Rad-Behälter / Kamm",
    "tour_analyse_table_bins_column_collection_left_right": "4-Rad-Behälter / Klapparm",
    "tour_analyse_notification_success": "Ihr Bericht wird in wenigen Sekunden angezeigt",
    "tour_analyse_notification_failure": "Beim Erstellen des Berichts ist ein Fehler aufgetreten",
    "tour_analyse_missing_tour": "Der Grund hierfür ist, dass sich das Fahrzeug auf keiner Sammeltour befand.",
    "tour_analyse_missing_one_part_tour": "Der Grund hierfür ist, dass die entleerten Behälter in einem Teil der Tour nicht fehlerfrei erfasst wurden.",
    "tour_analyse_error": "Der Grund hierfür ist eine nicht erfasste Entleerung.",
    "tour_analyse_turbine_avg": "Durchschnittsdrehzahl Gebläse",
    "tour_analyse_high_pressure_water_time": "Betriebszeit Hochdruckwasseranlage",
    "tour_analyse_low_pressure_water_time": "Betriebszeit Niederdruckwasseranlage",
    "tour_analyse_water_refilling_count": "Anzahl Befüllung Wassertank",
    "tour_analyse_emptying_number": "Anzahl Entleerungen Behälter",
    "report_signal_duration": "Signalfrequenz",
    "report_signal_type_min": "Minimum",
    "report_signal_type_max": "Maximum",
    "report_signal_type_avg": "Durchschnitt",
    "report_signal_type_sum": "Gesamt",
    "report_signal_frequency_second": "Sekunden",
    "report_signal_frequency_minut": "Minuten",
    "report_signal_frequency_hour": "Stunden",
    "report_signal_frequency_day": "Tage",
    "report_signal_missing_signal": "Feld muss eine Auswahl enthalten",
    "report_signal_button_save": "Bericht erzeugen",
    "report_signal_timeline": "Zeitraum",
    "report_signal_graph_title": "Graph",
    "report_signal_table_title": "Datentabelle",
    "report_signal_table_value": "Wert",
    "report_signal_table_count": "Häufigkeit",
    "report_signal_notification_success": "Ihr Bericht wird in wenigen Sekunden angezeigt",
    "report_signal_notification_success_email": "Ihre Berichtsanforderung wurde gesendet",
    "report_signal_notification_error": "Beim Erstellen des Berichts ist ein Fehler aufgetreten",
    "settings_button_save": "Speichern",
    "settings_button_display_details": "Anzeigen",
    "settings_button_hide_details": "Schließen",
    "settings_button_action": "Aktionen",
    "settings_profile": "Mein Konto",
    "settings_profile_title_1": "1. Benutzerverwaltung",
    "settings_profile_title": "Titel",
    "settings_profile_first_name": "Vorname",
    "settings_profile_last_name": "Nachname",
    "settings_profile_phone": "Telefonnummer",
    "settings_profile_e-mail": "E-Mail",
    "settings_profile_language": "Sprache",
    "settings_profile_time_line": "Zeitzone",
    "settings_profile_title_2": "2. Passwort bestätigen",
    "settings_profile_actuel_password": "Aktuelles Passwort",
    "settings_profile_title_3": "3. Passwort ändern (optional)",
    "settings_profile_new_password": "Neues Passwort",
    "settings_profile_confirmation_password": "Neues Passwort bestätigen",
    "settings_profile_notification_success": "Änderungen wurden gespeichert",
    "settings_profile_notification_failure": "Fehler beim Speichern der Änderungen",
    "settings_wrong_password": "Ungültiges Passwort",
    "settings_password_confirmation": "Passwörter stimmen nicht überein",
    "settings_empty_field": " Dieses Feld darf nicht leer sein.",
    "settings_company_title": "Firmenverwaltung",
    "settings_company_title_1": "Beschreibung der Firma",
    "settings_company_name": "Firmenname",
    "settings_company_address": "Adresse",
    "settings_company_zip": "Postleitzahl",
    "settings_company_place": "Ort",
    "settings_company_country": "Land",
    "settings_company_phone": "Telefonnummer",
    "settings_company_email": "E-Mail-Adresse",
    "settings_company_id": "UST-ID-Nummer",
    "settings_company_internet_address": "Internetadresse",
    "settings_company_internal_address": "Internetadresse",
    "settings_company_success_notification_save": "Firma gespeichert",
    "settings_company_success_notification_delete": "Firma gelöscht",
    "settings_company_failure_notification_save": "Fehler beim Speichern der Firma",
    "settings_company_failure_notification_delete": "Fehler beim Löschen der Firma",
    "settings_company_error_duplication": "Firmenname schon vergeben",
    "settings_company_error_format": "Ungültiges Format",
    "settings_company_empty": "Es sind keine Firmen vorhanden. Erstellen Sie eine neue Firma über den",
    "settings_company_save_title": "Bestätigung",
    "settings_company_save_message": "Sind Sie sicher, die Firma zu speichern?",
    "settings_company_enable_title": "Bestätigung",
    "settings_company_enable_message": "Sind Sie sicher, die Firma zu aktivieren?",
    "settings_company_disable_title": "Bestätigung",
    "settings_company_disable_message": "Sind Sie sicher, die Firma zu deaktivieren?",
    "settings_company_new": "Neu",
    "settings_company_new_company": "Neue Firma",
    "settings_company_detail": "Firmendetails",
    "settings_company_info": "Firmeninfo",
    "settings_company_no_such_company": "Es gibt keine solche Firma!",
    "settings_company_go_to_management": "Gehen Sie zur Firmenverwaltung",
    "settings_company_name_min_length": "Der Firmenname muss mindestens 5 Zeichen lang sein",
    "settings_company_save_modules": "Module Speichern",
    "settings_company_save_modules_title": "Bestätigung",
    "settings_company_save_modules_message": "Sind Sie sicher, die Module zu speichern?",
    "settings_company_no_data": "Es gibt keine Firmen!",
    "settings_vehicle_title": "Fahrzeugverwaltung",
    "settings_vehicle_name": "Name",
    "settings_vehicle_body_number": "Aufbaunummer",
    "settings_vehicle_construction_type": "Aufbau-Typ",
    "settings_vehicle_title_1": "Beschreibung des Fahrzeug",
    "settings_vehicle_title_2": "Firmen",
    "settings_vehicle_button_archive": "Archivieren",
    "settings_vehicle_button_restore": "Wiederherstellen",
    "settings_vehicle_table_archive_status": "Archiviert",
    "settings_vehicle_name_exist": "Name schon vergeben",
    "settings_vehicle_no_company_assigned": "Kein Fahrzeug der Firma zugeordnet",
    "settings_vehicle_save": "Fahrzeug gespeichert",
    "settings_vehicle_restore": "Fahrzeug wiederhergestellt",
    "settings_vehicle_delete": "Fahrzeug gelöscht",
    "settings_vehicle_delete_failure": "Fehler beim Löschen des Fahrzeug",
    "settings_vehicle_restore_failure": "Fehler beim wiederherstellen des Fahrzeuges",
    "settings_vehicle_save_failure": "Fehler beim Speichern des Fahrzeuges",
    "settings_vehicle_save_title": "Bestätigung",
    "settings_vehicle_save_message": "Sind Sie sicher, das Fahrzeug zu speichern?",
    "settings_vehicle_enable_title": "Bestätigung",
    "settings_vehicle_enable_message": "Sind Sie sicher, das Fahrzeug zu aktivieren?",
    "settings_vehicle_disable_title": "Bestätigung",
    "settings_vehicle_disable_message": "Sind Sie sicher, das Fahrzeug zu deaktivieren?",
    "settings_vehicle_new": "Neu",
    "settings_vehicle_new_vehicle": "Neues Fahrzeug",
    "settings_vehicle_detail": "Fahrzeugdetail",
    "settings_vehicle_info": "Fahrzeuginfo",
    "settings_vehicle_no_such_vehicle": "Es gibt kein solches Fahrzeug!",
    "settings_vehicle_go_to_management": "Gehen Sie zur Fahrzeugverwaltung",
    "settings_vehicle_no_data": "Es gibt keine Fahrzeuge!",
    "settings_module_title": "Modulverwaltung",
    "settings_module_title_modules": "Module",
    "settings_module_vehicle": "Fahrzeug",
    "settings_module_fleet_tracking": "Fleet Tracking",
    "settings_module_service_dashboard": "Service Dashboard",
    "settings_module_tour_analyse": "Tour analyse",
    "settings_module_reports": "Reports",
    "settings_module_jira_faun_complete": "Jira Faun Complete",
    "settings_status_active": "Aktivieren",
    "settings_status_deactivate": "Deaktivieren",
    "settings_module_firma_not_empty": "Firmenname schon vergeben",
    "settings_module_success": "Firmendaten gespeichert",
    "settings_module_delete_success": "Firma gelöscht",
    "settings_module_failure": "Firmendaten gespeichert",
    "settings_module_delete_failure": "Firma gelöscht",
    "settings_module_empty": "Es sind keine Firmen vorhanden",
    "settings_module_batch_update": "Batch-Update",
    "settings_role_title": "Rollenverwaltung",
    "settings_role_title_1": "Beschreibung der Rolle",
    "settings_role_title_roles": "Rollen",
    "settings_role_name": "Name",
    "settings_role_description": "Beschreibung",
    "settings_role_definition": "Zugangsrechte",
    "settings_role_expand_all": "Alle aufklappen",
    "settings_role_collapse_all": "Alle zuklappen",
    "settings_role_deselect_all": "Alle abwählen",
    "settings_role_create": "Speichern",
    "settings_role_view": "Ansicht",
    "settings_role_delete": "Entfernen",
    "settings_role_notification_success": "Rolle gespeichert",
    "settings_role_notification_failure": "Fehler beim Speichern der Rolle",
    "settings_role_notification_delete_success": "Rolle gelöscht",
    "settings_role_notification_delete_failure": "Fehler beim Löschen der Rolle",
    "settings_role_error_message_same_role": " Rollenname schon vergeben",
    "settings_role_empty": "Es sind keine Rollen vorhanden. Erstellen Sie eine neue Rolle über den",
    "settings_role_name_min_length": "Der Rollenname muss mindestens 5 Zeichen lang sein",
    "settings_role_save_title": "Bestätigung",
    "settings_role_save_message": "Sind Sie sicher, die Rolle zu speichern?",
    "settings_role_enable_title": "Bestätigung",
    "settings_role_enable_message": "Sind Sie sicher, die Rolle zu aktivieren?",
    "settings_role_disable_title": "Bestätigung",
    "settings_role_disable_message": "Sind Sie sicher, die Rolle zu deaktivieren?",
    "settings_role_new": "Neu",
    "settings_role_new_role": "Neue Rolle",
    "settings_role_detail": "Rollendetail",
    "settings_role_info": "RollenInfo",
    "settings_role_no_such_role": "Es gibt keine solche Rolle!",
    "settings_role_go_to_management": "Gehen Sie zur Rollenverwaltung",
    "settings_role_no_data": "Es gibt keine Rollen!",
    "settings_user_title": "Benutzerverwaltung",
    "settings_user_title_1": "Übersicht Portalbenutzer",
    "settings_user_title_users": "Benutzer",
    "settings_user_head_1": "Benutzer definieren",
    "settings_user_last_login": "Letzter Login",
    "settings_user_role": "Rolle",
    "settings_user_name_title": "Titel",
    "settings_user_name": "Name",
    "settings_user_phone": "Telefonnummer",
    "settings_user_head_2": "Firmen Zugang",
    "settings_user_change_password": "Passwort ändern",
    "settings_user_save_all": "Alles speichern",
    "settings_user_blocked": "Benutzer gesperrt",
    "settings_user_revoked": "Benutzer entsperrt",
    "settings_user_notification_success": "Benutzer gespeichert",
    "settings_user_notification_failure": "Fehler beim Speichern des Benutzers",
    "settings_user_mail_format_wrong": "Mailadresse ungültig",
    "settings_user_mail_exist": "Mailadresse schon vergeben",
    "settings_user_role_failure": " Sie dürfen dem Benutzer keine ausgewählten Rollen hinzufügen.",
    "settings_user_save_title": "Bestätigung",
    "settings_user_save_message": "Sind Sie sicher, die/der Benutzer-in zu speichern?",
    "settings_user_enable_title": "Bestätigung",
    "settings_user_enable_message": "Sind Sie sicher, die/der Benutzer-in zu aktivieren?",
    "settings_user_disable_title": "Bestätigung",
    "settings_user_disable_message": "Sind Sie sicher, die/der Benutzer-in zu deaktivieren?",
    "settings_user_new": "Neu",
    "settings_user_new_user": "Neuer Benutzer",
    "settings_user_detail": "Benutzerdetail",
    "settings_user_info": "Benutzerinfo",
    "settings_user_no_such_user": "Es gibt keinen solchen Benutzer!",
    "settings_user_go_to_management": "Gehen Sie zur Benutzerverwaltung",
    "settings_user_no_data": "Es gibt keine Benutzer!",
    "settings_user_link_button": "Link erneut senden",
    "settings_error_info_title": "Fehlerinfo",
    "settings_error_info_error_code": "Fehlercode",
    "settings_error_info_search_button": "Suchen",
    "settings_error_info_error_repair": "Fehler beheben",
    "settings_error_info_error_family": "Familie",
    "settings_error_info_level": "Level",
    "settings_error_info_escalator_1": "Eskalationsstufe 1",
    "settings_error_info_escalator_2": "Eskalationsstufe 2",
    "settings_error_info_escalator_3": "Eskalationsstufe 3",
    "settings_error_info_time_base": "Zeitpunkt",
    "settings_error_info_error_condition": "Fehlerzustand",
    "settings_error_info_text_condition": "Textzustand",
    "settings_error_info_error_text_required": "Dieses Feld ist erforderlich",
    "settings_error_info_min_value": "Mindest Anzahl ist 1",
    "settings_error_info_max_value": "Maximal Anzahl ist 60",
    "settings_error_info_notification_no_error_found": "Keine Fehlerinformation gefunden",
    "settings_error_info_notification_error_error_info": "Suche nach Fehlerinformationen fehlgeschlagen",
    "settings_error_info_notification_save_success": "Fehlerinformation erfolgreich gespeichert",
    "settings_error_info_notification_save_failure": "Fehler bei der Speicherung der Fehlerinformation",
    "cockpit_title_1": "Fuhrpark",
    "cockpit_title_2": "Cockpit",
    "cockpit_construction_type": "Aufbau-Typ",
    "cockpit_license_plate": "Kennzeichen",
    "cockpit_internal_fleet_number": "Interne Fuhrparknummer",
    "cockpit_construction_number": "Aufbau-Nummer",
    "cockpit_ago": "vor",
    "cockpit_days": "Tag(en)",
    "cockpit_hours": "Stunde(n)",
    "cockpit_minutes": "Minute(n)",
    "cockpit_seconds": "Sekunde(n)",
    "cockpit_tab_live": "Live",
    "cockpit_tab_live_hint": "Im Bereich \"Live\" können Sie Echtzeitinformationen über das Fahrzeug einsehen, zum Beispiel das aktuelle Gesamtgewicht.",
    "cockpit_tab_live_error_report": "Fehlermeldungen",
    "cockpit_tab_live_error_report_active_red": "aktive Fehler vorhanden",
    "cockpit_tab_live_error_report_active_green": "Fehlermeldungen vorhanden",
    "cockpit_tab_live_error_report_active_grey": "Fehlermeldungen vorhanden",
    "cockpit_tab_live_error_report_no": "Keine",
    "cockpit_tab_live_warning": "Warnung(en)",
    "cockpit_tab_live_prewarning": "Vorwarnung(en)",
    "cockpit_tab_live_green": "Grün(e)",
    "cockpit_tab_live_dashboard_summary": "Dashboard-Zusammenfassung",
    "cockpit_tab_live_payload": "Payload",
    "cockpit_tab_live_payload_unit": "KG",
    "cockpit_tab_live_mileage": "Kilometerstand",
    "cockpit_tab_live_no_module_activated": "Kein Modul dieses Fahrzeugs ist aktiviert.",
    "cockpit_tab_software": "Software",
    "cockpit_tab_software_hint": "Im Bereich \"Software\" finden Sie Angaben zu den aktuellen Software-Versionen für den Aufbau und Lifter.",
    "cockpit_tab_general": "Basisdaten",
    "cockpit_tab_general_hint": "Im Bereich \"Basisdaten\" finden Sie Angaben zu den Grunddaten des Fahrzeuges, z.B. die Softwareversionen. Des Weiteren können Sie manuelle Grunddaten ergänzen, z.B. das Datum der Erstzulassung oder die interne Fuhrparknummer.",
    "cockpit_tab_signals": "Signale",
    "cockpit_tab_signals_hint": "Im Bereich “Signale” finden Sie Angaben zu einzelnen Signalen, wie zum Beispiel Kilometerstand, Diesel-Füllstand, Ad-Blue-Füllstand, Turbinendrehzahl zum Fahrzeug.",
    "cockpit_tab_live_data": "Betriebsdaten",
    "cockpit_tab_live_data_hint": "Im Bereich \"Betriebsdaten\" finden Sie Angaben zu wichtigen Betriebsdaten des Fahrzeuges, z.B. den Kilometerstand oder die Betriebsstunden.",
    "cockpit_tab_error_code": "Fehlermeldungen",
    "cockpit_tab_error_code_hint": "Im Bereich \"Fehlermeldungen\" können Sie alle aktuellen Fehlermeldungen einsehen. Der Bereich stellt keine Fehlerhistorie dar.",
    "cockpit_tab_faun_complete": "FAUN Complete intern",
    "cockpit_tab_faun_complete_hint": "Im Bereich \"FAUN Complete intern\" werden alle aktuellen sowie bearbeiteten Fehlermeldungen mit Ticketnummer angezeigt.",
    "cockpit_tab_parameter": "Parameter auslesen",
    "cockpit_tab_parameter_hint": "Im Bereich \"Parameter\" können Sie aktuelle Parametersätze für das ausgewählte Fahrzeug ablesen oder Parameterlisten herunterladen. Des Weiteren können Sie Parameter ändern und die Parameterhistorie einsehen.",
    "cockpit_tab_parameter_update": "Parameter aktualisiern",
    "cockpit_tab_parameter_update_hint": "Im Bereich \"Parameter\" können Sie aktuelle Parametersätze für das ausgewählte Fahrzeug ablesen oder Parameterlisten herunterladen. Des Weiteren können Sie Parameter ändern und die Parameterhistorie einsehen.",
    "cockpit_tab_service_book": "Servicebuch",
    "cockpit_tab_service_book_hint": "Im Bereich \"Servicebuch\" finden Sie alle durchgeführten Inspektionen/Wartungen die am Aufbau/Lifter erledigt wurden. Des Weiteren können Sie manuell die durchgeführten gesetzlichen Prüfungen einpflegen. Diese dienen als Grundlage für die Überwachung im Service Daschboard.",
    "cockpit_tab_unit_management": "Fahrzeugverwaltung",
    "cockpit_tab_unit_management_hint": "Im Bereich \"Internal Debug Logs\" finden Sie alle ....",
    "cockpit_tab_debug_log": "Debug Logs",
    "cockpit_tab_debug_log_hint": "Im Bereich \"Debug Logs\" finden Sie alle ....",
    "cockpit_tab_depot_selection": "Depotauswahl",
    "cockpit_tab_depot_selection_hint": "",
    "cockpit_tab_default_table_category": "Kategorie",
    "cockpit_tab_default_table_action": "Aktionen",
    "cockpit_tab_default_info_title": "Allgemein",
    "cockpit_tab_default_info_body": "Aufbau",
    "cockpit_tab_default_info_lifter": "Lifter",
    "cockpit_tab_default_info_chassis": "Fahrgestell",
    "cockpit_tab_default_info_imei": "Imei",
    "cockpit_tab_default_info_first_registration": "Erstzulassung",
    "cockpit_tab_default_body_software_version_master_unit": "Softwareversion Aufbausteuerung",
    "cockpit_tab_default_body_runtime_version_master_unit": "BIOS Aufbausteuerung",
    "cockpit_tab_default_body_software_display_master_unit": "Softwareversion Anzeigeeinheit",
    "cockpit_tab_default_body_runtime_display_master_unit": "BIOS Anzeigeeinheit",
    "cockpit_tab_sweeper_default_body_sweeper_type": "Kehrmaschinentyp",
    "cockpit_tab_sweeper_default_body_drive_type": "Antriebstyp",
    "cockpit_tab_sweeper_default_software_version_display": "Softwareversion Display",
    "cockpit_tab_sweeper_default_software_version_cm": "Softwareversion CM",
    "cockpit_tab_sweeper_default_hardware_version_display": "Hardwareversion Display",
    "cockpit_tab_sweeper_default_hardware_version_cm": "Hardware Version CM",
    "cockpit_tab_default_lifter_lifter_number": "Lifternummer",
    "cockpit_tab_default_lifter_lifter_type": "Liftertyp",
    "cockpit_tab_default_lifter_date": "Softwarestand Liftersteuerung",
    "cockpit_tab_default_lifter_software_date": "Software Datum",
    "cockpit_tab_default_chassis_type": "Fahrgestelltyp:",
    "cockpit_tab_default_chassis_number": "Fahrgestellnummer",
    "cockpit_tab_default_not_available": "Nicht Verfügbar",
    "cockpit_tab_default_notification_save_success": "Änderungen gespeichert",
    "cockpit_tab_live_data_rcv_value": "Wert",
    "cockpit_tab_signal_data_rcv_value": "Wert",
    "cockpit_tab_signal_data_rcv_unit": "Einheit",
    "cockpit_tab_live_data_rcv_version": "Version",
    "cockpit_tab_live_data_rcv_signal": "Signalbezeichnung",
    "cockpit_tab_signal_data_rcv_date": "Letzte Meldung",
    "cockpit_tab_live_data_rcv_date": "Datum",
    "cockpit_tab_live_data_rcv_info_km_transport": "Kilometerstand Transport",
    "cockpit_tab_live_data_rcv_info_km_collection": "Kilometerstand Sammlung",
    "cockpit_tab_live_data_rcv_info_km_waiting": "Kilometerstand Wartung",
    "cockpit_tab_live_data_rcv_info_km_last": "Kilometerstand / Gesamt",
    "cockpit_tab_live_data_rcv_body_working_hours": "Betriebsstunden",
    "cockpit_tab_live_data_rcv_body_status_working_hours": "Status Wartung fällig",
    "cockpit_tab_live_data_rcv_body_status_working_hours_ok": "ok",
    "cockpit_tab_live_data_rcv_body_status_working_until_maintenance": "Betriebsstunden bis zur nächsten Wartung",
    "cockpit_tab_live_data_rcv_body_count_compaction": "Zähler Verdichtungssystem",
    "cockpit_tab_live_data_rcv_body_total_compaction": "Gesamt Verdichtungszeit",
    "cockpit_tab_live_data_rcv_body_optimization_compaction": "Fraktionsoptimierte Verdichtung",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_not_active": "Nicht aktiviert",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_haushold_garbage": "Hausmüll",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_bio_garbage": "Biomüll",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_dsd": "Dsd",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_paper": "Paper",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_glass": "Glassmüll",
    "cockpit_tab_live_data_rcv_body_fill_level": "Füllstandslevel Zentralschmieranlage",
    "cockpit_tab_live_data_rcv_body_pump": "Pumpe Zentralschmieranlage",
    "cockpit_tab_live_data_rcv_not_ok": "Nicht ok",
    "cockpit_tab_live_data_rcv_lifter_daily_working_hours": "Tageszähler Betriebsstunden Lifter",
    "cockpit_tab_live_data_rcv_lifter_working_hours": "Betriebsstunden Lifter",
    "cockpit_tab_live_data_rcv_lifter_working_hours_until_maintenace": "Betriebsstunden bis zur nächsten Wartung",
    "cockpit_tab_live_data_rcv_lifter_absolute_kam_bin": "4-Radgefäße Kammaufnahme Gesamt",
    "cockpit_tab_live_data_rcv_lifter_absolute_arm_bin": "4-Radgefäße Klapparm Gesamt",
    "cockpit_tab_live_data_rcv_lifter_4_wheel_total_bin": "4-Radgefäße Lifterzyklus Gesamt",
    "cockpit_tab_live_data_rcv_lifter_absolute_left_bin": "Schüttungszähler links Gesamt",
    "cockpit_tab_live_data_rcv_lifter_absolute_right_bin": "Schüttungszähler rechts Gesamt",
    "cockpit_tab_live_data_rcv_lifter_2_wheel_total_bin": "2-Radgefäße Lifterzyklus Gesamt",
    "cockpit_tab_live_data_rcv_chassis_working_hours": "Betriebsstunden Motor",
    "cockpit_tab_live_data_rcv_chassis_fill_level_h2": "Füllstand H2",
    "cockpit_tab_live_data_rcv_chassis_fill_level_battery": "Füllstand Batterie",
    "cockpit_tab_live_data_rcv_chassis_fill_level_diesel": "Füllstand Diesel",
    "cockpit_tab_live_data_rcv_chassis_fill_level_ad_blue": "Füllstand Adblue",
    "cockpit_tab_live_data_rcv_chassis_fill_level_diesel_warning": "Füllstandswarnung Diesel",
    "cockpit_tab_live_data_rcv_chassis_fill_level_adblue_warning": "Füllstandswarnung Adblue",
    "cockpit_tab_live_data_sweeper_working_hours": "Betriebsstunden",
    "cockpit_tab_live_data_sweeper_status_working_hours": "Status Wartung fällig",
    "cockpit_tab_live_data_sweeper_status_working_hours_ok": "ok",
    "cockpit_tab_live_data_sweeper_status_working_until_maintenance": "Betriebsstunden bis zur nächsten Wartung",
    "cockpit_tab_live_data_sweeper_general_total": "Kilometerstand / Gesamt",
    "cockpit_tab_live_data_sweeper_general_swap_total": "Transport Kilometer",
    "cockpit_tab_live_data_sweeper_general_swap_time": "Kehrzeit gesamt",
    "cockpit_tab_live_data_sweeper_general_swap_distance": "Kehrkilometer gesamt",
    "cockpit_tab_live_data_sweeper_body_air_pressure": "Luftdruck Kehrmaschinenaufbau",
    "cockpit_tab_live_data_sweeper_body_water_tank_level": "Füllstand Wassertank",
    "cockpit_tab_live_data_sweeper_body_water_tank_temperture": "Wassertank Temparatur",
    "cockpit_tab_live_data_sweeper_body_hydraulic_oil_temperture": "Temperatur Hydrauliköl",
    "cockpit_tab_live_data_sweeper_high_pressure_pump": "Betriebsstunden Hochdruckpumpe",
    "cockpit_tab_live_data_sweeper_drive": "Antrieb",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours": "Hydrostat Betriebsstunden",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours_until_mainteance": "Hydrostat Betriebsstunden bis zur nächsten Wartung",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_km": "Hydrostat Kilometerstand",
    "cockpit_tab_live_data_aux_working_hours": "Betriebsstunden Aufbaumotor",
    "cockpit_tab_live_data_aux_working_hours_until_mainteance": "Betriebsstunden bis zur nächsten Wartung Aufbaumotor",
    "cockpit_tab_live_data_sweeper_tank_level": "Füllstand Zusatzdieseltank",
    "cockpit_tab_live_data_state_change": "Ladezustand",
    "cockpit_error_code_last_date": "Letztes Datum",
    "cockpit_error_code_error_code": "Fehlercode",
    "cockpit_error_code_error_source": "Fehlerquelle",
    "cockpit_error_code_error_description": "Fehlerbeschreibung",
    "cockpit_error_code_error_working_hours": "Betriebsstunden",
    "cockpit_error_code_sweeper_fmi": "FMI",
    "cockpit_error_code_sweeper_additional_info": "Zusätzliche Information",
    "cockpit_faun_complete_first_date": "Erstes Datum",
    "cockpit_faun_complete_last_date": "Letztes Datum",
    "cockpit_error_code_error_code_hex": "Fehlercode(Hex)",
    "cockpit_error_code_error_code_dec": "Fehlercode(Dec)",
    "cockpit_faun_complete_error_description": "Fehlerbeschreibung",
    "cockpit_faun_complete_count": "Häufigkeit",
    "cockpit_faun_complete_working_hours": "Betriebsstunden",
    "cockpit_faun_complete_error_status": "Fehler Status",
    "cockpit_faun_complete_last_esclation": "letzte Esklation",
    "cockpit_faun_complete_Jira_link": "Jira link.",
    "cockpit_faun_complete_last_status": "Letzter verfügbarer Status",
    "cockpit_faun_complete_status_not_available": "UNSPECIFIED",
    "cockpit_faun_complete_status_occured": "OCCURRED",
    "cockpit_faun_complete_status_cleared": "CLEARED",
    "cockpit_faun_complete_status_ack": "ACKNOWLEDGED",
    "cockpit_faun_complete_esc_0": "0 - Trivial",
    "cockpit_faun_complete_esc_1": "1 - Medium",
    "cockpit_faun_complete_esc_2": "2 - Critical",
    "cockpit_faun_complete_esc_3": "3 - Blocker",
    "cockpit_faun_complete_jira_status_work": "IN_ARBEIT",
    "cockpit_faun_complete_jira_status_task": "AUFGABEN",
    "cockpit_faun_complete_jira_status_complete": "FERTIG",
    "cockpit_faun_complete_jira_status_spec": "GET_SPEC",
    "cockpit_faun_complete_jira_status_rfq": "RFQ",
    "cockpit_faun_complete_jira_status_nc": "NC",
    "cockpit_faun_complete_jira_status_order": "ORDER",
    "cockpit_faun_complete_jira_status_3d": "_3D",
    "cockpit_faun_complete_jira_status_2d": "_2D",
    "cockpit_faun_complete_jira_status_free_task": "FREIGABE",
    "cockpit_faun_complete_jira_status_rejected": "ABGEWIESEN",
    "cockpit_faun_complete_button_search": "Suchen anzeigen",
    "cockpit_faun_complete_button_check_status": "Ticket Status überprüfen",
    "cockpit_faun_complete_delete_filter": "Filter löschen",
    "cockpit_faun_complete_next": "Weiter",
    "parameter_body_title": "Aufbauparameter",
    "parameter_lifter_title": "Lifterparameter",
    "parameter_search_title": "Parameter Suchen",
    "parameter_search_machine_type": "Maschinentyp",
    "parameter_read_parameters": "Gespeicherte Parameteranfragen",
    "parameter_read_parameter_requests": "Parameter Anfordern",
    "parameter_send_request_button": "Anfrage senden",
    "parameter_read_parameters_status_head": "Status",
    "command_gateway_status_new": "Neue Anfrage",
    "command_gateway_status_to_be_queued": "Vorbereitung zum Senden",
    "command_gateway_status_queued": "Wartend",
    "command_gateway_status_sent": "Gesendet",
    "command_gateway_status_received": "Empfangen",
    "command_gateway_status_success": "Vollständig durchgeführt",
    "command_gateway_status_errors": "Mit Fehler durchgeführt",
    "command_gateway_under_modification": "Änderungsmodus",
    "parameter_read_parameters_no_in_progress": "Es gibt keine laufende Anfrage",
    "parameter_empty": "Es sind keine Parameteranforderungen verfügbar.",
    "parameter_history": "Historie",
    "parameter_count_modification": "Anzahl der Änderungen",
    "parameter_action": "Aktionen",
    "parameter_no_log_history": "Es sind keine Logs vorhanden",
    "parameter_download_button": "Herunterladen",
    "parameter_read_close_details": "Änderungen Schließen",
    "parameter_read_show_details": "Änderungen Anzeigen",
    "parameter_read_send_request_confirmation_message": "Sind Sie sicher, dass Sie eine neue Leseparameteranforderung senden?",
    "parameter_number": "Nummer",
    "parameter_current_value": "aktueller Wert",
    "parameter_description": "Beschreibung",
    "parameter_value": "Wert",
    "parameter_value_old": "Alter Wert",
    "parameter_value_from": "(von) Wert",
    "parameter_value_to": "(auf) Wert",
    "parameter_value_change_source": "Quelle ändern",
    "parameter_value_min": "Min",
    "parameter_value_max": "Max",
    "parameter_value_date": "Datum",
    "parameter_value_number_of_receives" : "Anzahl der Empfangen",
    "parameter_value_last_date" : "letztes Datum",
    "parameter_value_user": "Benutzer",
    "parameter_error_desc": "Kein param. Info",
    "parameter_us_desc": "US English Desc",
    "parameter_fr_desc": "French Desc",
    "parameter_gr_desc": "German Desc",
    "parameter_us_detail_cond": "US Detail Cond.",
    "parameter_fr_detail_cond": "French Detail Cond.",
    "parameter_gr_detail_cond": "German Detail Cond.",
    "parameter_us_display_cond": "US Display Cond.",
    "parameter_fr_display_cond": "French Display Cond.",
    "parameter_gr_display_cond": "German Display Cond.",
    "parameter_default": "Default",
    "parameter_minimum": "Minimum",
    "parameter_maximum": "Maximum",
    "parameter_step": "Schritt",
    "parameter_unit": "Unit",
    "parameter_shown": "gezeigt",
    "parameter_show_more": "mehr anzeigen",
    "parameter_category": "Aktueller Parametersatz",
    "parameter_title": "Titel",
    "parameter_from": "von",
    "parameter_to": "bis",
    "parameter_count_in_group": "Anzahl in Parametergruppe",
    "parameter_head_2": "Parameterwertprotokoll",
    "parameter_table_change_source": "Quelle ändern",
    "parameter_table_change_source_portal": "Portal",
    "parameter_table_change_source_kocobox": "Koco Box",
    "parameter_update_title": "Parameter Update",
    "parameter_update_buttonUpdate": "Import",
    "parameter_update_gets_generated": "Anfrage wird generiert",
    "parameter_update_change_status": "Status ändern",
    "parameter_update_inquiry_status": "Anfragestatus",
    "parameter_update_cancel_request": "Abbrechen",
    "parameter_update_delete_request": "Löschen",
    "parameter_update_type": "Typ",
    "parameter_update_error_unique_parameter_number": "Die Parameternummer sollte eindeutig sein",
    "parameter_update_new_value": "Nuer Wert",
    "parameter_update_new_request_title": "neues Update",
    "parameter_update_new_request_send_all": "Alle vorhandenen senden",
    "parameter_update_new_request_custom": "Benutzerdefinierte Eingabe",
    "parameter_update_new_request_import_file": "Datei importieren",
    "parameter_update_add_new_parameter": "Parameter hinzufügen",
    "parameter_update_add_new_send_all_existing_parameters_title": "Parameter Bestätigung senden",
    "parameter_update_add_new_send_all_existing_parameters_message": "Sind Sie sicher, alle vorhandenen Parameter zu senden?",
    "parameter_update_save_confirm_title": "Bestätigung speichern",
    "parameter_update_save_confirm_message": "Möchten Sie die Parameter Anforderung speichern?",
    "parameter_update_delete_confirm_title": "Bestätigung löschen",
    "parameter_update_delete_confirm_message": "Möchten Sie die Parameteranforderung löschen?",
    "parameter_update_delete_parameter_confirm_title": "Bestätigung löschen",
    "parameter_update_delete_parameter_confirm_message": "Möchten Sie den Parameter aus der Anfrage löschen?",
    "parameter_update_invalid_number_or_value": "Einige Parameter Nummern oder -werte sind ungültig",
    "parameter_save_notification_success": "Parameteranfrage gespeichert",
    "parameter_read_notification_success": "Parameteranfrage lesen wurde gespeichert.",
    "cockpit_service_book_title": "Digitales Servicebuch",
    "cockpit_service_book_note": "Hinweis",
    "cockpit_service_book_general_inspection": "Gesetzliche Prüfungen",
    "cockpit_service_book_update_button": "bearbeiten",
    "cockpit_service_book_new_examination": "Neu prufüng",
    "cockpit_service_book_date_empty": "Feld \"Datum\" muss eine Auswahl enthalten",
    "cockpit_service_book_type_empty": "Feld \"Typ\" muss eine Auswahl enthalten",
    "cockpit_service_book_number": "Nummer",
    "cockpit_service_book_date": "Datum",
    "cockpit_service_book_updated_manually": "Quelle der Veränderung",
    "cockpit_service_book_working_hours": "Betriebsstunden",
    "cockpit_service_book_type": "Art",
    "service_book_body_inspection": "Body Inspection",
    "service_book_lifter_inspection": "Lifter Inspection",
    "service_book_chassis_inspection": "Chassis Inspection",
    "service_book_legal_inspection": "Legal Inspection",
    "inspection_type_main": "Hauptuntersuchung/Abgasuntersuchung",
    "inspection_type_tachograph_check": "Tachographenprüfung",
    "inspection_type_safety_check": "Sicherheitsprüfung",
    "inspection_type_uvv_test": "UVV-Prüfung",
    "cockpit_unit_management_category": "Kategorie",
    "cockpit_unit_management_serial_number": "Serial Number",
    "cockpit_unit_management_kgu_status": "KGU status",
    "cockpit_unit_management_title2": "Internal Debug Log Files",
    "cockpit_unit_management_data_name": "Dateiname",
    "cockpit_unit_management_data_size": "Dateigröße",
    "cockpit_confirmation": "Sind sie sicher?",
    "chose_a_date": "Bitte wählen Sie ein Datum",
    "last_date_received": "Letzte Daten erhalten",
    "consumption_over_time": "Verbrauch im Laufe der Zeit",
    "mileage_over_time": "Zeitverlauf Kilometerstand",
    "last_week": "Letzte Woche",
    "last_2_week": "Letzten 2 Wochen",
    "last_3_week": "Letzten 3 Wochen",
    "last_4_week": "Letzten 4 Wochen",
    "last_tour_4": "Letzte 4 Touren",

    "last_tour_8": "Letzte 8 Touren",
    "tour_data": "Datentour",
    "fleet_info_km_transport": "Transport",
    "fleet_info_km_collection": "Sammlung",
    "fleet_info_km_waiting": "Wartung",
    "fleet_tracking_timeline": "Letzte Tourzeit",
    "live_data_h2": "Füllstand H2",
    "live_data_soc": "Füllstand Batterie",
    "live_data_paylod": "Gesamtgewicht",
    "live_optimization_compaction": "Optimization Compaction",
    "live_turbine_speed": "Turbinendrehzahl",
    "live_turbine_speed_unit": "RPM",
    "sweep": "Sweep",
    "overview_menu_km_total_widget": "Tageskilometerstand",
    "truck_not_on_tour_today": "LKW heute nicht auf Tour",
    "fleet_tracking_not_active": "nicht aktiv",
    "fleet_tracking_not_active_30": "nicht aktiv in den letzten 30 Minuten",
    "fleet_tracking_active": "aktiv",
    "scan_tec_bin_red": "Schlecht",
    "scan_tec_bin_yellow": "Mittel",
    "scan_tec_bin_green": "Gut",
    "scan_tec_bin_gray": "Nicht klassifizierbar",
    "scan_tec_total": "Gesamt",
    "scan_tec_region": "Gemeinde",
    "scan_tec_date_start": "Von",
    "scan_tec_date_end": "Bis",
    "scan_tec_road": "Tour",
    "scan_tec_classification_text": "Klassifizierung anpassen",
    "scan_tec_update": "Aktualisieren",
    "scantec_test": '>%',
    "scantec_overview_text": "Behälterübersicht",
    "scantec_overview_tab": "Tourenübersicht",
    "scantec_overview_bin": "Behälterdetails",
    "scantec_overview_graph_trend": "Trend der Letzten Ergebnisse(%)",
    "scantec_overview_sensore_detection": "Behälterzahl Letzte Touren ",
    "scantec_overview_total_bins": "Gesamt Behälter",
    "scantec_single_bin_detect": "Datentour",
    "scantec_single_bin_identification": "Behälteridentifizierung",
    "scantec_single_bin_identificatier": "Behälter ID",
    "scantec_single_bin_username": "Behälternutzer",
    "scantec_email": "Email",
    "scantec_phone": "Telefonnummer",
    "scantec_sensor_data": "Sensordaten",
    "scantec_trend": "Trend/Sensordaten (%)",
    "scantec_result": "Ergebnis",
    "value_more_100": "Wert über 100",
    "bad_should_be_more": "Schlecht sollte mehr als mittel sein",
    "next": "Nächste",
    "back": "Zurück",
    "done": "Fertig",
    "reset": "Zurücksetzen",
    "refresh_list_bins": "Liste aktualisieren",
    "show_hide_bins_map": "Tonne Anzeigen/Ausblenden",
    "command_gateway_type_no_command": "No Command",
    "command_gateway_type_number_of_commands": "Number of commands",
    "command_gateway_type_read_body_parameters": "Read Body Parameters",
    "command_gateway_type_read_lifter_parameters": "Read Lifter Parameters",
    "command_gateway_type_udpate_operation_mode": "Update Operation Mode",
    "command_gateway_type_write_body_parameters": "Write Body Parameters",
    "command_gateway_type_write_lifter_parameters": "Write Lifter Parameters",
    "command_gateway_type_write_unit_configuration_file": "Write Unit Configuration File",
    "command_gateway_type_inquiry_status": "Inquiery Status",
    "command_gateway_type_cancel_command": "Cancel Command",
    "save_general_info_confirmation_title": "Daten speichern",
    "save_general_info_confirmation_message": "Sind Sie sicher, allgemeine Daten zu speichern?",
    "module_status_activating": "wird aktiviert",
    "module_status_activated": "aktiviert",
    "module_status_deactivating": "wird deaktiviert",
    "module_status_deactivated": "deaktiviert",
    "settings_passive_vehicle_group_title": "Fahrzeuggruppenverwaltung",
    "settings_passive_vehicle_group_name": "Name",
    "settings_passive_vehicle_group_company_name": "Firma",
    "settings_passive_vehicle_privacy_status": "Datenschutz Status",
    "settings_passive_vehicle_group_description": "Bezeichnung",
    "settings_passive_vehicle_group_public": "öffentlich",
    "settings_passive_vehicle_group_private": "privat",
    "settings_passive_vehicle_group_save_title": "Bestätigung",
    "settings_passive_vehicle_group_save_message": "Sind Sie sicher, die Fahrzeuggruppe zu speichern?",
    "settings_passive_vehicle_group_enable_title": "Bestätigung",
    "settings_passive_vehicle_group_enable_message": "Sind Sie sicher, die Fahrzeuggruppe zu aktivieren?",
    "settings_passive_vehicle_group_disable_title": "Bestätigung",
    "settings_passive_vehicle_group_disable_message": "Sind Sie sicher, die Fahrzeuggruppe zu deaktivieren?",
    "settings_passive_vehicle_group_new": "Neu",
    "settings_passive_vehicle_group_new_vehicle_group": "Neue Fahrzeuggruppe",
    "settings_passive_vehicle_group_detail": "Fahrzeuggruppe Details",
    "settings_passive_vehicle_group_info": "Fahrzeuggruppe Info",
    "settings_passive_vehicle_group_no_such_vehicle_group": "Es gibt keine solche Fahrzeuggruppe!",
    "settings_passive_vehicle_group_go_to_management": "Gehen Sie zur Fahrzeuggruppenverwaltung",
    "settings_passive_vehicle_group_name_min_length": "Der Name muss mindestens 5 Zeichen lang sein",
    "settings_passive_vehicle_group_no_data": "Es gibt keine Fahrzeuggruppe!",
    "settings_subscription_title": "Abonnement-Verwaltung",
    "settings_subscription_name": "Name",
    "settings_tour_koco_subscription_name": "Name",
    "settings_subscription_company_name": "Firma",
    "settings_subscription_description": "Bezeichnung",
    "settings_tour_koco_subscription_description": "Bezeichnung",
    "settings_tour_koco_subscription_user": "Benutzer",
    "settings_tour_koco_subscription_vehicle": "Fahrzeug",
    "settings_tour_koco_subscription_email": "Email",
    "settings_subscription_number_of_users": "Anzahl der Nutzer",
    "settings_subscription_number_of_vehicles": "Anzahl der Fahrzeuge",
    "settings_subscription_number_of_events": "Anzahl der Ereignisse",
    "settings_subscription_save_title": "Bestätigung",
    "settings_subscription_save_message": "Sind Sie sicher, das Abonnement zu speichern?",
    "settings_subscription_enable_title": "Bestätigung",
    "settings_subscription_enable_message": "Sind Sie sicher, das Abonnement zu aktivieren?",
    "settings_subscription_disable_title": "Bestätigung",
    "settings_subscription_disable_message": "Sind Sie sicher, das Abonnement zu deaktivieren?",
    "settings_subscription_new": "Neu",
    "settings_subscription_new_subscription": "Neues Abonnement",
    "settings_subscription_detail": "Abonnement Details",
    "settings_subscription_info": "Abonnement Info",
    "settings_subscription_warnings": "Warnungen",
    "settings_subscription_errors": "Fehler",
    "settings_subscription_reports": "Reports",
    "settings_subscription_no_such_subscription": "Es gibt kein solches Abonnement!",
    "settings_subscription_go_to_management": "Gehen Sie zur Veranstaltung-Abonnement-Verwaltung",
    "settings_subscription_name_min_length": "Der Name muss mindestens 5 Zeichen lang sein",
    "settings_subscription_tour_sub": "Tour Koco Sub",
    "settings_subscription_no_data": "Es gibt keine Abonnements!",
    "settings_event_info_name": "Name",
    "settings_event_info_description": "Bezeichnung",
    "settings_event_info_type": "Typ",
    "settings_event_info_send_SMS": "SMS senden",
    "settings_event_info_send_Email": "E-Mail senden",
    "settings_event_info_title": "Ereignisinfoverwaltung",
    "settings_event_info_company_name": "Firma",
    "settings_event_info_save_title": "Bestätigung",
    "settings_event_info_save_message": "Sind Sie sicher, die Ereignisinfo zu speichern?",
    "settings_event_info_enable_title": "Bestätigung",
    "settings_event_info_enable_message": "Sind Sie sicher, die Ereignisinfo zu aktivieren?",
    "settings_event_info_disable_title": "Bestätigung",
    "settings_event_info_disable_message": "Sind Sie sicher, die Ereignisinfo zu deaktivieren?",
    "settings_event_info_new": "Neu",
    "settings_event_info_new_event_info": "Neue Ereignisinfo",
    "settings_event_info_detail": "Ereignisinfo Details",
    "settings_event_info_info": "Ereignisinfo",
    "settings_event_info_no_such_event_info": "Es gibt kein solche Ereignisinfo!",
    "settings_event_info_go_to_management": "Gehen Sie zur Ereignisinfoverwaltung",
    "settings_event_info_name_min_length": "Der Name muss mindestens 5 Zeichen lang sein",
    "settings_event_info_no_data": "Es gibt keine Ereignisinfos!",
    "event_info_warning": "Warnung",
    "event_info_error": "Fehler",
    "event_info_report": "Report",
    "settings_sc_tour_plan_title": "Touren-Planung",
    "settings_sc_tour_plan_date_from": "Datum von",
    "settings_sc_tour_plan_date_to": "Datum bis",
    "settings_sc_tour_plan_time_from": "Uhrzeit von",
    "settings_sc_tour_plan_time_to": "Uhrzeit bis",
    "settings_sc_tour_plan_classifier": "Klassifikator",
    "settings_sc_tour_plan_from_date": "von",
    "settings_sc_tour_plan_to_date": "bis",
    "settings_sc_tour_plan_repeating": "Wiederholung",
    "settings_sc_tour_plan_tour_count": "Tour Anzahl",
    "settings_sc_tour_plan_truck": "Fahrzeug",
    "settings_sc_tour_plan_has_overlap_in_db": "überlappt in DB",
    "settings_sc_tour_plan_tour": "Tour",
    "settings_sc_tour_plan_actions": "Aktionen",
    "settings_sc_tour_plan_save_confirm_title": "Bestätigung speichern",
    "settings_sc_tour_plan_save_confirm_message": "Möchten Sie die Touren-Planung speichern?",
    "settings_sc_tour_plan_delete_confirm_title": "Bestätigung löschen",
    "metric_delete_confirm_title": "Bestätigung löschen",
    "settings_sc_tour_plan_delete_confirm_message": "Möchten Sie die Touren-Planung löschen?",
    "metric_delete_confirm_message": "Möchten Sie die Metric löschen?",
    "settings_sc_tour_plan_save_success_notification": "Tourplan erfolgreich gespeichert.",
    "settings_sc_tour_plan_save_failure_notification": "Fehler beim Speichern des Tourenplans.",
    "settings_sc_tour_plan_delete_success_notification": "Tourplan erfolgreich gelöscht.",
    "settings_sc_tour_plan_delete_failure_notification": "Fehler beim Speichern des Tourenplans.",
    "settings_sc_tour_plan_new": "Neue Touren-Planung",
    "settings_sc_tour_plan_new_custom": "Manuelles erstellen",
    "settings_sc_tour_plan_new_import_file": "Datei importieren",
    "settings_sc_tour_plan_new_title": "Neue Touren-Planung",
    "settings_sc_tour_plan_split_title": "Tour aufteilen",
    "settings_sc_tour_plan_new_add_record": "Eintrag hinzufügen",
    "settings_sc_tour_new_title": "Neue Touren-Stammdaten",
    "settings_sc_tour_plan_save_button": "Änderungen speichern",
    "settings_st_tour_plan_import_label": "Datei zu importieren",
    "settings_st_tour_plan_template": "Vorlage",
    "settings_st_tour_plan_data_overlaps": "Eingegebene Daten überschneiden sich mit vorhandenen Daten",
    "st_classifier_bio": "BIO",
    "st_classifier_rest": "Rest",
    "st_classifier_gewerbe": "Gewerbe",
    "st_classifier_select_label": "Klassifikator auswählen",
    "st_tour_select_label": "Tour auswählen",
    "settings_sc_tour": "Touren-Stammdaten",
    "duplication_name": "Namensduplikat",
    "update_st_tour": "Erfolg aktualisieren",
    "failure_st_tour": "Ein Fehler ist aufgetreten",
    "delete_st_tour": "Erfolg löschen",
    "st_single_insertion": "Erstellen",
    "st_single_import": "Datei importieren",
    "settings_sc_tour_save_confirm_message": "Möchten Sie die Touren-Stammdaten speichern?",
    "settings_sc_tour_delete_confirm_title": "Bestätigung löschen",
    "settings_sc_tour_delete_confirm_message": "Möchten Sie die Touren-Stammdaten löschen?",
    "settings_button_option": "Neue Touren-Stammdaten",
    "settings_sc_tour_title_insert": " Neu Touren-Stammdaten",
    "settings_sc_tour_title": "Touren-Stammdaten",
    "scantec_tour_overview_title": "Tourenübersicht",
    "scantec_tour_overview_date": "Datum",
    "scantec_tour_overview_vehicle": "Fahrzeug",
    "scantec_tour_overview_tour": "Tour",
    "scantec_tour_overview_address" : "Adresse",
    "scantec_tour_overview_foreign_material": "Fremdstoffgehalt",
    "scantec_tour_overview_total": "Gesamt",
    "scantec_tour_overview_ok": "OK",
    "scantec_tour_overview_medium": "Mittel",
    "scantec_tour_overview_nok": "NOK",
    "scantec_tour_overview_not_emptied": "nicht geleert",
    "scantec_tour_overview_percentage": "NOK Prozent",
    "settings_sc_tour_plan_threshold_first": "Grenzwerte Gelb",
    "settings_sc_tour_plan_threshold_second": "Grenzwerte Rot",
    "settings_sc_tour_plan_is_lifter_stop_active": "Lifterstop aktiv",
    "scantec_tour_details": "Tourdetails",
    "scantec_map_view": "Kartenansicht",
    "scantec_analysis": "Analyse",
    "scantec_tour_overview_all_bin_are_emptied": "Alle Tonnen geleert.",
    "scantec_tour_overview_bin_not_emptied": "Tonne(n) nicht geleert.",
    "scantec_tour_overview_cockpit": "Deepscan Einstellungen",
    "scantec_tour_overview_tour_management": "Tourenverwaltung",
    "scantec_tour_overview_default_cockpit": "Default Cockpit",
    "scantec_tour_overview_split_tour" : "Tour aufteilen",
    "settings_sc_tour_delete_success_notification": "Touren-Stammdaten erfolgreich gelöscht",
    "settings_sc_tour_save_success_notification": "Touren-Stammdaten erfolgreich gespeichert.",
    "settings_sc_tour_save_failure_notification": "Fehler beim Speichern des Touren-Stammdaten.",
    "settings_sc_tour_delete_failure_notification": "Fehler beim Speichern des Touren-Stammdaten.",
    "st_tour_overview_sum_total": "Anz. Total",
    "st_tour_overview_sum_ok": "Anz. OK",
    "st_tour_overview_sum_nok": "Anz. NOK",
    "deep_scan_settings_lifter_stop_active": "Lifterstop",
    "st_analysis_title": "Qualitätsverteilung",
    "st_analysis_OK_title": "DeepScan Sauber",
    "st_analysis_middle_title": "DeepScan Mittel",
    "st_analysis_NotOK_title": "DeepScan Verschmutzt",
    "st_analysis_OK_smart_l_title": "SmartScan Sauber",
    "st_analysis_middle_smart_l_title": "SmartScan Mittel",
    "st_analysis_NotOK_smart_l_title": "SmartScan Verschmutzt",
    "st_analysis_OK_smart_title": "Sauber-S",
    "st_analysis_middle_smart_title": "Mittel-S",
    "st_analysis_NotOK_smart_title": "Verschmutzt-S",
    "st_analysis_OK_deep_title": "Sauber-D",
    "st_analysis_middle_deep_title": "Mittel-D",
    "st_analysis_NotOK_deep_title": "Verschmutzt-D",
    "st_analysis_no_tours": "Keine Touren vorhanden!",
    "st_analysis_not_emptied_bins_count": "Anzahl der nicht geleerten Tonnen:",
    "st_analysis_not_emptied_bins_percentage": "Prozentsatz der nicht geleerten Tonnen:",
    "st_analysis_max_10_tours_are_displayed": "Es werden maximal 10 Touren angezeigt.",
    "st_analysis_tours_count": "Anzahl der Touren:",
    "st_analysis_not_emptied_percentage": "Nicht geleerten Tonnen",
    "st_analysis_summary_selected_series": "Abfallzusammensetzung für gefilterten Bereich:",
    "st_analysis_summary_no_selected_series": "Abfallzusammensetzung",
    "scantec_map_date" : "Datum",
    "scantec_map_address" : "Anschrift",
    "scantec_map_measurement" : "Messwert",
    "scantec_map_result" : "Bewertung",
    "scantec_map_binId" : "Bin Id",
    "scantec_map_tour" : "Tour",
    "scantec_map_emptied" : "Leerungsstatus",
    "scantec_map_copy_text" : "Daten kopiert",
    "st_deep_scan_settings_send" : "Senden",
    "st_tour_collector_classifier_type_unassigned" : "nicht zugeordnet",
    "st_tour_details_title": "Tourdetails",
    "st_tour_details_rfid": "RFID",
    "st_tour_details_other_material_load": "Fremdstoff-Belastung",
    "st_tour_details_result": "OK/NOK",
    "st_tour_details_date": "Datum",
    "st_tour_details_is_emptied": "Ist geleert",
    "st_tour_details_thresholds_not_available": "Grenzwerte nicht verfügbar",
    "st_tour_details_bin_emptied": "Tonne geleert",
    "st_tour_details_bin_not_emptied": "Tonne nicht geleert",
    "st_tour_details_lifter_type": "Lifter-Typ",
    "st_tour_details_lifter_size": "Lifter-Größe",
    "st_tour_details_measurement": "Messung",
    "st_tour_details_export_in_progress_message": "Export in Bearbeitung",
    "st_tour_details_report_cannot_be_generated": "Bitte verfeinern Sie die Filter, zu viele Daten.",
    "st_tour_details_select_bin_result": "Resultat auswählen",
    "st_tour_details_rfid_description": "RFID-Beschreibung",
    "st_tour_detail_ident_system_not_available": "Identsystem nicht verfügbar",
    "st_tour_detail_chip_not_detected": "CHIP nicht erkannt",
    "st_bin_type_left": "Left",
    "st_bin_type_right": "Right",
    "st_bin_type_arm": "Arm",
    "st_bin_type_comb": "Comb",
    "st_bin_type_two_wheel_left": "2-Rad links",
    "st_bin_type_two_wheel_right": "2-Rad rechts",
    "st_bin_type_four_wheel": "4-Rad",
    "st_bin_event_result_green": "Grün",
    "st_bin_event_result_yellow": "Gelb",
    "st_bin_event_result_red": "Rot",
    "st_bin_event_result_not_evaluated": "nicht bewertet",
    "st_tour_overview_sum_medium": "Anz. Mittel",
    "st_tour_overview_avg_percentage": "Ø Prozent",
    "scantec_settings_tourcollector_title" : "Tourensammler",
    "st_settings_tourcollector_date" : "Datum *",
    "st_settings_tourcollector_time" : "Uhrzeit",
    "st_settings_tourcollector_truck" : "Fahrzeug",
    "st_settings_tourcollector_tour" : "Tour",
    "st_settings_tourcollector_number_of_emptyings" : "Anz. Leerungen",
    "st_settings_tourcollector_save_confirm_title": "Bestätigung speichern",
    "st_settings_tourcollector_save_confirm_message": "Möchten Sie die Änderung speichern?",
    "st_settings_tourcollector_split_tour": "Tour aufteilen",
    "st_settings_tourcollector_merge_tour": "Tour verschmelzen",
    "cockpit_scantec_deep_scan_setings_title": "DeepScan-Einstellungen",
    "cockpit_scantec_current_deep_scan_setings": "Aktuell",
    "cockpit_scantec_deep_scan_setings_history": "Historie",
    "cockpit_scantec_update_deep_scan_setings": "Ändern",
    "cockpit_scantec_deep_scan_setings_item": "Einstellungselement",
    "cockpit_scantec_deep_scan_setings_description": "Bescreibung",
    "cockpit_scantec_deep_scan_setings_from_value": "(von) Wert",
    "cockpit_scantec_deep_scan_setings_to_value": "(auf) Wert",
    "deep_scan_settings_is_left_deep_scan_on": "DeepScan links",
    "deep_scan_settings_is_right_deep_scan_on": "DeepScan rechts",
    "deep_scan_settings_threshold_first": "Grenzwerte Gelb",
    "deep_scan_settings_threshold_second": "Grenzwerte Rot",
    "deep_scan_settings_is_setting_via_display_allowed": "Einstellung über Display erlaubt",
    "deep_scan_settings_lifter_stop_inactive": "Lifterstop aktiv",
    "deep_scan_settings_alarm_is_enabled": "Alarmton",
    "deep_scan_settings_thresholds": "Grenzwerte",
    "st_deep_scan_settings_save_confirm_title": "Bestätigung speichern",
    "st_deep_scan_settings_save_confirm_message": "Möchten Sie die Deepscan-Einstellungen Anforderung speichern?",
    "st_deep_scan_settings_delete_confirm_title": "Bestätigung löschen",
    "st_deep_scan_settings_delete_confirm_message": "Möchten Sie die Deepscan-Einstellungen Anforderung löschen?",
    "st_deep_scan_settings_enter_valid_number": "Bitte geben Sie eine gültige Zahl ein.",
    "st_deep_scan_settings_first_threshold_is_required": "Erste Grenze ist ein Pflichtfeld",
    "st_deep_scan_settings_allowed_min_is_15": "Der zulässige Mindestwert ist 15.",
    "st_deep_scan_settings_allowed_max_is_200": "Der zulässige Mindestwert ist 200.",
    "st_deep_scan_settings_first_thresh_less_than_second": "Die erste Grenze muss kleiner sein als die zweite Grenze.",
    "st_deep_scan_settings_second_threshold_is_required": "Zweite Grenze ist ein Pflichtfeld",
    "st_deep_scan_settings_allowed_min_is_20": "Der zulässige Mindestwert ist 20.",
    "st_deep_scan_settings_second_thresh_greather_than_first": "Die zweite Grenze muss größer sein als die erste Grenze.",
    "st_deep_scan_settings_request_change_status": "Änderungsmodus",
    "st_deep_scan_settings_request_desription": "Beschreibung",
    "st_deep_scan_settings_current_information_is_read_only": "Die Informationen auf dieser Seite können nur gelesen werden.",
    "st_deep_scan_settings_current_received_date": "Empfangsdatum: ",
    "st_tour_overview_status_today_less_5_green": "DeepScan ist eingeschaltet.",
    "st_tour_overview_status_today_less_5_red": "Ein Fehler ist aufgetreten. Bitte überprüfen Sie die Fehlermeldungen!",
    "st_tour_overview_status_today_less_5_grey": "DeepScan ist ausgeschaltet.",
    "st_tour_overview_status_today_grey": "Es wurden in den letzten 5 min keine Daten empfangen.",
    "st_tour_overview_status_not_today_green": "Tour wurde erfolgreich abgeschlossen.",
    "st_tour_overview_status_not_today_red": "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Fehlermeldungen!",
    "st_tour_overview_status_not_today_grey": "Es sind keine Ergebnisse vorhanden, da DeepScan ausgeschaltet war.",
    "st_tour_overview_last_data_received": "Letzter Datenempfang: ",
    "st_tour_overview_to_the_tour_details": "zu den Tourdetails",
    "st_tour_overview_to_the_map_view": "zur Kartenübersicht",
    "settings_subscription_daily": "Täglich",
    "settings_subscription_weekly": "Wöchentlich",
    "settings_subscription_daily_desc": "Tägliche Entgegennahme von Reiseberichten",
    "settings_subscription_weekly_desc": "Wöchentlicher Erhalt von Reiseberichten",
    "change_picture_btn": "Bild ändern",
    "upload_picture_btn": "Bild hochladen",
    "settings_subscription_tour": "Tour Koco Sub",
    "layout_default_logout": "Ausloggen",
    "layout_default_my_profile": "Mein Profil",
    "layout_default_no_options": "keine Optionen",
    "layout_default_clear": "löschen",
    "st_deep_scan_settings_update_new_request_title": "Einstellungen ändern",
    "layout_default_font_size": "Schriftgröße",
    "layout_default_toggle_fullscreen": "Vollbild umschalten",
    "subscription_type": "Typ",
    "tour_analyze_active": "Tour Analyse aktiv",
    "tour_analyze_inactive": "Tour Analyse inaktiv",
    "fleet_tracking_icon": "Fleet Tracking",
    "refresh_lanes": "Aktualisieren",
    "last_location": "Letzter Standort",
    "st_tour_new": "Eintrag hinzufügen",
    "service_dashboard_rcv_body_remain_work_hour": "Verbleibende Stunden bis zur nächsten Aufbauwartung:",
    "service_dashboard_rcv_body_active_error_count": "Aktive Fehlerzählung:",
    "service_dashboard_sweeper_water_tank_level": "Füllstand Wassertank:",
    "service_dashboard_sweeper_next_service_time": "Zeit bis zum nächsten Dienst:",
    "service_dashboard_sweeper_body_motor_diesel": "Aufbau motor diesel:",
    "service_dashboard_sweeper_adBlue": "AdBlue:",
    "service_dashboard_sweeper_diesel": "Diesel:",
    "fleet_overview_basic_data": "Basisdaten",
    "fleet_overview_operational_data": "Betriebsdaten",
    "fleet_overview_notification": "Meldungen",
    "search_address_map": "Adresse suchen",
    "not_emptied": "Nicht geleert",
    "report_title_1": "1. Anzeigeformat definieren",
    "report_show_here": " Hier anzeigen",
    "report_show_excel": " Excel exportieren",
    "report_send_to_me": "An mich senden",
    "report_title_2": "2. Zeitraum definieren",
    "report_predefined_selection": "Vordefiniert",
    "report_individule_selection": "Individuell",
    "report_please_select": "Bitte Wählen",
    "report_predefined_5_mn": "Letzte 5 minuten",
    "report_predefined_60_mn": "Letzte 60 minuten",
    "report_predefined_today": "Heute",
    "report_predefined_yesterday": "Gestern",
    "report_predefined_last_week": "Letzte Woche",
    "report_predefined_last_3_days": "Letzte 3 Tagen",
    "report_from": "Vom",
    "report_to": "Bis",
    "report_clone" : "Eingabe kopieren",
    "report_delete" : "Eingabe löschen",
    "report_cancel" : "Abbrechen",
    "report_selection_one_hour": "Letzte Stunde",
    "report_selection_today": "Heute",
    "report_selection_yesterday": "Gestern",
    "report_selection_three_days": "Letzte 3 Tage",
    "report_selection_seven_days": "Letzte 7 Tage",
    "report_selection_fourteen_days": "Letzte 14 Tage",
    "report_selection_thrirty_days": "Letzte 30 Tage",
    "report_selection_custom": "Individuelle Auswahl",
    "report_title_3": "1. Fahrzeug auswählen",
    "report_vehicle": "Fahrzeug",
    "report_title_4": "3. Signalspezifikation",
    "report_signal_specification": "Signalbezeichnung",
    "report_signal_type": "Berechnungstyp",
    "report_add_signal" : "Signal hinzufügen",
    "report_apply" : "Bericht erstellen",
    "report_January" : "Januar",
    "report_no_data": "Keine Daten gefunden",
    "report_February" : "Februar",
    "report_March" : "März",
    "report_April" : "April",
    "report_May" : "Mai",
    "report_June" : "Juni",
    "report_July" : "Juli",
    "report_August" : "August",
    "report_September" : "September",
    "report_October" : "Oktober",
    "report_November" : "November",
    "report_December" : "Dezember",
    "report_monday" : "M",
    "report_tues": "D",
    "report_wed": "M",
    "report_thurs": "D",
    "report_fri": "F",
    "report_sat": "S",
    "report_sun":"S",
    "report_type_max": "Maximum",
    "report_type_min": "Minimum",
    "report_type_sum": "Summ",
    "report_type_avg": "Durchschnitt",
    "report_type_diff": "Differenz",
    "report_chart_type": "Diagramm Typ",
    "report_chart_type_column": "Balkendiagramm",
    "report_chart_type_line": "Liniendiagramm",
    "report_data_time_range" : "Wählen Sie den Datums-/Zeitbereich",
    "report_signal_frequency_week": "Woche",
    "report_signal_frequency_month": "Monat",
    "go_to_page": "Gehe zu Seite: ",
    "scantec_more_filters_icon": "Weitere Filter anzeigen",
    "scantec_less_filters_icon": "Weniger Filter anzeigen",
    "settings_st_tour_data_duplicated": "Tourenstammdaten schon vorhanden",
    "settings_st_tour_is_uplicated_in_db": "dupliziert in DB",


    "settings_st_bin_event_delete_confirm_message_1": 'Möchten Sie die',
    "settings_st_bin_event_delete_confirm_message_2": 'ausgewählten Tourdetails löschen?',
    "settings_st_bin_event_delete_confirm_title": "Bestätigung löschen",
    "settings_st_bin_event_delete_success_notification": "Tourdetails erfolgreich gelöscht.",
    "settings_st_bin_event_actions": "Aktionen",
    "settings_st_bin_event_title": "Tourdetails bearbeiten",
    "settings_st_bin_event_to_edit_page" : "Tourdetails bearbeiten",
    "settings_depot_title": "Depotverwaltung",
    "settings_depot_new_depot": "Neuer Depot",
    "settings_depot_new": "Neu",
    "settings_depot_depot": "Depot",
    "settings_depot_description": "Beschreibung",
    "settings_depot_vehicles": "Anzahl Fahrzeuge",
    "settings_depot_no_data": "Es gibt keine Depot!",
    "settings_depot_no_such_depot": "Es gibt keinen solchen Depot!",
    "settings_depot_go_to_management": "Gehen Sie zur Depotverwaltung",
    "settings_depot_abbreviaiton": "Abbreviatur",
    "settings_depot_detail": "Depotdetail",
    "settings_depot_info": "Depotinfo",
    "settings_depot_name": "Name",
    "settings_depot_streetName": "Straße Name",
    "settings_depot_streeNumber": "Straße Nummer",
    "settings_depot_city": "Stadt",
    "settings_depot_zip": "Postleitzahl",
    "settings_depot_country": "Land",
    "settings_depot_delete_content": "Sind Sie sicher, dass Sie dieses Depot löschen wollen?",
    "settings_depot_delete": "Löschen",
    "settings_depot_depot_name": "Depot Name",
    "cockpit_ecotec": "ECOTEC",
    "cockpit_ecotec_basic_info_hint": "Grundlegende Informationen über den Lkw",
    "cockpit_ecotec_detail_info_hint": "Detailinformationen zum Lkw",
    "cockpit_ecotec_detail_info": "Einzelheiten",
    "cockpit_ecotec_basic_info": "Grundlegende Informationen",
    "cockpit_ecotec_vehicle_name": "Name",
    "cockpit_ecotec_vehicle_manufacturer": "Hersteller",
    "cockpit_ecotec_vehicle_license": "Kennzeichen",
    "cockpit_ecotec_vehicle_weight": "Gewicht (kg)",
    "cockpit_ecotec_vehicle_timezone": "Zeitzone",
    "cockpit_ecotec_vehicle_vehicleType": "Fahrzeugtyp",
    "cockpit_ecotec_vehicle_labelId": "Label-ID",
    "cockpit_ecotec_vehicle_model": "Modell",
    "cockpit_ecotec_vehicle_ktr": "Ktr",
    "cockpit_ecotec_vehicle_constructionType": "Aufbau-Typ",
    "cockpit_ecotec_vehicle_chassisType": "Fahrgestell Typ",
    "cockpit_ecotec_vehicle_chassisNumber": "Fahrgestellnummer",
    "cockpit_ecotec_vehicle_lifterType": "Lifter-Typ",
    "cockpit_ecotec_vehicle_lifter": "Lifter",
    "cockpit_ecotec_vehicle_lifterNumber": "Lifter-Nr",
    "cockpit_ecotec_vehicle_axisConfiguration": "Konfiguration der Achsen",
    "cockpit_ecotec_vehicle_examination": "Hauptuntersuchung",
    "cockpit_ecotec_vehicle_securityCheck": "Sicherheitsprüfung",
    "cockpit_ecotec_vehicle_tachoCheck": "Tachoprüfung",
    "cockpit_ecotec_vehicle_uvvCheck": "UVV-Prüfung",
    "cockpit_ecotec_save_btn": "Speichern",
    "st_tour_details_overview_title": "Überblick",
    "st_tour_details_smartscan_title": "SmartScan",
    "st_tour_details_smartscan_col": "SmartScan",
    "st_tour_details_waiting_for_data": "warten auf Daten von Smartscan",
    "st_tour_details_no_smartscan_data_received": "no SmartScan data received",
    "st_tour_details_popup_image_info": "Bild Informationen",
    "st_tour_details_popup_date_time": "Datum/Uhrzeit",
    "st_tour_details_popup_image_number": "Bildnummer",
    "st_tour_details_popup_rfid_number": "RFID", 
    "st_tour_details_popup_captured_fractions": "Erfasste Fraktionen",
    "st_tour_details_popup_no_results": "keine Ergebnisse",
    "st_tour_details_popup_reason": "Grund", 
    "st_data_device_no_option": "keine Option",
    "st_data_device_deep_scan": "DeepScan",
    "st_data_device_deep_and_smart_scan": "Deep- & SmartScan",
    "st_data_device_smart_scan": "SmartScan",
    "st_tour_overview_select_device_type": "Scantyp auswählen",
    "st_tour_overview_classifier": "Klassifikator",
    "st_tour_overview_device_type": "Scantyp",
    "st_tour_overview_thresholds": "Grenzwerte",
    "st_tour_overview_evaluation_good": "Gute Bewertung",
    "st_tour_overview_evaluation_middle": "Mittlere Bewertung",
    "st_tour_overview_evaluation_bad": "Schlechte Bewertung",
    "st_tour_overview_not_allowed": "nicht erlaubt",
    "st_tour_overview_allowed": "erlaubt",
    "st_tour_overview_description": "Beschreibung",
    "st_tour_overview_classifier_type": "Typ",
    "st_tour_overview_classifier_public": "öffentlich",
    "st_tour_overview_classifier_private": "privat",
    "st_tour_overview_parent": "Elternklassifikator",
    "st_tour_overview_ai_model": "KI-Modell",
    "st_fraction_0": "Hintergrund",
    "st_fraction_1": "Ignore",
    "st_fraction_2": "Restmüll",
    "st_fraction_3": "Biomüll",
    "st_fraction_4": "Abbaubarer Sack",
    "st_fraction_5": "Glas",
    "st_fraction_6": "Metall",
    "st_fraction_7": "Papier",
    "st_fraction_8": "Karton",
    "st_fraction_9": "Kunststoff",
    "st_fraction_10": "PET",
    "st_fraction_11": "Sonstiger Sack",    
    "st_tour_plan_scan_type": "Scantyp",
    "st_no_scan_device": "Kein Scangerät verfügbar",
    "smartscan_classifier": "SmartScan Classifer Settings",
    "smartscan_stclassifier": "SmartScan-Klassifikatoren",
    "new_smartscan_stclassifier": "Neuer Klassifikator",
    "smartscan_search_stclassifier": "Suche Klassifikator",
    "stclassifier_table_name": "Name",
    "stclassifier_table_description": "Beschreibung",
    "stclassifier_table_company": "Unternehmen",
    "stclassifier_table_type": "Typ",
    "stclassifier_table_enabled": "Aktiviert",
    "smartscan_stsclassifier_details": "Klassifikator Detail",
    "settings_stclassifier_delete": "Löschen",
    "smartscan_stclassifier_save_title": "Konfirmation",
    "smartscan_stclassifier_save_message": "Sind Sie sicher, dass Sie den Klassifikator speichern wollen?",
    "smartscan_stClassifier_ispublic": "Ist öffentlich",
    "smartscan_stclassifier_aimodels": "AI-Modelle",
    "smartscan_stclassifier_classifiers": "Klassifikatoren",
    "smartscan_stclassifier_goodrating": "Gute Bewertung:",
    "smartscan_stclassifier_mediumrating": "Mittlere Bewertung:",
    "smartscan_stclassifier_badrating": "Schlechte Bewertung:",
    "smartscan_stclassifier_notallowed": "Nicht Erlaubt",
    "smartscan_stclassifier_allowed": "Erlaubt",
    "smartscan_stclassifier_addcluster": "Cluster Hinzufügen",
    "smartscan_stclassifier_enable": "Aktivieren",
    "smartscan_stclassifier_disable": "Deaktivieren",
    "smartscan_stclassifier_enable_title": "Konfirmation",
    "smartscan_stclassifier_enable_message": "Sind Sie sicher, dass Sie den Klassifikator aktivieren wollen?",
    "smartscan_stclassifier_disable_title": "Konfirmation",
    "smartscan_stclassifier_disable_message": "Sind Sie sicher, dass Sie den Klassifikator deaktivieren wollen?",
    "smartscan_stclassifier_name_unique": "Klassifikator mit diesem Namen existiert bereits, Name sollte eindeutig sein",
    "smartscan_stclassifier_aimodel_required": "KI-Modell ist erforderlich",
    "smartscan_stclassifier_parent_required": "Übergeordneter Klassifikator ist erforderlich",
    "smartscan_stclassifier_allowed_threshold_error": "Die gute Bewertung kann nicht kleiner oder gleich der schlechten Bewertung sein.",
    "smartscan_stclassifier_notallowed_threshold_error": "Die gute Bewertung kann nicht größer oder gleich der schlechten Bewertung sein.",
    "st_smartscan_OK_title": "Smartscan Sauber",
    "st_smartscan_middle_title": "Smartscan Mittel",
    "st_smartscan_NotOK_title": "Smartscan Verschmutzt",
    "st_smartscan_grey": "Smartscan nicht klassifiziert",
    "st_smartscan_white": "Smartscan ausstehend",
    "st_smartscan_data_missing_map": "einige der Smartscan-Daten fehlen",
    "st_analysis_overview_title": "Übersicht",
    "st_analysis_deepscan_title": "DeepScan",
    "st_analysis_smartscan_title": "SmartScan",      
    "settings_user_actions": "Aktionen",
    "setting_update_action": "Kopieren",    
    "smartscan_stclassifier_editable": "Dieser Klassifikator ist nur bis zum nächsten Arbeitstag bearbeitbar.",
    "smartscan_stclassifier_noteditable": "Dieser Klassifikator kann nicht mehr bearbeitet werden.",

    "handovers_tab_hint": "Im PDF-Protokoll finden Sie Informationen über die Übergabe von LKWs, wie z.B. das Datum, ob der LKW übergeben wurde oder nicht, die Kilometerzahl, die Betriebsstunden und den Namen des Kunden. Außerdem können Sie den Übergabebericht von hier herunterladen",
    "handovers_tab": "PDF-Protokoll",
    "handovers_date": "Datum",
    "handovers_type": "Art",
    "handovers_customer": "Kunde",
    "handovers_km": "Kilometerstand",
    "handovers_hours_used": "Betriebsstunden",
    "handovers_actions": "Aktionen",
    "handovers_handover": "Übergabe",
    "handovers_withdrawl": "Rücknahme",
    "handovers_download": "Herunterladen",
    "cockpit_errorreports_internal_fleet_manager": "Interne Fuhrparknummer",
    "cockpit_errorreports_construction_type": "Aufbau-Typ",
    "cockpit_errorreports_license_plate": "Kennzeichen",
    "cockpit_errorreports_report_date": "Erstellungsdatum des Berichts",
    "cockpit_tab_live_data_rcv_unit": "Einheit",
    "st_mapoverview_emptied": "Geleert",
    "st_mapoverview_popup_binId": "CHIP nicht erkannt",
    "st_bin_event_measuremnt_code_0": "DeepScan off",
    "st_bin_event_measuremnt_code_minus_1": "No measurements recorded",
    "st_bin_event_measuremnt_code_minus_2": "Plausibility check failed",
    "st_bin_event_measuremnt_code_minus_3": "No valid measurements recorded (e.g., >200)",
    "st_bin_event_measuremnt_code_minus_4": "No COMP-2 performed yet",
    "st_bin_event_measuremnt_code_minus_5": "Samples less than 33 (plausibility cannot be performed)",
    "st_bin_event_measuremnt_code_minus_6": "Detectors not available / disabled ",
    "st_bin_event_measuremnt_code_104": "Plausibility check failed",
    "st_bin_event_measuremnt_code_255": "No measurements recorded",
    "st_bin_event_measuremnt_code_not_given": "unknown behavior",
    "settings_error_group_body_type": "Aufbau Typ",
    "settings_error_code_group_title": "Fehlergruppen Verwaltung",
    "settings_error_code_group_new_error_group": "Neue Fehlergruppe",
    "settings_error_code_group_new": "Neu",
    "settings_error_group_info": "Fehlergruppe Info",
    "settings_error_group_no_such_error_group": "Es gibt keine solche Fehlergruppe!",
    "settings_error_group_go_to_management": "Zur Fehlergruppe Verwaltung",
    "settings_error_group_no_data": "Es gibt keine Fehlergruppen!",
    "settings_error_group_new": "Neu",
    "settings_error_code_group_detail": "Fehlergruppe Details",
    "settings_body_type": "Aufbau Typ",
    "overview_menu_error_code": "Fehlercode",
    "overview_menu_error_description": "Fehlerbeschreibung",
    "settings_error_group_description": "Bezeichnung",
    "settings_error_group_name": "Name",
    "settings_error_group_name_min_length": "Der Name muss mindestens 5 Zeichen lang sein",
    "settings_error_group_save_message": "Sind Sie sicher, dass Sie die Fehlergruppe speichern wollen?",
    "settings_error_group_save_title": "Bestätigung",
    "settings_error_group_enable_title": "Bestätigung",
    "settings_error_group_enable_message": "Sind Sie sicher, die Fehlergruppen zu aktivieren?",
    "settings_error_group_disable_title": "Bestätigung",
    "settings_error_group_disable_message": "Sind Sie sicher, die Fehlergruppen zu deaktivieren?",
    "settings_error_group_public_title": "Publikum",
    "settings_error_group_public_message": "Sind Sie sicher, den Status der Fehlergruppe aktualisieren müssen?",
    "settings_error_group_private_title" : "Privat",
    "settings_error_group_private_message": "Sind Sie sicher, den Status der Fehlergruppe aktualisieren müssen??",
    "settings_error_groups_privacy_status": "Datenschutz Status",
    "settings_error_group_public": "öffentlich",
    "settings_error_group_private": "privat",
    "error_group_not_accessible": "Sie haben keinen Zugriff auf die Fehlergruppen",
    "settings_error_tab_send_notifications_on_resovled": "Benachrichtigungen über aufgelöste",
    "event_info_tour_koco": "Tour Koco",
    "deep_scan_settings_thresholds_tooltip": "Kleinerer Wert bedeutet höhere Empfindlichkeit",
    "pagination_go_to_first_page": "zur ersten Seite",
    "pagination_go_to_last_page": "zur letzten Seite",
    "pagination_go_to_next_page": "zur nächsten Seite",
    "pagination_go_to_previous_page": "zur vorherigen Seite",
    "settings_user_sendlink_title": "Bestätigung",
    "settings_user_sendlink_message": "Sind Sie sicher, dass Sie den Link erneut an den Benutzer senden möchten?",
    "settings_subscription_type": "Wählen Sie zuerst den Aufbautyp, dann den Abonnementtyp",
    "settings_subscription_types": "Abonnement-Typ",
    "settings_subscription_users": "Benutzer",
    "settings_errors_tab_body_type": "Aufbau Typ",
    "settings_error_group_warning_title": "WARNUNG",
    "settings_error_group_warning_message": "Ein Benutzer hat diese Fehlergruppe abonniert. Die Deaktivierung hat Auswirkungen auf die Ergebnisse des Abonnements",    "st_map_show_markers": "Tour anzeigen",
    "st_map_hide_markers": "Tour ausblenden",
    "st_analysis_NotEvaluated_title": "Deepscan nicht bewertet",
    "st_mapoverview_popup_binId_not_avail": "Identsystem nicht verfügbar",
    "st_analysis_NotEvaluated_title": "Deepscan nicht bewertet",
    "tour_overview_title": "Übersicht",
    "tour_overview_save_success_notification": "Tourenübersicht erfolgreich gespeichert.",
    "tour_overview_save_failure_notification": "Fehler beim Speichern.",
    "tour_overview_save_confirm_title": "Bestätigung speichern",
    "tour_overview_save_confirm_message": "Möchten Sie die Übersicht speichern?",
    "tour_analyse_tour_data_duplicated": "Tourenstammdaten schon vorhanden",
    "tour_analyse_tour_plan_save_confirm_title": "Bestätigung speichern",
    "tour_analyse_tour_plan_save_confirm_message": "Möchten Sie die Touren-Planung speichern?",
    "tour_analyse_tour_delete_confirm_title": "Bestätigung löschen",
    "tour_analyse_tour_delete_confirm_message": "Möchten Sie die Touren-Stammdaten löschen?",
    "tour_analyse_tour_delete_success_notification": "Touren-Stammdaten erfolgreich gelöscht",
    "tour_analyse_tour_delete_failure_notification": "Fehler beim Speichern des Touren-Stammdaten.",
    "tour_analyse_settings_role_name": "Name",
    "tour_analyse_tour_plan_actions": "Aktionen",
    "tour_analyse_tour": "Touren-Stammdaten",
    "tour_analyse_tour_plan_save_button": "Änderungen speichern",
    "tour_analyse_tour_save_confirm_message": "Möchten Sie die Touren-Stammdaten speichern?",
    "settings_ta_tour_save_success_notification": "Touren-Stammdaten erfolgreich gespeichert.",
    "settings_ta_tour_title": "Touren-Stammdaten",
    "settings_ta_tour_new_title": "Neue Touren-Stammdaten",
    "settings_ta_tour_plan_save_button": "Änderungen speichern",
    "settings_ta_tour_save_confirm_message": "Möchten Sie die Touren-Stammdaten speichern?",
    "settings_ta_tour_plan_template": "Vorlage",
    "settings_ta_tour_data_duplicated": "Tourenstammdaten schon vorhanden",
    "tour_analyse_tour_plan_save_confirm_title": "Bestätigung speichern",
    "tour_analyse_tour_plan_save_confirm_message": "Möchten Sie die Touren-Planung speichern?",
    "settings_ta_tour_delete_confirm_title": "Bestätigung löschen",
    "settings_ta_tour_delete_confirm_message": "Möchten Sie die Touren-Stammdaten löschen?",
    "settings_ta_tour_is_uplicated_in_db": "dupliziert in DB",
    "settings_ta_tour_plan_actions": "Aktionen",
    "ta_tour_new": "Eintrag hinzufügen",
    "settings_ta_tour_plan_import_label": "Datei zu importieren",
    "ta_tour_details_title": "Adressliste",
    "ta_tour_details_bin_not_emptied": "Tonne nicht geleert",
    "ta_tour_details_bin_emptied": "Tonne geleert",
    "ta_tour_details_date": "Datum",
    "ta_tour_details_rfid": "RFID",
    "ta_tour_detail_ident_system_not_available": "Identsystem nicht verfügbar",
    "ta_tour_detail_chip_not_detected": "CHIP nicht erkannt",
    "ta_tour_details_is_emptied": "Ist geleert",
    "ta_tour_details_lifter_type": "Lifter-Typ",
    "ta_bin_type_two_wheel_left": "2-Rad links",
    "ta_bin_type_two_wheel_right": "2-Rad rechts",
    "ta_bin_type_arm": "Arm",
    "ta_bin_type_four_wheel": "4-Rad",
    "ta_bin_type_comb": "Comb",
    "tour_analyse_overview_vehicle": "Fahrzeug",
    "tour_analyse_overview_tour": "Tour",
    "tour_analyse_overview_address" : "Adresse",
    "ta_tour_details_overview_title": "Überblick",
    "ta_tour_details_export_in_progress_message": "Export in Bearbeitung",
    "ta_tour_select_label": "Tour auswählen",
    "tour_analyse_less_filters_icon": "Weniger Filter anzeigen",
    "tour_analyse_more_filters_icon": "Weitere Filter anzeigen",
    "ta_tour_details_rfid": "RFID",
    "ta_tour_details_report_cannot_be_generated": "Bitte verfeinern Sie die Filter, zu viele Daten.",
    "ta_map_fleet_tracking_title": "Karte",
    "ta_map_search_address_map": "Adresse suchen",
    "ta_map_mapoverview_emptied": "Geleert",
    "ta_map_not_emptied": "Nicht geleert",
    "ta_map_tour" : "Tour",
    "ta_map_date" : "Datum",
    "ta_map_address" : "Anschrift",
    "ta_map_layout_default_no_options": "keine Optionen",
    "ta_map_layout_default_clear": "löschen",
    "ta_map_tour_select_label": "Tour auswählen",
    "ta_map_tour_analyse_truck_selection": "Fahrzeug auswählen",
    "ta_map_settings_tour_plan_new_add_record": "Eintrag hinzufügen",
    "ta_map_layout_default_search": "Suche",
    "errors_tab_default_enabled": "Aktiviert/Öffentlich",    
    "ta_map_type_bin": "Bin-Typ",
    "ta_map_left_bin": "2-Rad links",
    "ta_map_right_bin": "2-Rad rechts",
    "ta_map_4wheel_bin": "4-Rad",
    "scantec_map_plausibility_check": "Plausibilitätsüberprüfung fehlgeschlagen",
    "scantec_map_no_valid_value": "keine gültigen Messwerte erhalten",
    "scantec_map_compensation_check": "Compensation2 nicht durchgeführt",
    "scantec_map_few_samples": "zu wenig Messwerte",
    "scantec_map_deepscan_off": "DeepScan nicht verfügbar",

    "tour_plan_maxmimum_payload_allowed": "Nutzlast",
    "collection_type_rest": "Restmüll",
    "collection_type_bio": "Bio",
    "collection_type_glass": "Glas",
    "collection_type_dsd_recycling": "DSD",
    "collection_type_bulky": "Spermüll",
    "collection_type_paper": "Papier",
    "collection_type_title": "Abfallart",
    "send_new_request": "Neue Anfrage senden",
    "ta_map_reset_filter": "Filter zurücksetzen",
    "reports_title": "Leistungsberichte",
    "reports_date": "Datum",
    "reports_vehicle": "Fahrzeug",
    "reports_type": "Typ",
    "reports_actions": "Aktionen",
    "report_detail_title": "Berichtsdetails",
    "report_type_performance_rcv_diesel": "Perf. RCV Diesel",
    "report_type_performance_rcv_bluepower": "Perf. RCV Bluepower",
    "ta_total_bins" : "Behälteranzahl",
    "login_character_match": "Regeln: Mindestens 8 Zeichen, 1 Großbuchstabe und mindestens 1 Zahl",
    "login_UpperCase_match" : "Das Passwort muss mindestens einen Großbuchstaben enthalten.",
    "login_Number_match" : "Das Passwort muss mindestens eine Zahl enthalten.",
    "digital_service_book_body_title": "Aufbau",
    "digital_service_book_lifter_title": "Lifter",
    "digital_service_book_legal_title": "Legal",
    "settings_st_regions_title": "Gebiete",
    "settings_st_region_new": "Neues Gebiet",
    "st_regions_search_text": "Region für die Suche eingeben",
    "settings_st_regions_name": "Name",
    "settings_st_regions_description": "Beschreibung",
    "settings_st_regions_type": "Typ",
    "settings_st_regions_actions": "Aktionen",
    "settings_st_region_title": "Gebiete",
    "settings_st_region_detail": "Angaben zur Region",
    "settings_st_region_name": "Name",
    "settings_st_region_type": "Typ",
    "login_Number_match" : "Das Passwort muss mindestens eine Zahl enthalten.", 
    "st_tour_detail_invalid_RFID": "Ungültige RFID",
    "ta_tour_detail_invalid_RFID": "Ungültige RFID",
    "ta_overview_view_mode": "Ansichtsmodus",
    "ta_overview_edit_mode": "Bearbeitungsmodus",
    "st_no_scantec_device_role_assigned": "Es wurde keine SCANTEC-Device-Rolle zugewiesen. Bitte wenden Sie sich an den Administrator.",
    "cockpit_tab_default_not_available_abbrevation": "Nicht verfügbar",
    "reports_fit_to_content": "An Inhalt anpassen",
    "report_dialog_hint": "Bitte füllen Sie das folgende Formular aus. Das Feld \„Name\“ ist erforderlich.",
    "report_save_new_layout": "Neue Vorlage speichern",
    "report_name": "Name",
    "report_description": "Beschreibung",
    "report_save_as_new_template": "als neue Vorlage speichern",
    "report_update_template": "Vorlage aktualisieren",
    "report_this": "Diese",
    "report_value": "Wert",
    "report_indicator": "zeigt an, dass es sich um",
    "report_not_avaliable": "nicht verfügbar",
    "report_truck": "auf Lkw",
    
};
export default locale;