import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getCompany = createAsyncThunk('main/settingsCompany/company/getCompany', async (id) => {
  const response = await axios.get(`api/v1/companies/${id}`, { headers: { 'X-UI-State': 'user.settings.companies' }});
  const data = await response.data;

  return data === undefined ? null : data;
});

export const getCompanies = createAsyncThunk('main/settingsCompany/company/getCompanies', async () => {
  const response = await axios.get(`api/v1/companies`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.companies', 'X-UI-Role': 'user.edit' } });
  const data = await response.data;

  return data.companies;
});

export const removeProduct = createAsyncThunk(
  'eCommerceApp/product/removeProduct',
  async (val, { dispatch, getState }) => {
    const { id } = getState().eCommerceApp.product;
    await axios.post('/api/e-commerce-app/remove-product', { id });

    return id;
  }
);

export const patchCompany = createAsyncThunk('main/settingsCompany/company/patchCompany', async (company, { getState }) => {
  // const { main } = getState();

  let clone = _.cloneDeep(company);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'company',clone);

  const response = await axios.patch(`api/v1/companies/${company.id}`, payload);

  const data = await response.data;

  return data;
});


export const patchCompanyModules = createAsyncThunk('main/settingsCompany/company/patchCompanyModules', async (modules, { getState }) => {
  const { main } = getState();
  const { entity: company } = main.settingsCompany.company;
  let payload = { name: company.name };
  if (modules) {
    payload.companyAccesses = modules.map(unit => (
      {
        company: unit.company.id,
        passiveVehicle: unit.passive_vehicle.id,
        fleetTrackingStatus: unit.fleetTrackingStatus,
        serviceDashboardStatus: unit.serviceDashboardStatus,
        ecotecFleetStatus: unit.ecotecFleetStatus ? unit.ecotecFleetStatus : 4,
        tourAnalyzeStatus: unit.tourAnalyzeStatus,
        reportsStatus: unit.reportsStatus,
        tourAnalyse2: unit.tourAnalyse2,
        jiraFaunCompleteStatus: unit.jiraFaunCompleteStatus ? unit.jiraFaunCompleteStatus : 4,
      }));
  }
  else
    payload.companyAccesses = [];

  const response = await axios.patch(`api/v1/companies/${company.id}`, { company: payload });

  const data = await response.data;

  return data;
});

export const postCompany = createAsyncThunk('main/settingsCompany/company/postCompany', async (company, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(company);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'company', clone);

  const response = await axios.post(`api/v1/companies`, payload);

  const data = await response.data;

  return data;
});

export const getUnitsByCompany = createAsyncThunk('main/settingsUnit/company/getUnitsByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/passivevehicles`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;
  if (!data || !data.passivevehiclecompanyaccesses)
    return [];  

  return data.passivevehiclecompanyaccesses.map(access => {
    return {
      ...access.passive_vehicle,
      passive_vehicle: access.passive_vehicle,
      company: access.company,
      fleetTrackingStatus: access.fleetTrackingStatus,
      jiraFaunCompleteStatus: access.jiraFaunCompleteStatus,
      reportsStatus: access.reportsStatus,
      serviceDashboardStatus: access.serviceDashboardStatus,
      ecotecFleetStatus: access.ecotecFleetStatus,
      tourAnalyzeStatus: access.tourAnalyzeStatus,
      tourAnalyse2: access.tourAnalyse2,
    }
  });
});

export const activateCompany = createAsyncThunk('main/settingsCompany/company/activateCompany', async (company, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/companies/${company.id}/enable`);
  const data = await response.data;

  return data;
});

export const deleteCompany = createAsyncThunk('main/settingsCompany/company/deleteCompany', async (company, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/companies/${company.id}`);
  const data = await response.data;

  return data;
});

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsCompany.company.units);

export const getselectedUnits = (state) =>
  state.main.settingsCompany.company.selectedUnits;

  const generateNewCompany = () => {
    return {
      id: '',
      name: '',
      address: '',
      country: '',
      generalEmail: '',
      generalPhone: '',
      internetAddress: '',
      location: '',
      zipcode: '',
    };
  };

  const companySlice = createSlice({
    name: 'main/settingsCompany/company',
    initialState: {
      loadingCompany: false,
      tabValue: 0, 
      entity: null,
      loadingUnits: false,
      savingModulesCompany: false,
      savingCompany: false,
      selectedUnits: [],
      units: unitsAdapter.getInitialState({ }),
    },
  reducers: {
    setTabValue: {
      reducer: (state, action) => {
        state.tabValue = action.payload;
      },
    },
    setUnits : {
      reducer: (state, action) => {
        unitsAdapter.setAll(state.units, action.payload);
      },
    },
    setSelectedUnits: {
      reducer: (state, action) => {
        state.selectedUnits = action.payload;
      },
    },
    resetCompany: {
      reducer: (state, action) => {
        state.entity = generateNewCompany();
        state.tabValue = 0;
        state.loadingCompany = false;
      },
    },
    newCompany: {
      reducer: (state, action) => {
        state.entity = generateNewCompany();
        state.entity.id = 'new';
      },
    },
  },
  extraReducers: {
    [getCompany.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingCompany = false;
      state.tabValue = 0;
      unitsAdapter.removeAll(state.units);
    },
    [getCompany.pending]: (state) => {
      state.loadingCompany = true;
    },
    [getCompany.rejected]: (state) => {
      state.loadingCompany = false;
    },

    [patchCompany.fulfilled](state, { payload }) {
      state.savingCompany = false;
    },
    [patchCompany.pending](state, { payload }) {
      state.savingCompany = true;
    },
    [patchCompany.rejected](state, { payload }) {
      state.savingCompany = false;
    },

    [patchCompanyModules.fulfilled](state, { payload }) {
      state.savingCompany = false;
    },
    [patchCompanyModules.pending](state, { payload }) {
      state.savingCompany = true;
    },
    [patchCompanyModules.rejected](state, { payload }) {
      state.savingCompany = false;
    },

    [postCompany.fulfilled](state, { payload }) {
      state.savingCompany = false;
    },
    [postCompany.pending](state, { payload }) {
      state.savingCompany = true;
    },
    [postCompany.rejected](state, { payload }) {
      state.savingCompany = false;
    },

    [getUnitsByCompany.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnitsByCompany.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnitsByCompany.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getCompanies.fulfilled](state, { payload }) {
      CompaniesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      CompaniesAdapter.removeAll(state.companies);
      state.loadingCompanies = false;
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },

    [activateCompany.fulfilled](state, { payload }) {
      state.savingCompany = false;
    },
    [activateCompany.pending](state, { payload }) {
      state.savingCompany = true;
    },
    [activateCompany.rejected](state, { payload }) {
      state.savingCompany = false;
    },

    [deleteCompany.fulfilled](state, { payload }) {
      state.savingCompany = false;
    },
    [deleteCompany.pending](state, { payload }) {
      state.savingCompany = true;
    },
    [deleteCompany.rejected](state, { payload }) {
      state.savingCompany = false;
    },
  },
});

export const { setTabValue, resetCompany, newCompany,  setUnits,  setSelectedUnits } = companySlice.actions;

export default companySlice.reducer;
