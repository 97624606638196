// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    border-radius: 5px;
}

.legend {
    text-align: left;
    line-height: 18px;
    color: #555;
}

img {
    float: left;
    margin-right: 8px;
    margin-top: 3px;
}
svg {
    float: unset;
    /* margin-right: 18px;
    margin-top: 3px; */
    margin-bottom: 0px;
    display: inline;
}

.calendarCell {
    border-radius :50% !important;
}

.applyButton {
   background-color: rgb(17, 24, 39) !important;
    color:white  !important;
    border: 0px !important;
}

.cancelButton {
    background-color: rgb(17, 24, 39) !important;
    color:white  !important;
    border: 0px !important;
}

.activeNotifier {
    visibility: hidden;
}

.maxDateLabel {
    visibility: hidden;
}

.input-group {
    display: none;
}

.fromDateHourContainer {
    border: 0px !important;
}
.fromDateTimeContainer{
    width: 255px !important;
    float: left;
}

.rangecontainer div {
    outline: unset !important;
}

.MuiButton-sizeMedium svg {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.daterangepicker {
    width: 150%  !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/fleetTracking/css/legend.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4CAA4C;IAC5C,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,YAAY;IACZ;sBACkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;GACG,4CAA4C;IAC3C,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,4CAA4C;IAC5C,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".info {\n    padding: 6px 8px;\n    font: 14px/16px Arial, Helvetica, sans-serif;\n    background: white;\n    border-radius: 5px;\n}\n\n.legend {\n    text-align: left;\n    line-height: 18px;\n    color: #555;\n}\n\nimg {\n    float: left;\n    margin-right: 8px;\n    margin-top: 3px;\n}\nsvg {\n    float: unset;\n    /* margin-right: 18px;\n    margin-top: 3px; */\n    margin-bottom: 0px;\n    display: inline;\n}\n\n.calendarCell {\n    border-radius :50% !important;\n}\n\n.applyButton {\n   background-color: rgb(17, 24, 39) !important;\n    color:white  !important;\n    border: 0px !important;\n}\n\n.cancelButton {\n    background-color: rgb(17, 24, 39) !important;\n    color:white  !important;\n    border: 0px !important;\n}\n\n.activeNotifier {\n    visibility: hidden;\n}\n\n.maxDateLabel {\n    visibility: hidden;\n}\n\n.input-group {\n    display: none;\n}\n\n.fromDateHourContainer {\n    border: 0px !important;\n}\n.fromDateTimeContainer{\n    width: 255px !important;\n    float: left;\n}\n\n.rangecontainer div {\n    outline: unset !important;\n}\n\n.MuiButton-sizeMedium svg {\n    margin-left: 0 !important;\n    margin-right: 0 !important;\n}\n\n.daterangepicker {\n    width: 150%  !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
