import { combineReducers } from '@reduxjs/toolkit';
import tourPlans from './TourPlansSlice';
import tourPlanNew from './TourPlanNewSlice';

const reducer = combineReducers({
   tourPlans,
   tourPlanNew
});

export default reducer;
