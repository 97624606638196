import _ from '@lodash';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from 'app/utils/KocoUtils';
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import clsx from 'clsx';

function TourInfoWidget(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    const {liveData,total,transport,collection,waiting,sweep } = useSelector(({ main }) =>  main.fleetTracking.fleetLiveData);
    const {load,signals,position,unloading} = useSelector(({ main }) =>  main.fleetTracking.fleetSignals);
    const { t } = useTranslation('common');
    let isSet = false;
    console.log("TOTAL IS HERE", total);
    
    useEffect(() => {
        if (liveData) {
                console.log("TOTAL IS Inside UseEffect", total);

            let transportCalculation = transport && transport.value ? parseInt(transport.value) : 0;
            let collectionCalcuation = collection && collection.value ? parseInt(collection.value) : 0;
            let waitingCalcuation = waiting && waiting.value ? parseInt(waiting.value) : 0;
            let sweepCalculation = sweep && sweep.value ? parseInt(sweep.value)*1000 : 0
            let mileageSum = floor(sweepCalculation/1000) + floor(transportCalculation/1000)+ floor(collectionCalcuation/1000)+ floor(waitingCalcuation/1000);

            //
            // setSerie({
            //     series: [
            //         (floor(sweepCalculation/mileageSum)/10),
            //         (floor(transportCalculation/mileageSum)/10),
            //         (floor(collectionCalcuation/mileageSum)/10),
            //         (floor(waitingCalcuation/mileageSum)/10),
            //     ],
            //     options: {
            //         colors: ['#A5978B','#5C4742', '#A5978B', '#8D5B4C'],
            //         labels: [t('sweep'),t('fleet_info_km_transport'), t('fleet_info_km_collection'), t('fleet_info_km_waiting')],
            //         chart: {
            //             type: 'donut',
            //         },
            //         legend: {
            //             position: 'bottom',
            //             horizontalAlign: 'left',
            //             show: false,
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         responsive: [{
            //             breakpoint: 480,
            //             options: {
            //                 chart: {
            //                     height: 300,
            //                     width:500
            //                 },
            //                 legend: {
            //                     position: 'bottom',
            //                     show: false,
            //                 }
            //             }
            //         }]
            //     }
            // });
            isSet = true;
        }
    }, [liveData]);
    if (load) return <FuseLoading />;
    return (
        <Paper className="w-full rounded-20 shadow flex flex-col justify-between">
            <div className="flex items-center justify-between px-4 pt-8">
                {total && <Typography className="text-16 px-16 font-medium" color="textSecondary">
                    {t('overview_menu_km_total')}
                </Typography> }
            </div>

            <div className="text-center py-12">
                {total && <Typography
                    className={
                        clsx(
                            'text-40 font-semibold leading-none tracking-tighter',
                        )} padding={1.8}>
                    {KocoUtils.convertToKm(total.value)} km
                </Typography> }
                {total &&<Typography className={
                    clsx(
                        'text-18 font-normal',
                    )}>
                    {position && position.firstSignal && <Moment unix local={"de"} format="DD.MM.YYYY">
                        {position.firstSignal.sec}
                    </Moment>
                    }
                </Typography>}
                {total && <Typography className="p-20 pt-0 h-56 flex justify-center items-end text-13 font-medium"
                    color="textSecondary">
                    <span className="truncate">
                         {/*1 minute(s) ago*/}
                        {KocoUtils.formatDate(total.updatedDate, 'HH:mm')}
                    </span>
                </Typography> }
            </div>

            {/*<div className="h-256 relative">*/}
            {/*    {serie && serie.options && <ReactApexChart height={270} options={serie.options} series={serie.series} type="donut" />}*/}

            {/*</div>*/}

            {/*<div className="mb-24 flex flex-row items-center justify-center">*/}
            {/*    {transport && transport.value && transport.date &&<div className="px-12 flex flex-col items-center">*/}
            {/*        <Typography className="h4 font-semibold" color="#5C4742">*/}
            {/*            {t('fleet_info_km_transport')}*/}
            {/*        </Typography>*/}
            {/*        <Typography className="text-11 font-semibold py-8" color="#5C4742">*/}
            {/*            {KocoUtils.convertToKm(transport.value)} km*/}
            {/*        </Typography>*/}

            {/*        <div className="flex flex-row items-start justify-center">*/}
            {/*            <Typography className="h5 px-4 font-semibold" color="#5C4742">*/}
            {/*                <Moment  local={"de"} format="DD.MM.YYYY">*/}
            {/*                    {transport.date}*/}
            {/*                </Moment>*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    }*/}
            {/*    {collection&& collection.value && collection.date && <div  className="px-12 flex flex-col items-center">*/}
            {/*        <Typography className="h4 font-semibold" color="#A5978B">*/}
            {/*            {t('fleet_info_km_collection')}*/}
            {/*        </Typography>*/}
            {/*        <Typography className="text-11 font-semibold py-8" color="#A5978B">*/}
            {/*            {KocoUtils.convertToKm(collection.value)} km*/}
            {/*        </Typography>*/}

            {/*        <div className="flex flex-row items-start justify-center" >*/}
            {/*            <Typography className="h5 px-4 font-semibold" color="#A5978B">*/}
            {/*                <Moment  local={"de"} format="DD.MM.YYYY">*/}
            {/*                    {collection.date}*/}
            {/*                </Moment>*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    }*/}
            {/*    {waiting && waiting.value && waiting.date && <div className="px-12 flex flex-col items-center">*/}
            {/*        <Typography className="h4 font-semibold" color="#8D5B4C">*/}
            {/*            {t('fleet_info_km_waiting')}*/}
            {/*        </Typography>*/}
            {/*        <Typography className="text-11 font-semibold py-8" color="#8D5B4C">*/}
            {/*            {KocoUtils.convertToKm(waiting.value)} km*/}
            {/*        </Typography>*/}

            {/*        <div className="flex flex-row items-start justify-center">*/}
            {/*            <Typography className="h5 px-4 font-semibold" color="#8D5B4C">*/}
            {/*                <Moment local={"de"} format="DD.MM.YYYY">*/}
            {/*                    {waiting.date}*/}
            {/*                </Moment>*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    }*/}
            {/*    {sweep && sweep.value && sweep.date && <div className="px-12 flex flex-col items-center">*/}
            {/*        <Typography className="h4 font-semibold" color="#A5978B">*/}
            {/*            {t('sweep')}*/}
            {/*        </Typography>*/}
            {/*        <Typography className="text-11 font-semibold py-8" color="#A5978B">*/}
            {/*            {sweep.value} km*/}
            {/*        </Typography>*/}

            {/*        <div className="flex flex-row items-start justify-center">*/}
            {/*            <Typography className="h5 px-4 font-semibold" color="#A5978B">*/}
            {/*                <Moment local={"de"} format="DD.MM.YYYY">*/}
            {/*                    {sweep.date}*/}
            {/*                </Moment>*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    }*/}

            {/*</div>*/}
        </Paper>
    );
}

export default TourInfoWidget;