import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourPlans = createAsyncThunk('main/settingsStTourPlan/stTourPlans/getStTourPlans', async (searchParams) => {
  const response = await axios.get(`api/v1/sttourplans`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  let data = await response.data;
  if (data?.sttourplans) {
    data.sttourplans = data.sttourplans.map(stp => ({
      ...stp,
      isLifterStopActive: !!stp.isLifterStopActive,
      tourPlanDeviceType: (typeof stp.tourPlanDeviceType !== 'undefined' ? { id: stp.tourPlanDeviceType } : null) 
    }));
  }

  return { sttourplans: data.sttourplans, totalcount: data.totalcount };
});

export const getStTourPlanHasOverlap = createAsyncThunk('main/settingsStTourPlan/stTourPlans/getStTourPlanHasOverlap', async (searchParams) => {
  const response = await axios.get(`api/v1/sttourplanhasoverap`, {
    params:
    {
      id: searchParams.id,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      tour: searchParams.tour,
    }
  });
  const data = await response.data;

  return { sttourplans: data.sttourplans, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsStTourPlan/stTourPlans/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const patchStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourPlans/patchStTourPlan', async (stTourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlan);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'sttourplan',clone);
  console.log('stTourPlan to patch', payload);

  try {
    const response = await axios.patch(`api/v1/batchsttourplans/${stTourPlan.id}`, payload);

    const data = await response.data;
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

});

export const patchBatchStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourPlans/patchBatchStTourPlan', async (stTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlans.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'sttourplan', row)); });

  try {
    const response = await axios.patch(`api/v1/batchsttourplan`, { st_tour_plans: payload });
    const data = await response.data;
    if (data && data.length > 0) {

      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }
    return data;
  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const deleteStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourPlans/deleteStTourPlan', async (stTourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/sttourplans/${stTourPlan.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsStTourPlan/stTourPlans/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.st_tours;
});

export const getStClassifiers = createAsyncThunk('main/settingsStTourPlan/stTourPlans/getStClassifiers', async () => {
  const response = await axios.get(`api/v1/stclassifiers`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.stclassifiers;
});


const stTourPlansAdapter = createEntityAdapter({});

export const { selectAll: selectStTourPlans, selectById: selectStTourPlanById } =
  stTourPlansAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlans.stTourPlans);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlans.stTours);

const stClassifiersAdapter = createEntityAdapter({});

export const { selectAll: selectStClassifiers, selectById: selectStClassifierById } =
  stClassifiersAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlans.stClassifiers);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlans.units);

const stTourPlansSlice = createSlice({
  name: 'main/settingsStTourPlan/stTourPlans',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: 10,
    sortModel: [],
    rangeDate: [null, null],
    selectedUnit: null,
    selectedClassifier: [],
    selectedTour: null,
    loadingStTourPlans: false,
    loadingUnits: false,
    savingStTourPlan: false,
    deletingStTourPlan: false,
    loadingStTours: false,
    loadingStClassifiers: false,
    stTourPlans: stTourPlansAdapter.getInitialState({ }),
    dataStTourPlans: [],
    stTourPlansCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    stClassifiers: stClassifiersAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    isDataDirty: false,
    errorOnSave: null,
  },
  reducers: {
    setStTourPlansSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setDataStTourPlans: {
      reducer: (state, action) => {
        state.dataStTourPlans = action.payload;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedClassifier: {
      reducer: (state, action) => {
        state.selectedClassifier = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    resetStTourPlans: {
      reducer: (state, action) => {
        state.errorOnSave = null;
        state.isDataDirty = false;
        stTourPlansAdapter.removeAll(state.stTourPlans);
        state.loadingStTourPlans = false;
        state.savingStTourPlan = false;
      },
    },
  },
  extraReducers: {
    [getStTourPlans.fulfilled](state, { payload }) {
      stTourPlansAdapter.setAll(state.stTourPlans, payload.sttourplans);
      state.stTourPlansCount = payload.totalcount;
      state.loadingStTourPlans = false;
    },
    [getStTourPlans.pending]: (state) => {
      state.errorOnSave = null;
      state.loadingStTourPlans = true;
      stTourPlansAdapter.removeAll(state.stTourPlans);
    },
    [getStTourPlans.rejected]: (state) => {
      state.loadingStTourPlans = false;
    },

    [patchStTourPlan.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
    },
    [patchStTourPlan.pending](state, { payload }) {
      state.savingStTourPlan = true;
    },
    [patchStTourPlan.rejected](state, { payload }) {
      state.savingStTourPlan = false;
    },

    [patchBatchStTourPlan.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
    },
    [patchBatchStTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingStTourPlan = true;
    },
    [patchBatchStTourPlan.rejected](state, { payload }) {
      state.errorOnSave = payload?.error;
      // if(payload?.error?.data){
      //   console.log('payload to update');
      //   stTourPlansAdapter.updateMany(state.stTourPlans, payload.error.data);
      // }
      state.savingStTourPlan = false;
    },

    [deleteStTourPlan.fulfilled](state, { payload }) {
      state.deletingStTourPlan = false;
    },
    [deleteStTourPlan.pending](state, { payload }) {
      state.deletingStTourPlan = true;
    },
    [deleteStTourPlan.rejected](state, { payload }) {
      state.deletingStTourPlan = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getStClassifiers.fulfilled](state, { payload }) {
      stClassifiersAdapter.setAll(state.stClassifiers, payload);
      state.loadingStClassifiers = false;
    },
    [getStClassifiers.pending]: (state) => {
      state.loadingStClassifiers = true;
      stClassifiersAdapter.removeAll(state.stClassifiers);
    },
    [getStClassifiers.rejected]: (state) => {
      state.loadingStClassifiers = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const {
  setStTourPlansSearchText,
  setIsDataDirty,
  setDataStTourPlans,
  setPage,
  setPageSize,
  setRowsPerPage,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifier,
  setSelectedTour,
  resetStTourPlans,
} = stTourPlansSlice.actions;

export default stTourPlansSlice.reducer;
