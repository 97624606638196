import { combineReducers } from '@reduxjs/toolkit';
import passiveVehicleGroup from './PassiveVehicleGroupSlice';
import passiveVehicleGroups from './PassiveVehicleGroupsSlice';

const reducer = combineReducers({
  passiveVehicleGroups,
  passiveVehicleGroup,
});

export default reducer;
