import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TourAnalyseMap = lazy(() => import('./TourAnalyseMap'));

const TourAnalyseMapConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'touranalysemap',
      element: <TourAnalyseMap />,
      auth: [authRoles.ROLE_TA_MAP_READ], 
    },
  ],
};

export default TourAnalyseMapConfig;
