

export const EVENT_INFO_TYPE = {
    WARNING: 1,
    ERROR: 2,
    REPORT: 3,
};

class EventInfoType {
    static getLabel = (type) => {

        switch (type) {
            case 1:
                return 'Warning';
            case 2:
                return 'Error';
            case 3:
                return 'Report';
            default:
                return '-';
        }
    };

    static getTranslation = (type) => {

        switch (type) {
            case 1:
                return 'event_info_warning';
            case 2:
                return 'event_info_error';
            case 3:
                return 'event_info_report';
            default:
                return '-';
        }

    };
}

export default EventInfoType;

