import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import CookieService from './../cookieService';
/* eslint-disable camelcase */


const expiresAt = 60 * 24;

class FosService extends FuseUtils.EventEmitter {

  constructor() {
    super();
    this.isLoggedIn = false; 
  }

  setLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
  }

  getLoggedIn() {
    return this.isLoggedIn;
  }

  init() {
    // this.setInterceptors();
    this.handleAuthentication();
  }

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password, remember) => {
    return new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true;
      axios
        .post('/api/v1/users/login_check', { _username: email, _password: password })
        .then((response) => {

          console.log('signInWithEmailAndPassword response', response);

          if (response.data && response.data.success == true) {

            if (!remember) {
              const options = { path: "/" };
              CookieService.set("access_token", 'isAuthenticated', options);
            }
            else {
              let date = new Date();
              date.setTime(date.getTime() + expiresAt * 60 * 1000);
              const options = { path: "/", expires: date };
              CookieService.set("access_token", 'isAuthenticated', options);
            }

            this.getCurrentUser(remember).then(user => {
              this.setLoggedIn(true);
              resolve(user);
            });
          } else {
            console.log('in login rejet', response);
            reject(response.data.error);
          }
        }).catch( error => { 

          console.log('in catch', error);
          reject([{ type: 'email' , message: 'invalid User Name or Password' }]); });
    });
  };

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true;
      axios
        .get('/api/v1/users/current')
        .then((resCurrentUser) => {

          return resolve(resCurrentUser.data);

        }).catch((error) => {
          reject(error);
        });
    });
  };

  handleAuthentication = () => {

    // this.emit('onAutoLogin', true);

    const access_token = CookieService.get('access_token');
    console.log('handle fos authentication', access_token);

    if (!access_token || access_token === 'undefined') {
     console.log('on no access token');
      this.isLoggedIn = false;
      this.emit('onNoAccessToken');
    } else {
      this.isLoggedIn = true;
      this.emit('onAutoLogin', true);
    }
  };
 
  updateUserData = (user) => {
    return axios.post('/api/auth/user/update', {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    // this.setSession(null);
    // console.log('on fos logout');
    CookieService.remove("access_token");
    return new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true;
      axios
        .get('/api/v1/users/logout')
        .then(() => {
          caches.keys().then(cacheNames => {
            cacheNames.forEach(value => {
              caches.delete(value);
            });
          })
          localStorage.clear();
         // HttpContext.Session.Remove("sessionName"); //this will clear a specific session
          return resolve();

        }).catch((error) => {
          reject(error);
        });
    });
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new FosService();

export default instance;
