import { combineReducers } from "@reduxjs/toolkit";
import stClassifiers from "./StClassifiersSlice";
import stClassifier from "./StClassifierSlice";

const reducer = combineReducers({
  stClassifiers,
  stClassifier,
});

export default reducer;
