import _ from '@lodash';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {default as React, memo, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
function SkeltonBigWidget(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let [serie, setSerie] = useState({});

  const [tabValue, setTabValue] = useState(1);
  var options = {};
  console.log('i am in consumption widget');
    const { t } = useTranslation('common');
    var filter = [{"id" : 1, "label" :t('last_week')},
        {"id" : 2, "label" :t('last_2_week')},
    ];

  return (
    <Card className="w-full rounded-20 shadow">
        <div className="flex items-center justify-between px-4 pt-8">
            <Typography className="text-16 px-16 font-medium" color="textSecondary">
                Trend
            </Typography>
        </div>

        <div className="flex flex-row items-center">
            <div>
                <FormControl className="" variant="filled">
                    <Select
                        classes={{ select: 'py-8' }}
                    >
                        {filter.map((key) => (
                            <MenuItem key={key.id} value={key.id}>
                                {key.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>

      <div className="relative h-300 sm:h-320 sm:pb-16">
          <Stack spacing={1}>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
          </Stack>
      </div>
    </Card>
  );
}

export default SkeltonBigWidget;
