import _ from '@lodash';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {default as React, memo, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import {floor} from "lodash";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {setFilterDate} from "../store/FleetTrackingConsumptionData";
import {useTranslation} from "react-i18next";
function ConsumptionWidget(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let [serie, setSerie] = useState({});

  const [tabValue, setTabValue] = useState(1);
  const data = _.merge({}, props.data);
  const {load,consumption,allConsumption} = useSelector(({ main }) =>  main.fleetTracking.fleetConsumption);
  var options = {};
  console.log('i am in consumption widget');
    const { t } = useTranslation('common');
    var filter = [{"id" : 1, "label" :t('last_week')},
        {"id" : 2, "label" :t('last_2_week')},
        {"id" : 3, "label" :t('last_3_week')},
        {"id" : 4, "label" :t('last_4_week')}
    ];
  useEffect(()=> {
        if (consumption) {
            console.log('inside consumption');
            if (props.unitSelected.chassisTypeKoco == 9|| props.unitSelected.id == "663a6aa512dff792060002e4") {
                setSerie({
                    series: [
                        {
                            name: t('overview_menu_km_total') + ' (km)',
                            data: generateDayWiseTimeSeries(consumption, 1, props.unitSelected)
                        }
                    ],
                    options: {
                        chart: {
                            type: 'bar',
                            height: 350,
                            stacked: false,
                            events: {
                                selection: function (chart, e) {
                                    console.log(new Date(e.xaxis.min))
                                }
                            },
                        },
                        colors: ['#666d78', '#25d4ec'],
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                opacityFrom: 0.6,
                                opacityTo: 0.8,
                            }
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'left'
                        },
                        xaxis: {
                            type: 'datetime'
                        },
                    }
                });
            } else {
                setSerie({
                    series: [
                        {
                            name: t('overview_menu_km_total') + ' (km)',
                            data: generateDayWiseTimeSeries(consumption, 1, props.unitSelected)
                        },
                        {
                            name: t('fleet_tracking_diesel') + ' (l)',
                            data: generateDayWiseTimeSeries(consumption, 2, props.unitSelected)
                        }
                    ],
                    options: {
                        chart: {
                            type: 'bar',
                            height: 350,
                            stacked: false,
                            events: {
                                selection: function (chart, e) {
                                    console.log(new Date(e.xaxis.min))
                                }
                            },
                        },
                        colors: ['#666d78', '#25d4ec'],
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                opacityFrom: 0.6,
                                opacityTo: 0.8,
                            }
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'left'
                        },
                        xaxis: {
                            type: 'datetime'
                        },
                    }
                });
            }
        }
        console.log('inside hook');
  },[consumption]);
  function setFilter(ev) {
      setTabValue(ev.target.value);
      dispatch(setFilterDate(ev.target.value));
  }
  _.setWith(data, 'options.colors', [theme.palette.secondary.main, theme.palette.primary.main]);
  if (load) return <FuseLoading />;
  return (
    <Card className="w-full rounded-20 shadow">
      <div className="relative p-20 flex flex-row items-center justify-between">
        <div className="flex flex-col">
            {(props.unitSelected.chassisTypeKoco != 9 && props.unitSelected.id != '663a6aa512dff792060002e4') && <Typography className="h3 sm:h2 font-medium">{t('fleet_tracking_diesel')} & {t('overview_menu_km_total')}</Typography>}
            {(props.unitSelected.chassisTypeKoco == 9 || props.unitSelected.id == "663a6aa512dff792060002e4") && <Typography className="h3 sm:h2 font-medium"> {t('mileage_over_time')}</Typography>}

        </div>

        <div className="flex flex-row items-center">
            <div>
                <FormControl className="" variant="filled">
                    <Select
                        classes={{ select: 'py-8' }}
                        value={tabValue}
                        onChange={(ev) => setFilter(ev)}
                    >
                        {filter.map((key) => (
                            <MenuItem key={key.id} value={key.id}>
                                {key.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
      </div>

      <div className="relative h-300 sm:h-320 sm:pb-16">
          {serie && serie.options && <ReactApexChart
              options={serie.options}
              series={serie.series}
              type="bar"
              height={300}
          />
          }
      </div>
    </Card>
  );
}

export default ConsumptionWidget;
function generateDayWiseTimeSeries(data, type,unitSelected) {
    var i = 0;
    var series = [];
    let y =0;
    let x = 0;
    console.log(data);

    _.each(data, function (e) {
        if (type == 1) {
            y = unitSelected.isRcv ? floor(parseInt(e.mileageConsumption)/1000) : floor(parseInt(e.mileageConsumption));
            if (y>1000) {
                y = 150;
            }
            x = e.timestamp * 1000;
        } else if (type == 3) {
            y = floor(parseInt(e.hydrogenConsumption));
            x = e.timestamp * 1000;
        }else {
            y = floor(parseInt(e.dieselConsumption));
            x = e.timestamp * 1000;
            if (y>1000) {
                y = 150;
            }
        }
        series.push([x, y]);
    });
    return series;
}
