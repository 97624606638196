import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    FeatureGroup,
    GeoJSON,
    LayersControl,
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    useMap,
    useMapEvent
} from "react-leaflet";
import Card from "@mui/material/Card";
import React, {memo, useEffect, useRef, useState} from "react";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import Fullscreen from "react-leaflet-fullscreen-plugin";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Legend from "../widgets/Legend";
import Moment from "react-moment";
import Markers from "./Markers";
import "../css/graphs.css";
import HeatmapLayer from "react-leaflet-heatmap-layer-v3/lib/HeatmapLayer";
import {setIds} from "../../fleetTracking/store/FleetTrackingSlice";
import {setList} from "../store/positionScantec";
import Control from "react-leaflet-custom-control";
import {SyncAlt} from "@mui/icons-material";
import Button from "@mui/material/Button";

function ScantecMap(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const firstOverlayRef = useRef();

    const groupRef = useRef();
    const isUpdating = useRef();
    let {load, isFilterSet, filter, redPosition, greenPosition, yellowPosition,markerSet} = useSelector(({main}) => main.scantec.positionScantec);
    const [map, setMap] = useState();
    let arrayIds = [];
    isUpdating.current  = 0;
    const [region, setRegion] = React.useState(props.region);
    const [move, setMove] = React.useState(false);
    useEffect(()=>{
         if (map && markerSet && markerSet._id) {
                console.log(markerSet);
               // firstOverlayRef.current.checked= false;
             isUpdating.current = 1;
            // map.removeLayer(firstLayer);
            // setMove(true);
                map.flyTo([markerSet.location.coordinates[1],markerSet.location.coordinates[0]],18);
            }
            isUpdating.current = 0;

            //
        },
        [markerSet]);
    if (load) {
        return <FuseLoading/>;
    }
    return (
        <Card className="w-full h-512 rounded-20 shadow scantec">
            <MapContainer style={{height: "51.2rem", width: "100%"}} center={[53.49573, 8.62839]}
                          zoom={10}
                          whenCreated={map => {
                              setMap(map);
                              props.mapEl(map);
                          }}
                          >

                <LayersControl position="topright">
                    {move == false && <LayersControl.Overlay ref={firstOverlayRef} checked name="Heatmap">
                        <FeatureGroup>
                            <HeatmapLayer
                                fitBoundsOnLoad
                                fitBoundsOnUpdate
                                points={redPosition}
                                longitudeExtractor={m => m.location.coordinates[0]}
                                latitudeExtractor={m => m.location.coordinates[1]}
                                gradient={{0: 'red', 1: 'red'}}
                                blur={5}
                                radius={10}
                                intensityExtractor={m => parseFloat(m.value ? m.value : 0)}/>
                            <HeatmapLayer
                                fitBoundsOnLoad
                                fitBoundsOnUpdate
                                points={yellowPosition}
                                longitudeExtractor={m => m.location.coordinates[0]}
                                latitudeExtractor={m => m.location.coordinates[1]}
                                gradient={{0: 'yellow', 1: 'yellow'}}
                                blur={5}
                                radius={10}
                                intensityExtractor={m => parseFloat(m.value ? m.value : 0)}/>
                            <HeatmapLayer
                                fitBoundsOnLoad
                                fitBoundsOnUpdate
                                points={greenPosition}
                                longitudeExtractor={m => m.location.coordinates[0]}
                                latitudeExtractor={m => m.location.coordinates[1]}
                                gradient={{0: 'green', 1: 'green'}}
                                blur={5}
                                radius={10}
                                intensityExtractor={m => parseFloat(m.value ? m.value : 0)}/>
                        </FeatureGroup>
                    </LayersControl.Overlay>
                    }
                    <LayersControl.Overlay checked name="Open streetMap">

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.Overlay>
                </LayersControl>
                <Fullscreen/>
                <Legend map={map}/>
                <MyComponent/>
                <Markers setDetails={props.setDetails} date={props.date}/>
                <MyData geo={props.region} isFilterSet={isFilterSet} setDetails={props.setDetails} marker={markerSet}/>
            </MapContainer>
        </Card>
    );
    function MyComponent() {
        const map = useMapEvent({
            dragend: (e) => {
                if (isUpdating.current == 0 && (groupRef.current == null || groupRef.current < 3)) {
                    //dispatch(setList(map.getBounds()));
                } else {
                    groupRef.current++;
                }
            },
            zoomend: (e) => {
                if (isUpdating.current == 0 && (groupRef.current == null || groupRef.current < 3)) {
                   // dispatch(setList(map.getBounds()));
                } else {
                    groupRef.current++;
                }
            },
            click: (e) => {
                //L.DomEvent.stopPropagation(e);
                console.log('marker click!');
                //isSet = false;
            }
        });
        return null;
    }
}

const MyData = (props) => {
    const [data, setData] = React.useState();
    const map = useMap();
    let update = false;
    if (props.isFilterSet || props.setDetails.id) {
        update = true;
    }
    useEffect(() => {
        setData(props.geo);
    }, []);

    if (data) {
        const geojsonObject = L.geoJSON(data);
        if (update == false) {
            map.fitBounds(geojsonObject.getBounds());
        } else if (props.marker && props.marker._id) {
            console.log(props.marker.location);
        }
        return <GeoJSON data={data} />;
    } else {
        return null;
    }
};

function getIcon(el) {
    let iconUrl = "/assets/images/gray-circle.png";
    if (el.result == 0) {
        iconUrl = "/assets/images/green-circle.png";
    } else if (el.result == 1) {
        iconUrl = "/assets/images/yellow-circle.png";
    } else if (el.result == 2) {
        iconUrl = "/assets/images/red-circle.png";
    }
    return new L.icon({
        iconUrl: iconUrl,
        iconSize: [20, 20],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40]
    });
}
export default ScantecMap;
