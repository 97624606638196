import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import axios from "axios";

export const getStClassifiers = createAsyncThunk(
  "main/settingsStClassifier/stClassifiers/getStClassifiers",
  async (searchParams) => {
    const response = await axios.get("/api/v1/stclassifiers", {
      params: {
        offset: searchParams.offset,
        limit: searchParams.limit,
        name: searchParams.name,
        sort: searchParams.sort,
        order: searchParams.order,
      },
      headers: { "X-UI-State": "stClassifier.settings.stClassifiers" },
    });
    const data = response.data;

    return data;
  }
);

const stClassifiersAdapter = createEntityAdapter({
  selectId: (model) => model.id,
});

export const {
  selectAll: selectStClassifiers,
  selectById: selectStClassifierById,
} = stClassifiersAdapter.getSelectors((state) => {
  return state.main.settingsStClassifier.stClassifiers.stClassifiers;
});

const stClassifiersSlice = createSlice({
  name: "main/settingsStClassifier/stClassifiers",
  initialState: {
    searchText: "",
    page: 0,
    pageSize: 10,
    sortModel: [{ field: "name", sort: "desc" }],
    rowsPerPage: 10,
    rangeDate: [null, null],
    selectedClassifiers: null,
    name: null,
    loadingClassifiers: false,
    stClassifiers: stClassifiersAdapter.getInitialState([]),
    stClassifiersCount: 0,
  },
  reducers: {
    setSettingsStClassifiersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },

    resetStClassifiers: {
      reducer: (state, action) => {
        stClassifiersAdapter.setAll(state.stClassifiers, "");
        state.loadingClassifiers = false;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },

    setSelectedClassifiers: {
      reducer: (state, action) => {
        state.selectedClassifiers = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setName: {
      reducer: (state, action) => {
        state.name = action.payload;
      },
    },
  },

  extraReducers: {
    [getStClassifiers.fulfilled](state, { payload }) {
      if (payload) {
        stClassifiersAdapter.setAll(state.stClassifiers, payload);
        state.stClassifiersCount = payload.totalcount;
        state.loadingClassifiers = false;
      }
    },
    [getStClassifiers.pending]: (state) => {
      state.loadingClassifiers = true;
      state.stClassifiersCount = 0;
      stClassifiersAdapter.removeAll(state.stClassifiers);
    },
    [getStClassifiers.rejected]: (state) => {
      state.loadingClassifiers = false;
    },
  },
});

export const {
  setsettingsStClassifiersSearchText,
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifiers,
  setRowsPerPage,
  resetStClassifiers,
  setName,
} = stClassifiersSlice.actions;

export default stClassifiersSlice.reducer;
