import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "../../fleetTracking/store/FleetTrackingSlice";

const initialState = {
  signals: [],
  loadingSignals: false,
  all:[],
}

export const getSignals = createAsyncThunk('reports/signals', async ({ type, value }) => {
  console.log("checking", value);
  const response = await axios.get(apiUrlConfig.GET_PARSE_DATA, { params: { limit: 0,type:type,default:1, truckId: value.id}, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.parsedatavalues;
});


export const signalsReportsSlice = createSlice({
  name: 'scantec/signals',
  initialState,
  reducers: {
    setSearchParse: {
      reducer: (state, action) => {
        state.loading = true;
        console.log(action.payload);
        console.log(state.all);
        state.signals = FuseUtils.filterArrayByString(state.all, action.payload)
      }
    }
  },
  extraReducers: {
    [getSignals.pending]: (state) => {
      state.loadingSignals = true
    },
    [getSignals.fulfilled]: (state, { payload }) => {
      state.loadingSignals = false
      state.signals = payload
      state.all = payload
    },
    [getSignals.rejected]: (state) => {
      state.loadingSignals = false
    },
  },
});

export const postReducer = signalsReportsSlice.reducer;
export const {setSearchParse} = signalsReportsSlice.actions;

export default signalsReportsSlice.reducer;