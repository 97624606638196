import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import MACHINE_TYPE from 'app/types/MachineType';
import REQUEST_PARAMETER_TYPE from 'app/types/RequestParameterType';
import _ from 'lodash';
import ParameterCategoriesSP from 'app/types/ParameterCategoriesSP';
import ParameterCategoriesSW from 'app/types/ParameterCategoriesSW';
import ParameterCategoriesSidepress from 'app/types/parameterCategoriesSidepress';
import ParameterCategoriesMediumx from 'app/types/parameterCategoriesMediumx';
import ParameterCategoriesSweeperViajetBody from 'app/types/ParameterCategoriesSweeperViajetBody';

export const getBodyReadParameterRequests = createAsyncThunk('main/cockpit/bodyParameterRead/getBodyReadParameterRequests', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/types/${REQUEST_PARAMETER_TYPE.READ_BODY_PARAMETERS}/parameterrequests`, { params: { inprogress: false } });
  const data = await response.data;
  if (!data)
    return;

  return data.parameterrequests;
});

export const getBodyReadParameterHistories = createAsyncThunk('main/cockpit/bodyParameterRead/getBodyReadParameterHistories', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.BODY}/parameterhistory`);
  const data = await response.data;
  if (!data)
    return;

  return data.dates;
});

export const sendBodyReadParameterRequest = createAsyncThunk('main/cockpit/bodyParameterRead/sendBodyReadParameterRequest', async (unit, { getState }) => {
  const response = await axios.post(`api/v1/parameterrequests`,
    {
      parameter_request: {
        vehicle: unit.id,
        type: REQUEST_PARAMETER_TYPE.READ_BODY_PARAMETERS,
        machineType: MACHINE_TYPE.BODY,
        parameterRequestDetails: []
      }
    });
  const data = await response.data;

  return data;
});

export const getBodyHistoryChanges = createAsyncThunk('main/cockpit/bodyParameterRead/getBodyHistoryChanges', async (bodyHistory, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const responseParamVehicle = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.BODY}/parameterfilechange`, { params: { 'date': bodyHistory.date.date } });
  const responseParam = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.BODY}/parameterfilechangeinfo`, { params: { 'date': bodyHistory.date.date } });

  let data = await responseParamVehicle.data;
  let dataParam = await responseParam.data;
  if (!data)
    return;

  let parameterVehicleValues = data.parametervehiclevalues;
  let parameters = dataParam.parameters;

  if (parameterVehicleValues && parameterVehicleValues.length > 0 && parameters && parameters.length > 0) {

    parameterVehicleValues = parameterVehicleValues.map(paramVehicle => {
      let parameter = parameters.find(param => param.parameterNumber == paramVehicle.number);
      paramVehicle.parameter = parameter;
      return paramVehicle;
    });
  }

  return {
    id: bodyHistory.id,
    changes: {
      loadingChanges: false,
      parameterVehicleValues: parameterVehicleValues,
    }
  }
});

export const getInUseBodyParameterCategories = createAsyncThunk('main/cockpit/bodyParameterRead/getInUseBodyParameterCategories', async (unit, { getState }) => {

  const response = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.BODY}/parametercategory`);

  let data = await response.data;
  if (!data)
    return;

  // const { main } = getState();
  // const bodyReadParameterCategories = main.cockpit.bodyParameterRead.bodyReadParameterCategories;
  // let copyBodyParameterCategories = _.cloneDeep(bodyReadParameterCategories);

  // let bodyInUseParameterCategories = data.categories;

  // for (var parameterCategory of copyBodyParameterCategories) {
  //   for (var inUseParameterCategory of bodyInUseParameterCategories) {
  //     if (parameterCategory.value == inUseParameterCategory.category) {
  //       parameterCategory.count = inUseParameterCategory.count;
  //       break;
  //     }
  //   }
  // }

  return data.categories.map(category => {
    return {
      id: category.category, 
      changes: {
        loading: false,
        count: category.count,
      }
    }
  });
});

export const getBodyParameterVehicles = createAsyncThunk('main/cockpit/bodyParameterRead/getBodyParameterVehicles', async (paramCategory, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const responseParamVehicle = await axios.get(`api/v1/units/${unit.id}/machinetypes/${MACHINE_TYPE.BODY}/parametervehicles`, { params: { 'category': paramCategory.value } });
  const responseParam = await axios.get(`api/v1/parameters`,
    {
      params: {
        bodytype: KocoUtils.getParameterBodyType(unit),
        vehicletype: KocoUtils.getPassiveVehicleType(unit),
        category: paramCategory.value,
        limit: 1000,
        machinetype: MACHINE_TYPE.BODY
      },
      headers: {
        'X-UI-State': 'unit.parameters.overview'
      }
    });

  let data = await responseParamVehicle.data;
  let dataParam = await responseParam.data;
  if (!data)
    return;

  let parametervehicles = data.parametervehicles;
  let parameters = dataParam.parameters;

  if (parametervehicles && parametervehicles.length > 0 && parameters && parameters.length > 0) {

    parametervehicles = parametervehicles.map(paramVehicle => {
      let parameter = parameters.find(param => param.parameterNumber == paramVehicle.number);
      paramVehicle.parameter = parameter;
      return paramVehicle;
    });
  }

  return {
    id: paramCategory.value,
    changes: {
      loading: false,
      parameterVehicles: parametervehicles,
    }
  }
});

const bodyReadParameterRequestsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectBodyReadParameterRequests, selectById: selectBodyReadParameterRequestById, selectIds: selectBodyReadParameterRequestIds } =
  bodyReadParameterRequestsAdapter.getSelectors((state) => { return state.main.cockpit.bodyParameterRead.bodyReadParameterRequests; });

const bodyReadParameterHistoriesAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectBodyReadParameterHistories, selectById: selectBodyReadParameterHistoryById, selectIds: selectBodyReadParameterHistoryIds } =
  bodyReadParameterHistoriesAdapter.getSelectors((state) => { return state.main.cockpit.bodyParameterRead.bodyReadParameterHistories; });

const bodyReadParameterCategoriesAdapter = createEntityAdapter({ selectId: (model) => model.value });
export const { selectAll: selectBodyReadParameterCategories, selectById: selectBodyReadParameterCategoryById, selectIds: selectBodyReadParameterCategoryIds } =
  bodyReadParameterCategoriesAdapter.getSelectors((state) => { return state.main.cockpit.bodyParameterRead.bodyReadParameterCategories; });

const initializeParameterCategoriesBody = (unit) => {

  const bodytype = KocoUtils.getParameterBodyType(unit);

  switch (bodytype) {
    case 1: {
      return _.cloneDeep(ParameterCategoriesSP);
    }
    case 4: {
      return _.cloneDeep(ParameterCategoriesSW);
    }
    case 2: {
      return _.cloneDeep(ParameterCategoriesSP);
    }
    case 8: {
      return _.cloneDeep(ParameterCategoriesSidepress);
    }
    case 16: {
      return null;
    }
    case 32: {
      return _.cloneDeep(ParameterCategoriesMediumx);
    }
    case 64: {
      return _.cloneDeep(ParameterCategoriesMediumx);
    }
    case 0: {
      return _.cloneDeep(ParameterCategoriesSweeperViajetBody);
    }
    default: {
      return null;
    }
  }
};

const BodyParameterReadSlice = createSlice({
  name: 'main/cockpit/bodyParameterRead',
  initialState: {
    searchText: '',
    loadingBodyReadParameterRequests: false,
    loadingBodyReadParameterHistories: false,
    loadingInUseBodyParameterCategories: false,
    sendingBodyReadParameterRequest: false,
    bodyReadParameterRequests: bodyReadParameterRequestsAdapter.getInitialState({}),
    bodyReadParameterHistories: bodyReadParameterHistoriesAdapter.getInitialState({}),
    bodyReadParameterCategories: bodyReadParameterCategoriesAdapter.getInitialState({}),
  },
  reducers: {
    setReadParameterSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: {
    [getBodyReadParameterRequests.fulfilled](state, { payload }) {
      bodyReadParameterRequestsAdapter.setAll(state.bodyReadParameterRequests, payload);
      state.loadingBodyReadParameterRequests = false;
    },
    [getBodyReadParameterRequests.pending](state, { payload }) {
      bodyReadParameterRequestsAdapter.removeAll(state.bodyReadParameterRequests);
      state.loadingBodyReadParameterRequests = true;
    },
    [getBodyReadParameterRequests.rejected](state, { payload }) {
      state.loadingBodyReadParameterRequests = false;
    },

    [sendBodyReadParameterRequest.fulfilled](state, { payload }) {
      state.sendingBodyReadParameterRequest = false;
    },
    [sendBodyReadParameterRequest.pending](state, { payload }) {
      state.sendingBodyReadParameterRequest = true;
    },
    [sendBodyReadParameterRequest.rejected](state, { payload }) {
      state.sendingBodyReadParameterRequest = false;
    },

    [getBodyReadParameterHistories.fulfilled](state, { payload }) {
      bodyReadParameterRequestsAdapter.setAll(state.bodyReadParameterHistories, payload);
      state.loadingBodyReadParameterHistories = false;
    },
    [getBodyReadParameterHistories.pending](state, { payload }) {
      bodyReadParameterRequestsAdapter.removeAll(state.bodyReadParameterHistories);
      state.loadingBodyReadParameterHistories = true;
    },
    [getBodyReadParameterHistories.rejected](state, { payload }) {
      state.loadingBodyReadParameterHistories = false;
    },

    [getBodyHistoryChanges.fulfilled](state, { meta, payload }) {
      bodyReadParameterHistoriesAdapter.updateOne(state.bodyReadParameterHistories, payload);
    },
    [getBodyHistoryChanges.pending](state, { meta, payload }) {
      let bodyHistory = meta.arg;
      let changes = {
        id: bodyHistory.id,
        changes: {
          loadingChanges: true,
          parameterVehicleValues: null,
        }
      }
      bodyReadParameterHistoriesAdapter.updateOne(state.bodyReadParameterHistories, changes);
    },
    [getBodyHistoryChanges.rejected](state, { meta, payload }) {
      let bodyHistory = meta.arg;
      let changes = {
        id: bodyHistory.id,
        changes: {
          loadingChanges: false,
        }
      }
      bodyReadParameterHistoriesAdapter.updateOne(state.bodyReadParameterHistories, changes);
    },

    [getInUseBodyParameterCategories.fulfilled](state, { meta, payload }) {
      bodyReadParameterCategoriesAdapter.updateMany(state.bodyReadParameterCategories, payload);
      state.loadingInUseBodyParameterCategories = false;
    },
    [getInUseBodyParameterCategories.pending](state, { meta, payload }) {
      let unit = meta.arg;
      bodyReadParameterCategoriesAdapter.setAll(state.bodyReadParameterCategories, initializeParameterCategoriesBody(unit));
      state.loadingInUseBodyParameterCategories = true;
    },
    [getInUseBodyParameterCategories.rejected](state, { meta, payload }) {
      state.loadingInUseBodyParameterCategories = false
    },

    [getBodyParameterVehicles.fulfilled](state, { meta, payload }) {
      console.log('body paraemter vehiclessssss', payload);
      bodyReadParameterCategoriesAdapter.updateOne(state.bodyReadParameterCategories, payload);
    },
    [getBodyParameterVehicles.pending](state, { meta, payload }) {
      let category = meta.arg;
      let changes = {
        id: category.value,
        changes: {
          loading: true,
          parameterVehicles: null,
        }
      }
      bodyReadParameterCategoriesAdapter.updateOne(state.bodyReadParameterCategories, changes);
    },
    [getBodyParameterVehicles.rejected](state, { meta, payload }) {
      let category = meta.arg;
      let changes = {
        id: category.value,
        changes: {
          loading: false,
        }
      }
      bodyReadParameterCategoriesAdapter.updateOne(state.bodyReadParameterCategories, changes);
    },
  },
});

export const { setReadParameterSearchText } = BodyParameterReadSlice.actions;

export default BodyParameterReadSlice.reducer;
