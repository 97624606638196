import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useDispatch, useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import {setColorSelected} from "../store/positionScantec";
function CountBinsWidget(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    let { entities, load,greenPosition,grayPosition,redPosition,yellowPosition } = useSelector(({ main }) => main.scantec.positionScantec);
    const { t } = useTranslation('common');
    let isSet = false;
    let color ="#228B22";
    const dispatch = useDispatch();
    useEffect(() => {
        if (entities) {
            let green = parseFloat((greenPosition.length/entities.length).toFixed(2))*100 > 0 ? parseFloat((greenPosition.length/entities.length))*100 : 0;
            let yellow = parseFloat(yellowPosition.length/entities.length).toFixed(2) *100 > 0 ? parseFloat((yellowPosition.length/entities.length))*100: 0;
            let red = parseFloat(redPosition.length/entities.length).toFixed(2) *100 ? parseFloat((redPosition.length/entities.length))*100 : 0;
            let gray = parseFloat(grayPosition.length/entities.length).toFixed(2) *100 ?parseFloat((grayPosition.length/entities.length))*100 : 0;
            setSerie({
                series: [


                    (Math.round(green * 100) / 100),  (Math.round(gray * 100) / 100),
                    (Math.round(yellow * 100) / 100),  (Math.round(red * 100) / 100)
                ],
                options: {
                    colors: ['#228B22','#5C4742', '#FFD700', '#FF0000'],
                    labels: [t('scan_tec_bin_green'),t('scan_tec_bin_gray'), t('scan_tec_bin_yellow'), t('scan_tec_bin_red')],
                    chart: {
                        type: 'donut',
                        events: {
                            selection: function (chart, e) {
                                console.log(new Date(e.xaxis.min))
                            },dataPointSelection: (event, chartContext, config) => {
                                let color = 'Gray';
                                if (config.w.config.labels[config.dataPointIndex] == t('scan_tec_bin_green')) {
                                    color='Green';
                                }
                                if (config.w.config.labels[config.dataPointIndex] == t('scan_tec_bin_yellow')) {
                                    color='Yellow';
                                }
                                if (config.w.config.labels[config.dataPointIndex] == t('scan_tec_bin_red')) {
                                    color='Red';
                                }
                                dispatch(setColorSelected(color));
                            }
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                return value + ' %'
                            }
                        }
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left',
                        show: false,
                    },
                    dataLabels: {
                        enabled: false
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 300,
                                width:500
                            },
                            legend: {
                                position: 'bottom',
                                show: false,
                            }
                        }
                    }]
                }
            });
            isSet = true;
        }
    }, [entities]);
    if (load) return <FuseLoading />;
    return (
        <Card className="w-full rounded-20 shadow p-20">
            <div className="pb-24">
                {entities && <Typography className="h3 font-medium">{t('scan_tec_total')} : {entities.length}</Typography> }
            </div>

            {entities && entities.length > 0 && <div className="h-256 relative">
                {serie && serie.options && <ReactApexChart height={270} options={serie.options} series={serie.series} type="donut" />}
            </div>}
            {entities && entities.length == 0 && <div className="h-256 relative">
                <Typography className="text-16 px-16 font-medium"
                            color="textSecondary">{t('cockpit_tab_default_not_available')}</Typography>
            </div>
            }
            <div className="mb-24 flex flex-row items-center justify-center">
                {grayPosition && grayPosition.length > 0 && <div className="px-12 flex flex-col items-center">
                    <Typography className="h4 font-semibold" color="#5C4742">
                        {t('scan_tec_bin_gray')}
                    </Typography>
                    <Typography className="text-11 font-semibold py-8" color="#5C4742">
                        {grayPosition.length}
                    </Typography>
                </div>
                }
                {yellowPosition && yellowPosition.length > 0 && <div className="px-12 flex flex-col items-center">
                    <Typography className="h4 font-semibold" color="#FFD700">
                        {t('scan_tec_bin_yellow')}
                    </Typography>
                    <Typography className="text-11 font-semibold py-8" color="#FFD700">
                        {yellowPosition.length}
                    </Typography>
                </div>
                }
                {redPosition && redPosition.length > 0 && <div className="px-12 flex flex-col items-center">
                    <Typography className="h4 font-semibold" color="#FF0000">
                        {t('scan_tec_bin_red')}
                    </Typography>
                    <Typography className="text-11 font-semibold py-8" color="#FF0000">
                        {redPosition.length}
                    </Typography>
                </div>
                }
                {greenPosition && greenPosition.length > 0 && <div className="px-12 flex flex-col items-center">
                    <Typography className="h4 font-semibold" color="#228B22">
                        {t('scan_tec_bin_green')}
                    </Typography>
                    <Typography className="text-11 font-semibold py-8" color="#228B22">
                        {greenPosition.length}
                    </Typography>
                </div>
                }

            </div>
        </Card>
    );
}

export default CountBinsWidget;
