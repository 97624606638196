import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStRegions = createAsyncThunk('main/settingsStRegion/stRegions/getStRegions', async (searchParams) => {
  const response = await axios.get(`api/v1/stregions`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      sort: searchParams.sort,
      order: searchParams.order,
      type: searchParams.type,
      name: searchParams.name,

     }, headers: { 'X-UI-State': 'stRegion.settings.stRegions' } });
  const data = await response.data;

  return { sttourplans: data.stregions, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsStRegion/stRegions/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const patchStRegion = createAsyncThunk('main/settingsStRegion/stRegions/patchStRegion', async (stRegion, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stRegion);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'sttourplan',clone);
  console.log('stRegion to patch', payload);

  try {
    const response = await axios.patch(`api/v1/batchstregions/${stRegion.id}`, payload);
    console.log('response', response);

    const data = await response.data;
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const patchBatchStRegion = createAsyncThunk('main/settingsStRegion/stRegions/patchBatchStRegion', async (stRegions, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stRegions.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'sttourplan', row)); });

  try {
    const response = await axios.patch(`api/v1/batchstregion`, { st_tour_plans: payload });
    console.log('response', response);
    const data = await response.data;
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const deleteStRegion = createAsyncThunk('main/settingsStRegion/stRegions/deleteStRegion', async (stRegion, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/stregions/${stRegion.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsStRegion/stRegions/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stRegion.settings.stRegions' } });
  const data = await response.data;

  return data.st_tours;
});


const stRegionsAdapter = createEntityAdapter({});

export const { selectAll: selectStRegions, selectById: selectStRegionById } =
  stRegionsAdapter.getSelectors((state) => state.main.settingsStRegion.stRegions.stRegions);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsStRegion.stRegions.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsStRegion.stRegions.units);

const stRegionsSlice = createSlice({
  name: 'main/settingsStRegion/stRegions',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: 10,
    sortModel: [],
    rangeDate: [null, null],
    selectedUnit: null,
    selectedType: [],
    name: null,
    selectedTour: null,
    loadingStRegions: false,
    loadingUnits: false,
    savingStRegion: false,
    loadingStTours: false,
    stRegions: stRegionsAdapter.getInitialState({ }),
    dataStRegions: [],
    stRegionsCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    isDataDirty: false,
  },
  reducers: {
    setStRegionsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      // prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setDataStRegions: {
      reducer: (state, action) => {
        state.dataStRegions = action.payload;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedType: {
      reducer: (state, action) => {
        state.selectedType = action.payload;
      },
    },
    setName: {
      reducer: (state, action) => {
        state.name = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
  },
  extraReducers: {
    [getStRegions.fulfilled](state, { payload }) {
      stRegionsAdapter.setAll(state.stRegions, payload.sttourplans);
      state.stRegionsCount = payload.totalcount;
      state.loadingStRegions = false;
    },
    [getStRegions.pending]: (state) => {
      state.loadingStRegions = true;
      stRegionsAdapter.removeAll(state.stRegions);
    },
    [getStRegions.rejected]: (state) => {
      state.loadingStRegions = false;
    },

    [patchStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [patchStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [patchStRegion.rejected](state, { payload }) {
      state.savingStRegion = false;
    },

    [patchBatchStRegion.fulfilled](state, { payload }) {
      state.savingStRegion = false;
    },
    [patchBatchStRegion.pending](state, { payload }) {
      state.savingStRegion = true;
    },
    [patchBatchStRegion.rejected](state, { payload }) {
      state.savingStRegion = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const {
  setStRegionsSearchText,
  setIsDataDirty,
  setDataStRegions,
  setPage,
  setPageSize,
  setRowsPerPage,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedType,
  setName,
  setSelectedTour,
} = stRegionsSlice.actions;

export default stRegionsSlice.reducer;
