import _ from '@lodash';
import { styled, ThemeProvider, useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import {default as React, memo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';
import Icon from "@mui/material/Icon";
import {useTranslation} from "react-i18next";
import {selectMainTheme} from "../../../store/fuse/settingsSlice";

const Root = styled('div')(({ theme }) => ({
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.primary.contrastText,
}));

function HeaderWidget(props) {
    const theme = useTheme();
    const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));
    const data = _.merge({}, props.data);
    const mainTheme = useSelector(selectMainTheme);

    _.setWith(data, 'options.fill.colors', [theme.palette.secondary.main]);
    _.setWith(data, 'options.markers.colors', [theme.palette.secondary.main]);
    _.setWith(data, 'options.stroke.colors', [theme.palette.primary.contrastText]);
    _.setWith(data, 'options.markers.strokeColors', [theme.palette.primary.contrastText]);
    _.setWith(data, 'options.grid.borderColor', alpha(theme.palette.primary.contrastText, 0.3));
    const { t } = useTranslation('common');

    return (
        <ThemeProvider theme={contrastTheme}>
            <Root>
                <div className="container relative p-16 sm:p-24 flex flex-col sm:flex-row justify-between items-center">
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        <div className="flex items-center">
                            <Icon
                                component={motion.span}
                                initial={{ scale: 0 }}
                                animate={{ scale: 1, transition: { delay: 0.2 } }}
                                className="text-24 md:text-32"
                            >
                                gps_fixed
                            </Icon>
                            <Typography
                                component={motion.span}
                                initial={{ x: -20 }}
                                animate={{ x: 0, transition: { delay: 0.2 } }}
                                delay={300}
                                className="text-16 md:text-24 mx-12 font-semibold"
                            >
                                { t('menu_scantec') }
                            </Typography>

                        </div>
                    </motion.div>
                </div>
            </Root>
        </ThemeProvider>
    );
}

export default HeaderWidget;
