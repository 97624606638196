import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
  useMapEvent,
} from "react-leaflet";
import * as React from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import _ from "@lodash";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import * as L from "leaflet";
import { useRef } from "react";
import { useEffect } from "react";
import FuseUtils from "../../../../@fuse/utils";
import {
  getUnitFleetTracking,
  setIds,
  setElement,
} from "../../fleetTracking/store/FleetTrackingSlice";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import { getUnitMarkers } from "../store/FleetTrackingMarkerSlice";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import AirplaneMarker from "./AirplanWidget";
import LegendTrucks from "./Trucks";
import getVehicleIcon from "../../../types/kocoIconType";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../../store/fuse/messageSlice";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Moment from "react-moment";
require("leaflet.animatedmarker/src/AnimatedMarker");

function MapMarkersWidget(props) {
  var polyUtil = require("polyline-encoded");
  //var animatedMarker = require('leaflet.animatedmarker/src/AnimatedMarker');
  const geocoder = L.Control.Geocoder.nominatim();
  const language = useSelector(({ i18n }) => i18n.language);

  var encoded = "_p~iF~cn~U_ulLn{vA_mqNvxq`@";
  //  let isMapMovement = false;
  var latlngs = polyUtil.decode(encoded);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const customMarker = new L.icon({
    iconUrl: "/assets/images/vehicles/avariopress.png",
    iconSize: [15, 30],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });
  const { markers, bounds, loading, search, allMarkers, idMarker } =
    useSelector(({ main }) => main.fleetTracking.fleetMarkers);
  const { load, lines } = useSelector(
    ({ main }) => main.fleetTracking.fleetLines
  );
  useEffect(() => {
    dispatch(getUnitMarkers()).then(() => {
      //setLegos(true);
    });
  }, [dispatch]);

  const [map, setMap] = useState();
  const [text, setText] = useState("");
  let position = [53.49573, 8.62839];
  //let text = useRef();
  let isMapMovement = useRef();
  //let text = useRef();
  let isSet = false;
  let counter = 0;
  let arrayIds = [];
  const [polylines, setPolylines] = useState([]);
  const [isPolylineActive, setIsPolylineActive] = useState(false);
  const [positionMoving, setPositionMoving] = useState([53.49573, 8.62839]);
  isMapMovement.current = 0;
  useEffect(() => {
    isMapMovement.current = 0;
    //if (isMapMovement.current == false) {
    if (
      isMapMovement.current == false &&
      !load &&
      !loading &&
      !isPolylineActive &&
      map &&
      bounds && bounds[0].length > 0 && bounds[1].length > 0 &&
      bounds.length > 0 &&
      idMarker == ""
    ) {
      //isMapMovement.current = false;
      map.fitBounds(bounds);
      setTimeout(function () {
        isMapMovement.current = true;
      }, 2000);
    }
    if (idMarker && map) {
      map.dragging.disable();
      var markerData = FuseUtils.filterArrayByString(markers, idMarker);
      isMapMovement.current = 1;
      changePos(markerData[0].lat, markerData[0].lng, markerData[0].id);
      isMapMovement.current = 0;
      map.dragging.enable();
    }
  }, [bounds, markers, search, allMarkers, loading, map, idMarker]);

  function MyComponent() {
    const map = useMapEvent({
      dragend: (e) => {
        L.DomEvent.stopPropagation(e);
        console.log("move" + isSet);
        console.log(isMapMovement);
        if (isMapMovement.current != 1) {
          arrayIds = [];
          map.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              if (map.getBounds().contains(layer.getLatLng())) {
                if (layer._childCount && layer._childCount > 1) {
                  layer.getAllChildMarkers().map(function (lay) {
                    arrayIds = [...arrayIds, lay.options.data];
                  });
                } else {
                  arrayIds = [...arrayIds, layer.options.data];
                }
              }
            }
          });
          dispatch(setIds(arrayIds));
          console.log(arrayIds);
        } else {
          counter++;
          console.log(counter);
          if (counter > 5) {
            isSet = false;
          }
          isMapMovement.current = 0;
        }
        console.log("drag end");
      },
    });
    return null;
  }
  function MyComponent2() {
    const map = useMapEvent({
      zoomend: (e) => {
        L.DomEvent.stopPropagation(e);
        console.log("move" + isSet);
        console.log(isMapMovement.current);
        if (isMapMovement.current != 1) {
          arrayIds = [];
          map.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              if (map.getBounds().contains(layer.getLatLng())) {
                if (layer._childCount && layer._childCount > 1) {
                  layer.getAllChildMarkers().map(function (lay) {
                    arrayIds = [...arrayIds, lay.options.data];
                  });
                } else {
                  arrayIds = [...arrayIds, layer.options.data];
                }
              }
            }
          });
          dispatch(setIds(arrayIds));
          console.log(arrayIds);
        } else {
          counter++;
          console.log(counter);
          if (counter > 3) {
            isSet = false;
          }
          isMapMovement.current = 0;
        }
        console.log("zoom end");
      },
    });
    return null;
  }
  function MyComponent3() {
    const map = useMapEvent({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        console.log("marker click!");
        isSet = false;
      },
    });
    return null;
  }

  function changePos(e, pos, id) {
    console.log(isSet);
    if (map && e) {
      isMapMovement.current = 1;
      map.setView([e, pos], 18);
      dispatch(setElement(id));
      setTimeout(function () {
        isMapMovement.current = false;
      }, 2000);
    }
  }
  function handleData(data2, lat, lng, id) {
    let obj = {
      lat: lat,
      lon: lng,
      addressdetails: 1,
      format: "json",
      "accept-language": language,
    };

    fetch(
      "https://nominatim.openstreetmap.org/reverse?" + new URLSearchParams(obj)
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let addressText = "";
        
        if (data.address) {
          addressText += data.address.road ? ` ${data.address.road}` : "";
          addressText += data.address.house_number ? ` ${data.address.house_number},` : data.address.road ? "," : "";
          addressText += data.address.postcode ? ` ${data.address.postcode}` : "";
          addressText += data.address.city ? ` ${data.address.city},` : 
            data.address.town && data.address.village ? ` ${data.address.town}-${data.address.village},` : 
            data.address.town && data.address.suburb ? ` ${data.address.town}-${data.address.suburb},` :
            data.address.town ? ` ${data.address.town},` : 
            data.address.suburb ? ` ${data.address.suburb},` : 
            data.address.village ? ` ${data.address.village}, `: data.address.postcode ? "," : "";
          addressText += data.address["ISO3166-2-lvl4"] ? ` ${data.address["ISO3166-2-lvl4"].split('-')[1]}` : "";
          addressText += data.address.country ? ` ${data.address.country}` : "";
        } else {
          addressText = data.display_name;
      }
        setText(addressText);
      });
    // geocoder.reverse(
    //     data2,1,
    //     results => {
    //         var r = results[0];
    //         if (r) {
    //             setText("Address :"+ (r.name? r.name : r.html));
    //             changePos(lat,lng, id);
    //         }
    //     }
    // );
  }
  if (loading) return <FuseLoading />;
  if (load) return <FuseLoading />;
  return (
    <Card className="w-full h-512 rounded-20 shadow">
      <MapContainer
        whenCreated={(map) => setMap(map)}
        center={position}
        style={{ height: "51.2rem", width: "100%" }}
        zoom={13}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MyComponent2 />
        <MyComponent />
        <MyComponent3 />
        <Fullscreen />
        <LegendTrucks map={map} />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          onClusterClick={(cluster) =>
            console.log(
              "cluster-click",
              cluster,
              cluster.layer.getAllChildMarkers()
            )
          }
        >
          {markers.length > 0 &&
            markers.map((marker) => (
              <Marker
                key={marker.id}
                data={marker.id}
                eventHandlers={{
                  click: (e) => {
                    handleData(e.latlng, marker.lat, marker.lng, marker.id);
                    //changePos();
                  },
                }}
                icon={getIcon(marker)}
                position={[marker.lat, marker.lng]}
              >
                <Popup closeButton={false}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="50"
                        image={getVehicleIcon(marker.constructionType)}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {marker.layer}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Coordinates: {marker.lat}, {marker.lng}
                        </Typography>
                        <Typography variant="body2">{text}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>
        {polylines.length > 0 &&
          polylines.map((polyline) => (
            <Polyline key={polyline} positions={polyline} color="blue" />
          ))}
      </MapContainer>
    </Card>
  );

  function getIcon(marker) {
    let size = [38, 17];
    if (marker.constructionType.indexOf("viajet") > -1) {
      size = [32, 17];
    }
    return new L.icon({
      iconUrl: getVehicleIcon(marker.constructionType),
      iconSize: size,
      popupAnchor: [2, -20],
    });
  }
}

export default MapMarkersWidget;
