import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsers = createAsyncThunk('main/settingsUser/users/getUsers', async () => {
  const response = await axios.get(`api/v1/users`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.users' } });
  const data = await response.data;

  return data.users;
});

export const getUsersByCompany = createAsyncThunk('main/settingsUser/users/getUsersByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/users`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.users' } });
  const data = await response.data;

  return data.users;
});

export const getRoles = createAsyncThunk('main/settingsUser/users/getRoles', async () => {
  const response = await axios.get(`api/v1/rolekocos`, { params: { limit: 0 } });
  const data = await response.data;

  return data.rolekocos;
});

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state) => state.main.settingsUser.users.users);

const rolesAdapter = createEntityAdapter({});

export const { selectAll: selectRoles, selectById: selectRoleById } =
rolesAdapter.getSelectors((state) => state.main.settingsUser.users.roles);

const usersSlice = createSlice({
  name: 'main/settingsUser/users',
  initialState: {
    searchText: '',
    loadingUsers: false,
    users: usersAdapter.getInitialState({ }),
    roles: rolesAdapter.getInitialState({ }),
  },
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled](state, { payload }) {
      usersAdapter.setAll(state.users, payload);
      state.loadingUsers = false;
    },
    [getUsers.pending]: (state) => {
      state.loadingUsers = true;
      usersAdapter.removeAll(state.users);
    },
    [getUsers.rejected]: (state) => {
      state.loadingUsers = false;
    },

    [getRoles.fulfilled](state, { payload }) {
      rolesAdapter.setAll(state.roles, payload);
      state.loadingRoles = false;
    },
    [getRoles.pending]: (state) => {
      state.loadingRoles = true;
      rolesAdapter.removeAll(state.roles);
    },
    [getRoles.rejected]: (state) => {
      state.loadingRoles = false;
    },

    [getUsersByCompany.fulfilled](state, { payload }) {
      usersAdapter.setAll(state.users, payload);
      state.loadingUsers = false;
    },
    [getUsersByCompany.pending]: (state) => {
      state.loadingUsers = true;
      usersAdapter.removeAll(state.users);
      state.loadingUsers = false;
    },
    [getUsersByCompany.rejected]: (state) => {
      state.loadingUsers = false;
    },
  },
});

export const { setUsersSearchText } = usersSlice.actions;

export default usersSlice.reducer;
