import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  lines: [],
  loadingLines: false,
};

export const getAllLines = createAsyncThunk(
  "fleetTracking/units/lines",
  async (obj) => {
    // const {id,date,date2} = obj;

    const { id, date, date2, isTodayDate } = obj;

    const params = { date: date, date2: date2 };
    if (isTodayDate) {
      params.isTodayDate = isTodayDate;
    }

    const response = await axios.get(
      apiUrlConfig.GET_UNITS + "/" + id + apiUrlConfig.GET_LINE_AREA,
      { params: params, headers: { "X-UI-State": "kocobox.overview" } }
    );
    const data = await response.data;
    return data;
  }
);

export const fleetTrackingLinesSlice = createSlice({
  name: "fleetTracking/linesAll",
  initialState,
  reducers: {
    setClearLines: {
      reducer: (state, action) => {
        state.lines = [];
      },
    },
  },
  extraReducers: {
    [getAllLines.pending]: (state) => {
      state.loadingLines = true;
    },
    [getAllLines.fulfilled]: (state, { payload }) => {
      state.loadingLines = false;
      state.lines = payload;
    },
    [getAllLines.rejected]: (state) => {
      state.loadingLines = false;
    },
  },
});
export const postReducer = fleetTrackingLinesSlice.reducer;
export const { setClearLines } = fleetTrackingLinesSlice.actions;
export default fleetTrackingLinesSlice.reducer;
