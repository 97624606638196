import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import reducer from '../store';
import ScantecContent from "./ScantecContent";

const Root = styled(FusePageCarded)(({ theme }) => ({
}));

function Scantec() {
  return <ScantecContent />;
}

export default withReducer('main', reducer)(Scantec);
