
const ParameterCategoriesSweeperViajetBody = [

    { 'value': 0, 'from': 0, 'to': 99, 'count': 0, 'label': '00 - 99' },
    { 'value': 1, 'from': 100, 'to': 199, 'count': 0, 'label': '100 - 199' },
];

export default ParameterCategoriesSweeperViajetBody;


