import Card from "@mui/material/Card";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getUnitFleetTracking} from "../../fleetTracking/store/FleetTrackingSlice";
import unitsScantec, {getUnitsScantec, setIds, setSelected} from "../store/unitsScantec";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import {HideImage, Image,Refresh,HideSourceOutlined,RadioButtonCheckedOutlined} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import getVehicleIcon from "../../../types/kocoIconType";
import WidgetMultiAction from "../../fleetTracking/Widgets/widgetMultiAction";
import {setIdVehicle, setList, setMarker} from "../store/positionScantec";
import Moment from "react-moment";
import {getTrand} from "../store/trendPositionScantec";
import TablePagination from "@mui/material/TablePagination";
import {showMessage} from "../../../store/fuse/messageSlice";

function ListScanTecWidget(props) {
    const dispatch = useDispatch();
    const widgets = null;
    const { t } = useTranslation('common');
    let { entities, load,filter,vehicles,list } = useSelector(({ main }) => main.scantec.positionScantec);
    let [array,setArray] = useState([]);
    let [showMarker,setShowMarker] = useState(true);
    useEffect(() => {
        dispatch(getUnitsScantec()).then(() => {
            // setLoading(false);
        });

    }, [dispatch]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [order, setOrder] = useState({
        direction: 'asc',
        id: null,
    });
    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
    }
    function showMarkers(id) {
        let array2 = array;
        console.log(array2);
        if (array2.includes(id)) {
            const index = array.indexOf(id);
            if (index > -1) {
                array2.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            array2.push(id);
        }
        setArray(array2);
        dispatch(setIdVehicle(array));
        dispatch(setSelected(array));
        setShowMarker(!showMarker);
    }

    function showDetails(row) {
        dispatch(showMessage({ message: t('layout_default_loading_data') }));
        props.setDetails(row);
        dispatch(getTrand({binid : row.binId, date :props.date}));
        setTimeout(function () {
            dispatch(setMarker(row));
        }, 1000);
    }
    function syncData() {
        dispatch(setList(props.mapEl.getBounds()));
    }
    return (
        <Card style={{ height: "51.2rem", width: "105%", overflow:"scroll" }} className="w-full rounded-20 shadow">

            <table className="simple clickable">
                <thead>
                <tr>
                    <th aria-label="title">
                        {t('fleet_tracking_mileage_date')}
                        <Tooltip title={t('show_hide_bins_map')}>
                            <IconButton  className="first_icon" aria-label="more" style={{    "margin-left": "5rem;"}} size="large">
                                {showMarker && <HideSourceOutlined onClick={()=> showMarkers(undefined)}></HideSourceOutlined>}
                                {showMarker == false && <RadioButtonCheckedOutlined onClick={()=> showMarkers()}></RadioButtonCheckedOutlined>}
                            </IconButton>
                        </Tooltip>
                    </th>
                    <th className="text-right">
                        <Tooltip title={t('refresh_list_bins')}>
                        <IconButton aria-label="more" size="large">

                            <Refresh onClick={()=> syncData()}></Refresh>
                        </IconButton>
                        </Tooltip>
                    </th>
                </tr>
                </thead>

                <tbody>
                {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <tr key={row.id} className={
                        clsx(
                            row.isSelected && 'background-color',
                            row.isSelected == false && '',
                        )} key={row.id} onClick={() => {
                        showDetails(row);
                    }}>
                        <td>
                            <Moment unix local={"de"} format="DD.MM.YYYY HH:mm:ss">{row.date.sec}</Moment>
                        </td>
                        <Tooltip title={row.binId}>
                            <td className="text-center">
                                <img className="icon"
                                     src={getIcon(row)}
                                     alt={row.name}/>
                            </td>
                        </Tooltip>
                    </tr>
                ))}
                </tbody>
            </table>
            <TablePagination
                className="shrink-0 border-t-1 pagination"
                rowsPerPageOptions={[8, 16, 24]}
                labelRowsPerPage={""}
                labelDisplayedRows={
                    ({ from, to, count }) => {
                        return '' + from + '-' + to + ' ' + t("layout_default_of") + ' ' + count
                    }
                }
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    );
}
function getIcon(el) {
    let iconUrl = "/assets/images/gray-circle.png";
    if (el.result == 0) {
        iconUrl = "/assets/images/bin_green.png";
    } else if (el.result == 1) {
        iconUrl = "/assets/images/bin_yellow.png";
    } else if (el.result == 2) {
        iconUrl = "/assets/images/bin_red.png";
    } else {
        iconUrl = "/assets/images/bin_gey.png"
    }
    return iconUrl;
}

export default ListScanTecWidget;

