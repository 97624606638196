import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "../../fleetTracking/store/FleetTrackingSlice";
import moment from "moment";
import {fleetMarkersSlice} from "../../fleetTracking/store/FleetTrackingMarkerSlice";

const initialState = {
  data: [],
  loadingReport: false,
  type:1,
  bucket:1,
  max:0,
  min:0,
  avg:0,
  count:0,
  finaleData:[],
  graph: [],
}

export const getOldData = createAsyncThunk('reports/old', async (obj) => {

  const {vehicle,signal,date,endDate,bucket,type} = obj;
  const response = await axios.get(apiUrlConfig.GET_OLD_DATA, { params: obj , headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.olddata
});


export const oldDataSlice = createSlice({
  name: 'scantec/old',
  initialState,
  reducers: {
    setFilterData: {
      reducer: (state, action) => {
        //state.ids = action.payload;
        if (action.payload) {
          let type = action.payload.sampling ==1 ? 2: action.payload.type;
          let graph = [];
          _.each(state.data, function (el) {
            fillDetails(el, type);
          });
          console.log(state.data);
         // if (action.payload.sampling == 2) {
            state.finaleData = fillTime(state.data, action.payload.frequency,action.payload.sampling);
            console.log(state.finaleData);
          _.each(state.finaleData, function (el) {
            graph.push([el.date.getTime(), el.totalWeightGain]);
          });
          state.graph = graph;
          state.loadingReport = false;
        }
      }
    },setClearAll: {
      reducer: (state, action) => {
        state.finaleData =[];
        state.data = [];
        state.graph= [];
      }
    }
  //state.ids = action.payload;
  },
  extraReducers: {
    [getOldData.pending]: (state) => {
      state.loadingReport = true
    },
    [getOldData.fulfilled]: (state, { payload }) => {
      state.loadingReport = false;
      state.data = _.sortBy(payload,function (data) {
        return data.date;
      });
    },
    [getOldData.rejected]: (state) => {
      state.loadingReport = false
    },
  },
});

export const postReducer = oldDataSlice.reducer;
export default oldDataSlice.reducer;
export const {setFilterData,setClearAll} = oldDataSlice.actions;


function fillDetails(el, customSearch) {
  switch (customSearch) {
    case 1:
      el.totalWeightGain = el.minv;
      break;
    case 2:
    default:
      el.totalWeightGain = el.maxv;
      break;
    case 3:
      el.totalWeightGain = el.sum;
      break;
    case 4:
      el.totalWeightGain = el.avg;
      break;
  }
}

function fillTime(data, frequency,sampling) {
  var oldDate;
  var atBeginning = true;
  var time = [];
  if (sampling ==2) {
    if (data && data.length > 0) {
      _.each(data, function (test) {
        // console.log('nex lines');
        if (oldDate) {
          switch (frequency) {
            case 1:
              oldDate = _.cloneDeep(moment(oldDate).add(1, "seconds"));
              break;
            case 2:
              oldDate = _.cloneDeep(moment(oldDate).add(1, "minutes"));
              break;
            case 3:
              oldDate = _.cloneDeep(moment(oldDate).add(1, "hours"));
              break;
            case 4:
              oldDate = _.cloneDeep(moment(oldDate).add(1, "days"));
              break;
          }
          while (moment(oldDate).isBefore(moment(test.date))) {

            //console.log(moment(oldDate).toString());

            time.push({
              totalWeightGain: 0,
              date: moment(oldDate).toDate(),
              count: 0
            });
            switch (frequency) {
              case 1:
                oldDate = _.cloneDeep(moment(oldDate).add(1, "seconds"));
                break;
              case 2:
                oldDate = _.cloneDeep(moment(oldDate).add(1, "minutes"));
                break;
              case 3:
                oldDate = _.cloneDeep(moment(oldDate).add(1, "hours"));
                break;
              case 4:
                oldDate = _.cloneDeep(moment(oldDate).add(1, "days"));
                break;
            }
          }

        }
        if (atBeginning && test.totalWeightGain == 0) {
        } else {
          atBeginning = false;
          time.push({
            totalWeightGain: test ? parseFloat(test.totalWeightGain).toFixed(2) : 0,
            date: moment(test.date).toDate(),
            count: test ? test.count : 0
          });
        }
        oldDate = moment(test.date);

      });
    }
  } else {
    if (data && data.length > 0) {
      _.each(data, function (test) {
        time.push({
          totalWeightGain: test ? parseFloat(test.totalWeightGain).toFixed(2) : 0,
          date: moment(test.date).toDate(),
          count: 1
        });
      });

    }
  }
  return time;
}