import { authRoles } from 'app/auth';
import i18next from 'i18next';
import de from './navigation-i18n/de';
import en from './navigation-i18n/en';
// import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('de', 'navigation', de);
// i18next.addResourceBundle('tr', 'navigation', tr);

const navigationConfig = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    translate: 'DASHBOARDS',
    type: 'collapse',
    icon: 'dashboard',
    auth: [ authRoles.ROLE_FLEET_OVERVIEW_READ, authRoles.ROLE_SERVICE_DASHBOARD_READ ],
    children: [
      {
        id: 'fleet_overiview',
        title: 'Fleet Overview',
        translate: 'FLEET_OVERVIEW',
        type: 'item',
        icon: 'table_view',
        url: '/fleetoverview',
        auth: [authRoles.ROLE_FLEET_OVERVIEW_READ],
      },
      {
        id: 'service_dashboard',
        title: 'Service Dashboard',
        translate: 'SERVICE_DASHBOARD',
        type: 'item',
        icon: 'support_agent',
        url: '/servicedashboard',
        auth: [authRoles.ROLE_SERVICE_DASHBOARD_READ],
      },
    ],
  },
  {
    id: 'appliations',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'collapse',
    icon: 'apps',
    auth: [ authRoles.ROLE_FLEET_TRACKING_READ, authRoles.ROLE_TOUR_ANALYZE_READ, authRoles.ROLE_REPORTS_READ, authRoles.ROLE_SCANTEC_READ ],
    children: [
      {
        id: 'fleet_tracking',
        title: 'Fleet Tracking',
        translate: 'FLEET_TRACKING',
        type: 'item',
        icon: 'gps_fixed',
        url: '/fleettracking',
        auth: [authRoles.ROLE_FLEET_TRACKING_READ],
      },
      {
        id: 'tourAnalyse',
        title: 'Tour Analyse',
        translate: 'tour_Analyse_2.0',
        type: 'collapse',
        icon: 'settings_overscan',
        auth: [ authRoles.ROLE_TOUR_OVERVIEW_READ, authRoles.ROLE_TA_TOUR_DETAIL_READ, authRoles.ROLE_TA_MAP_READ, authRoles.ROLE_TA_TOUR_READ, authRoles.ROLE_TOUR_PLAN_READ],
        children: [
          {
            id: 'touranalyse-overview',
            title: 'Overview',
            translate: 'touranalyse_overview',
            type: 'item',
            icon: 'table_view',
            url: '/touranalyseoverview',
    
            auth: [authRoles.ROLE_TOUR_OVERVIEW_READ],
          },
          {
            id: 'touranalyse-details',
            title: 'Details',
            translate: 'touranalyse_details',
            type: 'item',
            icon: 'view_list',
            url: '/tatourdetails',
            auth: [authRoles.ROLE_TA_TOUR_DETAIL_READ],
          },
          {
            id: 'touranalyse-map',
            title: 'Map',
            translate: 'touranalyse_map',
            type: 'item',
            icon: 'map',
            url: '/touranalysemap',
            auth: [authRoles.ROLE_TA_MAP_READ],
          },
          {
            id: 'performance-reports',
            title: 'Performance Reports',
            translate: 'report_infos_title',
            type: 'item',
            icon: 'receipt_long',
            url: '/perfreports',
            auth: [authRoles.ROLE_REPORT_INFO_READ],
          },
          {
            id: 'touranalyse-settings',
            title: 'Verwaltung',
            translate: 'touranalyse_settings',
            type: 'collapse',
            icon: 'settings',
            auth: [ authRoles.ROLE_TA_TOUR_READ, authRoles.ROLE_TOUR_PLAN_READ],
            children: [
              {
                id: 'touranalyse-fleet-overiview',
                title: 'Tour',
                translate: 'touranalyse_settings_tour',
                type: 'item',
                icon: 'playlist_add_check',
                url: '/tatours',
                auth: [authRoles.ROLE_TA_TOUR_READ],
              },
              { 
                id: 'touranalyse-settings-planning',
                title: 'Planning',
                translate: 'touranalyse_settings_plannig',
                type: 'item',
                icon: 'schedule',
                url: '/touranalyseplans',
                auth: [authRoles.ROLE_TOUR_PLAN_READ],
              },
              // {
              //   id: 'touranalyse-settings-collector',
              //   title: 'Collector',
              //   translate: 'touranalyse_settings_collector',
              //   type: 'item',
              //   icon: 'backup_table',
              //   url: '/touranalysecollectors',
              //   auth: [authRoles.ROLE_SUPER_ADMIN],
              // },
            ],
          },
        ],
      },
      {
        id: 'tour_analysis',
        title: 'Tour Analysis',
        translate: 'TOUR_ANALYSIS',
        type: 'item',
        icon: 'insights',
        url: '/touranalysis',
        auth: [authRoles.ROLE_TOUR_REPORT_READ],
      },
      // {
      //   id: 'reports',
      //   title: 'Reports',
      //   translate: 'REPORTS',
      //   type: 'item',
      //   icon: 'receipt_long',
      //   url: '/reports',
      //   auth: [authRoles.ROLE_REPORTS_READ],
      // },
      {
        id: 'new_reports',
        title: 'Reports_2.0',
        translate: 'REPORTS',
        type: 'item',
        icon: 'receipt_long',
        url: '/reports2',
        auth: [authRoles.ROLE_REPORTS_READ],
      }, 
      // {
      //   id: 'testmap',
      //   title: 'testmap',
      //   translate: 'testmap',
      //   type: 'item',
      //   icon: 'receipt_long',
      //   url: '/testmap',
      //   auth: [authRoles.ROLE_COMPANY_READ],
      // },
      {
        id: 'scantec',
        title: 'SCANTEC',
        translate: 'SCANTEC',
        type: 'item',
        icon: 'receipt_long',
        url: '/scantec',
        auth: [authRoles.ROLE_SCANTEC_READ],
      },
    ],
  },
  {
    id: 'settings_main',
    title: 'Settings',
    translate: 'menu_settings',
    type: 'collapse',
    icon: 'settings',
    auth: [authRoles.ROLE_USER_SETTINGS_READ],
    children: [
      {
        id: 'ecotec_fleet',
        title: 'Ecotec Fleet',
        translate: 'ecotec_fleet',
        type: 'collapse',
        icon: 'settings_overscan',
        auth: [authRoles.ROLE_ECOTEC_FLEET_READ],
        children: [
          {
            id: 'settings_depots',
            title: 'Depot Administration',
            translate: 'settings_depot_title',
            type: 'item',
            icon: 'store',
            url: '/depots',
            auth: [authRoles.ROLE_DEPOT_READ], 
          },
        ],
      },
      {
        id: 'settings_profile',
        title: 'Profile',
        translate: 'settings_profile',
        type: 'item',
        icon: 'account_circle',
        url: '/profile',
        auth: [authRoles.ROLE_PROFILE_READ],        
      },
      {
        id: 'settings_companies',
        title: 'Company Administration',
        translate: 'settings_company_title',
        type: 'item',
        icon: 'apartment',
        url: '/companies',
        auth: [authRoles.ROLE_COMPANY_READ],        
      },
      {
        id: 'settings_trucks',
        title: 'Vehicle Administration',
        translate: 'settings_vehicle_title',
        type: 'item',
        icon: 'local_shipping',
        url: '/units',
        auth: [authRoles.ROLE_UNIT_SETTINGS_READ], 
      },
      // {
      //   id: 'settings_modules',
      //   title: 'Module Administration',
      //   translate: 'settings_module_title',
      //   type: 'item',
      //   icon: 'fact_check',
      //   url: '/modules',
      //   auth: [authRoles.ROLE_MODULE_READ], 
      // },
      {
        id: 'settings_roles',
        title: 'Role Administration',
        translate: 'settings_role_title',
        type: 'item',
        icon: 'admin_panel_settings',
        url: '/roles',
        auth: [authRoles.ROLE_ROLE_READ], 
      },
      {
        id: 'settings_users',
        title: 'User Administration',
        translate: 'settings_user_title',
        type: 'item',
        icon: 'group',
        url: '/users',
        auth: [authRoles.ROLE_USER_SETTINGS_READ], 
      },
      // {
      //   id: 'settings_error_reports',
      //   title: 'Error report',
      //   translate: 'settings_error_info_title',
      //   type: 'item',
      //   icon: 'report',
      //   url: '',
      //   auth: [authRoles.ROLE_ERROR_CODE_READ], 
      // },
      {
        id: 'settings_passive_vehicle_groups',
        title: 'Vehicle Group Administration',
        translate: 'settings_vehicle_group_title',
        type: 'item',
        icon: 'local_shipping',
        url: 'vehiclegroups',
        auth: [authRoles.ROLE_PASSIVE_VEHICLE_GROUP_READ], 
      },
      {
        id: 'settings_error_code_groups',
        title: 'Error group administration',
        translate: 'settings_error_code_group_title',
        type: 'item',
        icon: 'error_outline',
        url: 'errorgroups',
        auth: [authRoles.ROLE_ERROR_GROUP_READ], 
      },
      {
        id: 'settings_subscriptions',
        title: 'Event Reponsibility Administration',
        translate: 'settings_subscription_title',
        type: 'item',
        icon: 'playlist_add_check',
        url: 'subscriptions',
        auth: [authRoles.ROLE_SUBSCRIPTION_READ,
          authRoles.ROLE_SUBSCRIPTION_ERROR_READ,
          authRoles.ROLE_SUBSCRIPTION_REPORT_READ,
          authRoles.ROLE_SUBSCRIPTION_TOUR_KOCO_READ
        ], 
      },
      {
        id: 'settings_event_infos',
        title: 'Event Info Administration',
        translate: 'settings_event_info_title',
        type: 'item',
        icon: 'add_alert',
        url: 'eventinfos',
        auth: [authRoles.ROLE_EVENT_INFO_READ], 
      },
    ],
  },
  {
    id: 'scantec',
    title: 'SCANTEC',
    translate: 'scantec',
    type: 'collapse',
    icon: 'settings_overscan',
    auth: [ authRoles.ROLE_ST_TOUR_READ, 
      authRoles.ROLE_ST_TOUR_PLAN_READ, 
      authRoles.ROLE_ST_TOUR_OVERVIEW_READ, 
      authRoles.ROLE_ST_TOUR_DETAIL_READ, 
      authRoles.ROLE_ST_MAP_VIEW, 
      authRoles.ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ, 
      authRoles.ROLE_ST_TOUR_COLLECTOR_READ, 
      authRoles.ROLE_ST_REGION_READ,
      authRoles.ROLE_ST_TOUR_DETAIL_REMOVE,
      authRoles.ROLE_ST_CLASSIFIER_SETTINGS_READ,
      ],
    children: [
      {
        id: 'scantec-tour-overview',
        title: 'Tour Overview',
        translate: 'scantec_tour_overview',
        type: 'item',
        icon: 'table_view',
        url: '/sttouroverview',
        auth: [authRoles.ROLE_ST_TOUR_OVERVIEW_READ],
      },
      {
        id: 'scantec-tour-details',
        title: 'Tour Details',
        translate: 'scantec_tour_details',
        type: 'item',
        icon: 'view_list',
        url: '/sttourdetails',
        auth: [authRoles.ROLE_ST_TOUR_DETAIL_READ],
      },
      {
        id: 'scantec-tour-map',
        title: 'Map Overview',
        translate: 'scantec_tour_map',
        type: 'item',
        icon: 'map',
        url: '/stmapoverview',
        auth: [authRoles.ROLE_ST_MAP_VIEW],
      },
      {
        id: 'scantec-analysis',
        title: 'Tour Analysis',
        translate: 'scantec_analysis',
        type: 'collapse',
        icon: 'bar_chart',
        auth: [authRoles.ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ],
        children: [
          {
            id: 'quality-distribution',
            title: 'Quality Distribution',
            translate: 'scantec_quality_distribution',
            type: 'item',
            icon: 'analytics',
            url: '/stqualitydistribution',
            auth: [authRoles.ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ],
          },
        ]
      },
      {
        id: 'scantec-settings',
        title: 'Verwaltung',
        translate: 'scantec_settings',
        type: 'collapse',
        icon: 'settings',
        auth: [ authRoles.ROLE_ST_TOUR_READ, authRoles.ROLE_ST_TOUR_PLAN_READ, authRoles.ROLE_ST_TOUR_COLLECTOR_READ, 
          authRoles.ROLE_ST_REGION_READ, authRoles.ROLE_ST_TOUR_DETAIL_REMOVE, authRoles.ROLE_ST_CLASSIFIER_SETTINGS_READ,],
        children: [
          {
            id: 'smartscan_classifier',
            title: 'Smartscan Classifier',
            translate: 'smartscan_classifier',
            type: 'item',
            icon: 'filter_center_focus',
            url: '/stclassifiers',
            auth: [authRoles.ROLE_ST_CLASSIFIER_SETTINGS_READ,],
          },
          {
            id: 'fleet-overiview',
            title: 'Fleet Overview',
            translate: 'scantec_settings_tour',
            type: 'item',
            icon: 'playlist_add_check',
            url: '/sttours',
            auth: [authRoles.ROLE_ST_TOUR_READ],
          },
          {
            id: 'scantec-settings-tourplanning',
            title: 'Tour Planning',
            translate: 'scantec_settings_tourplannig',
            type: 'item',
            icon: 'schedule',
            url: '/sttourplans',
            auth: [authRoles.ROLE_ST_TOUR_PLAN_READ],
          },
          {
            id: 'scantec-settings-tourcollector',
            title: 'Tour Collector',
            translate: 'scantec_settings_tourcollector',
            type: 'item',
            icon: 'backup_table',
            url: '/sttourcollectors',
            auth: [authRoles.ROLE_ST_TOUR_COLLECTOR_READ],
          },
          {
            id: 'scantec-settings-region',
            title: 'Regions',
            translate: 'scantec_settings_region',
            type: 'item',
            icon: 'crop_square',
            url: '/stregions',
            auth: [authRoles.ROLE_ST_REGION_READ],
          },
          {
            id: 'scantec-settings-tour-details',
            title: 'Tour Details Settings',
            translate: 'scantec_settings_tour_details',
            type: 'item',
            icon: 'crop_square',
            url: '/settingssttourdetails',
            auth: [authRoles.ROLE_ST_TOUR_DETAIL_REMOVE],
          }
        ],
      },
    ],
  },
  
  // {
  //   id: 'documentation',
  //   title: 'documentation',
  //   translate: 'documentation',
  //   type: 'item',
  //   icon: 'import_contacts',
  //   auth: [authRoles.ROLE_UNIT_READ],
  //   url: "documentation"
  // },
];

export default navigationConfig;
