import { authRoles } from "app/auth";
import { lazy } from "react";

const ServiceTickets = lazy(() => import("./ServiceTickets/ServiceTickets"));
const ServiceTicket = lazy(() => import("./ServiceTicket/ServiceTicket"));

const ServiceTicketsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "servicetickets",
      element: <ServiceTickets />,
      auth: [authRoles.ROLE_TICKET_READ],
    },
    {
      path: "servicetickets/:serviceticketId/*",
      element: <ServiceTicket />,
      auth: [authRoles.ROLE_TICKET_READ],
    },
  ],
};

export default ServiceTicketsConfig;
