import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  load: false,
  lines:[]
}

export const getLines = createAsyncThunk('fleetTracking/units/lines', async (obj) => {
  const {id,date} = obj;
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+id+ apiUrlConfig.GET_LINES, { params: { date: date }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.lines;
});


export const fleetLinesSlice = createSlice({
  name: 'fleetTracking/lines',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getLines.pending]: (state) => {
      state.load = true
    },
    [getLines.fulfilled]: (state, { payload }) => {
      state.load = false;
      state.lines =payload;

    },
    [getLines.rejected]: (state) => {
      state.load = false
    },
  },
});

export const postReducer = fleetLinesSlice.reducer;
export default fleetLinesSlice.reducer;