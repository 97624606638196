import Tooltip from "@mui/material/Tooltip";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { default as React, memo, useEffect, useState } from "react";
import clsx from "clsx";
import WidgetMultiAction from "./widgetMultiAction";
import getVehicleIcon from "../../../types/kocoIconType";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnitFleetTracking,
  setElement,
  setSearch,
} from "../store/FleetTrackingSlice";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import Input from "@mui/material/Input";
import { setUnitsSearchText } from "../../fleet-overview/store/FleetOverviewSlice";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { setMarker, setUniqMarker } from "../store/FleetTrackingMarkerSlice";
import { Refresh, AirportShuttle } from "@mui/icons-material";
import fleetCalculation from "../store/FleetTrackingCalculation";

function ListWidget(props) {
  const dispatch = useDispatch();
  const widgets = null;
  let { entities, loading, search, ids, searchEntities } = useSelector(
    ({ main }) => main.fleetTracking.unitFleetTracking
  );

  let state = props.displayDetails;
  const { t } = useTranslation("common");

  useEffect(() => {
    dispatch(getUnitFleetTracking()).then(() => {
      // setLoading(false);
    });
  }, [dispatch]);
  if (loading) return <FuseLoading />;
  return (
    <Card
      style={{ height: "510px", width: "105%", overflow: "scroll" }}
      className="w-full rounded-20 shadow"
    >
      <div className="simple clickable">
        <div className="table-header pl-16 py-20">
          <div
            className="table-row w-full"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className="table-cell flex text-center pr-24 pl-4">
                <Typography color="textSecondary" className="font-semibold">
                  {t("fleet_tracking_menu_status")}
                </Typography>
              </div>
              <div className="table-cell flex text-center pr-24">
                <Typography color="textSecondary" className="font-semibold">
                  {t("fleet_tracking_menu_icon")}
                </Typography>
              </div>
              <div className="table-cell flex text-center pr-24 ">
                <Typography color="textSecondary" className="font-semibold">
                  {t("fleet_tracking_menu_action")}
                </Typography>
              </div>
            </div>
            <div>
              <div className="table-cell flex items-center self-end items-end">
                <IconButton
                  aria-label="more"
                  size="large"
                  className="self-end items-end p-0"
                >
                  <Refresh onClick={() => reload()}></Refresh>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="table-body">
          {searchEntities.map((row) => (
            <div
              className="table-col cursor-pointer hover:bg-gray-200 transition-colors duration-400 pt-8"
              key={row.id}
            >
              <div
                className={clsx(
                  "table-cell",
                  row.isSelected == 1 && "font-bold",
                  row.isSelected == 0 && "font-semibold",
                  "pl-20"
                )}
                onClick={() => {
                  showDetails(row.id, row);
                }}
              >
                {row.isDriving == 1 && (
                  <IconButton aria-label="more" size="small">
                    <AirportShuttle />
                  </IconButton>
                )}{" "}
                {row.nameKoco}
              </div>
              <div className=" flex items-center ">
                <div className="table-cell px-8 h-1/2 text-center self-center">
                  <Tooltip
                    title={
                      row.isActivToday && row.isStillRunning == false
                        ? t("fleet_tracking_active")
                        : row.isActivToday == true && row.isStillRunning == true
                        ? t("fleet_tracking_not_active_30")
                        : t("fleet_tracking_not_active")
                    }
                  >
                    <i
                      className={clsx(
                        "inline-block w-24 h-24 rounded mx-16",
                        row.isActivToday &&
                          row.isStillRunning == false &&
                          "bg-green",
                        row.isActivToday == true &&
                          row.isStillRunning == true &&
                          "bg-blue",
                        row.isActivToday == false && "bg-red"
                      )}
                    />
                  </Tooltip>
                </div>
                <div className="table-cell px-8 h-1/2 text-center self-center">
                  <img
                    className="icon"
                    src={getVehicleIcon(row.constructionType)}
                    alt={row.name}
                  />
                </div>
                <div className="table-cell px-8 h-1/2 text-center self-center">
                  <WidgetMultiAction
                    setLoadTotal={props.setLoadTotal}
                    setUnit={props.setUnit}
                    setNewUnit={props.setNewUnit}
                    id={row.id}
                    setId={props.setId}
                    setUnitSelected={props.setUnitSelected}
                    setDataFetched={props.setDataFetched}
                    setText={props.setText}
                    item={row}
                    state={props.displayDetails}
                    setDisplayMapFleet={props.setDisplayMapFleet}
                  />
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </Card>
  );

  function showDetails(details, row) {
    dispatch(setUniqMarker(details));
  }
  function reload() {
    window.location.reload();
  }
}

export default ListWidget;
