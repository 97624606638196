import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "../../fleetTracking/store/FleetTrackingSlice";

const initialState = {
  entities2: [],
  loading: false,
  isMap:false,
  search: '',
  searchEntities:[],
  ids:[],
  markers:[],
  selectedMarker: {}
}

export const getUnitsScantec = createAsyncThunk('scantec/units', async () => {
  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.units.map(val => {
    val.nameKoco = val.nameKoco ? val.nameKoco: "-";
    val.title= (val.nameKoco ? val.nameKoco: "-");
    val.value = val.id;
    val.isSelected = false;
    return val;
  });
});


export const unitsScantecSlice = createSlice({
  name: 'scantec/units',
  initialState,
  reducers: {
    setIds: {
      reducer: (state, action) => {
        state.ids = action.payload;
        state.isMap = true;
        if (action.payload && action.payload.length > 0) {
          state.searchEntities = _.intersectionWith(state.entities2, action.payload, (o, id) => o.id === id);
        } else {
          state.searchEntities = state.entities2;
        }
        state.searchEntities.map(function(el) {
          el.isSelected = false;
          el.isDriving = 0;
        })
      },
    },
    setSelected: {
      reducer: (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.searchEntities.map(function (el) {
            if (action.payload.includes(el.id)) {
              el.isSelected = true;
            } else {
              el.isSelected = false;
            }
          })
        } else {
        state.searchEntities.map(function (el) {
            el.isSelected = false;
        })
        }
      }
    }
  },
  extraReducers: {
    [getUnitsScantec.pending]: (state) => {
      state.loading = true
    },
    [getUnitsScantec.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.searchEntities = payload
      state.entities2 = payload
      //state.markers = _.map(payload, 'latestPosition');
    },
    [getUnitsScantec.rejected]: (state) => {
      state.loading = false
    },
  },
});
export const {setIds,setSelected} = unitsScantecSlice.actions;

export const postReducer = unitsScantecSlice.reducer;
export default unitsScantecSlice.reducer;