export const initialRcvColumnsValue = [
    {
        id: 'information',
        value: '100',
        colSpan: 2,
        align: 'left',
        disablePadding: false,
        translate: 'Information',
        translateTooltip: 'Information',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                // {
                //   id: 'icon',
                //   value: '101',
                //   align: 'left',
                //   disablePadding: false,
                //   translate: '',
                //   sort: false,
                // },
                {
                    id: 'licencePlatColor',
                    value: '102',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_license_plate', //'Kennenzeichen',
                    translateTooltip: 'service_dashboard_license_plate', 
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'internalFleetManagerColor',
                    value: '103',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_internal_fleet_number', //'Interne Fuhrparknummer',
                    translateTooltip: 'service_dashboard_internal_fleet_number', 
                    sort: true,
                },
            ]
    },
    {
        id: 'bodyIndicators',
        value: '200',
        colSpan: 6,
        align: 'left',
        disablePadding: false,
        translate: 'service_dashboard_menu_body', // 'Aufbau',
        translateTooltip: 'service_dashboard_menu_body',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                {
                    id: 'remainBodyWorkingHoursColor', // Aufbauwartung
                    value: '1',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_maintenance_short', // 'AW',
                    translateTooltip: 'service_dashboard_rcv_body_maintenance', 
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'fehlerMeldungenColor', // Fehlermeldungen
                    value: '16',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_error_short', // 'FM',
                    translateTooltip: 'service_dashboard_rcv_body_error',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'bodyFillLevelColor', // Füllstandlevel
                    value: '3',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_fill_level_short', // 'FZ',
                    translateTooltip: 'service_dashboard_rcv_body_fill_level',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'bodyPumpFillLevelColor', // Pumpe Zentralschmieranlage
                    value: '4',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_pump_short', // 'PZ',
                    translateTooltip: 'service_dashboard_rcv_body_pump', 
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'leftFootstepActiveColor', // Warnung linker Fusstritt
                    value: '5',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_warning_left_short', // 'WLA',
                    translateTooltip: 'service_dashboard_rcv_body_warning_left',
                    sort: true,
                    borderLeft: 0,
                },
                {
                    id: 'rightFootstepActiveColor', // Warnung rechter Fusstritt
                    value: '6',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_body_warning_right_short', // 'WRA',
                    translateTooltip: 'service_dashboard_rcv_body_warning_right',
                    sort: true,
                    borderLeft: 0,
                },
            ]
    },
    {
        id: 'lifterIndicators',
        value: '300',
        colSpan: 1,
        align: 'left',
        disablePadding: false,
        translate: 'service_dashboard_menu_lifter', // 'Lifter',
        translateTooltip: 'service_dashboard_menu_lifter',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                {
                    id: 'lifterRemainWorkingHoursColor', // Lifterwartung
                    value: '7',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_lifter_warning_short', // 'LW',
                    translateTooltip: 'service_dashboard_rcv_lifter_warning',
                    sort: true,
                    css: 'border-l-1',
                },
            ]
    },
    {
        id: 'chassisIndicators',
        value: '400',
        colSpan: 4,
        align: 'left',
        disablePadding: false,
        translate: 'service_dashboard_menu_chassis', // 'Fahrgestell',
        translateTooltip: 'service_dashboard_menu_chassis',
        sort: false,
        css: 'border-l-1',
        columns:
            [
                {
                    id: 'chassisDieselColor', // Fullstand Diesel
                    value: '8',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_diesel_short', //'FD',
                    translateTooltip: 'service_dashboard_rcv_chassis_diesel',
                    sort: true,
                    css: 'border-l-1',
                },
                {
                    id: 'chassisAdblueColor', // Fullstand AdBlue
                    value: '9',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_adblue_short', // 'FAB',
                    translateTooltip: 'service_dashboard_rcv_chassis_adblue', 
                    sort: true,
                },
                {
                    id: 'engineCoolantTempWarningColor', // Temperatur Motorkühlmittel
                    value: '10',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_motor_temperature_short', // 'TM',
                    translateTooltip: 'service_dashboard_rcv_chassis_motor_temperature',
                    sort: true,
                },
                {
                    id: 'engineOilPressureWarningColor', // Motoröldruck
                    value: '11',
                    align: 'left',
                    disablePadding: false,
                    translate: 'service_dashboard_rcv_chassis_motor_pressure_short', // 'M',
                    translateTooltip: 'service_dashboard_rcv_chassis_motor_pressure',
                    sort: true,
                },
            ]
    },
    // {
    //     id: 'gesetzlichePrüfungen',
    //     value: '500',
    //     colSpan: 4,
    //     align: 'left',
    //     disablePadding: false,
    //     translate: 'service_dashboard_menu_examination', // 'Gesetzliche Prüfungen',
    //     translateTooltip: 'service_dashboard_menu_examination', 
    //     sort: false,
    //     css: 'border-l-1',
    //     columns:
    //         [
    //             {
    //                 id: 'hsMeetingGasMeetingColor', // Hauptuntersuchung
    //                 value: '12',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_general_short', // 'HU/AU',
    //                 translateTooltip: 'service_dashboard_rcv_examination_general', 
    //                 sort: true,
    //                 css: 'border-l-1',
    //             },
    //             {
    //                 id: 'tachoMeetingColor', // Tachographenprüfung
    //                 value: '13',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_tacho_short', // 'TP',
    //                 translateTooltip: 'service_dashboard_rcv_examination_tacho',
    //                 sort: true,
    //             },
    //             {
    //                 id: 'securityMeetingColor', // Sicherheitsprüfung
    //                 value: '14',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_security_short', // 'SP',
    //                 translateTooltip: 'service_dashboard_rcv_examination_security',
    //                 sort: true,
    //             },
    //             {
    //                 id: 'uvvMeetingColor', // UVV_Prüfung
    //                 value: '15',
    //                 align: 'left',
    //                 disablePadding: false,
    //                 translate: 'service_dashboard_rcv_examination_uvv_short', // 'UVV-P',
    //                 translateTooltip: 'service_dashboard_rcv_examination_uvv',
    //                 sort: true,
    //             },
    //         ]
    // },

];