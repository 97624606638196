import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TourAnalyseCollectors = lazy(() => import('./TourAnalyseCollectors'));

const TourAnalyseCollectorsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'touranalysecollectors',
      element: <TourAnalyseCollectors />,
      auth: [authRoles.ROLE_SUPER_ADMIN], 
    },
  ],
};

export default TourAnalyseCollectorsConfig;
