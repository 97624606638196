import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { memo, default as React } from "react";
import ReactApexChart from "react-apexcharts";
import _ from "@lodash";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../css/graphs.css";
import { Skeleton } from "@mui/material";

function BatterieWidget(props) {
  const theme = useTheme();
  const data = _.merge({}, props.data);
  _.setWith(data, "options.colors", [theme.palette.secondary.main]);
  const { t } = useTranslation("common");
  let mileage = _.find(props.data, (element) => {
    return element.signalId == "30808" ? true : false;
  });
  let data3 = {};
  let color = "#228B22";
  if (mileage) {
    let mileageLast = parseInt(mileage.lastValue);
    if (mileageLast < 15) {
      color = "#FF0000";
    } else if (mileageLast < 40) {
      color = "#FFD700";
    }
    let minx = 0;
    let maxx = 0;
    let data2 = generateDayWiseTimeSeries(
      mileage.array,
      mileage.lastValue,
      minx,
      maxx
    );

    data3 = {
      series: [
        {
          name: t("live_data_soc"),
          data: data2.series,
        },
      ],
      options: {
        chart: {
          stacked: true,
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min));
            },
            mouseMove: function (event, chartContext, config) {},
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
        },
        colors: [color, "#00E396", "#CED4DC"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            left: -20,
            right: -5,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          show: false,
        },
        yaxis: {
          show: false,
          floating: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          min: 0,
          max: 100,
        },
        xaxis: {
          type: "datetime",
          show: false,
          labels: {
            show: false,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("HH:mm"); // formats to hours:minutes
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          crosshairs: [true],
          formatter: function () {
            return (
              "Datetime: " +
              Moment.utc(Moment.unix(this.x / 1000)).format("HH:mm") +
              "<br> Ad-Blue: " +
              this.y
            );
          },
        },
      },
    };
    console.log(minx);
  }

  if (props.loading) {
    mileage = null;
    return (
      <Card className="w-full rounded-20 shadow">
        <div className="w-full isolate ">
          <Skeleton className="w-1/3 h-36 m-10" variant="recangular" />
          <Skeleton className="h-96 m-10" variant="rectangular" />
        </div>
      </Card>
    );
  }
  if (!mileage) {
    return (
      <Card className="w-full rounded-20 shadow">
        <div className="p-20 pb-0">
          <Typography className="h3 font-medium">
            {t("live_data_soc")}
          </Typography>

          <div className="flex flex-row flex-wrap items-center mt-12">
            <Typography className="text-36 font-semibold leading-none tracking-tighter">
              {t("cockpit_tab_default_not_available")}
            </Typography>
          </div>
        </div>
        <div className="h-96 w-100-p empty-chart"></div>
      </Card>
    );
  }
  return (
    <Card className="w-full rounded-20 shadow">
      <div className="p-20 pb-0">
        <Typography className="h3 font-medium">{t("live_data_soc")}</Typography>

        <div className="flex flex-row flex-wrap items-center mt-12">
          <Typography
            className="text-48 font-semibold leading-none tracking-tighter"
            color={color}
          >
            {mileage.lastValue2} %
          </Typography>

          <div className="flex flex-col mx-8">
            <div className="flex items-center">
              <Typography className="font-semibold" color="textSecondary">
                <Moment unix local={"de"} format="DD.MM.YYYY HH:mm">
                  {mileage.lastDate2.sec}
                </Moment>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="h-96 w-100-p pt-8">
        <ReactApexChart
          options={data3.options}
          series={data3.series}
          type="area"
          height={"100px"}
        />
      </div>
    </Card>
  );
}
export default BatterieWidget;
function generateDayWiseTimeSeries(data, lastValue, minx, maxx) {
  let i = 0;
  var series = [];
  var times = [];
  let firstValue = 0;
  let y = 0;
  let x = 0;
  const myOrderedArray = _.sortBy(data, function (o) {
    return o.date;
  });
  _.each(myOrderedArray, function (e) {
    if (firstValue == 0) {
      firstValue = e.value;
    }
    y = e.value;
    if (times.indexOf(e.date * 1000) == -1) {
      x = e.date * 1000;
      times.push(x);
      series.push([x, y]);
    }
  });
  let seriesData = { series: series, lastValue: y, lastDate: x };

  return seriesData;
}
