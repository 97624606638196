// /* eslint import/no-extraneous-dependencies: off */
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import history from "@history";
import _ from "@lodash";
import {
  setInitialSettings,
  setDefaultSettings,
} from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import auth0Service from "app/services/auth0Service";
import firebaseService from "app/services/firebaseService";
import jwtService from "app/services/jwtService";
import settingsConfig from "app/fuse-configs/settingsConfig";
import fosService from "app/services/fosService";
import axios from "axios";
import authRoles from "../authRoles";
import { changeLanguage } from "app/store/i18nSlice";

export const setUserDataAuth0 = (tokenData) => async (dispatch) => {
  const user = {
    role: ["admin"],
    from: "auth0",
    data: {
      displayName: tokenData.username || tokenData.name,
      photoURL: tokenData.picture,
      email: tokenData.email,
      settings:
        tokenData.user_metadata && tokenData.user_metadata.settings
          ? tokenData.user_metadata.settings
          : {},
      shortcuts:
        tokenData.user_metadata && tokenData.user_metadata.shortcuts
          ? tokenData.user_metadata.shortcuts
          : [],
    },
  };

  return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
  if (
    user &&
    user.data &&
    user.data.settings &&
    user.data.settings.theme &&
    user.data.settings.layout &&
    user.data.settings.layout.style
  ) {
    // Set user data but do not update
    return dispatch(setUserData(user));
  }

  // Create missing user settings
  return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase =
  (authUser) => async (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const fuseDefaultSettings = getState().fuse.settings.defaults;
    const { currentUser } = firebase.auth();

    /**
     * Merge with current Settings
     */
    const user = _.merge({}, guestUser, {
      uid: authUser.uid,
      from: "firebase",
      role: ["admin"],
      data: {
        displayName: authUser.displayName,
        email: authUser.email,
        settings: { ...fuseDefaultSettings },
      },
    });
    currentUser.updateProfile(user.data);

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
  };

export const setUserDataFos = (user) => async (dispatch) => {
  user = {
    role: user.roles,
    from: "fos",
    data: {
      displayName: user.firstName + " " + user.lastName,
      photoURL: user.picture,
      email: user.email,
      settings: {},
      shortcuts: [],
      locale: user.locale,
    },
    ...user,
  };
  delete user.roles;

  return dispatch(setUserData(user));
};

export const setUserData = (user) => async (dispatch, getState) => {
  /*
  You can redirect the logged-in user to a specific route depending on his role
  */
  // if (user.loginRedirectUrl) {
  //   settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  // }

  /*
  Set User Settings
  */
  // if(user.data && user.data.settings)
  //   dispatch(setDefaultSettings(user.data.settings));

  dispatch(setDefaultSettings(settingsConfig));
  dispatch(changeLanguage(user.locale, user));
  dispatch(setUser(user));
  dispatch(getUserSettings(user));

  let loginRedirecturl = "";

  if (window.location.pathname) {
    loginRedirecturl = window.location.pathname;
  }
  if (user.role.includes("ROLE_SUPER_ADMIN")) {
    loginRedirecturl = "fleetoverview";
  } else if (user.role.includes(authRoles.ROLE_FLEET_OVERVIEW_READ) ) {
    loginRedirecturl = "fleetoverview";
  } else if (user.role.includes(authRoles.ROLE_SERVICE_DASHBOARD_READ) ) {
    loginRedirecturl = "servicedashboard";
  } else if (user.role.includes(authRoles.ROLE_FLEET_TRACKING_READ) ) {
    loginRedirecturl = "fleettracking";
  } else if (user.role.includes(authRoles.ROLE_TOUR_ANALYZE_READ) ) {
    loginRedirecturl = "touranalysis";
  } else if (user.role.includes(authRoles.ROLE_REPORTS_READ) ) {
    loginRedirecturl = "reports";
  } else if (user.role.includes(authRoles.ROLE_SCANTEC_READ) ) {
    loginRedirecturl = "scantec";
  }

  settingsConfig.loginRedirectUrl = loginRedirecturl;
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts =
  (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return dispatch(setUserData(newUser));
  };

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  // if (!user.role || user.role.length === 0) {
  //   // is guest
  //   return null;
  // }

  history.push({
    pathname: "/",
  });

  switch (user.from) {
    case "firebase": {
      firebaseService.signOut();
      break;
    }
    case "auth0": {
      auth0Service.logout();
      break;
    }
    case "fos": {
      fosService.logout();
      break;
    }
    default: {
      jwtService.logout();
    }
  }

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    case "firebase": {
      firebaseService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: "User data saved to firebase" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    case "auth0": {
      auth0Service
        .updateUserData({
          settings: user.data.settings,
          shortcuts: user.data.shortcuts,
        })
        .then(() => {
          dispatch(showMessage({ message: "User data saved to auth0" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: "User data saved with api" }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

export const getUserSettings = createAsyncThunk(
  "auth/user/getConfigs",
  async (user) => {
    const response = await axios.get(
      `/api/v1/users/${user.id}/systemconfigs/1`
    );
    const data = await response.data;

    return data === undefined ? null : data.system_configs;
  }
);

// TODO: move to settingsSlice
export const saveUserConfigs = createAsyncThunk(
  "auth/user/saveConfigs",
  async (newConfig, { dispatch, getState }) => {
    const { configs, id: userId, company } = getState().auth.user;
    let config = _.cloneDeep(configs).filter(
      (config) => config.configType == newConfig.configType
    );
    let system_config;

    if (config && config.length > 0) {
      system_config = config[0];
      system_config.configs[0].value = newConfig.value;
    } else {
      system_config = {
        user: userId,
        company: company.id,
        configType: newConfig.configType,
        isGlobal: false,
        configs: [{ key: newConfig.key, value: newConfig.value }],
      };
    }

    let data;
    if (system_config.id) {
      const id = system_config.id;
      delete system_config.id;
      const response = await axios.patch(`/api/v1/systemconfigs/${id}`, {
        system_config,
      });
      data = await response.data;
    } else {
      const response = await axios.post("/api/v1/systemconfigs", {
        system_config,
      });
      data = await response.data;
    }
    return data;
  }
);

const initialState = {
  role: [], // guest
  configs: [],
  data: {
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@withinpixels.com",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
    configs: [],
  },
};

const getUserRole = (state) => state.auth.user.role;
const getFleetOverviewColumnsConfig = (state) => {
  if (!state.auth.user.configs || state.auth.user.configs.length === 0)
    return null;

  const config = state.auth.user.configs.filter(
    (config) => config.configType == 1
  );
  if (config && config.length > 0) return config[0];
  return [];
};

export const selectUserRoles = createSelector([getUserRole], (role) => {
  return role;
});

export const selectFleetOverviewColumnsConfig = createSelector(
  [getFleetOverviewColumnsConfig],
  (config) => {
    return config;
  }
);

export const hasAccess =
  (rolesArray, companyIds, operator = "or") =>
  (state) => {

    if (!rolesArray || rolesArray.length === 0) return false;

    if (!state?.auth?.user?.role || state.auth.user.role.length === 0);

    if(!state?.auth?.user?.company) return false;

    if (state.auth.user.role.includes("ROLE_SUPER_ADMIN") == true) return true;

    if (companyIds && companyIds.length > 0 && !companyIds.some(companyId => state.auth.user.company.id == companyId)) {
      if (!state?.auth?.user?.companyAccesses || state.auth.user.companyAccesses.length === 0)
        return false;
    }

    if (!companyIds || (companyIds && companyIds.some(companyId => state.auth.user.company.id === companyId))) {

      // TODO: We need to refactor this method (hasAccess to cover the scenario when we need to check access right in all companies)
      if (operator === "or") {

        return rolesArray.some((role) => state.auth.user.role.includes(role)) ||
          state.auth.user.companyAccesses?.some(compAccess => rolesArray.some((role) => compAccess.roles.includes(role)));
      }

      if (operator === "and")
        return rolesArray.every((role) => state.auth.user.role.includes(role));

      }
      else {

        let result = false;
        companyIds.forEach(companyId => {
          const companyAccess = state.auth.user.companyAccesses.find(ca => ca.company.id === companyId);
          if (companyAccess) {
            if (operator === "or") {
              result = result || rolesArray.some((role) => companyAccess.roles.includes(role));
            }

            if (operator === "and") {
              result = result || rolesArray.every((role) => companyAccess.roles.includes(role));
            }
          }
        });
        return result;
      }
    };

export const isSuperAdmin = () => (state) => {
  if (state.auth.user.role.includes("ROLE_SUPER_ADMIN") == true) return true;
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {
    [getUserSettings.fulfilled]: (state, action) => {
      state.configs = action.payload;
    },
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
