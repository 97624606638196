import _ from '@lodash';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
function TourInfoSweeperWidget(props) {
  const theme = useTheme();
  let [serie, setSerie] = useState({});
    const {liveData,total,transport,collection,waiting,sweep } = useSelector(({ main }) =>  main.fleetTracking.fleetLiveData);
    const {load,signals,position,unloading} = useSelector(({ main }) =>  main.fleetTracking.fleetSignals);
    const { t } = useTranslation('common');
  let isSet = false;
    useEffect(() => {
        if (liveData) {
            let sweepCalculation = sweep && sweep.value ? parseFloat(sweep.value) : 0;
            let mileageSum =(total && total.value)? parseFloat(total.value): 0;
            let transportCalculation = (sweep && sweep.value && total && total.value )? (parseFloat(total.value) - parseFloat(sweep.value)) : 0;
// console.log(sweepCalculation);
// console.log(transportCalculation);
// console.log(mileageSum);
// console.log(floor(parseFloat(sweepCalculation)/parseFloat(mileageSum)));
// console.log((parseFloat(transportCalculation)/parseFloat(mileageSum)));
            // setSerie({
            //     series: [
            //         (floor(parseFloat(sweepCalculation)/parseFloat(mileageSum)*100)),
            //         (floor(parseFloat(transportCalculation)/parseFloat(mileageSum)*100)),
            //     ],
            //     options: {
            //         colors: ['#A5978B','#5C4742'],
            //         labels: [t('sweep'),t('fleet_info_km_transport')],
            //         chart: {
            //             type: 'donut',
            //         },
            //         legend: {
            //             position: 'bottom',
            //             horizontalAlign: 'left',
            //             show: false,
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         responsive: [{
            //             breakpoint: 480,
            //             options: {
            //                 chart: {
            //                     height: 300,
            //                     width:500
            //                 },
            //                 legend: {
            //                     position: 'bottom',
            //                     show: false,
            //                 }
            //             }
            //         }]
            //     }
            // });
            isSet = true;
        }
        // console.log(serie);
    }, [liveData]);

    if (load) return <FuseLoading />;
  return (
      <Paper className="w-full rounded-20 shadow flex flex-col justify-between">
          <div className="flex items-center justify-between px-4 pt-8">
              {total && <Typography className="text-16 px-16 font-medium" color="textSecondary">
                  {t('overview_menu_km_total')}
              </Typography> }
          </div>

          <div className="text-center py-12">
              <Typography
                  className={
                      clsx(
                          'text-40 font-semibold leading-none tracking-tighter',
                      )} padding={1.8}>
                  {total.value} km
              </Typography>
              <Typography className={
                  clsx(
                      'text-18 font-normal',
                  )}>
                  {position && position.firstSignal && <Moment unix local={"de"} format="DD.MM.YYYY">
                      {position.firstSignal.sec}
                  </Moment>
                  }
              </Typography>
              <Typography className="p-20 pt-0 h-56 flex justify-center items-end text-13 font-medium"
                          color="textSecondary">
                    <span className="truncate">
                         {/*1 minute(s) ago*/}
                        {KocoUtils.formatDate(total.updatedDate, 'HH:mm')}
                    </span>
              </Typography>
          </div>
      {/*<div className="pb-24">*/}
      {/*    {total && <Typography className="h3 font-medium">{t('overview_menu_km_total')} : {(total.value)} km</Typography> }*/}
      {/*</div>*/}

      {/*<div className="h-256 relative">*/}
      {/*    {serie && serie.options && <ReactApexChart height={270} options={serie.options} series={serie.series} type="donut" />}*/}

      {/*</div>*/}

          {/*  {sweep && sweep.value && total && total.date &&<div className="px-12 flex flex-col items-center">*/}
          {/*  <Typography className="h4 font-semibold" color="#5C4742">*/}
          {/*      {t('fleet_info_km_transport')}*/}
          {/*  </Typography>*/}
          {/*  <Typography className="text-11 font-semibold py-8" color="#5C4742">*/}
          {/*      {total.value - sweep.value} km*/}
          {/*  </Typography>*/}

          {/*  <div className="flex flex-row items-start justify-center">*/}
          {/*      <Typography className="h5 px-4 font-semibold" color="#5C4742">*/}
          {/*          <Moment  local={"de"} format="DD.MM.YYYY">*/}
          {/*              {sweep.date}*/}
          {/*          </Moment>*/}
          {/*      </Typography>*/}
          {/*  </div>*/}
          {/*</div>}*/}
         {/*{sweep && sweep.value && sweep.date && <div className="px-12 flex flex-col items-center">*/}
         {/*       <Typography className="h4 font-semibold" color="#A5978B">*/}
         {/*           {t('sweep')}*/}
         {/*       </Typography>*/}
         {/*       <Typography className="text-11 font-semibold py-8" color="#A5978B">*/}
         {/*           {sweep.value} km*/}
         {/*       </Typography>*/}

         {/*       <div className="flex flex-row items-start justify-center">*/}
         {/*           <Typography className="h5 px-4 font-semibold" color="#A5978B">*/}
         {/*               <Moment local={"de"} format="DD.MM.YYYY">*/}
         {/*                   {sweep.date}*/}
         {/*               </Moment>*/}
         {/*           </Typography>*/}
         {/*       </div>*/}
         {/*   </div>*/}
         {/*   }*/}

      </Paper>
  );
}

export default TourInfoSweeperWidget;
