import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StAnalysis = lazy(() => import('./StAnalysis'));

const StAnalysisConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'stqualitydistribution',
      element: <StAnalysis />,
      auth: [authRoles.ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ], 
    },
  ],
};

export default StAnalysisConfig;
