import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getTourPlans = createAsyncThunk('main/settingsTourPlan/tourPlanNew/getTourPlans', async (searchParams) => {
  const response = await axios.get(`api/v1/tourplans`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'tourPlan.settings.tourPlans' } });
  const data = await response.data;

  return { tourplans: data.tourplans, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsTourPlan/tourPlanNew/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getTourPlanTemplate = createAsyncThunk('main/settingsTourPlan/tourPlanNew/getTourPlanTemplate', async () => {
  const response = await axios.get(`api/v1/tourplantemplate`);
  const data = await response.data;

  return data.units;
});

export const postBatchTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlanNew/postBatchTourPlan', async (tourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(tourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'tourplan', row)); });

  try {
    const response = await axios.post(`api/v1/newbatchtourplans`, { tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const splitTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlanNew/splitTourPlan', async (tourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(tourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'tourplan', row)); });

  try {
    const response = await axios.post(`api/v1/splittourplans`, { tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const postBatchTourPlanCheck = createAsyncThunk('main/settingsTourPlan/tourPlanNew/postBatchTourPlanCheck', async (tourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(tourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'tourplan', row)); });

  try {
    const response = await axios.post(`api/v1/newbatchtourplanscheck`, { tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const deleteTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlanNew/deleteTourPlan', async (tourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/tourplans/${tourPlan.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsTourPlan/tourPlanNew/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'tourPlan.settings.tourPlans' } });
  const data = await response.data;

  return data.st_tours;
});

const tourPlansAdapter = createEntityAdapter({});

export const { selectAll: selectTourPlans, selectById: selectTourPlanById } =
  tourPlansAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlanNew.tourPlans);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlanNew.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlanNew.units);

const tourPlanNew = createSlice({
  name: 'main/settingsTourPlan/tourPlanNew',
  initialState: {
    searchText: '',
    loadingTourPlans: false,
    loadingUnits: false,
    savingTourPlan: false,
    loadingStTours: false,
    tourPlans: tourPlansAdapter.getInitialState({ }),
    tourPlansCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    isDataDirty: false,
    hasError : false,
    isErrorInDirtyData: false,
    dataTourPlans: [],
    rowModesModel: {},
    errorOnSave: null,

  },
  reducers: {
    setTourPlansSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setHasError: {
      reducer: (state, action) => {
        state.hasError = action.payload;
      },
    },
    setIsErrorInDirtyData: {
      reducer: (state, action) => {
        state.isErrorInDirtyData = action.payload;
      },
    },    
    setDataTourPlans: {
      reducer: (state, action) => {
        state.dataTourPlans = action.payload;
      },
    },
    setRowModesModel: {
      reducer: (state, action) => {
        state.rowModesModel = action.payload;
      },
    },
    resetTourPlansNew: {
      reducer: (state, action) => {
        state.errorOnSave = null;
        state.isDataDirty = false;
        tourPlansAdapter.removeAll(state.tourPlans);
        state.loadingTourPlans = false;
        state.savingTourPlan = false;
        state.rowModesModel = {};
        state.isErrorInDirtyData = false;
        state.hasError = false;
        state.dataTourPlans = [];
      },
    },
  },
  extraReducers: {
    [getTourPlans.fulfilled](state, { payload }) {
      tourPlansAdapter.setAll(state.tourPlans, payload.tourplans);
      state.tourPlansCount = payload.totalcount;
      state.loadingTourPlans = false;
    },
    [getTourPlans.pending]: (state) => {
      state.loadingTourPlans = true;
      tourPlansAdapter.removeAll(state.tourPlans);
    },
    [getTourPlans.rejected]: (state) => {
      state.loadingTourPlans = false;
    },

    [postBatchTourPlan.fulfilled](state, { payload }) {
      state.savingTourPlan = false;
      state.isDataDirty = false;
      // state.dataTourPlans = [];
    },
    [postBatchTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingTourPlan = true;
    },
    [postBatchTourPlan.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.savingTourPlan = false;
    },

    [splitTourPlan.fulfilled](state, { payload }) {
      state.savingTourPlan = false;
      state.isDataDirty = false;
      // state.dataTourPlans = [];
    },
    [splitTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingTourPlan = true;
    },
    [splitTourPlan.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.savingTourPlan = false;
    },

    [postBatchTourPlanCheck.fulfilled](state, { payload }) {
      state.checkingTourPlan = false;
    },
    [postBatchTourPlanCheck.pending](state, { payload }) {
      state.errorOnSave = null;
      state.checkingTourPlan = true;
    },
    [postBatchTourPlanCheck.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.checkingTourPlan = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getTourPlanTemplate.fulfilled](state, { payload }) {
      state.downloadingTemplate = false;
    },
    [getTourPlanTemplate.pending]: (state) => {
      state.downloadingTemplate = true;
    },
    [getTourPlanTemplate.rejected]: (state) => {
      state.downloadingTemplate = false;
    },
    
  },
});

export const { 
  setTourPlansSearchText, 
  setHasError, 
  setIsErrorInDirtyData, 
  setIsDataDirty, 
  setDataTourPlans,
  setRowModesModel,
  resetTourPlansNew } = tourPlanNew.actions;

export default tourPlanNew.reducer;
