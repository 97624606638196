import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PassiveVehicleGroup = lazy(() => import('./ErrorGroup/ErrorGroup'));
const PassiveVehicleGroups = lazy(() => import('./ErrorGroups/ErrorGroups'));

const SettingsErrorGroupConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'errorgroups',
      element: <PassiveVehicleGroups />,
    },
    {
      path: 'errorgroups/:errorGroupId/*',
      element: <PassiveVehicleGroup />,
    },
  ],
};

export default SettingsErrorGroupConfig;