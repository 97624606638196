import { authRoles } from 'app/auth';
import { lazy } from 'react';

const UnitDetail = lazy(() => import('./UnitDetail'));

const UnitDetailConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_USER_READ],
  routes: [
    {
      path: 'unit/:id/*',
      element: <UnitDetail />,
    },
  ],
};

export default UnitDetailConfig;
