import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import moment from "moment";
import _ from '@lodash';
import {unitsScantecSlice} from "./unitsScantec";
import {positionScantecSlice} from "./positionScantec";

const initialState = {
  entities: [],
  load: false,
  green: [],
  red: [],
  yellow: [],
  total:[],
  dates:[], pourcentageGreen:[],
    pourcentageYellow:[],
    pourcentageRed:[],
    allEntites:[],

}

export const getPourcentage = createAsyncThunk('scantec/pourcentage', async (obj) => {
  const {id,date,date2,area,red,yellow} = obj;
  const response = await axios.get(apiUrlConfig.GET_POSITIONS+"/"+id+"/pourcentage", { params: { limit: 0, date:date, endDate:date2,area:area,red:red,yellow:yellow }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const pourcentageScantecSlice = createSlice({
  name: 'scantec/pourcentage',
  initialState,
  reducers: {
      setClearAllGraph: {
          reducer: (state, action) => {
              state.green = [];
              state.yellow = [];
              state.green = [];
              state.red =[];
              state.dates=[];
              state.pourcentageGreen = [];
              state.pourcentageRed = [];
              state.pourcentageYellow = [];
              state.entities=[];
          }
      }
  },
  extraReducers: {
    [getPourcentage.pending]: (state) => {
      state.load = true;
        state.red = []; state.green = []; state.yellow = []; state.gray = [];state.dates = [];
    },
    [getPourcentage.fulfilled]: (state, { payload }) => {
          state.load = false;
          state.entities = payload;
          state.allEntites = payload;
          state.red = _.map(payload, 'totalRed');
          state.green = _.map(payload, 'totalGreen');
          state.yellow = _.map(payload, 'totalYellow');
         // state.gray = _.map(payload, 'totalGray');
          state.total =  _.map(payload, 'total');
          state.pourcentageGreen =  _.map(payload, 'pourcentGreen');
        state.pourcentageRed =  _.map(payload, 'pourcentRed');
        state.pourcentageYellow =  _.map(payload, 'pourcentYellow');
          state.dates = _.map(payload, 'date');
    },
    [getPourcentage.rejected]: (state) => {
      state.load = false;
      state.entities= [];
      state.red = []; state.green = []; state.yellow = []; state.gray = [];state.dates = [];
    },
  },
});
export const postReducer = pourcentageScantecSlice.reducer;
export const {setClearAllGraph} = pourcentageScantecSlice.actions;

export default pourcentageScantecSlice.reducer;