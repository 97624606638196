

export const INSPECTION_TYPE = {
    BODY_INSPECTION: 1,
    LIFTER_INSPECTION: 2,
    CHASSIS_INSPECTION: 3,
    LEGAL_INSPECTION: 4,
};

class InspectionType {
    static getLabel = (type) => {

        switch (type) {
            case 1:
                return 'Body Inspection';
            case 2:
                return 'Lifter Inspection';
            case 3:
                return 'Chassis Inspection';
            case 4:
                return 'Legal Inspection';
            default:
                return '-';
        }
    };

    static getTranslation = (type) => {

        switch (type) {
            case 1:
                return 'service_book_body_inspection';
            case 2:
                return 'service_book_lifter_inspection';
            case 3:
                return 'service_book_chassis_inspection';
            case 4:
                return 'service_book_legal_inspection';
            default:
                return '-';
        }

    };
}

export default InspectionType;

