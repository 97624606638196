import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import authRoles from "../../auth/authRoles";

const StTourNewTable = lazy(() => import('./inserTour/StTourNew'));
const StTour = lazy(() => import('./stTour/StTour'));


const SettingsStTourConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'sttours',
      element: <StTour />,
      auth: [authRoles.ROLE_ST_TOUR_READ],
    },
    {
      path: 'stinsertTour/new',
      element: <StTourNewTable mode="new" />,
      auth: [authRoles.ROLE_ST_TOUR_WRITE],
    },
    {
      path: 'stinsertTour/import',
      element: <StTourNewTable mode="import" />,
      auth: [authRoles.ROLE_ST_TOUR_WRITE],
    }

  ],
};

export default SettingsStTourConfig;
