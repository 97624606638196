import L from "leaflet";
import { useEffect } from "react";
import "../css/legend.css";
import {useTranslation} from "react-i18next";


function LegendTrucks({ map }) {
    console.log(map);
    const { t } = useTranslation('common');
    useEffect(() => {
        if (map) {
            const legend = L.control({ position: "bottomright" });
            if (document.getElementsByClassName('legend').length == 0) {
                legend.onAdd = function () {

                    var div = L.DomUtil.create('div', 'info legend'),
                        grades = ["FAUN VARIOPRESS",
                            "FAUN ROTOPRESS",
                            "FAUN SIDEPRESS",
                            "FAUN FRONTPRESS",
                            "FAUN POWERPRESS",
                            ],
                        grades2 = [
                            "ZÖLLER MEDIUM",
                            ],
                        grades3 =  [
                            "FAUN VIAJET",
                        ],
                        labels = ["/assets/images/vehicles/avariopress.png",
                            "/assets/images/vehicles/arotopress.png",
                            "/assets/images/vehicles/asidepress.png",
                            "/assets/images/vehicles/bfrontpress.png",
                            "/assets/images/vehicles/bpowerpress.png",
                            "/assets/images/vehicles/MEDIUM.PNG",
                            "/assets/images/vehicles/asweeper2.png",
                            "/assets/images/vehicles/zoellerSLF.png"
                        ];

                    // loop through our density intervals and generate a label with a colored square for each interval
                    for (var i = 0; i < grades.length; i++) {
                        div.innerHTML +=
                            (" <img src=" + labels[i] + " height='17' width='30'>")+ grades[i]+ '<br>';
                    }
                    div.innerHTML +=
                        (" <img  src=" + labels[5] + " height='17' width='30'>")+ grades2[0]+ '<br>';
                    div.innerHTML +=
                        (" <img style='    margin-left: 4px;\n" +
                            "    margin-right: 9Px;' src=" + labels[6] + " height='17' width='25'>")+ grades3[0]+ '<br>';

                    return div;
                };

                legend.addTo(map);
            }
        }
    }, [map]); //here add map
    return null;
}

export default LegendTrucks;