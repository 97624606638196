import { combineReducers } from '@reduxjs/toolkit';
import company from './CompanySlice';
import companies from './CompaniesSlice';

const reducer = combineReducers({
  companies,
  company,
});

export default reducer;
