import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from '@lodash';
import {fleetMarkersSlice} from "../../fleetTracking/store/FleetTrackingMarkerSlice";

export const getSignals = createAsyncThunk('main/cockpit/unitDetail/getSignals', async (obj, { getState }) => {
    const {type,unit,lng} = obj;
    const response = await axios.get(`api/v1/units/${unit.id}/all`,{ params: { 'type': type, 'lng': lng}, fields: 'signalId, unit, unitEn, signalName, date, value' });
    const data = await response.data;
    if (!data)
      return;

    return data.signals;
  });

const SignalsSlice = createSlice({
  name: 'main/cockpit/unitDetail/signals',
  initialState: {
    loading: true,
    data : [],
    allSignals: [],
    all:[],
    searchText: ''
  },
  reducers: {
      setSearchSignals: {
          reducer: (state, action) => {
              //console.log( action.payload);
              //console.log( action);
              state.searchText = action.payload;
              state.allSignals = state.all.filter(marker => marker.signalName.toLowerCase().indexOf(action.payload.toLowerCase()) !== -1);
          },
      prepare: (event) => ({ payload: event.target.value || '' }),
      },
      setClearLogs: {
          reducer: (state, action) => {
              state.data = [];
              state.allSignals =  [];
              state.all = []
          }
      }
  },

  extraReducers: {
    [getSignals.fulfilled](state, { payload }) {
        state.data = payload;
        state.loading = false;
        state.allSignals = _.union(payload, state.allSignals);
        state.all = _.union(payload, state.allSignals);
    },
    [getSignals.pending](state, { payload }) {
      state.data = [];
      state.loading = true;
    },
    [getSignals.rejected](state, { payload }) {
      state.data = [];
      state.loading = false;
    },
  },
});

export const {setSearchSignals,setClearLogs} = SignalsSlice.actions;

export default SignalsSlice.reducer;
