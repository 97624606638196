import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const EventInfo = lazy(() => import('./event-info/EventInfo'));
const EventInfos = lazy(() => import('./event-infos/EventInfos'));

const SettingsEventInfoConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'eventinfos',
      element: <EventInfos />,
    },
    {
      path: 'eventinfos/:eventInfoId/*',
      element: <EventInfo />,
    },
  ],
};

export default SettingsEventInfoConfig;
