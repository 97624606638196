import { combineReducers } from '@reduxjs/toolkit';
import stRegions from './StRegionsSlice';
import stRegion from './StRegionSlice';

const reducer = combineReducers({
  stRegions,
  stRegion
});

export default reducer;
