import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FosService from 'app/services/fosService';

export const getUnits = createAsyncThunk('main/settingsUnit/units/getUnits', async () => {
  const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

  const response = await axios.get(`api/v1/units`, { params: params, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;
  FosService.setLoggedIn(false);
  return data.units;
});

export const getUnitsByCompany = createAsyncThunk('main/settingsUnit/units/getUnitsByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/passivevehicles`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;
  console.log('getUnitsByCompany', response.data);
  if (!data || !data.passivevehiclecompanyaccesses)
    return [];

  return data.passivevehiclecompanyaccesses.map(access => {
    return {
      ...access.passive_vehicle,
      fleetTrackingStatus: access.fleetTrackingStatus,
      jiraFaunCompleteStatus: access.jiraFaunCompleteStatus,
      reportsStatus: access.reportsStatus,
      serviceDashboardStatus: access.serviceDashboardStatus,
      ecotecFleetStatus: access.ecotecFleetStatus,
      tourAnalyzeStatus: access.tourAnalyzeStatus,
      tourAnalyse2: access.tourAnalyse2,
    }
  });
});

export const getCompanies = createAsyncThunk('main/settingsUnit/units/getCompanies', async () => {
  const response = await axios.get(`api/v1/companies`, { params: { limit: 0 } });
  const data = await response.data;

  return data.companies;
});

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsUnit.units.units);

const companiesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectRoleById } =
companiesAdapter.getSelectors((state) => state.main.settingsUnit.units.companies);

const unitsSlice = createSlice({
  name: 'main/settingsUnit/units',
  initialState: {
    searchText: '',
    loadingUnits: false,
    loadingCompanies: false,
    units: unitsAdapter.getInitialState({ }),
    companies: companiesAdapter.getInitialState({ }),
  },
  reducers: {
    setUnitsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getCompanies.fulfilled](state, { payload }) {
      companiesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      companiesAdapter.removeAll(state.companies);
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },

    [getUnitsByCompany.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnitsByCompany.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
      state.loadingUnits = false;
    },
    [getUnitsByCompany.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const { setUnitsSearchText } = unitsSlice.actions;

export default unitsSlice.reducer;
