import { authRoles } from 'app/auth';
import React, { lazy } from 'react';

const StTourMap = lazy(() => import('./StTourMap'));

const StTourMapConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_ST_MAP_VIEW],
  routes: [
    {
      path: '/stmapoverview',
      element: <StTourMap />,
    },
  ],
};

export default StTourMapConfig;