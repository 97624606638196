import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Depot = lazy(() => import('./depot/Depot'));
const Depots = lazy(() => import('./depots/Depots'));

const SettingsDepotConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'depots',
      element: <Depots />,
    },
    {
      path: 'depots/:depotId/*',
      element: <Depot />,
    },
  ],
};

export default SettingsDepotConfig;
