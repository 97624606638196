import { authRoles } from 'app/auth';
import React, { lazy } from 'react';
import Scantec from "./Scantec";

const scantec = lazy(() => import('./Scantec'));

const ScantecConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_SCANTEC_READ],
  routes: [
    {
      path: 'scantec',
      element: <Scantec />,
    },
  ],
};

export default ScantecConfig;