import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  load: false,
  data: { vehicle: false },
};

export const getCalculation = createAsyncThunk(
  "fleetTracking/units/calculation",
  async (obj) => {
    const { id, type } = obj;
    const response = await axios.get(
      apiUrlConfig.GET_UNITS + "/" + id + apiUrlConfig.GET_LINES_CALCULATION,
      { params: { type: type }, headers: { "X-UI-State": "kocobox.overview" } }
    );
    const data = await response.data;
    console.log(data);
    //onsole.log(response);
    return data;
  }
);

export const fleetCalculationSlice = createSlice({
  name: "fleetTracking/units/calculation",
  initialState,
  reducers: {},
  extraReducers: {
    [getCalculation.pending]: (state) => {
      state.load = true;
    },
    [getCalculation.fulfilled]: (state, { payload }) => {
      state.load = false;
      console.log(payload);
      state.data = payload;
    },
    [getCalculation.rejected]: (state) => {
      state.load = false;
    },
  },
});

export const postReducer = fleetCalculationSlice.reducer;
export default fleetCalculationSlice.reducer;
