// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-chart{
    min-height: 115px;
    height: 115px;
}

.apexcharts-tooltip{
    top : 20px !important;
    overflow: visible !important;
}

.leaflet-popup-content {
   margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/main/fleetTracking/css/graphs.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;GACG,oBAAoB;AACvB","sourcesContent":[".empty-chart{\n    min-height: 115px;\n    height: 115px;\n}\n\n.apexcharts-tooltip{\n    top : 20px !important;\n    overflow: visible !important;\n}\n\n.leaflet-popup-content {\n   margin: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
