import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TourAnalysePlan = lazy(() => import('./TourAnalysePlan'));
const TourPlanSplit = lazy(() => import('./tour-plan-split/TourPlanSplit'));
const TourPlanNew = lazy(() => import('./tour-plan-new/TourPlanNew'));

const TourAnalysePlanConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'touranalyseplans',
      element: <TourAnalysePlan />,
      auth: [authRoles.ROLE_TOUR_PLAN_READ],
    },
    {
      path: 'touranalyseplans/new',
      element: <TourPlanNew mode="new" />,
      auth: [authRoles.ROLE_TOUR_PLAN_WRITE], 
    },
    {
      path: 'touranalyseplans/split',
      element: <TourPlanSplit mode="new" />,
      auth: [authRoles.ROLE_TOUR_PLAN_WRITE], 
    },
    {
      path: 'touranalyseplans/import',
      element: <TourPlanNew mode="import" />,
      auth: [authRoles.ROLE_TOUR_PLAN_WRITE], 
    },
  ],
};

export default TourAnalysePlanConfig;
