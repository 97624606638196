import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import moment from "moment";
import _ from '@lodash';
import {unitsScantecSlice} from "./unitsScantec";

const initialState = {
  entities: [],
  filter: [],
  vehicles : [],
  load: false,
  greenPosition : [],
  redPosition : [],
  yellowPosition: [],
  grayPosition:[],
  vehiclePosition:[],
  binsPerWeek:[],
  isFilterSet: false,
  isDataSet: false,
  ids: [],
  decision: 255,
  markerSet : {},
  list:  [],
  selected:{}
}

export const getPositions = createAsyncThunk('scantec/positions', async (obj) => {
  const {id,date,date2,area} = obj;
  const response = await axios.get(apiUrlConfig.GET_POSITIONS+"/"+id+"/region", { params: { limit: 0, date:date, endDate:date2,area:area }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const positionScantecSlice = createSlice({
  name: 'scantec/geofences',
  initialState,
  reducers: {
    setClearAll: {
      reducer: (state, action) => {
        state.isFilterSet = false;
        state.filter =[];
        state.entities=[];
        state.greenPosition = [];
        state.redPosition = [];
        state.yellowPosition = [];
        state.grayPosition =[];
        state.markerSet= {};
        state.binsPerWeek= [];
        state.list= [];
      }
    }
    ,setColorSelected: {
      reducer: (state, action) => {
        if (action.payload) {
          state.isFilterSet = true;
          let newDecision = 127;
            switch (action.payload) {
              case 'Green':
                newDecision = 0;
                break;
              case 'Red':
                newDecision = 2;
                break;
              case 'Yellow':
                newDecision = 1;
                break;
              case 'Gray':
                newDecision= 127;
                break;
            }
            if (newDecision == state.decision) {
                state.decision = 255;
            } else {
              state.decision = newDecision;
            }
            if (state.decision == 255) {
              state.filter = _.filter(state.entities, function (el) {
                return true;
              });
            } else {
              state.filter = _.filter(state.entities, function (el) {
                    if (state.decision == el.decision) {
                      return true;
                    }
                  return false;
                });
            }
        }
        }
    },setIdVehicle: {
      reducer: (state, action) => {
        if (action.payload) {
          //state.ids = action.payload;
          state.isFilterSet = true;
          if (state.decision == 255) {
            state.filter = _.filter(state.entities, function (el) {
              if (action.payload && action.payload.length > 0) {
                if (action.payload.includes(el.vehicleId)) {
                  return true;
                }
                return false;
              }
              return true;
            });
          } else {
            state.filter = _.filter(state.entities, function (el) {
              if (state.ids && state.ids.length > 0) {
                if (state.ids.includes(el.vehicleId)) {
                  if (state.decision == el.decision) {
                    return true;
                  } else if (state.decision == 127 && state.isBioCleanActive == false) {
                    return true;
                  } else {
                    return false;
                  }
                }
                return false;
              }
              if (state.decision == el.decision) {
                return true;
              } else if (state.decision == 127 && state.isBioCleanActive == false) {
                return true;
              } else {
                return false;
              }
            });
          }
        } else {
          state.filter = state.entities;
        }
      },
    }, setMarker: {
      reducer: (state, action) => {
        //state.ids = action.payload;
        if (action.payload) {
          state.isFilterSet = true;
          state.markerSet = action.payload;
        } else {
          state.isFilterSet = false;
          state.markerSet= {};
        }
      }
    },setList: {
      reducer: (state, action) => {
          //state.list = action.payload;
        console.log(action.payload);
        state.isFilterSet= true
        state.list = _.filter(state.entities,function (el) {
            let lng = {lat :el.location.coordinates[1], lng : el.location.coordinates[0]};
            console.log(lng);
            return inBounds(lng, action.payload) == true;
        })
      }
    },
    setMarkers:{
      reducer: (state, action) => {
        //state.list = action.payload;
        console.log(action.payload);
        //state.isFilterSet = true
        //state.list = action.payload
      }
    },setColors:{
      reducer: (state, action) => {
        //state.ids = action.payload;
        if (action.payload) {
          const {yellow, red} = action.payload;
          state.entities = _.each(state.entities, function(el) {
              if (el.sensorValue) {
                 if (el.sensorValue >= red) {
                    el.decision = 2;
                 } else if (el.sensorValue >= yellow) {
                   el.decision = 1;
                 } else {
                   el.decision = 0;
                 }
              }
          });
          state.list = state.entities;
          state.filter = _.filter(state.entities, function(el) {
            if (state.ids && state.ids.length > 0) {
              if (state.ids.includes(el.vehicleId)) {
                return true;
              }
              return false;
            }
              return true;

          });
          state.greenPosition = _.filter(state.entities, function(el) {
            return (el.isBioCleanActive && el.decision == 0);
          })
          state.yellowPosition = _.filter(state.entities, function(el) {
            return (el.isBioCleanActive && el.decision == 1);
          })
          state.redPosition = _.filter(state.entities, function(el) {
            return (el.isBioCleanActive && el.decision == 2);
          })
        } else {

        }
      }
    },
  },
  extraReducers: {
    [getPositions.pending]: (state) => {
      state.load = true
      state.isDataSet= false
      state.ids = [];
      state.list= [];
      state.isFilterSet =false
      state.markerSet = {}
     state.decision = 255;
    },
    [getPositions.fulfilled]: (state, { payload }) => {
      state.decision = 255;
      state.isDataSet=true
          state.load = false
      state.entities = payload.result;
      state.filter = payload.result;
      state.list = payload.result;
      state.isFilterSet = false;
      state.redPosition = payload.red; state.greenPosition = payload.green; state.yellowPosition = payload.yellow;

     /* state.ids = [];
      state.list = payload;
      state.isFilterSet = false;
     // state.vehicles = _.map(_.uniqBy(payload, 'vehicleId'), 'vehicleId');
       _.each(payload, function(el) {
        if (el.isBioCleanActive && el.decision == 0) {
          state.greenPosition.push(el);
        } else if (el.isBioCleanActive && el.decision == 1) {
          state.yellowPosition.push(el);
        } else if (el.isBioCleanActive && el.decision == 2) {
          state.redPosition.push(el);
        }
      })*/
    },
    [getPositions.rejected]: (state) => {
      state.load = false
      state.isDataSet=false
      state.entities= [],
          state.greenPosition =[]
    },
  },
});
export const postReducer = positionScantecSlice.reducer;
export const {setIdVehicle,setMarker,setColors,setColorSelected,setList,setMarkers,setClearAll} = positionScantecSlice.actions;

export default positionScantecSlice.reducer;
function inBounds(point, bounds) {
  var eastBound = point.lng < bounds._northEast.lng;
  var westBound = point.lng > bounds._southWest.lng;
  var inLong;

  if (bounds._northEast.lng < bounds._southWest.lng) {
    inLong = eastBound || westBound;
  } else {
    inLong = eastBound && westBound;
  }

  var inLat = point.lat > bounds._southWest.lat && point.lat < bounds._northEast.lat;
  return inLat && inLong;
}