
const iconMap = {
    'frontpress': process.env.PUBLIC_URL + '/assets/images/vehicles/bfrontpress.png',
    'sidepress': process.env.PUBLIC_URL + '/assets/images/vehicles/asidepress.png',
    'rotopress': process.env.PUBLIC_URL + '/assets/images/vehicles/arotopress.png',
    'powerpress': process.env.PUBLIC_URL + '/assets/images/vehicles/bpowerpress.png',
    'city': process.env.PUBLIC_URL + '/assets/images/vehicles/asweeper2.png',
    'medium': process.env.PUBLIC_URL + '/assets/images/vehicles/MEDIUM.PNG',
    'variopress': process.env.PUBLIC_URL + '/assets/images/vehicles/avariopress.png',
    'sweeper': process.env.PUBLIC_URL + '/assets/images/vehicles/asweeper2.png',
    'sideloader': process.env.PUBLIC_URL + '/assets/images/vehicles/zoellerSLF.png',
};

function getVehicleIcon(constructionType) { 

    if (!constructionType) 
        return '';
    {
        let type = constructionType.toLowerCase();
        let icon = '';

        if (type.indexOf("frontpress") > -1) {
            icon = 'frontpress';
        } else if (type.indexOf("sidepress") > -1) {
            icon = 'sidepress';
        } else if (type.indexOf("sideloader") > -1) {
            icon = 'sideloader';
        } else if (type.indexOf("rotopress") > -1) {
            icon = 'rotopress';
        } else if (type.indexOf("powerpress") > -1) {
            icon = 'powerpress';
        } else if (type.indexOf("city") > -1) {
            icon = 'city';
        } else if (type.indexOf("medium") > -1) {
            icon = 'medium';
        } else if (type.indexOf("micro") > -1) {
            icon = 'medium'; // to be changed
        } else if (type.indexOf("variopress") > -1) {
            icon = 'variopress';
        } else if (type.indexOf("viajet") > -1) {
            icon = 'sweeper';
        } else if (type.indexOf("sk 500") > -1) {
            icon = 'sweeper';
        } else if (type.indexOf("asvac") > -1) {
            icon = 'sweeper';
        } else if (type.indexOf("terra") > -1) {
            icon = 'sweeper';
        } else {
            icon = 'sweeper';
        }

        return iconMap[icon];
    }
};

export default getVehicleIcon;