import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import getChassisManufacturer from 'app/types/chassisManufacturerType';
import ServiceDashboardUtils, { COLUMN_TYPES } from 'app/utils/ServiceDashboardUtils';
import FosService from 'app/services/fosService';

export const getUnits = createAsyncThunk('main/tourAnalysis/units/getUnits', async () => {
    const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

    const response = await axios.get(apiUrlConfig.GET_UNITS, { params: params, headers: { 'X-UI-State': 'kocobox.fleetoverview' } });
    const data = await response.data;
    if (!data)
        return null;
    
    FosService.setLoggedIn(false);
    return data.units;
});

export const getRcvLines = createAsyncThunk('main/tourAnalysis/units/getRcvLines', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/lines`, { params: { date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD') } });
    const data = await response.data;
    if (!data)
        return null;

    return data.lines;
});

export const getSweeperLines = createAsyncThunk('main/tourAnalysis/units/getSweeperLines', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/lines`, { params: { date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD') } });
    const data = await response.data;
    if (!data)
        return null;

    return data.lines;
});

export const getSweeperSweepedLines = createAsyncThunk('main/tourAnalysis/units/getSweeperSweepedLines', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/lines`, { params: { date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD'), sweeper: 1 } });
    const data = await response.data;
    if (!data)
        return null;

    return data.lines;
});

export const getTours = createAsyncThunk('main/tourAnalysis/units/getTours', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/tourkocoboxs`, { params: { date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD') } });
    const data = await response.data;
    if (!data)
        return null;

    return data.tourkocoboxs;
});

export const getPositions = createAsyncThunk('main/tourAnalysis/units/getPositions', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/positions`, { params: { onlyStop: 1, date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD'), isSweeper: (kocotrack.unit.isRcv ? 0 : 1) } });
    const data = await response.data;
    if (!data)
        return null;

    return data.positions;
});

export const getDataGeneral = createAsyncThunk('main/tourAnalysis/units/getDataGeneral', async (kocotrack, { getState }) => {
    const response = await axios.get(`api/v1/units/${kocotrack.unit.id}/signals`, { params: { type: kocotrack.type, date: KocoUtils.formatDate(kocotrack.date, 'YYYY-MM-DD') } });
    const data = await response.data;
    if (!data)
        return null;

    return data.signals;
    // if (kocotrack.type == 1)
    //     return [{ "_id": { "signalId": "20011" }, "dateStringDay": null, "firstSignal": 1, "lastSignal": 1, "count": 31, "diff": 0 }];
    // if (kocotrack.type == 2)
    //     return [{ "_id": { "signalId": "20401" }, "dateStringDay": "2022-05-18", "firstDate": { "sec": 1652848440, "usec": 0 }, "signalId": "20401", "lastDate": { "sec": 1652873040, "usec": 0 }, "firstValue": 21629050, "lastValue": 21717810, "avg": 21667355.229985, "type": 2, "diff": 88760 }, { "_id": { "signalId": "20402" }, "dateStringDay": "2022-05-18", "firstDate": { "sec": 1652848980, "usec": 0 }, "signalId": "20402", "lastDate": { "sec": 1652873040, "usec": 0 }, "firstValue": 11139.5, "lastValue": 11177.5, "avg": 11158.081332892, "type": 3, "diff": 38 }, { "_id": { "signalId": "10003" }, "dateStringDay": "2022-05-18", "firstDate": { "sec": 1652848800, "usec": 0 }, "signalId": "10003", "lastDate": { "sec": 1652872800, "usec": 0 }, "firstValue": 0, "lastValue": 3, "avg": 16.343995227788, "type": 1 }];
    // if (kocotrack.type == 3)
    //     return [{ "_id": null, "firstSignal": { "sec": 1652848417, "usec": 0 }, "lastSignal": { "sec": 1652874181, "usec": 0 }, "firstPosition": { "coords": ["9.032482", "48.521191"] }, "lastPositionn": { "coords": ["9.032516", "48.521191"] }, "firstDate": "06:33", "lastDate": "13:43", "duration": "7 Std :9 mn" }];

});


const initializeGeneralTourKocobox = () => {
    return {
        _id: 0,
        vehicleSpeed: 0,
        startTime: '',
        endTime: '',
        dieselConsumption: 0,
        mileageConsumption: 0,
        leftValue: 0,
        rightValue: 0,
        leftBigValue: 0,
        rightBigValue: 0,
        kickDowns: 0,
        reverse: 0,
        duration: ''
    };
};

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById, selectIds: selectUnitIds } =
    unitsAdapter.getSelectors((state) => { return state.main.tourAnalysis.units; });

const initialState = () => {
    return {
        searchText: '',
        units: unitsAdapter.getInitialState({}),
        loadingUnits: false,
        lines: [],
        loadingLines: false,
        sweepedLines: [],
        loadingSweepedLines: false,
        tourkocobox: [],
        loadingTours: false,
        positions: [],
        loadingPositions: false,
        generalTourKocoBox: initializeGeneralTourKocobox(),
        loadingTourKocoBox: false
    }
};

const TourAnalysisSlice = createSlice({
    name: 'main/tourAnalysis',
    initialState: initialState(),
    reducers: {
        setUnitsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        initializeTourAnalysisSlice: {
            reducer: (state, action) => {
                state.lines = [];
                state.loadingLines = false;
                state.sweepedLines = [];
                state.loadingSweepedLines = false;
                state.tourkocobox = [];
                state.loadingTours = false;
                state.positions = [];
                state.loadingPositions = false;
                state.generalTourKocoBox = initializeGeneralTourKocobox();
                state.loadingTourKocoBox = false;
            },
        },
    },
    extraReducers: {
        [getUnits.pending](state, { payload }) {
            state.loadingUnits = true;
            unitsAdapter.removeAll(state.units);
        },
        [getUnits.fulfilled](state, { payload }) {
            unitsAdapter.setAll(state.units, _.orderBy(
                payload,
                ['constructionType'],
                ['asc']
            ));
            state.loadingUnits = false;
        },
        [getUnits.rejected](state, { payload }) {
            state.loadingUnits = false;
        },

        [getRcvLines.pending](state, { payload }) {
            state.loadingLines = true;
            state.lines = null;
        },
        [getRcvLines.fulfilled](state, { payload }) {
            state.lines = payload;
            state.loadingLines = false;
        },
        [getRcvLines.rejected](state, { payload }) {
            state.loadingLines = false;
        },

        [getSweeperLines.pending](state, { payload }) {
            state.loadingLines = true;
            state.lines = null;
        },
        [getSweeperLines.fulfilled](state, { payload }) {
            state.lines = payload;
            state.loadingLines = false;
        },
        [getSweeperLines.rejected](state, { payload }) {
            state.loadingLines = false;
        },

        [getSweeperSweepedLines.pending](state, { payload }) {
            state.loadingSweepedLines = true;
            state.sweepedLines = null;
        },
        [getSweeperSweepedLines.fulfilled](state, { payload }) {
            state.sweepedLines = payload;
            state.loadingSweepedLines = false;
        },
        [getSweeperSweepedLines.rejected](state, { payload }) {
            state.loadingSweepedLines = false;
        },

        [getTours.pending](state, { payload }) {
            state.loadingTours = true;
            state.tourkocobox = null;
        },
        [getTours.fulfilled](state, { payload }) {

            if(payload && payload.length > 0)
                state.tourkocobox = payload[0];
                
            state.loadingTours = false;
        },
        [getTours.rejected](state, { payload }) {
            state.loadingTours = false;
        },

        [getPositions.pending](state, { payload }) {
            state.loadingPositions = true;
            state.positions = null;
        },
        [getPositions.fulfilled](state, { meta, payload }) {
            state.positions = payload;
            state.loadingPositions = false;
        },
        [getPositions.rejected](state, { payload }) {
            state.loadingPositions = false;
        },

        [getDataGeneral.pending](state, { meta, payload }) {
            const { type } = meta.arg;
            state.loadingDataGeneral = true;
            if (type == 1)
                state.generalTourKocoBox = initializeGeneralTourKocobox();
        },
        [getDataGeneral.fulfilled](state, { meta, payload }) {
            const { type } = meta.arg;

            if (type == 1) {
                if (payload && payload.length > 0) {
                    _.each(payload, function (data) {
                        if (data._id.signalId == 'L03') {
                            state.generalTourKocoBox.leftValue = data.diff;
                        } else if (data._id.signalId == 'L05') {
                            state.generalTourKocoBox.rightValue = data.diff;
                        } else if (data._id.signalId == 'L07' || data._id.signalId == 'L09') {
                            state.generalTourKocoBox.leftBigValue += data.diff;
                        } else if (data._id.signalId == '20011') {
                            state.generalTourKocoBox.reverse = data.count;
                        } else if (data._id.signalId == '20014') {
                            state.generalTourKocoBox.kickDowns = data.count;
                        }
                    });
                }
            }
            else if (type == 2) {
                if (payload && payload.length > 0) {
                    _.each(payload, function (data) {
                        if (data.type == 1) {
                            state.generalTourKocoBox.vehicleSpeed = data.avg;
                        } else if (data.type == 2) {
                            state.generalTourKocoBox.mileage = data.diff;
                        } else {
                            state.generalTourKocoBox.diesel = data.diff;
                        }
                    });
                }
            }
            else if (type == 3) {
                var datas = [];
                _.each(payload, function (data) {
                    if (data.firstSignal.sec > data.lastSignal.sec) {
                        state.generalTourKocoBox.startTime = data.lastDate;
                        state.generalTourKocoBox.endTime = data.firstDate;
                    } else {
                        state.generalTourKocoBox.startTime = data.firstDate;
                        state.generalTourKocoBox.endTime = data.lastDate;
                    }
                    state.generalTourKocoBox.duration = data.duration;

                    datas.push(new L.LatLng(data.firstPosition.coords[1], data.firstPosition.coords[0]));
                    datas.push(new L.LatLng(data.lastPositionn.coords[1], data.lastPositionn.coords[0]));
                    // mapDisplay.fitBounds(datas);

                });
            }
            state.loadingDataGeneral = false;
        },
        [getDataGeneral.rejected](state, { payload }) {
            state.loadingDataGeneral = false;
        },
    },
});

export const { setUnitsSearchText, initializeTourAnalysisSlice } = TourAnalysisSlice.actions;

export default TourAnalysisSlice.reducer;
