import _ from "@lodash";
import { styled, ThemeProvider, useTheme, alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { default as React, memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { selectContrastMainTheme } from "app/store/fuse/settingsSlice";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Input from "@mui/material/Input";
import { useTranslation } from "react-i18next";
import { setMarker } from "../store/FleetTrackingMarkerSlice";
import {
  setSearch,
  setSelectedVehicleGroups,
  selectPassiveVehicleGroups,
  getPassiveVehicleGroups,
} from "../store/FleetTrackingSlice";
import Paper from "@mui/material/Paper";
import { selectMainTheme } from "../../../store/fuse/settingsSlice";
import { Autocomplete, MenuItem, TextField } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  color: theme.palette.primary.contrastText,
}));

function HeaderWidget(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );
  const data = _.merge({}, props.data);
  const mainTheme = useSelector(selectMainTheme);
  const {
    loadingPassiveVehicleGroups,
    selectedVehicleGroups,
    unitFleetTracking: { searchEntities, isMap },
  } = useSelector(({ main }) => main.fleetTracking);
  const passiveVehicleGroups = useSelector(selectPassiveVehicleGroups);

  useEffect(() => {
    if (!loadingPassiveVehicleGroups) dispatch(getPassiveVehicleGroups());
  }, [dispatch]);

  useEffect(() => {
    console.log("use effect searchEntities called");
    let ids = [];
    if (isMap == false && searchEntities && searchEntities.length > 0) {
      ids = searchEntities.map((ent) => ent.id);
      dispatch(setMarker(ids));
    }
  }, [searchEntities]);

  _.setWith(data, "options.fill.colors", [theme.palette.secondary.main]);
  _.setWith(data, "options.markers.colors", [theme.palette.secondary.main]);
  _.setWith(data, "options.stroke.colors", [
    theme.palette.primary.contrastText,
  ]);
  _.setWith(data, "options.markers.strokeColors", [
    theme.palette.primary.contrastText,
  ]);
  _.setWith(
    data,
    "options.grid.borderColor",
    alpha(theme.palette.primary.contrastText, 0.3)
  );

  const { t } = useTranslation("common");

  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
    // dispatch(setMarker(e.target.value));
    dispatch(setSearch(e.target.value));
  };
  return (
    <ThemeProvider theme={contrastTheme}>
      <Root>
        <div className="container relative flex flex-col sm:flex-row justify-between items-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className="flex items-center">
              <Icon
                component={motion.span}
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
                className="text-24 md:text-32"
              >
                gps_fixed
              </Icon>
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-16 md:text-24 mx-12 font-semibold"
              >
                {t("menu_fleet_tracking")}
              </Typography>
            </div>
          </motion.div>
          <div className="flex flex-1 items-end justify-end px-12">
            <ThemeProvider theme={mainTheme}>
              <Paper
                component={motion.div}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex items-end w-full max-w-512 px-8 py-4 rounded-4 shadow"
              >
                <Icon color="action">search</Icon>

                <div className="grid grid-cols-3">
                  {
                    <Autocomplete
                      noOptionsText={t('layout_default_no_options')}
                      clearText={t('layout_default_clear')}
                      multiple
                      size="small"
                      className="mx-8 col-span-2"
                      getOptionLabel={(option) => (option ? option.name : "")}
                      onChange={(event, value) => {
                        dispatch(setSelectedVehicleGroups(value));
                      }}
                      id="passiveVehicleGroups"
                      options={passiveVehicleGroups}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedVehicleGroups}
                      renderOption={(props, option) => (
                        <MenuItem
                          key={option.id}
                          {...props}
                          value="Europe/Berlin"
                        >{`${option.name}`}</MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"

                          // label={t('')}
                        />
                      )}
                    />
                  }

                  <Input
                    placeholder={t("layout_default_search")}
                    className="mx-8 col-span-1"
                    disableUnderline
                    fullWidth
                    value={inputValue}
                    inputProps={{
                      "aria-label": "Search",
                    }}
                    onChange={handleChange}
                  />
                </div>
              </Paper>
            </ThemeProvider>
          </div>
        </div>
      </Root>
    </ThemeProvider>
  );
}

export default HeaderWidget;
