import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {setFilterDate} from "../../fleetTracking/store/FleetTrackingConsumptionData";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import _ from '@lodash';
import {getTrand, setFilterTrend} from "../store/trendPositionScantec";
function SkeltonWidgetLeft(props) {
    let { trends, loadTrend} = useSelector(({ main }) => main.scantec.trandScantec);
    let {load, markerSet } = useSelector(({ main }) => main.scantec.positionScantec);
    const { t } = useTranslation('common');
    let dispatch = useDispatch();
    let [serie, setSerie] = useState({});
    var filter = [{"id" : 1, "label" :t('last_tour_4')},
        {"id" : 2, "label" :t('last_tour_8')}
    ];
    const [tabValue, setTabValue] = useState(null);
    function setFilter(ev) {
        setTabValue(ev.target.value);
        console.log(ev);
        dispatch(setFilterTrend(ev.target.value));
    }
    function getDecision (el) {
        if (el > props.red) {
            return t('scan_tec_bin_red')
        } else if (el > props.yellow) {
            return t('scan_tec_bin_yellow')
        } else {
            return t('scan_tec_bin_green')
        }
    }
    function generateData(trends,type) {
        var series = [];let y= 0;
        _.each(trends, function (el) {
            if (type == 1) {
               // y = moment(el.date.sec * 1000;
               y= moment.unix(el.date.sec).format("DD.MM.YYYY")
            } else {
                y =el.sensorValue;
            }
            series.push(y);
        });
        return series;
    }
    console.log("eeerzeazea");
    useEffect(() => {
        if (trends && trends.length >0) {
            if (trends.length <= 4) {
                setTabValue(1);
            }
            setSerie({
                series: [
                    {
                        name: t('scantec_result'),
                        data: generateData(trends,0)
                    }
                ], options: {
                    chart: {
                        height: 110,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 15,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#666d78'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        padding: {
                            left: 15,
                            right:15
                        },
                        margin: {
                            left:10
                        }

                    },
                    markers: {
                        size: 1
                    },
                    padding: {
                        left: 15,
                        right:15
                    },
                    xaxis: {
                        type: 'string',
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: true
                        },
                        categories:generateData(trends,1),
                    },
                    tooltip: {
                        y: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                return getDecision(value)
                            }
                        }

                    },
                    yaxis: {
                        title: {
                            text: 'Temperature',
                            show:false
                        },
                        show:false
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: 0,
                        offsetX: 0,
                    }
                }
            });
        }
        console.log('come here');
        console.log(trends);
        //setTabValue(1);
        if (markerSet.binId && markerSet.binId != "-" && trends.length == 0 && loadTrend == false) {
         //   dispatch(getTrand({binid : markerSet.binId, date :props.date}));
        }
    }, [trends,markerSet]);
    if (load) {
        return <FuseLoading/>;
    }
    return (
        <Card className="w-full rounded-20 shadow p-20 big-graph new-card-filter trend">
            <div className="relative p-20 flex flex-row items-center justify-between new-card-filter">
                <div className="flex flex-col">
                    <Typography className="text-16 px-16 font-medium" color="textSecondary">{t('scantec_trend')}</Typography>
                </div>

                <div className="flex flex-row items-center">
                    <div>
                        <FormControl className="" variant="filled">
                            <Select
                                classes={{ select: 'py-8' }}
                                value={tabValue}
                                onChange={(ev) => setFilter(ev)}
                            >
                                {filter.map((key) => (
                                    <MenuItem key={key.id} value={key.id}>
                                        {key.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>


            <div className="h-128 relative">
                {trends && trends.length > 0 && <div className="relative h-300 sm:h-320 sm:pb-16">
                    {serie && serie.options && <ReactApexChart
                        options={serie.options}
                        series={serie.series}
                        type="line"
                        height={120}
                    />}
                </div>
                }
                {trends && trends.length == 0 &&  <div className="relative h-300 sm:h-320 sm:pb-16">
                    <Typography className="text-16 px-16 font-medium" color="textSecondary">{t('cockpit_tab_default_not_available')}</Typography>
                </div>}
            </div>
        </Card>
    );
}

export default SkeltonWidgetLeft;
