import L from "leaflet";
import {useEffect, useRef} from "react";
import "../../fleetTracking/css/legend.css";
import {useTranslation} from "react-i18next";
import {useMap} from "react-leaflet";


function Legend({ map }) {
    console.log(map);
    let legendRef = useRef();
    const { t } = useTranslation('common');
    useEffect(() => {
        console.log(legendRef.current);
        if (map && legendRef.current == null) {
            const legend = L.control({ position: "bottomright" });
            if (document.getElementsByClassName('legend').length == 0) {
                legend.onAdd = function () {

                    var div = L.DomUtil.create('div', 'info legend'),
                        grades = [t('scan_tec_bin_yellow'), t('scan_tec_bin_red'), t('scan_tec_bin_green')],
                        labels = [
                            "/assets/images/yellow-circle.png",
                            "/assets/images/red-circle.png",
                            "/assets/images/green-circle.png"];

                    // loop through our density intervals and generate a label with a colored square for each interval
                    for (let i = 0; i < grades.length; i++) {
                        div.innerHTML += (" <img src=" + labels[i] + " height='15' width='15'>")+ grades[i]+ '<br>';
                    }

                    return div;
                };
                if (legend) {
                    try {
                        legend.addTo(map);
                        legendRef.current = legend;
                    } catch (Exception) {

                    }
                }
            }
        }
    }, [map]); //here add map
    return null;
}

export default Legend;