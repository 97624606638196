import { authRoles } from 'app/auth';
import React, { lazy } from 'react';
import Api from "./Api";
import GettingStartedDocRoutes from "./getting-started/GettingStartedDocRoutes";
import IntroductionRoutes from "./first-interface/GettingStartedDocRoutes";

const api = lazy(() => import('./ApiContent'));

const ApiConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_UNIT_READ],
  routes: [
    {
      path: 'documentation',
      element: <Api />,
      children: [
      ...GettingStartedDocRoutes,
          ...IntroductionRoutes
      ]
   }
  ],
};

export default ApiConfig;