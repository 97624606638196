
const ParameterCategoriesSP = [

    { 'value': 0, 'from': 0, 'to': 99, 'count': 0, 'label': '00 - General config' },
    { 'value': 1, 'from': 100, 'to': 199, 'count': 0, 'label': '01 - Chassis config' },
    { 'value': 2, 'from': 200, 'to': 299, 'count': 0, 'label': '02 - Display config (1)' },
    { 'value': 3, 'from': 300, 'to': 399, 'count': 0, 'label': '03 - Display config (2)' },
    { 'value': 4, 'from': 400, 'to': 499, 'count': 0, 'label': '04 - Compaction' },
    { 'value': 5, 'from': 500, 'to': 599, 'count': 0, 'label': '05 - Wastes packing' },
    { 'value': 6, 'from': 600, 'to': 699, 'count': 0, 'label': '06 - Emptying' },
    { 'value': 7, 'from': 700, 'to': 799, 'count': 0, 'label': '07 - Greasing' },
    { 'value': 8, 'from': 800, 'to': 899, 'count': 0, 'label': '08 - Footstep detection' },
    { 'value': 9, 'from': 900, 'to': 999, 'count': 0, 'label': '09 - Weighing General' },
    { 'value': 10, 'from': 1000, 'to': 1099, 'count': 0, 'label': '10 - Faun D2 Weighing' },
    { 'value': 11, 'from': 1100, 'to': 1199, 'count': 0, 'label': '11 - Hydraulic' },
    { 'value': 12, 'from': 1200, 'to': 1299, 'count': 0, 'label': '12 - Crane' },
    { 'value': 13, 'from': 1300, 'to': 1399, 'count': 0, 'label': '13 - Interface EN1501-5' },
    { 'value': 14, 'from': 1400, 'to': 1499, 'count': 0, 'label': '14 - Twin General' },
    { 'value': 15, 'from': 1500, 'to': 1599, 'count': 0, 'label': '15 - Twin speeds' },
    { 'value': 16, 'from': 1600, 'to': 1699, 'count': 0, 'label': '16 - UniTr2 General' },
    { 'value': 17, 'from': 1700, 'to': 1799, 'count': 0, 'label': '17 - UniTr2 speeds' },
    { 'value': 18, 'from': 1800, 'to': 1899, 'count': 0, 'label': '18 - UniRr General' },
    { 'value': 19, 'from': 1900, 'to': 1999, 'count': 0, 'label': '19 - UniRr speeds' },
    { 'value': 20, 'from': 2000, 'to': 2099, 'count': 0, 'label': '20 - CEV-OKS beam general' },
    { 'value': 21, 'from': 2100, 'to': 2199, 'count': 0, 'label': '21 - CEV-OKS beam speeds' },
    { 'value': 22, 'from': 2200, 'to': 2299, 'count': 0, 'label': '22 - OKS comb General' },
    { 'value': 23, 'from': 2300, 'to': 2399, 'count': 0, 'label': '23 - OKS comb speeds' },
    { 'value': 24, 'from': 2400, 'to': 2499, 'count': 0, 'label': '24 - UniPw General' },
    { 'value': 25, 'from': 2500, 'to': 2599, 'count': 0, 'label': '25 - UniPw speeds' },
    { 'value': 26, 'from': 2600, 'to': 2699, 'count': 0, 'label': '26 - Powerlift General' },
    { 'value': 27, 'from': 2700, 'to': 2799, 'count': 0, 'label': '27 - Powerlift speeds' },
    { 'value': 28, 'from': 2800, 'to': 2899, 'count': 0, 'label': '28 - Tailift General' },
    { 'value': 29, 'from': 2900, 'to': 2999, 'count': 0, 'label': '29 -  Tailift speeds' },
    { 'value': 30, 'from': 3000, 'to': 3099, 'count': 0, 'label': '30 -  FAUN Barlift' },
    { 'value': 39, 'from': 3900, 'to': 3999, 'count': 0, 'label': '39 - Energy recovery' },
    { 'value': 40, 'from': 4000, 'to': 4099, 'count': 0, 'label': '40 - Service intervals' },
    { 'value': 41, 'from': 4100, 'to': 4199, 'count': 0, 'label': '41 - Forcing outputs' },
    { 'value': 42, 'from': 4200, 'to': 4299, 'count': 0, 'label': '42 - Reserved Forcing outputs ' },
    { 'value': 43, 'from': 4300, 'to': 4399, 'count': 0, 'label': '43 - Sympapress' },
    { 'value': 44, 'from': 4400, 'to': 4499, 'count': 0, 'label': '44 - no title' },
    { 'value': 45, 'from': 4500, 'to': 4599, 'count': 0, 'label': '45 - no title' },
    { 'value': 46, 'from': 4600, 'to': 4699, 'count': 0, 'label': '46 - no title' },
    { 'value': 47, 'from': 4700, 'to': 4799, 'count': 0, 'label': '47 - no title' },
    { 'value': 48, 'from': 4800, 'to': 4899, 'count': 0, 'label': '48 - no title' },
    { 'value': 49, 'from': 4900, 'to': 4999, 'count': 0, 'label': '49 - no title' },
    { 'value': 50, 'from': 5000, 'to': 5099, 'count': 0, 'label': '50 - no title' },
    { 'value': 51, 'from': 5100, 'to': 5199, 'count': 0, 'label': '51 - no title' },
    { 'value': 52, 'from': 5200, 'to': 5299, 'count': 0, 'label': '52 - no title' },
    { 'value': 53, 'from': 5300, 'to': 5399, 'count': 0, 'label': '53 - no title' },
    { 'value': 54, 'from': 5400, 'to': 5499, 'count': 0, 'label': '54 - no title' },
    { 'value': 55, 'from': 5500, 'to': 5599, 'count': 0, 'label': '55 - no title' },
    { 'value': 56, 'from': 5600, 'to': 5699, 'count': 0, 'label': '56 - no title' },
    { 'value': 57, 'from': 5700, 'to': 5799, 'count': 0, 'label': '57 - no title' },
    { 'value': 58, 'from': 5800, 'to': 5899, 'count': 0, 'label': '58 - no title' },
    { 'value': 59, 'from': 5900, 'to': 5999, 'count': 0, 'label': '59 - no title' },
    { 'value': 60, 'from': 6000, 'to': 6099, 'count': 0, 'label': '60 - no title' },
    { 'value': 61, 'from': 6100, 'to': 6199, 'count': 0, 'label': '61 - no title' },
    { 'value': 62, 'from': 6200, 'to': 6299, 'count': 0, 'label': '62 - no title' },
    { 'value': 63, 'from': 6300, 'to': 6399, 'count': 0, 'label': '63 - no title' },
    { 'value': 64, 'from': 6400, 'to': 6499, 'count': 0, 'label': '64 - no title' },
    { 'value': 65, 'from': 6500, 'to': 6599, 'count': 0, 'label': '65 - no title' },
    { 'value': 66, 'from': 6600, 'to': 6699, 'count': 0, 'label': '66 - no title' },
    { 'value': 67, 'from': 6700, 'to': 6799, 'count': 0, 'label': '67 - no title' },
    { 'value': 68, 'from': 6800, 'to': 6899, 'count': 0, 'label': '68 - no title' },
    { 'value': 69, 'from': 6900, 'to': 6999, 'count': 0, 'label': '69 - no title' },
    { 'value': 70, 'from': 7000, 'to': 7099, 'count': 0, 'label': '70 - no title' },
    { 'value': 71, 'from': 7100, 'to': 7199, 'count': 0, 'label': '71 - no title' },
    { 'value': 72, 'from': 7200, 'to': 7299, 'count': 0, 'label': '72 - no title' },
    { 'value': 73, 'from': 7300, 'to': 7399, 'count': 0, 'label': '73 - no title' },
    { 'value': 74, 'from': 7400, 'to': 7499, 'count': 0, 'label': '74 - no title' },
    { 'value': 75, 'from': 7500, 'to': 7599, 'count': 0, 'label': '75 - no title' },
    { 'value': 76, 'from': 7600, 'to': 7699, 'count': 0, 'label': '76 - no title' },
    { 'value': 77, 'from': 7700, 'to': 7799, 'count': 0, 'label': '77 - no title' },
    { 'value': 78, 'from': 7800, 'to': 7899, 'count': 0, 'label': '78 - no title' },
    { 'value': 79, 'from': 7900, 'to': 7999, 'count': 0, 'label': '79 - no title' },
    { 'value': 80, 'from': 8000, 'to': 8099, 'count': 0, 'label': '80 - no title' },
    { 'value': 81, 'from': 8100, 'to': 8199, 'count': 0, 'label': '81 - no title' },
    { 'value': 82, 'from': 8200, 'to': 8299, 'count': 0, 'label': '82 - no title' },
    { 'value': 83, 'from': 8300, 'to': 8399, 'count': 0, 'label': '83 - no title' },
    { 'value': 84, 'from': 8400, 'to': 8499, 'count': 0, 'label': '84 - no title' },
    { 'value': 85, 'from': 8500, 'to': 8599, 'count': 0, 'label': '85 - CleanOpen' },
    { 'value': 86, 'from': 8600, 'to': 8699, 'count': 0, 'label': '86 - no title' },
    { 'value': 87, 'from': 8700, 'to': 8799, 'count': 0, 'label': '87 - no title' },
    { 'value': 88, 'from': 8800, 'to': 8899, 'count': 0, 'label': '88 - no title' },
    { 'value': 89, 'from': 8900, 'to': 8999, 'count': 0, 'label': '89 - no title' },
    { 'value': 90, 'from': 9000, 'to': 9099, 'count': 0, 'label': '90 - no title' },
    { 'value': 91, 'from': 9100, 'to': 9199, 'count': 0, 'label': '91 - no title' },
    { 'value': 92, 'from': 9200, 'to': 9299, 'count': 0, 'label': '92 - no title' },
    { 'value': 93, 'from': 9300, 'to': 9399, 'count': 0, 'label': '93 - no title' },
    { 'value': 94, 'from': 9400, 'to': 9499, 'count': 0, 'label': '94 - no title' },
    { 'value': 95, 'from': 9500, 'to': 9599, 'count': 0, 'label': '95 - no title' },
    { 'value': 96, 'from': 9600, 'to': 9699, 'count': 0, 'label': '96 - no title' },
    { 'value': 97, 'from': 9700, 'to': 9799, 'count': 0, 'label': '97 - no title' },
    { 'value': 98, 'from': 9800, 'to': 9899, 'count': 0, 'label': '98 - no title' },
    { 'value': 99, 'from': 9900, 'to': 9999, 'count': 0, 'label': '99 - Options' },
    { 'value': 100, 'from': 10000, 'to': 10099, 'count': 0, 'label': '100 - no title' }
];

export default ParameterCategoriesSP;



