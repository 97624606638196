import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function Impressum() {
    return (
        <div style={{"padding-left":"2Opx !important","margin-top":"40px"}} className="flex-1 items-center sm:justify-center px-16 lg:px-12">

            <Typography variant="h6" color="inherit" className="font-medium mb-16">
                Imprint KOCO
            </Typography>
            <Typography className="mb-16" component="p">
                KOCO Solutions GmbH
            </Typography>
            <Typography className="mb-16" component="p">
                Robert-Bosch-Strasse 7
            </Typography>
            <Typography className="mb-16" component="p">
                D-64293 Darmstadt
            </Typography>
            <Typography className="mb-16" component="p">
                Tel.: +49 174 345 236 5
            </Typography>
            <Typography className="mb-16" component="p">
                Fax:
            </Typography>
            <Typography className="mb-16" component="p">
                E-mail: maltesonnenburg@kocofleet.com
            </Typography>
            <Typography className="mb-16" component="p">
                HRB 91850 Darmstadt District Court
            </Typography>
            <Typography className="mb-16" component="p">
                VAT ID number DE281797923
            </Typography>
            <Typography className="mb-16" component="p">
                Tax NR. 00723725220
            </Typography>
            <Typography className="mb-16" component="p">
                Represented by:
            </Typography>
            <Typography className="mb-16" component="p">
                Malte Sonnenburg (Managing Director)
            </Typography>
            <Link className="font-normal" to="/">
                Go back to dashboard
            </Link>

        </div>
    );
}

export default Impressum;