import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";

export const getTicket = createAsyncThunk(
  "main/serviceTickets/serviceTicket/getTicket",
  async (id) => {
    const response = await axios.get(`/api/v1/tickets/${id}`, {
      headers: { "X-UI-State": "Ticket.detail" },
    });

    let data = await response.data;
    if (!data || data === undefined) return null;
    if (data){
      data = {
        ...data,
        description: data.description ?? ''
      }
      return data;
    }
  }
);

export const patchTicket = createAsyncThunk(
  "main/serviceTickets/serviceTicket/patchTicket",
  async (ticket, { getState }) => {
    const { main } = getState();
    let clone = _.cloneDeep(ticket);
    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "ticket",
      clone
    );
    const response = await axios.patch(`/api/v1/tickets/${ticket.id}`, payload);

    const data = await response.data;

    return data;
  }
);

export const postTicket = createAsyncThunk(
  "main/serviceTickets/serviceTicket/postTicket",
  async (ticket, { getState }) => {
    const { main } = getState();

    let clone = _.cloneDeep(ticket);
    let payload = AxiosInterceptorUtils.preparePayload("post", "ticket", clone);

    const response = await axios.post(`/api/v1/tickets`, payload);
    const data = await response.data;

    return data;
  }
);

export const disableTicket = createAsyncThunk(
  "main/serviceTickets/serviceTicket/disableTicket",
  async (id, { getState }) => {
    const response = await axios.delete(`/api/v1/tickets/${id}`);
    const data = await response.data;
    return data;
  }
);

export const enableTicket = createAsyncThunk(
  "main/serviceTickets/serviceTicket/enableTicket",
  async (id, { getState }) => {
    const response = await axios.patch(`/api/v1/tickets/${id}/enable`);
    const data = await response.data;
    return data;
  }
);

export const getComments = createAsyncThunk(
  "main/serviceTickets/serviceTicket/getComments",
  async (id) => {
    const response = await axios.get(`/api/v1/tickets/${id}/comments`, {
      params: {
        limit: 20,
      },
      headers: { "X-UI-State": "Ticket.detail" },
    });

    let data = await response.data;
    if (!data || data === undefined) return null;
    return data;
  }
);

export const postComment = createAsyncThunk(
  "main/serviceTickets/serviceTicket/postComment",
  async (comment, { getState, dispatch }) => {
    const { main } = getState();
    let clone = _.cloneDeep(comment);
    let payload = AxiosInterceptorUtils.preparePayload(
      "post",
      "comment",
      clone
    );
    const response = await axios.post(`/api/v1/comments`, payload);
    const data = await response.data;
    dispatch(getComments(payload.comment.ticket));

    return data;
  }
);

export const patchComment = createAsyncThunk(
  "main/serviceTickets/serviceTicket/patchComment",
  async (comment, { getState, dispatch }) => {
    const { main } = getState();
    let clone = _.cloneDeep(comment);
    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "comment",
      clone
    );

    const response = await axios.patch(
      `/api/v1/comments/${comment.id}`,
      payload
    );

    const data = await response.data;

    dispatch(getComments(payload.comment.ticket));
    return data;
  }
);

export const deleteComment = createAsyncThunk(
  "main/serviceTickets/serviceTicket/deleteComment",
  async (comment, { getState, dispatch }) => {
    const response = await axios.delete(
      `/api/v1/comments/${comment.commentId}`
    );
    const data = await response.data;
    dispatch(getComments(comment.ticketId));
    return data;
  }
);

export const getServiceCenters = createAsyncThunk(
  "main/serviceTickets/serviceTicket/getServiceCenters",
  async (id) => {
    const response = await axios.get(
      `/api/v1/passivevehicles/${id}/servicecenter`,
      {
        headers: { "X-UI-State": "user.settings.companies" },
      }
    );

    const data = await response.data;
    return data.companies;
  }
);

export const getUnits = createAsyncThunk(
  "main/serviceTickets/serviceTicket/getUnits",
  async () => {
    const response = await axios.get(`api/v1/units`, {
      params: { limit: 0, isTicketPage: true },
      headers: { "X-UI-State": "user.settings.trucks" },
    });
    const data = await response.data;
    return data.units;
  }
);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors(
    (state) => state.main.serviceTickets.serviceTickets.units
  );

const serviceCenterAdapter = createEntityAdapter({});

export const {
  selectAll: selectServiceCenter,
  selectById: selectServiceCenterById,
} = serviceCenterAdapter.getSelectors(
  (state) => state.main.serviceTickets.serviceTickets.serviceCenters
);

const generateNewTicket = (vehicle) => {
  return {
    id: "",
    title: "",
    description: "",
    vehicle: vehicle && vehicle !== undefined ? vehicle : {},
    number: "",
    status: "",
    serviceCenter: {},
    createdBy: {},
  };
};

const ServiceTicketSlice = createSlice({
  name: "main/serviceTickets/serviceTicket",
  initialState: {
    loadingTicket: false,
    entity: null,
    comments: null,
    serviceCenters: [],
    loadingServiceCenters: false,
    loadingComments: false,
    loadingUnits: false,
    units: unitsAdapter.getInitialState({}),
  },
  reducers: {
    newTicket: {
      reducer: (state, action) => {
        state.entity = generateNewTicket(action.payload);
        state.entity.id = "new";
      },
    },
    resetTicket: {
      reducer: (state, action) => {
        state.entity = {};
        state.entity.id = "new";
      },
    },
    resetComments: {
      reducer: (state, action) => {
        state.comments = null;
      },
    },
    resetServiceCenters: {
      reducer: (state, action) => {
        state.serviceCenters = [];
      },
    },
  },
  extraReducers: {
    [getTicket.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingTicket = false;
    },
    [getTicket.pending]: (state) => {
      state.loadingTicket = true;
    },
    [getTicket.rejected]: (state) => {
      state.loadingTicket = false;
    },
    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
    [getComments.fulfilled](state, { payload }) {
      state.comments = payload.comments;
      state.loadingComments = false;
    },
    [getComments.pending]: (state) => {
      state.loadingComments = true;
    },
    [getComments.rejected]: (state) => {
      state.loadingComments = false;
    },
    [getServiceCenters.fulfilled](state, { payload }) {
      state.serviceCenters = payload;
      state.loadingServiceCenters = false;
    },
    [getServiceCenters.pending]: (state) => {
      state.loadingServiceCenters = true;
    },
    [getServiceCenters.rejected]: (state) => {
      state.loadingServiceCenters = false;
    },
    [postComment.fulfilled](state, { payload }) {
      // state.loadingComments = false;
    },
    [postComment.pending]: (state) => {
      state.loadingComments = true;
    },
    [postComment.rejected]: (state) => {
      state.loadingComments = false;
    },
    [patchComment.fulfilled](state, { payload }) {
      // state.loadingComments = false;
    },
    [patchComment.pending]: (state) => {
      state.loadingComments = true;
    },
    [patchComment.rejected]: (state) => {
      state.loadingComments = false;
    },
    [deleteComment.fulfilled](state, { payload }) {
      // state.loadingComments = false;
    },
    [deleteComment.pending]: (state) => {
      state.loadingComments = true;
    },
    [deleteComment.rejected]: (state) => {
      state.loadingComments = false;
    },
  },
});

export const {
  newTicket,
  resetComments,
  resetServiceCenters,
  resetTicket,
} = ServiceTicketSlice.actions;

export default ServiceTicketSlice.reducer;
