import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "./FleetTrackingSlice";

const initialState = {
  allMarkers: [],
  markers: [],
  bounds:[],
  loading: false,
  searchText: '',
  idMarker:''
}


export const getUnitMarkers = createAsyncThunk('fleetTracking/markers', async () => {
  const response = await axios.get(apiUrlConfig.GET_MARKERS, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});

export const fleetMarkersSlice = createSlice({
  name: 'fleetTracking/markers',
  initialState,
  reducers: {
    setMarker: {
      reducer: (state, action) => {
        state.searchText = action.payload;
        state.markers = state.allMarkers.filter(marker => action.payload.indexOf(marker.id) !== -1);
        state.idMarker = ''
      },
    },
    setUniqMarker:{
      reducer: (state, action) => {
        state.idMarker = action.payload;
        state.markers = state.allMarkers
      },
    }
  },
  extraReducers: {
    [getUnitMarkers.pending]: (state) => {
      state.loading = true
    },
    [getUnitMarkers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.markers = payload.markers;
      state.allMarkers = payload.markers;
      state.bounds = payload.bounds;
    },
    [getUnitMarkers.rejected]: (state) => {
      state.loading = false
    },
  },
});
export const {setMarker,setUniqMarker} = fleetMarkersSlice.actions;

export const postReducer = fleetMarkersSlice.reducer;
export default fleetMarkersSlice.reducer;
