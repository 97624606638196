const ST_DATA_DEVICE_TYPE = {
    NO_OPTION: 0,
    DEEP_SCAN: 1,
    SMART_SCAN: 2,
    DEEP_AND_SMART_SCAN: 3,
};

export class StDataDeviceType {

    static label = {
        0: 'NO_OPTION',
        1: 'DEEP_SCAN',
        2: 'SMART_SCAN',
        3: 'DEEP_AND_SMART_SCAN',
    };

    static translationKey = {
        0: 'st_data_device_no_option',
        1: 'st_data_device_deep_scan',
        2: 'st_data_device_smart_scan',
        3: 'st_data_device_deep_and_smart_scan',
    };
}

export default ST_DATA_DEVICE_TYPE;

