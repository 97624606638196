import { combineReducers } from '@reduxjs/toolkit';
import stTour from './StTourSlice';
import unitsStMap from "./StTourUnits";

const reducer = combineReducers({
    stTour,
    unitsStMap
});

export default reducer;
