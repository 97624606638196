import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import { INSPECTION_TYPE } from 'app/types/InspectionType';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getInspections = createAsyncThunk('main/cockpit/unitDetail/getInspections', async (category, { getState }) => {
  const { main } = getState();
  const { unit } = main.cockpit.unitDetail;

  const response = await axios.get(`api/v1/inspections`, { params: { vehicle: unit.id, category: category } });
  const data = await response.data;
  if (data) {
    console.log('data fetched', data.inspections);

    return data.inspections;
  }

  return data;
});

export const patchInspection = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchInspection', async (inspection, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(inspection);
  clone.isManuallyUpdated = 1;

  let payload = AxiosInterceptorUtils.preparePayload('patch', 'inspection', clone);

  const response = await axios.patch(`api/v1/inspection/${inspection.id}`, payload);

  const data = await response.data;

  return data;
});

export const postInspection = createAsyncThunk('main/cockpit/bodyParameterUpdate/postInspection', async (inspection, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(inspection);
  clone.isManuallyUpdated = 1;

  let payload = AxiosInterceptorUtils.preparePayload('post', 'inspection', clone);

  const response = await axios.post(`api/v1/inspection`, payload);

  const data = await response.data;

  return data;
});

const bodyInspectionsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectBodyInspections, selectById: selectBodyInspectionById, selectIds: bodyInspectionIds } =
  bodyInspectionsAdapter.getSelectors((state) => { return state.main.cockpit.serviceBook.bodyInspections; });

const lifterInspectionsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectLifterInspections, selectById: selectLifterInspectionById, selectIds: lifterInspectionIds } =
  lifterInspectionsAdapter.getSelectors((state) => { return state.main.cockpit.serviceBook.lifterInspections; });

const chassisInspectionsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectChassisInspections, selectById: selectChassisInspectionById, selectIds: chassisInspectionIds } =
  chassisInspectionsAdapter.getSelectors((state) => { return state.main.cockpit.serviceBook.chassisInspections; });

const legalInspectionsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectLegalInspections, selectById: selectLegalInspectionById, selectIds: legalInspectionIds } =
  legalInspectionsAdapter.getSelectors((state) => { return state.main.cockpit.serviceBook.legalInspections; });

  const getInitialState = () => {
  return {
    searchText: '',
    loadingBodyInspections: false,
    loadingLifterInspections: false,
    loadingChassisInspections: false,
    loadingLegalInspections: false,

    savingBodyInspection: false,
    savingLifterInspection: false,
    savingChassisInspection: false,
    savingLegalInspection: false,

    bodyInspections: bodyInspectionsAdapter.getInitialState({}),
    lifterInspections: lifterInspectionsAdapter.getInitialState({}),
    chassisInspections: chassisInspectionsAdapter.getInitialState({}),
    legalInspections: legalInspectionsAdapter.getInitialState({}),
  };
}

const serviceBookSlice = createSlice({
  name: 'main/cockpit/serviceBook',
  initialState: getInitialState(),
  reducers: {
    resetServiceBookSlice: {
      reducer: (state, action) => {
        state = getInitialState();   
      }
    },
    setServiceBookSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: {
    [getInspections.fulfilled](state, { meta, payload }) {
      let category = meta.arg;
      console.log('category fulfilled', category, payload);
      switch (category) {
        case 1:

          bodyInspectionsAdapter.setAll(state.bodyInspections, payload);
          state.loadingBodyInspections = false;
          break;
        case 2:
          lifterInspectionsAdapter.setAll(state.lifterInspections, payload);
          state.loadingLifterInspections = false;
          break;
        case 3:
          chassisInspectionsAdapter.setAll(state.chassisInspections, payload);
          state.loadingChassisInspections = false;
          break;
        case 4:
          legalInspectionsAdapter.setAll(state.legalInspections, payload);
          state.loadingLegalInspections = false;
          break;
      }
    },
    [getInspections.pending](state, { meta, payload }) {

      let category = meta.arg;
      switch (category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          bodyInspectionsAdapter.removeAll(state.bodyInspections, payload);
          state.loadingBodyInspections = true;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          lifterInspectionsAdapter.removeAll(state.lifterInspections, payload);
          state.loadingLifterInspections = true;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          chassisInspectionsAdapter.removeAll(state.chassisInspections, payload);
          state.loadingChassisInspections = true;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          legalInspectionsAdapter.removeAll(state.legalInspections, payload);
          state.loadingLegalInspections = true;
          break;
      }
    },
    [getInspections.rejected](state, { meta, payload }) {
      let category = meta.arg;
      switch (category) {
        case 1:
          state.loadingBodyInspections = false;
          break;
        case 2:
          state.loadingLifterInspections = false;
          break;
        case 3:
          state.loadingLifterInspections = false;
          break;
        case 4:
          state.loadingLegalInspections = false;
          break;
      }
    },

    [patchInspection.fulfilled](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = false;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = false;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = false;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = false;
          break;
      }
    },
    [patchInspection.pending](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = true;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = true;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = true;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = true;
          break;
      }
    },
    [patchInspection.rejected](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = false;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = false;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = false;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = false;
          break;
      }
    },

    [postInspection.fulfilled](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = false;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = false;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = false;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = false;
          break;
      }
    },
    [postInspection.pending](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = true;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = true;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = true;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = true;
          break;
      }
    },
    [postInspection.rejected](state, { meta, payload }) {
      let inspection = meta.arg;
      switch (inspection.category) {
        case INSPECTION_TYPE.BODY_INSPECTION:
          state.savingBodyInspection = false;
          break;
        case INSPECTION_TYPE.LIFTER_INSPECTION:
          state.savingLifterInspection = false;
          break;
        case INSPECTION_TYPE.CHASSIS_INSPECTION:
          state.savingChassisInspection = false;
          break;
        case INSPECTION_TYPE.LEGAL_INSPECTION:
          state.savingLegalInspection = false;
          break;
      }
    },
  },
});

export const { setServiceBookSearchText, resetServiceBookSlice } = serviceBookSlice.actions;

export default serviceBookSlice.reducer;
