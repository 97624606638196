import { lazy } from 'react';

const IntroductionDoc = lazy(() => import('./git-repository/GitRepositoryDoc'));

const IntroductionRoutes = [
  {
    path: 'introduction',
    element: <IntroductionDoc />,
  },
];

export default IntroductionRoutes;
