import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getDepot = createAsyncThunk('main/settingsDepot/depot/getDepot', async (id) => {
    if (id){
        const response = await axios.get(`api/v1/depots/${id}`, { headers: { 'X-UI-State': 'ecotecFleet' }});
        const data = await response.data;
        return data === undefined ? null : data;
    }
});

export const patchDepot = createAsyncThunk('main/settingsDepot/depot/patchDepot', async (depot, { getState }) => {
    // const { main } = getState();
    let clone = _.cloneDeep(depot);
    let payload = AxiosInterceptorUtils.preparePayload('patch', 'depot', clone);
    const response = await axios.patch(`api/v1/depots/${depot.id}`, payload);
    const data = await response.data;
    return data;
});

export const postDepot = createAsyncThunk('main/settingsDepot/depot/postDepot', async (depot, { getState }) => {
  // const { main } = getState();
  let clone = _.cloneDeep(depot);
  clone.type = 2;
  let payload = AxiosInterceptorUtils.preparePayload('post', 'depot', clone);
  const response = await axios.post(`api/v1/depots`, payload);
  const data = await response.data;
  return data;
});

export const deleteDepot = createAsyncThunk('main/settingsDepot/depot/deleteDepot', async (id, { getState }) => {

  const response = await axios.delete(`api/v1/depots/${id}`);
  const data = await response.data;
  return data;
});

export const getCountries = createAsyncThunk('main/settingsDepot/depot/getCountries', async () => {
    const response = await axios.get(`api/v1/countries`, { headers: { 'X-UI-State': 'user.settings.depots' } });
    const data = await response.data;
    return data.countries;
});

const countriesAdapter = createEntityAdapter({selectId: (model) => model.code});

export const { selectAll: selectCountries, selectById: selectCountryById } =
countriesAdapter.getSelectors((state) => { return state.main.settingsDepot.depot.countries });

const generateNewdepot = () => {
  return {
    id: "",
    name: "",
    description: "",
    abbreviation: "",
    position: {
      address: {
        streetName: "",
        streetNumber: "",
        zipCode: "",
        city: "",
        country: "",
      }
    },
  };
};

  const productSlice = createSlice({
    name: "main/settingsDepot/depot",
    initialState: {
      loadingDepot: false,
      loadingCountry: false,
      entity: {},
      countries: countriesAdapter.getInitialState({ }),
    },
    reducers: {
      resetDepot: {
        reducer: (state, action) => {
          state.entity = generateNewdepot();
          state.tabValue = 0;
          state.loadingDepot = false;
        },
      },
      newDepot: {
        reducer: (state, action) => {
          // state.loadingDepot = false;
          state.entity = generateNewdepot();
          state.entity.id = "new";
        },
      },
    },
    extraReducers: {
      [getDepot.fulfilled](state, { payload }) {
        state.entity = payload;
        state.loadingDepot = false;
      },
      [getDepot.pending]: (state) => {
        state.loadingDepot = true;
        state.entity = null;
      },
      [getDepot.rejected]: (state) => {
        state.loadingDepot = false;
      },
      [getCountries.fulfilled](state, { payload }) {
        state.loadingCountry = false;
        countriesAdapter.setAll(state.countries, payload);
      },
      [getCountries.pending]: (state) => {
        state.loadingCountry = true;
        countriesAdapter.removeAll(state.countries);
      },
      [getCountries.rejected]: (state) => {
        state.loadingCountry = false;
        countriesAdapter.removeAll(state.countries);
      },
    },
  });
  
  export const { newDepot, resetDepot } =
    productSlice.actions;

  export default productSlice.reducer;