import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import {Popup, useMap} from "react-leaflet";
import Tooltip from "@mui/material/Tooltip";
import "leaflet-control-geocoder";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import {LeafletTrackingMarker} from "react-leaflet-tracking-marker";

const icon = L.icon({
    iconSize: [20, 20],
    popupAnchor: [2, -20],
    iconUrl: "/assets/images/vehicles/avariopress.png"
});
const icon2 = L.icon({
    iconSize: [20, 20],
    popupAnchor: [2, -20],
    iconUrl: "/assets/images/loading.png"
});
export default function AirplaneMarker({ data }) {
    let lat = data.lat;
    let lng = data.lng;
    const [prevPos, setPrevPos] = useState([lat, lng]);
    const  [isStopped, setIsStopped] = useState(0);
    const  [indexStop, setIndexStop] = useState(0);
    const geocoder = L.Control.Geocoder.nominatim();
    const [text, setText] = useState("");
    const map = useMap();
    let myMovingMarker2 = useRef();
    let myRef = useRef();
    myMovingMarker2.current = 220;
    useEffect(() => {
        if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng]);
        let dist = distance(lat,lng,prevPos[0],prevPos[1],"");
        if (dist < 0.01){
           setIndexStop(indexStop+1);
        } else if (dist>10) {
            setIndexStop(4);
        } else if (dist> 3) {
            setIndexStop(0);
        } else {
            setIndexStop(0);
        }
    }, [lat]);
    function optimizeRoad(p1, p2, duration, t) {
        var k = t/duration;
        k = (k > 0) ? k : 0;
        k = (k > 1) ? 1 : k;
        return [(p1[0] + k * (p2[0] - p1[0])), (p1[1] + k * (p2[1] - p1[1]))];
    };
    return <LeafletTrackingMarker eventHandlers={{
        click: (e) => {
            handleData(e.latlng)
        },
    }} icon={indexStop > 2 ?icon2 : icon} position={[lat, lng]} keepAtCenter={true} previousPosition={prevPos} duration={920} >
        <Popup  closeButton={false}>
            {text}
        </Popup>
    </LeafletTrackingMarker>


    function handleData(data2) {
        geocoder.reverse(
            data2,1,
            results => {
                var r = results[0];
                if (r) {
                    console.log(r)

                    setText("Address :"+ (r.name? r.name : r.html));
                }
            }
        );
    }

    function distance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }

    function getAngle(latLng1, latlng2) {
        var dy = latlng2[0] - latLng1[0];
        var dx = Math.cos(Math.PI / 180 * latLng1[0]) * (latlng2[1] - latLng1[1]);
        var ang = ((Math.atan2(dy, dx) / Math.PI) * 180);
        return (ang).toFixed(2);
    }
}
