import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";
import { pourcentageScantecSlice } from "../../scantec/store/graphPositionScantec";

const initialState = {
  loadingDiesel: false,
  dieselData: [],
};

export const getDieselData = createAsyncThunk(
  "fleetTracking/units/dieselData",
  async (id) => {
    const response = await axios.get(
      `${apiUrlConfig.GET_UNITS}/${id}/dieseldata`,
      { params: { limit: 0 }, headers: { "X-UI-State": "kocobox.overview" } }
    );
    console.log("diesel data in slice is ", response.data.data);
    const data = await response.data;
    return data.data;
  }
);

export const fleetDieselDataSlice = createSlice({
  name: "fleetTracking/dieselData",
  initialState,
  reducers: {
    resetDiesel: {
      reducer: (state, action) => {
        state.loadingDiesel = true;
        state.dieselData = [];
      },
    },
  },
  extraReducers: {
    [getDieselData.pending]: (state) => {
      state.loadingDiesel = true;
      state.dieselData = [];
    },
    [getDieselData.fulfilled]: (state, { payload }) => {
      state.loadingDiesel = false;
      state.dieselData = payload;
    },
    [getDieselData.rejected]: (state) => {
      state.loadingDiesel = false;
      state.dieselData = [];
    },
  },
});

export const postReducer = fleetDieselDataSlice.reducer;
export const { resetDiesel } = fleetDieselDataSlice.actions;

export default fleetDieselDataSlice.reducer;
