import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {pourcentageScantec} from "../store/graphPositionScantec";
import Stack from "@mui/material/Stack";
import {setFilterDate} from "../../fleetTracking/store/FleetTrackingConsumptionData";
function CountSensorBin(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    let [filter, setFilter] = useState(1);
    let [filter2, setFilter2] = useState(1);
    let { entities, load,green,red,yellow,total,dates,gray } = useSelector(({ main }) => main.scantec.pourcentageScantec);
    const { t } = useTranslation('common');
    let isSet = false;
    let color ="#228B22";

    useEffect(() => {
        if (entities && entities.length > 0) {
            setSerie({
                series: [{
                    name: t('scan_tec_bin_green'),
                    data: green
                }, {
                    name: t('scan_tec_bin_yellow'),
                    data: yellow
                }, {
                    name:  t('scan_tec_bin_red'),
                    data: red
                }, {
                    name:  t('scan_tec_bin_gray'),
                    data: gray
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 300,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    }, colors: ['#228B22', '#FFD700', '#FF0000', '#000000'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10
                        },
                    },
                    xaxis: {
                        type: 'string',
                        categories:dates,
                    },
                    yaxis: {show: false},
                    legend: {
                        position: 'bottom',
                        offsetY: 40
                    },
                    fill: {
                        opacity: 1
                    }
                },


            });
        }
    }, [entities]);
    if (load) return <FuseLoading />;
    return (
        <Card className="w-full rounded-20 shadow p-20">
            <div className="pb-24">
                {entities && <Typography className="h3 font-medium">{t('scantec_overview_sensore_detection')}</Typography> }
            </div>

            {entities && entities.length > 0 && <div className="h-256 relative">
                {entities && entities.length > 0 && serie && serie.options && <ReactApexChart
                    options={serie.options}
                    series={serie.series}
                    type="bar"
                    height={300}
                />
                }
            </div>}
            {entities && entities.length == 0 && <div className="h-256 relative">
                <Typography className="text-16 px-16 font-medium"
                            color="textSecondary">{t('cockpit_tab_default_not_available')}</Typography>
            </div>
            }
        </Card>
    );

}

export default CountSensorBin;


function generateDayWiseTimeSeries(data) {
    var series = [];
    let y =0;
    let x = 0;
    _.each(data, function (e) {
         y= e.length;
         x = (e[0].date.sec) * 1000;
        let x1 = moment(x).minutes(0).seconds(0).milliseconds(0);
        series.push([x1, y]);
    });
    return series;
}

