import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import axios from "axios";

export const getTickets = createAsyncThunk(
  "main/serviceTickets/serviceTickets/getTickets",
  async (searchParams) => {
    const response = await axios.post(`/api/v1/ticketfilters`, {
      params: {
        offset: searchParams.offset,
        limit: searchParams.limit,
        fromDateTime: searchParams.fromDateTime,
        toDateTime: searchParams.toDateTime,
        unit: searchParams.unit,
        status: searchParams.status,
        searchTerm: searchParams.unitsSearchText,
        sort: searchParams.sort,
        order: searchParams.order,
        user: searchParams.createdBy,
      },
      headers: { "X-UI-State": "reportInfo.detail" },
    });

    let data = await response.data;

    if (!data || typeof data?.tickets === "undefined") return [];

    return data;
  }
);
export const getUnits = createAsyncThunk(
  "main/serviceTickets/serviceTickets/getUnits",
  async () => {
    const response = await axios.get(`api/v1/units`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "user.settings.trucks" },
    });
    const data = await response.data;

    return data.units;
  }
);

export const getUsers = createAsyncThunk(
  "main/serviceTickets/serviceTickets/getUsers",
  async () => {
    const response = await axios.get(`api/v1/users`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "user.settings.users" },
    });
    const data = await response.data;

    return data.users;
  }
);

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors(
    (state) => state.main.serviceTickets.serviceTickets.users
  );

const ticketsAdapter = createEntityAdapter({
  selectId: (model) => model.id,
});

export const { selectAll: selectTickets, selectById: selectTicketById } =
  ticketsAdapter.getSelectors(
    (state) => state.main.serviceTickets.serviceTickets.tickets
  );

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors(
    (state) => state.main.serviceTickets.serviceTickets.units
  );

const ServiceTicketsSlice = createSlice({
  name: "main/serviceTickets/serviceTickets",
  initialState: {
    page: 0,
    pageSize: 10,
    sortModel: [{ field: "name", sort: "desc" }],
    rowsPerPage: 10,
    rangeDate: [null, null],
    name: null,
    loadingTickets: false,
    ticketsCount: 0,
    unitsCombo: [],
    selectedUnit: [],
    unitsSearchText: "",
    loadingUsers: false,
    loadingUnits: false,
    tickets: ticketsAdapter.getInitialState([]),
    units: unitsAdapter.getInitialState({}),
    users: usersAdapter.getInitialState({}),
  },
  reducers: {
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setName: {
      reducer: (state, action) => {
        state.name = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
  },

  extraReducers: {
    [getUsers.fulfilled](state, { payload }) {
      usersAdapter.setAll(state.users, payload);
      state.loadingUsers = false;
    },
    [getUsers.pending]: (state) => {
      state.loadingUsers = true;
      usersAdapter.removeAll(state.users);
    },
    [getUsers.rejected]: (state) => {
      state.loadingUsers = false;
    },
    [getTickets.fulfilled](state, { payload }) {
      ticketsAdapter.setAll(state.tickets, payload.tickets);
      state.ticketsCount = payload.totalcount;
      state.loadingTickets = false;
    },
    [getTickets.pending]: (state) => {
      state.loadingTickets = true;
      state.ticketsCount = 0;
      ticketsAdapter.removeAll(state.tickets);
    },
    [getTickets.rejected]: (state) => {
      state.loadingTickets = false;
    },
    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const {
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setRowsPerPage,
  setName,
  setUnitsCombo,
} = ServiceTicketsSlice.actions;

export default ServiceTicketsSlice.reducer;
