const locale = {
    "login_welcome": "Welcome",
    "login_welcome_to": "to the",
    "login_welcome_koco_portal": "KOCO-Fleet Portal!",
    "login_welcome_message": "Analysis, tracking and monitoring system for refuse collection vehicles and sweepers of the Kirchhoff Group.",
    "login_email_required": "You must enter a email",
    "login_email_not_valid": "You must enter a valid email",
    "login_password_required": "Please enter your password",
    "login_password" : "Password",
    "report_predefined_last_3_days" : "Last 3 days",
    "login_password_forgotten" : "Forgot password?",
    "login_button" : "Login",
    "login_dont_have_an_account" : "Don't have an account?",
    "login_register": "Create an account",
    "login_pass_text": "Please set a password for your account",
    "login_pass_button": 'Confirm',
    "login_pass_match": "Password do not match",
    "login_reset_pass_text":"Please provide your email address below. If you are a registered user with us, we will send you an email containing a link to reset your password",
    "login_form_link": "login page",
    "login_pass_send_message": "An email has been sent to the given email address",
    "login_pass_reset_button": "Send Reset Link",
    "months": "January_February_March_April_May_June_July_August_September_October_November_December",
    "monthsShort": "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Dec.",
    "weekdays": "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday",
    "weekdaysShort": "Sun._Mon._Tue._Wed._Thr._Fri._Sat.",
    "weekdaysMin": "Sun_Mon_Tue_Wed_Thr_Fri_Sat",
    "login_title": "Log in",
    "login.username": "Username",
    "login.password": "Password",
    "login.password_forget": "Forgot Password",
    "login.contact": "Contact",
    "c": "API",
    "login.status": "Status",
    "login.impression": "Imprint",
    "login.notification_success": "Thank you for successfully logging into the web portal. A new window has opened. If you don't see it, please click .",
    "login_notification_success_2" : "here",
    "login.notification_failure": "Username or password are wrong",
    "layout_user": "Log-in",
    "layout_logout": "Username",
    "layout_quick_search": "Quick search",
    "layout_default_filter_all": "All",
    "layout_default_filter_disable": "Clear filters",
    "layout_default_filter_undo": "Reset",
    "layout_default_search": "Search",
    "layout_default_reset_filter": "Delete Filter",
    "layout_signal_search": "Signal",
    "layout_default_filter": "Filter",
    "layout_default_loading_data": "loading...",
    "layout_default_no_data": "no data",
    "layout_default_gps_no_data": "No gps data available",
    "profile_data_updated": "Data has been updated",
    "layout_default_creation_date": "Creation Date",
    "layout_default_last_update_date": "Last Update",
    "layout_default_user": "User",
    "layout_default_status": "Status",
    "layout_default_display_details" : "Show details",
    "layout_default_hide_details" : "Close details",
    "layout_default_confirmation" : "Are you sure?",
    "layout_default_field_required" : "This field is required",
    "layout_default_invalid_date_format" : "Invalid date format",
    "layout_default_save" : "Save",
    "layout_default_cancel" : "Cancel",
    "layout_default_delete" : "Delete",
    "layout_default_undo" : "Undo",
    "layout_default_yes" : "Yes",
    "layout_default_no" : "No",
    "layout_default_to" : "to",
    "layout_default_please_select" : "Please select",
    "layout_default_rows_per_page" : "Rows per page: ",
    "layout_default_of" : "of",
    "layout_confirmation_title": "Confirmation",
    "layout_default_action": "Actions",
    "layout_default_enabled": "Enabled",
    "layout_default_enable_button": "Enable",
    "layout_default_navigate_away_message": "Your changes have not been saved yet. Do you really want to leave the page discarding unsaved changes?",
    "layout_default_disable_button": "Disable",
    "layout_default_not_selected": "Not Selected",
    "menu_overview": "Fleet overview",
    "menu_fleet_tracking": "Fleet Tracking",
    "menu_dashboard": "Service Dashboard",
    "menu_tour_analyse": "Tour Report",
    "menu_reports": "Reports",
    "reports_plot": "CONFIGURE PLOT",
    "menu_settings": "Settings",
    "overview_title": "Fleet overview",
    "overview_export": "Export",
    "overview_columns": "Columns",
    "overview_hint_download": "Download fleet overview",
    "overview_save_column": "Save view",
    "overview_save_notification_success": "View saved",
    "overview_save_notification_failure": "Failed to save the view",
    "overview_menu_construction_type": "Body-\ntype",
    "overview_menu_construction_number": "Body-\nnumber",
    "overview_menu_chassis_manufacturer": "Chassis-\nmanufacturer",
    "overview_menu_internal_fleet_manager": "Internal\nFleet Number",
    "overview_menu_license_plate": "License\nnumber",
    "overview_menu_first_registration": "First-\nregistration",
    "overview_menu_km_total": "Mileage",
    "overview_menu_km_total_widget": "Mileage Day",
    "overview_menu_body_working_hours": "Body\nWH",
    "overview_menu_SoC": "SoC",
    "overview_menu_diesel_h2": "Diesel/\nH2",
    "overview_menu_lifter_working_hours": "Lifter\nWH",
    "overview_menu_status_vehicle": "Status-\nvehicle",
    "overview_menu_error_messages": "Error\nreport",
    "overview_menu_kgu_status": "KGU-Status",
    "overview_menu_last_date": "Last data reception",
    "overview_menu_last_location": "Last Location",
    "overview_menu_status_service": "Status-service",
    "overview_menu_hint_error_message_green": "There are no error reports",
    "overview_menu_hint_error_message_red": "Error reports are present",
    "overview_menu_hint_status_service_red": "Service overdue",
    "overview_menu_hint_status_service_yellow": "Service due shortly",
    "overview_menu_hint_status_service_green": "No service due",
    "overview_menu_hint_status_service_grey": "No service due",
    "overview_menu_hint_no_columns_selected": "No column is selected.",
    "overview_menu_construction_type_desc": "Body-type",
    "overview_menu_construction_number_desc": "Body-number",
    "overview_menu_chassis_manufacturer_desc": "Chassis-manufacturer",
    "overview_menu_internal_fleet_manager_desc": "Internal Fleet Number",
    "overview_menu_license_plate_desc": "License number",
    "overview_menu_first_registration_desc": "First-registration",
    "overview_menu_km_total_desc": "Mileage",
    "overview_menu_body_working_hours_desc": "Body working hours",
    "overview_menu_lifter_working_hours_desc": "Lifter working hours",
    "overview_menu_status_vehicle_desc": "Status-vehicle",
    "overview_menu_error_messages_desc": "Error report",
    "overview_menu_kgu_status_desc": "KGU-Status",
    "overview_menu_last_date_desc": "Last data reception",
    "overview_menu_status_service_desc": "Status-service",
    "fleet_tracking_title": "Map view",
    "fleet_tracking_menu_title": "Designation",
    "fleet_tracking_menu_construction_type": "Body-type",
    "fleet_tracking_popup_construction_number": "Body-number",
    "fleet_tracking_popup_lifter_number": "Lifternumber",
    "fleet_tracking_popup_Lifter_type": "Lifter type",
    "fleet_tracking_popup_km_total": "Mileage",
    "fleet_tracking_popup_last_emptying": "Last reception",
    "fleet_tracking_popup_neu_method": "new method",
    "fleet_tracking_popup_date_fleet": "Date route",
    "fleet_tracking_popup_button_details": "Details",
    "fleet_tracking_menu_icon" : "Icon",
    "fleet_tracking_menu_status" : "Status",
    "fleet_tracking_menu_action" : "Actions",
    "fleet_tracking_popup_button_calculate": "calculate",
    "fleet_tracking_popup_button_display_route": "Route",
    "fleet_tracking_popup_button_display_mileage": "Distance Tracker",
    "fleet_tracking_notification_gps_calculation": "Data is calculated in a few seconds",
    "fleet_tracking_notification_no_gps": "Data is calculated in a few seconds",
    "fleet_tracking_notification_failure_gps": "An error occurred while creating the report",
    "fleet_tracking_mileage_title": "In the Mileage area you can read the mileage for the selected vehicle",
    "fleet_tracking_mileage_truck": "Vehicle",
    "fleet_tracking_mileage_date_from": "from",
    "fleet_tracking_mileage_date_to": "to",
    "fleet_tracking_mileage_button_save": "Load Distance Tracker",
    "fleet_tracking_mileage_button_cancel": "Cancel",
    "fleet_tracking_mileage_date": "Date",
    "fleet_tracking_mileage_distance": "Distance in kilometers",
    "fleet_tracking_mileage_field_not_empty": "Field must contain a selection",
    "fleet_tracking_mileage_no_data": "There are no files",
    "fleet_tracking_legend_running" : "Move",
    "fleet_tracking_legend_stop" : "Stop",
    "service_dashboard_title": "Service Dashboard",
    "service_dashboard_title_rcv": "RCV",
    "service_dashboard_title_sweeper": "Sweeper",
    "service_dashboard_license_plate": "License number",
    "service_dashboard_internal_fleet_number": "Internal Fleet Number",
    "service_dashboard_menu_body": "Body",
    "service_dashboard_menu_lifter": "Lifter",
    "service_dashboard_menu_chassis": "Chassis",
    "service_dashboard_menu_examination": "Statutory checks",
    "service_dashboard_rcv_body_maintenance": "Body maintenance",
    "service_dashboard_rcv_body_maintenance_short": "BM",
    "service_dashboard_rcv_body_error": "Error reports",
    "service_dashboard_rcv_body_error_short": "ER",
    "service_dashboard_rcv_body_fill_level": "Central lubrication system level",
    "service_dashboard_rcv_body_fill_level_short": "LV",
    "service_dashboard_rcv_body_pump": "Central lubrication system pump",
    "service_dashboard_rcv_body_pump_short": "LP",
    "service_dashboard_rcv_body_warning_left": "Warning left foot bar",
    "service_dashboard_rcv_body_warning_left_short": "WLF",
    "service_dashboard_rcv_body_warning_right": "Warning right foot bar",
    "service_dashboard_rcv_body_warning_right_short": "WRF",
    "service_dashboard_rcv_lifter_warning": "Lifter maintenance",
    "service_dashboard_rcv_lifter_warning_short": "LM",
    "service_dashboard_rcv_chassis_diesel": "Diesel level",
    "service_dashboard_rcv_chassis_diesel_short": "DL",
    "service_dashboard_rcv_chassis_adblue": "AdBlue level",
    "service_dashboard_rcv_chassis_adblue_short": "ABL",
    "service_dashboard_rcv_chassis_motor_temperature": "Engine coolant temperature",
    "service_dashboard_rcv_chassis_motor_temperature_short": "ET",
    "service_dashboard_rcv_chassis_motor_pressure": "Engine oil pressure",
    "service_dashboard_rcv_chassis_motor_pressure_short": "EOP",
    "service_dashboard_rcv_examination_general": "General inspection / emission inspection",
    "service_dashboard_rcv_examination_general_short": "GI/EI",
    "service_dashboard_rcv_examination_tacho": "Tachograph test",
    "service_dashboard_rcv_examination_tacho_short": "TT",
    "service_dashboard_rcv_examination_security": "Safety check",
    "service_dashboard_rcv_examination_security_short": "SC",
    "service_dashboard_rcv_examination_uvv": "UVV-test",
    "service_dashboard_rcv_examination_uvv_short": "UVV-T",
    "service_dashboard_rcv_examination_details": "To the detailed view",
    "service_dashboard_sweeper_body_fill_level_water": "Water tank level",
    "service_dashboard_sweeper_body_fill_level_water_short": "WL",
    "service_dashboard_sweeper_body_motor_maintenance": "Body engine maintenance",
    "service_dashboard_sweeper_body_motor_maintenance_short": "BEM",
    "service_dashboard_sweeper_body_error_motor":"Error report body engine",
    "service_dashboard_sweeper_body_error_motor_short": "EBE",
    "service_dashboard_sweeper_body_fill_level_diesel":"Level diesel tank body engine",
    "service_dashboard_sweeper_body_fill_level_diesel_short": "LBE",
    "service_dashboard_sweeper_chassis_maintenance":"Chassis maintenance",
    "service_dashboard_sweeper_chassis_maintenance_short": "CM",
    "service_dashboard_sweeper_chassis_error":"Error report Chassis",
    "service_dashboard_sweeper_chassis_error_short": "ERC",
    "tour_analyse_truck_selection" : "Select vehicle *",
    "reports_time_range_selection" : "Time range",
    "tour_analyse_no_truck" : "No vehicle",
    "tour_analyse_truck_name" : "Vehicle",
    "tour_analyse_truck_button_save" : "Generate tour report",
    "tour_analyse_truck_button_cancel" : "Cancel",
    "tour_analyse_map_description" : "Description",
    "tour_analyse_map_rcv_direction" : "Direction",
    "tour_analyse_map_rcv_unloading" : "Unloading point",
    "tour_analyse_map_rcv_bin" : "Loading point",
    "tour_analyse_map_rcv_stop_point" : "Breakpoint",
    "tour_analyse_map_rcv_first_bin" : "First loading point",
    "tour_analyse_map_rcv_last_bin" : "Last loading point",
    "tour_analyse_map_rcv_depot" : "Depot",
    "tour_analyse_map_sweeper_right" : "Sweep right side",
    "tour_analyse_map_sweeper_left" : "Sweep left side",
    "tour_analyse_map_sweeper_both" : "Sweep both sides",
    "tour_analyse_map_unloading" : "Unloading point",
    "tour_analyse_map_report_title" : "Tour Report",
    "tour_analyse_map_report_general": "General",
    "tour_analyse_map_report_details" : "Details",
    "tour_analyse_popup_latitude" : "Latitude",
    "tour_analyse_popup_longitude" : "Longitude",
    "tour_analyse_popup_part" : "Part",
    "tour_analyse_popup_date" : "Date",
    "tour_analyse_title_table_total" : "Total",
    "tour_analyse_title_table_transport" :"Transport",
    "tour_analyse_title_table_collection" : "Collection",
    "tour_analyse_start_time" : "Start time",
    "tour_analyse_end_time" : "End time",
    "tour_analyse_duration" : "Duration",
    "tour_analyse_extra_duration" : "Dwell Time",
    "tour_analyse_distance" : "Route",
    "tour_analyse_avg_vehicle_speed" : "Average speed",
    "tour_analyse_axle_weights" : "Amount of waste",
    "tour_analyse_left_bins" : "Number of 2-wheel bins on the left",
    "tour_analyse_right_bins" : "Number of 2-wheel bins on the right",
    "tour_analyse_big_bins" : "Number of 4-wheel bins (4 compartments)",
    "tour_analyse_bins_pro_hour" : "Bins per hour (Collection)",
    "tour_analyse_bins_avg" : "Average",
    "tour_analyse_diesel_consumption": "Diesel consumption (liters)",
      "fleet_tracking_diesel" :"Diesel consumption",
    "tour_analyse_reverse" : "Reverse gear",
    "tour_analyse_kickdown" : "Number of kickdowns",
    "tour_analyse_status" : "Status",
    "tour_analyse_status_done" : "Done",
    "tour_analyse_status_not_done" : "Not ready",
    "tour_analyse_table_bins_title" : "Overview of the loading points",
    "tour_analyse_table_bins_column_sum" : "Total",
    "tour_analyse_table_bins_column_date" : "Date",
    "tour_analyse_table_bins_column_collection": "Assembly point",
    "tour_analyse_table_bins_column_collection_right": "Right tilt chair",
    "reports_chart_type_message": "Select Chart Type first for",
    "tour_analyse_table_bins_column_collection_left": "Left tilt chair",
    "tour_analyse_table_bins_column_collection_big_right": "4-wheel bin / comb",
    "tour_analyse_table_bins_column_collection_left_right": "4-wheel bin / Folding arm ",
    "tour_analyse_notification_success": "Your report will be displayed in a few seconds",
    "tour_clone_message": "Please fill out data first and then clone record",
    "tour_analyse_notification_failure" : "An error occurred while creating the report.",
    "tour_analyse_missing_tour" : "The reason for this is that the vehicle was not on a collection tour.",
    "tour_analyse_missing_one_part_tour" :"The reason for this is that the emptied containers were not correctly recorded in part of the tour.",
    "tour_analyse_error" : "The reason for this is an unrecognized emptying.",
    "tour_analyse_turbine_avg": "Average Turbine Speed",
    "tour_analyse_high_pressure_water_time": "High Pressure Water Time",
    "tour_analyse_low_pressure_water_time": "Low pressure Water Time",
    "tour_analyse_water_refilling_count": "Water Refilling Count",
    "tour_analyse_emptying_number": "Emptying Count",
    "report_title_1" : "1. Define the display format",
    "report_show_here" : " Show here",
    "report_show_excel" : " Export Excel",
    "report_send_to_me" : "Send to me",
    "report_title_2" : "2. Define period",
    "report_predefined_selection" : "Predefined",
    "report_individule_selection" : "Individually",
    "reports_time_range_selection_From": "Time Range",
    "report_please_select" : "Please select",
    "report_predefined_5_mn" : "Last 5 minutes",
    "report_predefined_60_mn" : "Last 60 minutes",
    "report_predefined_today" : "Today",
    "report_predefined_yesterday" : "Yesterday",
    "report_predefined_last_week" : "Last week",
    "report_from" : "From",
    "report_no_data": "No Data Found",
    "report_January" : "January",
    "report_February" : "February",
    "report_March" : "March",
    "report_April" : "April",
    "report_May" : "May",
    "report_June" : "June",
    "report_July" : "July",
    "report_August" : "August",
    "report_September" : "September",
    "report_October" : "October",
    "report_November" : "November",
    "report_December" : "December",
    "report_to" : "To",
    "report_title_3": "1. Select vehicle",
    "report_vehicle" : "vehicle",
    "report_title_4": "3. Signal specifikation",
    "report_signal_specification" : "Signal designation",
    "report_data_time_range" : "First, select the Date/Time Range",
    "report_signal_type": "Calculation type",
    "report_signal_duration" : "Signal frequency",
    "report_signal_type_min" : "Minimum",
    "report_signal_type_max": "Maximum",
    "report_signal_type_avg" : "Average",
    "report_signal_type_sum" : "Total",
    "report_signal_frequency_second" : "Second",
    "report_signal_frequency_minut" : "Minute",
    "report_signal_frequency_hour" : "Hour",
    "report_signal_frequency_day" : "Day",
    "report_signal_frequency_week": "Week",
    "report_signal_frequency_month": "Month",
    "report_type_max": "Maximum",
    "report_type_min": "Minimum",
    "report_type_sum": "Sum",
    "report_type_avg": "Average",
    "report_type_diff": "Serial Difference",
    "report_chart_type": "Chart Type",
    "report_chart_type_column": "Column",
    "report_chart_type_line": "Line",
    "report_signal_missing_signal" : "Field must contain a selection",
    "report_signal_button_save" : "Generate report",
    "report_signal_timeline" : "Period",
    "report_signal_graph_title" : "Graph",
    "report_signal_table_title" : "Data table",
    "report_signal_table_value" : "Value",
    "report_signal_table_count" : "Count",
    "report_signal_notification_success" : "Your report will be displayed in a few seconds",
    "report_signal_notification_success_email" : "Your report request has been sent",
    "report_signal_notification_error" : "An error occurred while creating the report",
    "settings_button_save": "Save",
    "settings_button_display_details" : "Show me",
    "settings_button_hide_details" : "Hide Details",
    "settings_button_action" : "Action",
    "settings_profile" : "My profile",
    "settings_profile_title_1": "1. User administration",
    "settings_profile_title" : "Titel",
    "settings_profile_first_name" : "First name",
    "settings_profile_last_name": "Last name",
    "settings_profile_phone" : "Phone number",
    "settings_profile_e-mail" : "E-Mail",
    "settings_profile_language" : "Language",
    "settings_profile_time_line" : "Time zone",
    "settings_profile_title_2" : "2. Confirm password",
    "settings_profile_actuel_password" : "Current passwort",
    "settings_profile_title_3": "3. Change password (optional)",
    "settings_profile_new_password" : "New password",
    "settings_profile_confirmation_password":  "Confirm new password",
    "settings_profile_notification_success" : "Changes have been saved",
    "settings_profile_notification_failure" : "Error saving changes",
    "settings_wrong_password" : "Invalid password",
    "settings_password_confirmation" : "Passwords do not match",
    "settings_empty_field" : "This field can not be empty.",
    "settings_company_title" : "Company administration",
    "settings_company_title_1":  "Description of the company",
    "settings_company_name" : "Company name",
    "settings_company_address" : "Address",
    "settings_company_zip" : "Zip-code",
    "settings_company_place" : "Location",
    "settings_company_country" : "Country",
    "settings_company_phone": "Phone number",
    "settings_company_email" : "Email address",
    "settings_company_id" : "ID number",
    "settings_company_internet_address" : "Internet Address",
    "settings_company_internal_address" : "Internal address",
    "settings_company_success_notification_save" : "Company saved",
    "settings_company_success_notification_delete" : "Company deleted",
    "settings_company_failure_notification_save" : "Failed to save the company",
    "settings_company_failure_notification_delete" : "Failed to delete the company",
    "settings_company_error_duplication" : "Company name already taken",
    "settings_company_error_format" : "Invalid format",
    "settings_company_empty" : "There are no companies. Create a new company using the",
    "settings_company_save_title": "Confirmation",
    "settings_company_save_message": "Are you sure to save company?",
    "settings_company_enable_title": "Confirmation",
    "settings_company_enable_message": "Are you sure to enable the company?",
    "settings_company_disable_title": "Confirmation",
    "settings_company_disable_message": "Are you sure to disable the company?",
    "settings_company_new": "New",
    "settings_company_new_company": "New Company",
    "settings_company_detail": "Company Detail",
    "settings_company_info": "Company Info",
    "settings_company_no_such_company": "There is no such company!",
    "settings_company_go_to_management": "To the Company Management",
    "settings_company_name_min_length": "The company name must be at least 5 characters",
    "settings_company_save_modules": "Save Modules",
    "settings_company_save_modules_title": "Save Modules Confirmation",
    "settings_company_save_modules_message": "Are you sure to save modules?",
    "settings_company_no_data": "There are no companies!",
    "settings_vehicle_title" : "Vehicle administration",
    "settings_vehicle_name" : "Name",
    "settings_vehicle_body_number" : "Body number",
    "settings_vehicle_construction_type" : "Body type",
    "settings_vehicle_title_1" : "Description of the vehicle",
    "settings_vehicle_title_2" : "Companies",
    "settings_vehicle_button_archive" : "Archive",
    "settings_vehicle_button_restore" : "Restore",
    "settings_vehicle_table_archive_status" : "Archived",
    "settings_vehicle_name_exist" : "Name already taken",
    "settings_vehicle_no_company_assigned" : "No vehicle assigned to the company ",
    "settings_vehicle_save" : "Vehicle saved",
    "settings_vehicle_restore" : "Vehicle restored",
    "settings_vehicle_delete" : "Vehicle deleted",
    "settings_vehicle_delete_failure" : "Error while deleting the vehicle",
    "settings_vehicle_restore_failure" : "Error when restoring the vehicle",
    "settings_vehicle_save_failure" : "Error when saving the vehicle",
    "settings_vehicle_save_title": "Confirmation",
    "settings_vehicle_save_message": "Are you sure to save the Vehicle?",
    "settings_vehicle_enable_title": "Confirmation",
    "settings_vehicle_enable_message": "Are you sure to enable the Vehicle?",
    "settings_vehicle_disable_title": "Confirmation",
    "settings_vehicle_disable_message": "Are you sure to disable the Vehicle?",
    "settings_vehicle_new": "New",
    "settings_vehicle_new_vehicle": "New Vehicle",
    "settings_vehicle_detail": "Vehicle Detail",
    "settings_vehicle_info": "Vehicle Info",
    "settings_vehicle_no_such_vehicle": "There is no such Vehicle!",
    "settings_vehicle_go_to_management": "To the Vehicle Management",
    "settings_vehicle_no_data": "There are no Vehicles!",
    "settings_module_title" : "Module administration",
    "settings_module_title_modules" : "Modules",
    "settings_module_vehicle" : "Vehicle",
    "settings_module_fleet_tracking" : "Fleet Tracking",
    "settings_module_service_dashboard" : "Service Dashboard",
    "settings_module_tour_analyse" : "Tour analysis",
    "settings_module_reports" : "Reports",
    "settings_module_jira_faun_complete": "Jira Faun Complete",
    "settings_status_active" : "Activate",
    "settings_status_deactivate" : "Deactivate",
    "settings_module_firma_not_empty" : "Company name already taken",
    "settings_module_success" : "Company data saved",
    "settings_module_delete_success" : "Delete success",
    "settings_module_failure" : "Company data saved",
    "settings_module_delete_failure" : "Company deleted",
    "settings_module_empty" : "There are no companies",
    "settings_module_batch_update" : "Batch-Update",
    "settings_role_title" : "Role administration",
    "settings_role_title_1" : "Description of the role",
    "settings_role_title_roles" : "Roles",
    "settings_role_name" : "Name",
    "settings_role_description" : "Description",
    "settings_role_definition" : "Access rights",
    "settings_role_expand_all" : "Expand all",
    "settings_role_collapse_all" : "Collapse all",
    "settings_role_deselect_all" : "Deselect all",
    "settings_role_create" : "Create/Update",
    "settings_role_view" : "View",
    "settings_role_delete" : "Delete",
    "settings_role_notification_success": "Role saved",
    "settings_role_notification_failure" : "Failed to save the role",
    "settings_role_notification_delete_success" : "Role deleted",
    "settings_role_notification_delete_failure" : "Failed to delete the role",
    "settings_role_error_message_same_role" : "Role name already taken",
    "settings_role_empty" : "There are no roles. Create a new role using the",
    "settings_role_name_min_length": "The Role name must be at least 5 characters",
    "settings_role_save_title": "Confirmation",
    "settings_role_save_message": "Are you sure to save the Role?",
    "settings_role_enable_title": "Confirmation",
    "settings_role_enable_message": "Are you sure to enable the Role?",
    "settings_role_disable_title": "Confirmation",
    "settings_role_disable_message": "Are you sure to disable the Role?",
    "settings_role_new": "New",
    "settings_role_new_role": "New Role",
    "settings_role_detail": "Role Detail",
    "settings_role_info": "Role Info",
    "settings_role_no_such_role": "There is no such Role!",
    "settings_role_go_to_management": "To the Role Management",
    "settings_role_no_data": "There are no Roles!",
    "settings_user_title" : "User administration",
    "settings_user_title_1" : "Overview of portal users",
    "settings_user_title_users" : "Users",
    "settings_user_head_1" : "Define users",
    "settings_user_last_login" : "Last Log-in",
    "settings_user_role" : "Role",
    "settings_user_name_title" : "Title",
    "settings_user_name" : "Name",
    "report_apply" : "Apply",
    "report_clone" : "Clone Metric",
    "report_delete" : "Delete Metric",
    "report_cancel" : "Cancel",
    "report_selection_one_hour": "Last Hour",
    "report_selection_today": "Today",
    "report_selection_yesterday": "Yesterday",
    "report_selection_three_days": "Last 3 Days",
    "report_selection_seven_days": "Last 7 Days",
    "report_selection_fourteen_days": "Last 14 Days",
    "report_selection_thrirty_days": "Last 30 Days",
    "report_selection_custom": "Custom Range",
    "report_monday" : "M",
    "report_tues": "T",
    "report_wed": "W",
    "report_thurs": "T",
    "report_fri": "F",
    "report_sat": "S",
    "report_sun":"S",
    "settings_user_phone" : "Telefonnummer",
    "settings_user_head_2" : "Company access",
    "report_add_signal" : "Add Signal",
    "settings_user_change_password": "Change Password",
    "settings_user_save_all" : "Save all",
    "settings_user_blocked" : "User blocked",
    "settings_user_revoked": "User revoked",
    "settings_user_notification_success" : "User saved",
    "settings_user_notification_failure" : "Error saving user",
    "settings_user_mail_format_wrong" : "Invalid email address",
    "settings_user_mail_exist" : "Mail address already taken",
    "settings_user_role_failure" : "You are not allowed to add selected roles to the user.",
    "settings_user_save_title": "Confirmation",
    "settings_user_save_message": "Are you sure to save the User?",
    "settings_user_enable_title": "Confirmation",
    "settings_user_enable_message": "Are you sure to enable the User?",
    "settings_user_disable_title": "Confirmation",
    "settings_user_disable_message": "Are you sure to disable the User?",
    "settings_user_new": "New",
    "settings_user_new_user": "New User",
    "settings_user_detail": "User Detail",
    "settings_user_info": "User Info",
    "settings_user_no_such_user": "There is no such User!",
    "settings_user_go_to_management": "To the User Management",
    "settings_user_no_data": "There are no Users!",
    "settings_user_link_button": "Resend Link",
    "settings_error_info_title" : "Error report",
    "settings_error_info_error_code" : "Error code",
    "settings_error_info_search_button":"Search",
    "settings_error_info_error_repair" : "Repair error",
    "settings_error_info_error_family" : "Family",
    "settings_error_info_level" : "Level",
    "settings_error_info_escalator_1" : "Escalator 1",
    "settings_error_info_escalator_2" : "Escalator 2",
    "settings_error_info_escalator_3" : "Escalator 3",
    "settings_error_info_time_base": "Period",
    "settings_error_info_error_condition" : "Error condition",
    "settings_error_info_text_condition" : "Text condition",
    "settings_error_info_error_text_required" : "This field is required",
    "settings_error_info_min_value": "Minimum number is 1",
    "settings_error_info_max_value" : "Maximum number is 60",
    "settings_error_info_notification_no_error_found" : "No error report found",
    "settings_error_info_notification_error_error_info" : "Failed to search for error information",
    "settings_error_info_notification_save_success" : "Error information saved successfully",
    "settings_error_info_notification_save_failure" : "Fehler bei der Speicherung der Fehlerinformation",
    "cockpit_title_1" : "Fleet management",
    "cockpit_title_2" : "Cockpit",
    "cockpit_construction_type" : "Body Type",
    "cockpit_license_plate" : "License Number",
    "cockpit_internal_fleet_number": "Internal Fleet Number",
    "cockpit_construction_number": "Body Number",
    "cockpit_ago": "ago",
    "cockpit_days": "day(s)",
    "cockpit_hours": "hour(s)",
    "cockpit_minutes": "minute(s)",
    "cockpit_seconds": "second(s)",
    "cockpit_tab_live" : "Live",
    "cockpit_tab_live_hint" : "In the \"Live\" area, you can view real-time information about the vehicle, for example, the current total weight.",
    "cockpit_tab_live_error_report" : "Error Report",
    "cockpit_tab_live_error_report_active_red" : "active error(s)",
    "cockpit_tab_live_error_report_active_green" : "active error",
    "cockpit_tab_live_error_report_active_grey" : "errors reported",
    "cockpit_tab_live_error_report_no" : "No",
    "cockpit_tab_live_warning" : "Warning(s)",
    "cockpit_tab_live_prewarning" : "Prewarning(s)",
    "cockpit_tab_live_green" : "Green(s)",
    "cockpit_tab_live_dashboard_summary" : "Dashboard Summary",
    "cockpit_tab_live_payload" : "Nutzlast",
    "cockpit_tab_live_payload_unit" : "KG",
    "cockpit_tab_live_mileage" : "Mileage",
    "cockpit_tab_live_no_module_activated": "No Module of this vehicle is activated.",
    "cockpit_tab_software" : "Software",
    "cockpit_tab_software_hint" : "In the \"Software\" area you will find information on the current software versions for the superstructure and lifter.",
    "cockpit_tab_general" : "General data",
    "cockpit_tab_general_hint" : "In the \"General data\" area you will find information on the basic data of the vehicle, e.g. the software versions. You can also add basic manual data, e.g. the date of first registration or the internal fleet number.",
    "cockpit_tab_signals" : "Signals",
    "cockpit_tab_signals_hint" : "In the \"Signals\" area you will find information for all the signals for a specific vehicle, like, mileage, diesel, Ad-Blue, turbine speed etc",
    "cockpit_tab_live_data" : "Operating data",
    "cockpit_tab_live_data_hint" : "In the \"Operating data\" area you will find information on important operating data of the vehicle, e.g. the mileage or the operating hours.",
    "cockpit_tab_error_code" : "Error reports",
    "cockpit_tab_error_code_hint" : "In the area \"Error reports \" you can see all current error messages. The area does not represent an error history.",
    "cockpit_tab_faun_complete" : "FAUN Complete internally",
    "cockpit_tab_faun_complete_hint": "In the \"FAUN Complete intern \" area, all current and processed error messages are displayed with ticket numbers.",
    "cockpit_tab_parameter" : "Read Parameters",
    "cockpit_tab_parameter_hint" : "In the \"Parameters \" area you can read the current parameter sets for the selected vehicle or download parameter lists. You can also change parameters and view the parameter history.",
    "cockpit_tab_parameter_update" : "Update parameters",
    "cockpit_tab_parameter_update_hint" : "In the \"Parameters \" area you can read the current parameter sets for the selected vehicle or download parameter lists. You can also change parameters and view the parameter history.",
    "cockpit_tab_service_book" : "Service manual",
    "cockpit_tab_service_book_hint" : "In the \"Service manual \" area you will find all the inspections / maintenance that have been carried out on the body / lifter. You can also manually enter the legal checks that have been carried out. These serve as the basis for monitoring in the service dashboard.",
    "cockpit_tab_unit_management" : "Fleet management",
    "cockpit_tab_unit_management_hint" : "In the \"Internal Debug Logs \" area you will find all ...",
    "cockpit_tab_debug_log" : "Debug Logs",
    "cockpit_tab_debug_log_hint" : "In the \"Debug Logs \" area you will find all ...",
    "cockpit_tab_depot_selection" : "Depot selection",
    "cockpit_tab_depot_selection_hint" : "",
    "cockpit_tab_default_table_category" : "Category",
    "cockpit_tab_default_table_action" : "Action",
    "cockpit_tab_default_info_title" : "General",
    "cockpit_tab_default_info_body" : "Body",
    "cockpit_tab_default_info_lifter" : "Lifter",
    "cockpit_tab_default_info_chassis" : "Chassis",
    "cockpit_tab_default_info_imei" : "Imei",
    "cockpit_tab_default_info_first_registration" : "First registration",
    "cockpit_tab_default_body_software_version_master_unit" : "Software version body control",
    "cockpit_tab_default_body_runtime_version_master_unit" : "BIOS body control",
    "cockpit_tab_default_body_software_display_master_unit" : "Software version display unit",
    "cockpit_tab_default_body_runtime_display_master_unit" : "BIOS display Unit",
    "cockpit_tab_sweeper_default_body_sweeper_type" : "Sweepertype",
    "cockpit_tab_sweeper_default_body_drive_type" : "Drive type",
    "cockpit_tab_sweeper_default_software_version_display" : "Software version view",
    "cockpit_tab_sweeper_default_software_version_cm" : "Software version CM",
    "cockpit_tab_sweeper_default_hardware_version_display" : "Hardware version view",
    "cockpit_tab_sweeper_default_hardware_version_cm" : "Hardware version CM",
    "cockpit_tab_default_lifter_lifter_number" : "Lifter number",
    "cockpit_tab_default_lifter_lifter_type" : "Lifter type",
    "cockpit_tab_default_lifter_date" : "Lifter control software status",
    "cockpit_tab_default_lifter_software_date" : "Software date",
    "cockpit_tab_default_chassis_type" : "Chassis type:",
    "cockpit_tab_default_chassis_number" : "Chassis number",
    "cockpit_tab_default_not_available" : "Not available",
    "cockpit_tab_default_notification_save_success":"Changes saved",
    "cockpit_tab_live_data_rcv_value" : "Value",
    "cockpit_tab_live_data_rcv_date": "Date",
    "cockpit_tab_signal_data_rcv_value" : "Value",
    "cockpit_tab_signal_data_rcv_unit" : "Unit",
    "cockpit_tab_live_data_rcv_version" : "Version",
    "cockpit_tab_live_data_rcv_signal" : "Signal designation",
    "cockpit_tab_signal_data_rcv_date" : "Last Received",
    "cockpit_tab_live_data_rcv_info_km_transport" : "Mileage transport",
    "cockpit_tab_live_data_rcv_info_km_collection" : "Mileage collection",
    "cockpit_tab_live_data_rcv_info_km_waiting" : "Mileage maintenance",
    "cockpit_tab_live_data_rcv_info_km_last" : "Mileage / total",
    "cockpit_tab_live_data_rcv_body_working_hours" : "Working hours",
    "cockpit_tab_live_data_rcv_body_status_working_hours" : "Maintenance due status",
    "cockpit_tab_live_data_rcv_body_status_working_hours_ok" : "ok",
    "cockpit_tab_live_data_rcv_body_status_working_until_maintenance" : "Working hours until the next maintenance",
    "cockpit_tab_live_data_rcv_body_count_compaction": "Counter compaction system",
      "cockpit_tab_live_data_rcv_body_total_compaction": "Total Compaction Time", // created the total compaction time enum for ROTOPRESS signal
      "cockpit_tab_live_data_rcv_body_optimization_compaction" : "Fraction optimized compaction",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_not_active" : "Not activated",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_haushold_garbage" : "Household garbage",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_bio_garbage" : "Bio garbage",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_dsd" : "Dsd",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_paper" : "Paper",
    "cockpit_tab_live_data_rcv_body_optimization_compaction_glass" : "Glass waste",
    "cockpit_tab_live_data_rcv_body_fill_level" : "Central lubrication system level",
    "cockpit_tab_live_data_rcv_body_pump" : "Central lubrication system pump",
    "cockpit_tab_live_data_rcv_not_ok": "Not OK",
    "cockpit_tab_live_data_rcv_lifter_daily_working_hours" : "Daily counter working hours lifter",
    "cockpit_tab_live_data_rcv_lifter_working_hours" : "Working hours Lifter",
    "cockpit_tab_live_data_rcv_lifter_working_hours_until_maintenace": "Working hours until the next maintenance",
    "cockpit_tab_live_data_rcv_lifter_absolute_kam_bin" : "4-wheel vessels, comb holder, total",
    "cockpit_tab_live_data_rcv_lifter_absolute_arm_bin" : "4-wheel vessels folding arm total",
    "cockpit_tab_live_data_rcv_lifter_4_wheel_total_bin" : "4-wheels Lifter Cycles Total",
    "cockpit_tab_live_data_rcv_lifter_absolute_left_bin": "Total counter on left side",
    "cockpit_tab_live_data_rcv_lifter_absolute_right_bin": "Total counter on right side",
    "cockpit_tab_live_data_rcv_lifter_2_wheel_total_bin": "2-wheels Lifter Cycles Total",
    "cockpit_tab_live_data_rcv_chassis_working_hours" : "Engine working hours",
    "cockpit_tab_live_data_rcv_chassis_fill_level_h2": "H2 level",
    "cockpit_tab_live_data_rcv_chassis_fill_level_battery": "Battery level",
    "cockpit_tab_live_data_rcv_chassis_fill_level_diesel": "Diesel level",
    "cockpit_tab_live_data_rcv_chassis_fill_level_ad_blue" : "Adblue level",
    "cockpit_tab_live_data_rcv_chassis_fill_level_diesel_warning" : "Diesel level warning",
    "cockpit_tab_live_data_rcv_chassis_fill_level_adblue_warning" : "Adblue level warning",
    "cockpit_tab_live_data_sweeper_working_hours" : "Working hours",
    "cockpit_tab_live_data_sweeper_status_working_hours" : "Maintenance due status",
    "cockpit_tab_live_data_sweeper_status_working_hours_ok" : "ok",
    "cockpit_tab_live_data_sweeper_status_working_until_maintenance" : "Working hours until the next maintenance",
    "cockpit_tab_live_data_sweeper_general_total" : "Mileage / total",
    "cockpit_tab_live_data_sweeper_general_swap_total" : "Mileage Transport",
    "cockpit_tab_live_data_sweeper_general_swap_time" : "Sweep Time",
    "cockpit_tab_live_data_sweeper_general_swap_distance" : "Mileage Sweep",
    "cockpit_tab_live_data_sweeper_body_air_pressure" : "Main air pressure",
    "cockpit_tab_live_data_sweeper_body_water_tank_level" : "Water tank level",
    "cockpit_tab_live_data_sweeper_body_water_tank_temperture" : "Water tank temperature",
    "cockpit_tab_live_data_sweeper_body_hydraulic_oil_temperture" : "Hydraulic oil temperature",
    "cockpit_tab_live_data_sweeper_high_pressure_pump" : "Working hours high pressure pump",
    "cockpit_tab_live_data_sweeper_drive" : "Drive",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours" : "Working Hours Hydrostatic Drive",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours_until_mainteance" :"Working Hours until the next Maintenance Hydrostatic Drive",
    "cockpit_tab_live_data_sweeper_drive_hydrostatic_km" : "Hydrostatic mileage",
    "cockpit_tab_live_data_aux_working_hours" : "Working Hours Auxiliary Engine",
    "cockpit_tab_live_data_aux_working_hours_until_mainteance" : "Working Hours untill the next Maintenance Auxiliary Engine",
    "cockpit_tab_live_data_sweeper_tank_level" : "Tank level",
    "cockpit_tab_live_data_state_change" : "State of charge",
    "cockpit_error_code_last_date" : "Last date",
    "cockpit_error_code_error_code_hex" : "Error code(Hex)",
    "cockpit_error_code_error_code_dec" : "Error code(Dec)",
    "cockpit_error_code_error_source" : "Error source",
    "cockpit_error_code_error_description" : "Error description",
    "cockpit_error_code_error_working_hours" : "Working hours",
    "cockpit_error_code_sweeper_fmi": "FMI",
    "cockpit_error_code_sweeper_additional_info" : "Additional information",
    "cockpit_faun_complete_first_date" : "First date",
    "cockpit_faun_complete_last_date" : "Last date",
    "cockpit_faun_complete_error": "Code",
    "cockpit_faun_complete_error_description" : "Description",
    "cockpit_faun_complete_count" : "Frequency",
    "cockpit_faun_complete_working_hours" : "Working hours",
    "cockpit_faun_complete_error_status" : "Error status",
    "cockpit_faun_complete_last_esclation" : "Last escalation",
    "cockpit_faun_complete_Jira_link" : "Jira link.",
    "cockpit_faun_complete_last_status" : "Last available status",
    "cockpit_faun_complete_status_not_available" : "UNSPECIFIED",
    "cockpit_faun_complete_status_occured" : "OCCURRED",
    "cockpit_faun_complete_status_cleared" : "CLEARED",
    "cockpit_faun_complete_status_ack" : "ACKNOWLEDGED",
    "cockpit_faun_complete_esc_0" :"0 - Trivial",
    "cockpit_faun_complete_esc_1" :"1 - Medium",
    "cockpit_faun_complete_esc_2" :"2 - Critical",
    "cockpit_faun_complete_esc_3" :"3 - Blocker",
    "cockpit_faun_complete_jira_status_work" : "IN PROGRESS",
    "cockpit_faun_complete_jira_status_task" : "TASK",
    "cockpit_faun_complete_jira_status_complete" : "COMPLETE",
    "cockpit_faun_complete_jira_status_spec" : "GET_SPEC",
    "cockpit_faun_complete_jira_status_rfq" : "RFQ",
    "cockpit_faun_complete_jira_status_nc" : "NC",
    "cockpit_faun_complete_jira_status_order": "ORDER",
    "cockpit_faun_complete_jira_status_3d": "_3D",
    "cockpit_faun_complete_jira_status_2d" : "_2D",
    "cockpit_faun_complete_jira_status_free_task": "RELEASE",
    "cockpit_faun_complete_jira_status_rejected": "REJECTED",
    "cockpit_faun_complete_button_search" : "Show search",
    "cockpit_faun_complete_button_check_status" : "Check ticket status",
    "cockpit_faun_complete_delete_filter" : "Delete filter",
    "cockpit_faun_complete_next" : "Next",
    "parameter_body_title" : "Body Parameters",
    "parameter_lifter_title" : "Lifter Parameters",
    "parameter_search_title" : "Search parameters",
    "parameter_search_machine_type" : "Machine Type",
    "parameter_read_parameters" : "Saved parameter requests",
    "parameter_read_parameter_requests" : "Read Requests",
    "parameter_send_request_button" : "Send request",
    "parameter_read_parameters_status_head" : "Status",
    "command_gateway_status_new" : "New request",
    "command_gateway_status_to_be_queued" : "Prepare to send",
    "command_gateway_status_queued" : "Waiting",
    "command_gateway_status_sent" : "Sent",
    "command_gateway_status_received" : "Received",
    "command_gateway_status_success" : "Completely done",
    "command_gateway_status_errors" : "Done with error",
    "command_gateway_under_modification" : "Change mode",
    "parameter_read_parameters_no_in_progress" : "There is no In Progress Request",
    "parameter_empty" : "There are no parameter requirements available.",
    "parameter_history" : "History",
    "parameter_count_modification" : "Number of changes",
    "parameter_action" : "Action",
    "parameter_no_log_history" : "There are no logs available",
    "parameter_download_button" : "Download",
    "parameter_read_close_details" : "Close changes",
    "parameter_read_show_details" : "Show changes",
    "parameter_read_send_request_confirmation_message" : "Are you sure to send a New Read Parameter Request?",
    "parameter_number" : "Number",
    "parameter_current_value" : "Current value",
    "parameter_description" : "Description",
    "parameter_value" : "Value",
    "parameter_value_old" : "Old Value",
    "parameter_value_from" : "(of) value",
    "parameter_value_to" : "(on) value",
    "parameter_value_change_source" : "Change source",
    "parameter_value_min" : "Min",
    "parameter_value_max" : "Max",
    "parameter_value_date" : "Date",
    "parameter_value_number_of_receives" : "Number of receives",
    "parameter_value_last_date" : "Last Date",
    "parameter_value_user" : "User",
    "parameter_error_desc" : "No parameter info",
    "parameter_us_desc" : "US English Desc",
    "parameter_fr_desc" : "French Desc",
    "parameter_gr_desc" : "German Desc",
    "parameter_us_detail_cond" : "US Detail Cond.",
    "parameter_fr_detail_cond" : "French Detail Cond.",
    "parameter_gr_detail_cond" : "German Detail Cond.",
    "parameter_us_display_cond" : "US Display Cond.",
    "parameter_fr_display_cond" : "French Display Cond.",
    "parameter_gr_display_cond" : "German Display Cond.",
    "parameter_default" : "Default",
    "parameter_minimum" : "Minimum",
    "parameter_maximum" : "Maximum",
    "parameter_step" : "Step",
    "parameter_unit" : "Unit",
    "parameter_shown" : "shown",
    "parameter_show_more" : "show more",   
    "parameter_category" : "Current Parameter Set",     
    "parameter_title" : "Title",   
    "parameter_from" : "from", 
    "parameter_to" : "to", 
    "parameter_count_in_group" : "Count in the category", 
    "parameter_head_2": "Parameter value log",
    "parameter_table_change_source" : "Change source",
    "parameter_table_change_source_portal" : "Portal",
    "parameter_table_change_source_kocobox" : "Koco Box",
    "parameter_update_title" : "Parameter Update",
    "parameter_update_buttonUpdate" : "Import",
    "parameter_update_gets_generated" : "Request is being generated",
    "parameter_update_change_status" : "Change Status",
    "parameter_update_inquiry_status" : "Inquiry Status",
    "parameter_update_cancel_request" : "Cancel",
    "parameter_update_delete_request" : "Delete",
    "parameter_update_type" : "Type",
    "parameter_update_error_unique_parameter_number" : "The parameter number should be unique ",
    "parameter_update_new_value" : "New Value",
    "parameter_update_new_request_title" : "new update",
    "parameter_update_new_request_send_all" : "Send All Existing",
    "parameter_update_new_request_custom": "New Custom",
    "parameter_update_new_request_import_file": "Import File",
    "parameter_update_add_new_parameter": "Add Parameter",
    "parameter_update_add_new_send_all_existing_parameters_title": "Send Parameter Confirmation",
    "parameter_update_add_new_send_all_existing_parameters_message": "Are you sure to send all existing parameters",
    "parameter_update_save_confirm_title": "Save Confirmation",
    "parameter_update_save_confirm_message": "Are you sure to save the parameter request?",
    "parameter_update_delete_confirm_title": "Delete Confirmation",
    "parameter_update_delete_confirm_message": "Are you sure to delete the parameter request?",
    "parameter_update_delete_parameter_confirm_title": "Delete parameter Confirmation",
    "parameter_update_delete_parameter_confirm_message": "Are you sure to delete the parameter from request?",
    "parameter_update_invalid_number_or_value": "Some parameter numbers or values are invalid",
    "parameter_save_notification_success" : "Parameter request saved",
    "parameter_read_notification_success" : "Read parameter request was saved.",
    "cockpit_service_book_title" : "Digital service book",
    "cockpit_service_book_note" : "Note",
    "cockpit_service_book_general_inspection" : "General inspection",
    "cockpit_service_book_update_button" : "Update",
    "cockpit_service_book_new_examination" : "Neu prufüng",
    "cockpit_service_book_date_empty" : "The \"Date \" field must contain a selection",
    "cockpit_service_book_type_empty" : "The \"Type \" field must contain a selection",
    "cockpit_service_book_number" : "Number",
    "cockpit_service_book_date" : "Date",
    "cockpit_service_book_updated_manually" : "Source of Change",
    "cockpit_service_book_working_hours" : "Working Hours",
    "cockpit_service_book_type" : "Type",
    "service_book_body_inspection" : "Body Inspection",
    "service_book_lifter_inspection" : "Lifter Inspection",
    "service_book_chassis_inspection" : "Chassis Inspection",
    "service_book_legal_inspection" : "Legal Inspection",
    "inspection_type_main": "Hauptuntersuchung/Abgasuntersuchung",
    "inspection_type_tachograph_check": "Tachographenprüfung",
    "inspection_type_safety_check": "Sicherheitsprüfung",
    "inspection_type_uvv_test": "UVV-Prüfung",
    "cockpit_unit_management_category" : "Category",
    "cockpit_unit_management_serial_number" : "Serial Number",
    "cockpit_unit_management_kgu_status" : "KGU status",
    "cockpit_unit_management_title2" : "Internal Debug Log Files",
    "cockpit_unit_management_data_name" : "Data name",
    "cockpit_unit_management_data_size" : "Data size",
    "cockpit_confirmation" : "Are you sure?",
    "chose_a_date" :"Please choose a date",
      "last_date_received" :"Last data received",
      "consumption_over_time" :"Consumption over time",
      "mileage_over_time" :"Mileage over time",
      "last_week" : "Last Week",
      "last_2_week" : "Last 2 weeks",
      "last_3_week" : "Last 3 weeks",
      "last_4_week" : "Last 4 weeks",
      "tour_data" : "Data tour",
      "fleet_info_km_transport" : "Transport",
      "fleet_info_km_collection" : "Collection",
      "fleet_info_km_waiting" : "Other",
      "fleet_tracking_timeline" : "Last tour time",
      "live_data_h2" :"Fuel level H2",
      "live_data_soc" :"Fuel level Battery",
      "live_data_paylod" :"Total weight",
      "live_optimization_compaction": "Optimization Compaction",
      "live_turbine_speed": "Turbine Speed",
      "live_turbine_speed_unit": "RPM",
      "sweep" :"Sweep",
      "truck_not_on_tour_today" :"Truck not on the tour today",
      "menu_scantec" : "SCANTEC",
      "fleet_tracking_not_active" : "not active",
      "fleet_tracking_not_active_30" :" not active last 30 minutes",
      "fleet_tracking_active" :"active",
      "scan_tec_bin_red" : "Bad",
      "scan_tec_bin_yellow" : "Middle",
      "scan_tec_bin_green" :"Good",
      "scan_tec_bin_gray" :"Unclassifiable",
      "scan_tec_total" :"Total Bins",
      "scan_tec_region" :"Region",
      "scan_tec_date_start" :"From",
      "scan_tec_date_end" :"To",
      "scan_tec_road" :"Tour",
      "scan_tec_classification_text": "Adjust Classification",
      "scan_tec_update": "Update",
      "scantec_test": '>%',
      "last_tour_4" : "last 4 Tours",
      "last_tour_8" : "last 8 Tours",
      "scantec_overview_text" : "Overview over bins",
      "scantec_overview_tab" : "Tour Overview",
      "scantec_overview_bin" :"Bin Insight",
      "scantec_overview_graph_trend": "Trend over last tours(%)",
      "scantec_overview_sensore_detection": "Bin Counts Last Tours",
      "scantec_overview_total_bins" :"Total Bins",
      "scantec_single_bin_detect" :"Data tour",
      "scantec_single_bin_identification" :"Bin Identification",
      "scantec_single_bin_identificatier" :"Bin Identifier",
      "scantec_single_bin_username" :"Username",
      "scantec_email" :"Email",
      "scantec_phone" :"Phone",
      "scantec_sensor_data" : "Sensor Data",
      "scantec_trend" :"Sensor Data Trend (%)",
      "scantec_result": "Result",
      "value_more_100" : "Value more than 100",
      "bad_should_be_more" : "Bad should be more than middle",
      "next" : "Next",
      "back" : "Back",
      "done" : "Done",
      "refresh_list_bins" :"Update list of bins visible on map ",
      "show_hide_bins_map": "Display/Hide bins on map",
      "reset" : "Zurücksetzen",
      "command_gateway_type_no_command": "No Command",
      "command_gateway_type_number_of_commands": "Number of commands",
      "command_gateway_type_read_body_parameters": "Read Body Parameters",
      "command_gateway_type_read_lifter_parameters": "Read Lifter Parameters",
      "command_gateway_type_udpate_operation_mode": "Update Operation Mode",
      "command_gateway_type_write_body_parameters": "Write Body Parameters",
      "command_gateway_type_write_lifter_parameters": "Write Lifter Parameters",
      "command_gateway_type_write_unit_configuration_file": "Write Unit Configuration File",
      "command_gateway_type_inquiry_status": "Inquiery Status",
      "command_gateway_type_cancel_command": "Cancel Command",
      "save_general_info_confirmation_title" : "Save Data",
      "save_general_info_confirmation_message" : "Are you sure to save General Data?",
      "module_status_activating": "activating",
      "module_status_activated": "activated",
      "module_status_deactivating": "deactivating",
      "module_status_deactivated": "deactivated",
      "settings_passive_vehicle_group_title": "Vehicle group administration",
      "settings_passive_vehicle_group_name": "Name",
      "settings_passive_vehicle_group_company_name" : "Company",
      "settings_passive_vehicle_privacy_status" : "Privacy Status",  
      "settings_passive_vehicle_group_description": "Description",
      "settings_passive_vehicle_group_public" : "public",
      "settings_passive_vehicle_group_private" : "private",
      "settings_passive_vehicle_group_save_title": "Confirmation",
      "settings_passive_vehicle_group_save_message": "Are you sure to save the vehicle group?",
      "settings_passive_vehicle_group_enable_title": "Confirmation",
      "settings_passive_vehicle_group_enable_message": "Are you sure to enable the vehicle group?",
      "settings_passive_vehicle_group_disable_title": "Confirmation",
      "settings_passive_vehicle_group_disable_message": "Are you sure to disable the vehicle group?",
      "settings_passive_vehicle_group_new": "New",
      "settings_passive_vehicle_group_new_vehicle_group": "New Vehicle group",
      "settings_passive_vehicle_group_detail": "Vehicle group Detail",
      "settings_passive_vehicle_group_info": "Vehicle group Info",
      "settings_passive_vehicle_group_no_such_vehicle_group": "There is no such vehicle group!",
      "settings_passive_vehicle_group_go_to_management": "To the Vehicle group Administration",
      "settings_passive_vehicle_group_name_min_length": "The Vehicle group name must be at least 5 characters",
      "settings_passive_vehicle_group_no_data": "There are no vehicle groups!",
      "settings_subscription_title": "Subscription administration",
      "settings_subscription_name": "Name",
      "settings_tour_koco_subscription_name": "Name",
      "settings_subscription_company_name" : "Company",
      "settings_subscription_description": "Description",
      "settings_tour_koco_subscription_description": "Description",
      "settings_tour_koco_subscription_user": "User",
      "settings_tour_koco_subscription_vehicle": "Vehicle",
      "settings_tour_koco_subscription_email": "Email",
      "settings_subscription_number_of_users": "Number of Users",
      "settings_subscription_number_of_vehicles": "Number of Vehicles",
      "settings_subscription_number_of_events": "Number of Events",
      "settings_subscription_save_title": "Confirmation",
      "settings_subscription_save_message": "Are you sure to save the subscription?",
      "settings_subscription_enable_title": "Confirmation",
      "settings_subscription_enable_message": "Are you sure to enable the subscription?",
      "settings_subscription_disable_title": "Confirmation",
      "settings_subscription_disable_message": "Are you sure to disable the subscription?",
      "settings_subscription_new": "New",
      "settings_subscription_new_subscription": "New Subscription",
      "settings_subscription_detail": "Subscription Detail",
      "settings_subscription_info": "Subscription Info",
      "settings_subscription_warnings": "Warnings",
      "settings_subscription_errors": "Errors",
      "settings_subscription_reports": "Reports",
      "settings_subscription_no_such_subscription": "There is no such Subscription!",
      "settings_subscription_go_to_management": "To the Subscription Administration",
      "settings_subscription_name_min_length": "The Subscription name must be at least 5 characters",
      "settings_subscription_no_data": "There are no Subscriptions!",
      "settings_event_info_name": "Name",
      "settings_event_info_description": "Description",
      "settings_event_info_type": "Type",
      "settings_event_info_send_SMS": "Send SMS",
      "settings_event_info_send_Email": "Send E-Mail",
      "settings_event_info_title": "Event Info administration",
      "settings_event_info_company_name" : "Company",
      "settings_event_info_save_title": "Confirmation",
      "settings_event_info_save_message": "Are you sure to save the event info?",
      "settings_event_info_enable_title": "Confirmation",
      "settings_event_info_enable_message": "Are you sure to enable the event info?",
      "settings_event_info_disable_title": "Confirmation",
      "settings_event_info_disable_message": "Are you sure to disable the event info?",
      "settings_event_info_new": "New",
      "settings_event_info_new_event_info": "New Event Info",
      "settings_event_info_detail": "Event Info Detail",
      "settings_event_info_info": "Event Info",
      "settings_event_info_no_such_event_info": "There is no such Event Info!",
      "settings_event_info_go_to_management": "To the Event Event Info Administration",
      "settings_event_info_name_min_length": "The Event Info name must be at least 5 characters",
      "settings_event_info_no_data": "There are no Event Infos!",
      "event_info_warning": "Warning",
      "event_info_error": "Error",
      "event_info_report": "Report",
      "settings_sc_tour_plan_title": "Tour Planning",
      "settings_sc_tour_plan_date_from": "Date from",
      "settings_sc_tour_plan_date_to": "Date to",
      "settings_sc_tour_plan_time_from": "Time from",
      "settings_sc_tour_plan_time_to": "Time to",
      "settings_sc_tour_plan_classifier": "Classifier",
      "settings_sc_tour_plan_from_date": "from",
      "settings_sc_tour_plan_to_date": "to",
      "settings_sc_tour_plan_repeating": "repetition",
      "settings_sc_tour_plan_tour_count": "Tour count",
      "settings_sc_tour_plan_truck": "Vehicle",
      "settings_sc_tour_plan_has_overlap_in_db": "overlapped in DB",
      "settings_sc_tour_plan_tour": "Tour",
      "settings_sc_tour_plan_actions": "Actions",
      "settings_sc_tour_plan_save_confirm_title": "Save Confirmation",
      "settings_sc_tour_plan_save_confirm_message": "Are you sure to save the Tour Plan?",
      "settings_sc_tour_plan_delete_confirm_title": "Delete Confirmation",
      "metric_delete_confirm_title": "Delete Confirmation",
      "settings_sc_tour_plan_delete_confirm_message": "Are you sure to delete the Tour Plan?",
      "metric_delete_confirm_message": "Are you sure to delete the Metric?",
      "settings_sc_tour_plan_save_success_notification": "Tour Plan successfully saved.",
      "settings_sc_tour_plan_save_failure_notification": "Error while saving Tour Plan",
      "settings_sc_tour_plan_delete_success_notification": "Tour Plan successfully deleted.",
      "settings_sc_tour_plan_delete_failure_notification": "Error while saving Tour Plan",
      "settings_sc_tour_plan_new": "New Tour Plan",
      "settings_sc_tour_plan_new_custom": "Manual Insert",
      "settings_sc_tour_plan_new_import_file": "Import File",
      "settings_sc_tour_plan_new_title": "New Tour Plan",
      "settings_sc_tour_plan_split_title": "Split Tour Plan",
      "settings_sc_tour_plan_new_add_record": "Add Record",
      "settings_sc_tour_new_title": "New Tour",
      "settings_sc_tour_plan_save_button": "Save Changes",
      "settings_sc_tour_plan_threshold_first": "Yellow Threshold",
      "settings_sc_tour_plan_threshold_second": "Red Threshold",
      "settings_sc_tour_plan_is_lifter_stop_active": "Lifter Stop",
      "settings_st_tour_plan_import_label": "File to import",
      "settings_st_tour_plan_template": "Template",
      "settings_st_tour_plan_data_overlaps": "Entered data overlaps with existing data",
      "st_classifier_bio" : "BIO",
      "st_classifier_rest" : "Rest",
      "st_classifier_gewerbe" : "Gewerbe",
      "st_classifier_select_label" : "Select classifier",
      "st_tour_select_label" : "Select tour",
      "settings_sc_tour_title": "Tour",
      "settings_sc_tour_title_insert": " New Tour",
      "duplication_name" : "Name duplicated",
      "update_st_tour" : "Update success",
      "failure_st_tour" : "An error has occur",
      "delete_st_tour" : "Delete success",
      "st_single_insertion" : "Insert line",
      "st_single_import" : "Import File",
      "settings_sc_tour_save_confirm_title": "Save Confirmation",
      "settings_sc_tour_save_confirm_message": "Are you sure to save the Tour?",
      "settings_sc_tour_delete_confirm_title": "Delete Confirmation",
      "settings_sc_tour_delete_confirm_message": "Are you sure to delete the Tour?",
      "settings_button_option" : "Create",
      "settings_sc_tour": "Tour",
      "scantec_tour_overview_title" : "Tour Overview",
      "scantec_tour_overview_date" : "Date",
      "scantec_tour_overview_vehicle" : "Vehicle",
      "scantec_tour_overview_tour" : "Tour",
      "scantec_tour_overview_address" : "Address",
      "scantec_tour_overview_foreign_material" : "Foreign Material",
      "scantec_tour_overview_total" : "Total",
      "scantec_tour_overview_ok" : "OK",
      "scantec_tour_overview_medium" : "Medium",
      "scantec_tour_overview_nok" : "NOK",
      "scantec_tour_overview_not_emptied": "not emptied",
      "scantec_tour_overview_percentage" : "NOK Percentage",
      "scantec_tour_details" : "Tour Details",
      "scantec_map_view" : "Map View",
      "scantec_analysis" : "Analysis",
      "scantec_tour_overview_all_bin_are_emptied" : "All bins emptied.",
      "scantec_tour_overview_bin_not_emptied" : "bins not emptied.",
      "scantec_tour_overview_cockpit" : "Deepscan Settings",
      "scantec_tour_overview_tour_management" : "Tour Management",
      "scantec_tour_overview_default_cockpit" : "Default Cockpit",
      "scantec_tour_overview_split_tour" : "Split Tour",
      "settings_sc_tour_save_success_notification": "Tour successfully saved",
      "settings_sc_tour_save_failure_notification": "Error by saving",
      "settings_sc_tour_delete_success_notification": "Tour successfully deleted.",
      "settings_sc_tour_delete_failure_notification": "Error while saving Tour",
      "st_tour_overview_sum_total": "SUM Total",
      "st_tour_overview_sum_ok": "SUM OK",
      "st_tour_overview_sum_medium": "SUM Medium",
      "st_tour_overview_sum_nok": "SUM NOK",
      "st_tour_overview_avg_percentage": "AVG Percentage",
      "scantec_settings_tourcollector_title" : "Tour Collector",
      "st_settings_tourcollector_date" : "Date *",
      "st_settings_tourcollector_time" : "Time",
      "st_settings_tourcollector_truck" : "Vehicle",
      "st_settings_tourcollector_tour" : "Tour",
      "st_settings_tourcollector_number_of_emptyings" : "Number of Emptyings",
      "st_settings_tourcollector_save_confirm_title" : "Save Confirmation",
      "st_settings_tourcollector_save_confirm_message": "Are you sure to save the changes?",
      "st_settings_tourcollector_split_tour": "Split Tour",
      "st_settings_tourcollector_merge_tour": "Merge Tour",
      "cockpit_scantec_deep_scan_setings_title": "DeepScan Settings",
      "cockpit_scantec_current_deep_scan_setings": "Current",
      "cockpit_scantec_deep_scan_setings_history": "History",
      "cockpit_scantec_update_deep_scan_setings": "Change Settings",
      "cockpit_scantec_deep_scan_setings_item": "Settings Item",
      "cockpit_scantec_deep_scan_setings_description": "Description",
      "cockpit_scantec_deep_scan_setings_from_value": "from (value)",
      "cockpit_scantec_deep_scan_setings_to_value": "to (value)",
      "deep_scan_settings_is_left_deep_scan_on": "Left DeepScan",
      "deep_scan_settings_is_right_deep_scan_on": "Right DeepScan",
      "deep_scan_settings_threshold_first": "Yellow Threshold",
      "deep_scan_settings_threshold_second": "Red Threshold",
      "deep_scan_settings_is_setting_via_display_allowed": "Is Settings Via Display Allowed",
      "deep_scan_settings_lifter_stop_active": "Lifter Stop",
      "deep_scan_settings_alarm_is_enabled": "Alarm Sound",
      "deep_scan_settings_thresholds": "Thresholds",
      "st_deep_scan_settings_save_confirm_title": "Confirm changes",
      "st_deep_scan_settings_save_confirm_message": "Do you want to send the setting changes?",
      "st_deep_scan_settings_delete_confirm_title": "Discard changes",
      "st_deep_scan_settings_delete_confirm_message": "Do you want to discard the setting changes?",
      "st_deep_scan_settings_enter_valid_number": "Please enter a valid number.",
      "st_deep_scan_settings_first_threshold_is_required": "First threshold is required.",
      "st_deep_scan_settings_allowed_min_is_15": "Allowed minimum value is 15.",
      "st_deep_scan_settings_allowed_max_is_200": "Allowed minimum value is 200.",
      "st_deep_scan_settings_first_thresh_less_than_second": "First threshold must be less than Second threshold.",
      "st_deep_scan_settings_second_threshold_is_required": "Second threshold is required.",
      "st_deep_scan_settings_allowed_min_is_20": "Allowed minimum value is 20.",
      "st_deep_scan_settings_second_thresh_greather_than_first": "Second threshold must be greater than First threshold.",
      "st_deep_scan_settings_request_change_status": "Edit Mode",
      "st_deep_scan_settings_request_desription": "Description",
      "st_deep_scan_settings_current_information_is_read_only": "The information on this page is readonly.",
      "st_deep_scan_settings_current_received_date": "Received Date: ",
      "st_deep_scan_settings_update_new_request_title": "Change Settings",
      "st_tour_overview_status_today_less_5_green": "DeepScan is turned on.",
      "st_tour_overview_status_today_less_5_red": "An error has occured. Please check the error messages!",
      "st_tour_overview_status_today_less_5_grey": "DeepScan is turned off.",
      "st_tour_overview_status_today_grey": "No data was received in the last 5 minutes.",
      "st_tour_overview_status_not_today_green": "Tour has been successfully completed.",
      "st_tour_overview_status_not_today_red": "There has been an error. Please check the error messages!",
      "st_tour_overview_status_not_today_grey": "There is no result, because DeepScan was turned off.",
      "st_tour_overview_last_data_received": "Last data received: ",
      "st_tour_overview_to_the_tour_details": "to the Tour Details",
      "st_tour_overview_to_the_map_view": "to the Map Overview",
      "st_analysis_title": "Quality Distribution",
      "st_analysis_OK_title": "DeepScan Clean",
      "st_analysis_middle_title": "DeepScan Middle",
      "st_analysis_NotOK_title": "DeepScan Dirty",
      "st_analysis_OK_smart_l_title": "SmartScan Clean",
      "st_analysis_middle_smart_l_title": "SmartScan Middle",
      "st_analysis_NotOK_smart_l_title": "SmartScan Dirty",
      "st_analysis_OK_smart_title": "Clean-S",
      "st_analysis_middle_smart_title": "Middle-S",
      "st_analysis_NotOK_smart_title": "Dirty-S",
      "st_analysis_OK_deep_title": "Sauber-D",
      "st_analysis_middle_deep_title": "Mittel-D",
      "st_analysis_NotOK_deep_title": "Verschmutzt-D",
      "st_analysis_no_tours": "There are no tours!",
      "st_analysis_not_emptied_bins_count": "Not Emptied bins count:",
      "st_analysis_not_emptied_bins_percentage": "Not Emptied bins percentage:",
      "st_analysis_max_10_tours_are_displayed": "A maximum of 10 tours are displayed.",
      "st_analysis_tours_count": "Tours count:",
      "st_analysis_not_emptied_percentage": "Not Emptied",
      "st_analysis_summary_selected_series": "Composition of waste for the filtered area",
      "st_analysis_summary_no_selected_series": "Composition of waste",
      "scantec_map_date" : "Date",
      "scantec_map_address" : "Address",
      "scantec_map_measurement" : "Measurement",
      "scantec_map_result" : "Result",
      "scantec_map_binId" : "Bin Id",
      "scantec_map_tour" : "Tour",
      "scantec_map_emptied" : "Emptying status",
      "scantec_map_copy_text" : "Message copied",
      "st_deep_scan_settings_send" : "Send",
      "st_tour_collector_classifier_type_unassigned" : "Unassigned",
      "st_tour_details_title": "Tour Details",
      "st_tour_details_rfid": "RFID",
      "st_tour_details_other_material_load": "Other material load",
      "st_tour_details_result": "OK/NOK",
      "st_tour_details_date": "Date",
      "st_tour_details_is_emptied": "Is Emptied",
      "st_tour_details_thresholds_not_available": "Thresholds not available",
      "st_tour_details_bin_emptied": "Bin emptied",
      "st_tour_details_bin_not_emptied": "Bin not emptied",
      "st_tour_details_lifter_type": "Lifter Type",
      "st_tour_details_lifter_size": "Lifter Size",
      "st_tour_details_measurement": "Measurement",
      "st_tour_details_export_in_progress_message": "Export in progress",
      "st_tour_details_report_cannot_be_generated": "Please refine the filters, too much data.",
      "st_tour_details_select_bin_result": "Select DeepScan result",
      "st_tour_details_select_st_bin_result": "Select SmartScan result",
      "st_tour_details_rfid_description": "RFID description",
      "st_tour_detail_ident_system_not_available": "Ident system not available",
      "st_tour_detail_chip_not_detected": "CHIP not detected",
      "st_bin_type_left": "Left",
      "st_bin_type_right": "Right",
      "st_bin_type_arm": "Arm",
      "st_bin_type_comb": "Comb",
      "st_bin_type_two_wheel_left": "2-wheel left",
      "st_bin_type_two_wheel_right": "2-wheel right",
      "st_bin_type_four_wheel": "4-wheel",
      "st_bin_event_result_green": "Green",
      "st_bin_event_result_yellow": "Yellow",
      "st_bin_event_result_red": "Red",
      "st_bin_event_result_not_evaluated": "not evaluated",
      "settings_subscription_daily": "Daily",
      "settings_subscription_weekly": "Weekly",
      "settings_subscription_daily_desc": "Receive tour reports on daily basis",
      "settings_subscription_weekly_desc": "Receive tour reports on weekly basis",
      "settings_subscription_tour_sub": "Tour Koco Sub",
      "change_picture_btn": "Change Picture",
      "upload_picture_btn": "Upload Picture",
      "settings_subscription_tour": "Tour Koco Sub",
      "layout_default_logout": "Log out",
      "layout_default_my_profile": "My Profile",
      "layout_default_no_options": "no options",
      "layout_default_clear": "clear",
      "layout_default_font_size": "Font Size",
      "layout_default_toggle_fullscreen": "Toggle fullscreen",
      "subscription_type": "Type",
      "tour_analyze_active": "Tour analyze is active",
      "tour_analyze_inactive": "Tour analyze is inactive",
      "fleet_tracking_icon": "Fleet Tracking",
      "refresh_lanes": "Refresh Lanes",
      "last_location": "Last Location",
      "st_tour_new": "Add Record",
      "service_dashboard_rcv_body_remain_work_hour": "Body remaining working hours:",
      "service_dashboard_rcv_body_active_error_count": "Active error count:",
      "service_dashboard_sweeper_water_tank_level": "Water tank level:",
      "service_dashboard_sweeper_next_service_time": "Time to next service:",
      "service_dashboard_sweeper_body_motor_diesel": "Body motor diesel:",
      "service_dashboard_sweeper_adBlue": "AdBlue:",
      "service_dashboard_sweeper_diesel": "Diesel:",
      "fleet_overview_basic_data": "Basic Data",
      "fleet_overview_operational_data": "Operational Data",
      "fleet_overview_notification": "Notifications",
      "search_address_map": "Search Address",
      "not_emptied": "Not emptied",
      "go_to_page": "Go to page: ",
      "scantec_more_filters_icon": "Show more filters",
      "scantec_less_filters_icon": "Show less filters",
      "settings_st_tour_data_duplicated": "Tour data already exists",
      "settings_st_tour_is_uplicated_in_db": "duplicated in DB",
      "settings_st_bin_event_delete_confirm_message_1": 'Are you sure to delete the',
      "settings_st_bin_event_delete_confirm_message_2": 'selected Tour Details?',
      "settings_st_bin_event_delete_confirm_title": "Delete Confirmation",
      "settings_st_bin_event_delete_success_notification": "Tour Detail(s) successfully deleted.",
      "settings_st_bin_event_actions": "Actions",
      "settings_st_bin_event_title": "Tour Details Edit",
      "settings_st_bin_event_to_edit_page" : "Tour Details Edit",
      "settings_depot_title": "Depot administration",
      "settings_depot_new_depot": "New Depot",
      "settings_depot_new": "New",
      "settings_depot_depot": "Depot",
      "settings_depot_description": "Description",
      "settings_depot_vehicles": "Number of Vehicles",
      "settings_depot_no_data": "There are no Depots!",
      "settings_depot_no_such_depot": "There is no such Depot!",
      "settings_depot_go_to_management": "To the Depot Management",
      "settings_depot_abbreviaiton": "Abbreviation",
      "settings_depot_detail": "Depot Detail",
      "settings_depot_info": "Depot Info",
      "settings_depot_name": "Name",
      "settings_depot_streetName": "Street Name",
      "settings_depot_streeNumber": "Street Number",
      "settings_depot_city": "City",
      "settings_depot_zip": "Zip Code",
      "settings_depot_country": "Country",
      "settings_depot_delete_content": "Are you sure you want to delete this depot?",
      "settings_depot_delete": "Delete",
      "settings_depot_depot_name": "Depot Name",
      "cockpit_ecotec": "ECOTEC",
      "cockpit_ecotec_basic_info_hint": "Basic information of the truck",
      "cockpit_ecotec_detail_info_hint": "Detail information of the truck",
      "cockpit_ecotec_detail_info": "Details",
      "cockpit_ecotec_basic_info": "Basic Information",
      "cockpit_ecotec_vehicle_name": "Name",
      "cockpit_ecotec_vehicle_manufacturer": "Manufacturer",
      "cockpit_ecotec_vehicle_license": "License",
      "cockpit_ecotec_vehicle_weight": "Weight (kg)",
      "cockpit_ecotec_vehicle_timezone": "Timezone",
      "cockpit_ecotec_vehicle_vehicleType": "Vehicle Type",
      "cockpit_ecotec_vehicle_labelId": "Label-ID",
      "cockpit_ecotec_vehicle_model": "Model",
      "cockpit_ecotec_vehicle_ktr": "Ktr",
      "cockpit_ecotec_vehicle_constructionType": "Construction Type",
      "cockpit_ecotec_vehicle_chassisType": "Chassis Type",
      "cockpit_ecotec_vehicle_chassisNumber": "Chassis Number",
      "cockpit_ecotec_vehicle_lifterType": "Lifter Type",
      "cockpit_ecotec_vehicle_lifter": "Lifter",
      "cockpit_ecotec_vehicle_lifterNumber": "Lifter Number",
      "cockpit_ecotec_vehicle_axisConfiguration": "Axis Configuration",
      "cockpit_ecotec_vehicle_examination": "Examination",
      "cockpit_ecotec_vehicle_securityCheck": "Security Check",
      "cockpit_ecotec_vehicle_tachoCheck": "Tacho Check",
      "cockpit_ecotec_vehicle_uvvCheck": "UVV Check",
      "cockpit_ecotec_save_btn": "Save",
      "st_tour_details_overview_title": "Overview",
      "st_tour_details_smartscan_title": "SmartScan",
      "st_tour_details_smartscan_col": "SmartScan",
      "st_tour_details_waiting_for_data": "waiting for data from Smartscan",
      "st_tour_details_no_smartscan_data_received": "no SmartScan data received",
      "st_tour_details_popup_image_info": "Image infos",
      "st_tour_details_popup_date_time": "Date/Time",
      "st_tour_details_popup_image_number": "Image Number",
      "st_tour_details_popup_rfid_number": "RFID",
      "st_tour_details_popup_captured_fractions": "Captured Fractions",
      "st_tour_details_popup_no_results": "no results",
      "st_tour_details_popup_reason": "reason", 
      "st_data_device_no_option": "No Option",
      "st_data_device_deep_scan": "DeepScan",
      "st_data_device_deep_and_smart_scan": "Deep- & SmartScan",
      "st_data_device_smart_scan": "SmartScan",
      "st_tour_overview_select_device_type": "Select Scan Type",
      "st_tour_overview_classifier": "Classifier",
      "st_tour_overview_device_type": "Scan Type",
      "st_tour_overview_thresholds": "Thresholds",
      "st_tour_overview_evaluation_good": "Good Evaluation",
      "st_tour_overview_evaluation_middle": "Middle Evaluation",
      "st_tour_overview_evaluation_bad": "Bad Evaluation",
      "st_tour_overview_not_allowed": "Not allowed",
      "st_tour_overview_allowed": "Allowed",
      "st_tour_overview_description": "Description",
      "st_tour_overview_classifier_type": "Type",
      "st_tour_overview_classifier_public": "Public",
      "st_tour_overview_classifier_private": "Private",
      "st_tour_overview_parent": "Parent",
      "st_tour_overview_ai_model": "AI-Model",
      "st_fraction_0": "Background",
      "st_fraction_1": "Ignore",
      "st_fraction_2": "Residual waste",
      "st_fraction_3": "Organic waste",
      "st_fraction_4": "Biodegradable bag",
      "st_fraction_5": "Glass",
      "st_fraction_6": "Metal",
      "st_fraction_7": "Paper",
      "st_fraction_8": "Cardboard",
      "st_fraction_9": "Plastic",
      "st_fraction_10": "PET",
      "st_fraction_11": "Other garbage bag",      
      "st_tour_plan_scan_type": "Scan Type",
      "st_no_scan_device": "no scan device available",

      "smartscan_classifier": "SmartScan Classifer Settings",
      "smartscan_stclassifier": "SmartScan Classifiers",
      "new_smartscan_stclassifier": "New Classifier",
      "smartscan_search_stclassifier": "Search Classifier",
      "stclassifier_table_name": "Name",
      "stclassifier_table_description": "Description",
      "stclassifier_table_company": "Company",
      "stclassifier_table_type": "Type",
      "stclassifier_table_enabled": "Enabled",
      "smartscan_stsclassifier_details": "Classifier Detail",
      "settings_stclassifier_delete": "Delete",
      "smartscan_stclassifier_save_title": "Confirmation",
      "smartscan_stclassifier_save_message": "Are you sure you want to save the classifier?",
      "smartscan_stClassifier_ispublic": "Is Public",
      "smartscan_stclassifier_aimodels": "AI Models",
      "smartscan_stclassifier_classifiers": "Classifiers",
      "smartscan_stclassifier_goodrating": "Good Rating:",
      "smartscan_stclassifier_mediumrating": "Medium Rating:",
      "smartscan_stclassifier_badrating": "Bad Rating:",
      "smartscan_stclassifier_notallowed": "Not Allowed",
      "smartscan_stclassifier_allowed": "Allowed",
      "smartscan_stclassifier_addcluster": "Add Cluster",
      "smartscan_stclassifier_enable": "Enable",
      "smartscan_stclassifier_disable": "Disable",
      "smartscan_stclassifier_enable_title": "Confirmation",
      "smartscan_stclassifier_enable_message": "Are you sure you want to enable the classifier?",
      "smartscan_stclassifier_disable_title": "Confirmation",
      "smartscan_stclassifier_disable_message": "Are you sure you want to disable the classifier?",
      "smartscan_stclassifier_name_unique": "Classifier with this name already exists, name must be unique",
      "smartscan_stclassifier_aimodel_required": "AI Model is required",
      "smartscan_stclassifier_parent_required": "Parent Classifier is required",
      "smartscan_stclassifier_allowed_threshold_error": "Good Rating cannot be less than or equal to Bad rating",
      "smartscan_stclassifier_notallowed_threshold_error": "Good Rating cannot be greater than or equal to Bad rating",
      "st_analysis_overview_title": "Overview",
      "st_analysis_deepscan_title": "DeepScan",
      "st_analysis_smartscan_title": "SmartScan",   
      "st_analysis_deepscan_thresholds_changed": "thresh. changed",
      "st_analysis_thresh_yellow": "threshh. y:",
      "st_analysis_thresh_red": "r:",     
      "settings_user_actions": "Actions",
      "setting_update_action": "Copy",
      "smartscan_stclassifier_editable": "This classifier is only editable till next working day.",
      "smartscan_stclassifier_noteditable": "This classifier cannot be edited anymore.",
      "st_smartscan_OK_title": "Smartscan Clean",
      "st_smartscan_middle_title": "Smartscan Middle",
      "st_smartscan_NotOK_title": "Smartscan Dirty",
      "st_smartscan_grey": "Smartscan not classified",
      "st_smartscan_white": "Smartscan pending",
      "st_smartscan_data_missing_map": "some of the smartscan data is missing",
      "handovers_tab_hint": "In PDF Protocol you can find information about handovers of trucks like date, has the truck handed over or not, km, hours used and the customer name. Moreover you can download the handover report from here",
      "handovers_tab": "PDF Protocol",
      "handovers_date": "Date",
      "handovers_type": "Type",
      "handovers_customer": "Customer",
      "handovers_km": "Mileage",
      "handovers_hours_used": "Working hours",
      "handovers_actions": "Actions",
      "handovers_handover": "Handover",
      "handovers_withdrawl": "Withdrawl",
      "handovers_download": "Download",
      "cockpit_errorreports_internal_fleet_manager": "Internal Fleet Number",
      "cockpit_errorreports_construction_type": "Body-type",
      "cockpit_errorreports_license_plate": "License number",
      "cockpit_errorreports_report_date": "Creation date of the report",      
      "cockpit_tab_live_data_rcv_unit": "Unit",
      "st_mapoverview_emptied": "Emptied",
      "st_mapoverview_popup_binId": "CHIP not recognized",
      "st_bin_event_measuremnt_code_0": "DeepScan off",
      "st_bin_event_measuremnt_code_minus_1": "No measurements recorded",
      "st_bin_event_measuremnt_code_minus_2": "Plausibility check failed",
      "st_bin_event_measuremnt_code_minus_3": "No valid measurements recorded (e.g., >200)",
      "st_bin_event_measuremnt_code_minus_4": "No COMP-2 performed yet",
      "st_bin_event_measuremnt_code_minus_5": "Samples less than 33 (plausibility cannot be performed)",
      "st_bin_event_measuremnt_code_minus_6": "Detectors not available / disabled ",
      "st_bin_event_measuremnt_code_104": "Plausibility check failed",
      "st_bin_event_measuremnt_code_255": "No measurements recorded",
      "st_bin_event_measuremnt_code_not_given": "unknown behavior",
      "settings_error_group_body_type" : "Body Type",
      "settings_error_code_group_title": "Error group administration",
      "settings_error_code_group_new_error_group": "New Error group",
      "settings_error_code_group_new": "New",
      "settings_error_group_info": "Error group Info",
      "settings_error_group_no_such_error_group": "There is no such error group!",
      "settings_error_group_go_to_management": "To the Error group Administration",
      "settings_error_group_no_data": "There are no error groups!",
      "settings_error_group_new": "New",
      "settings_error_code_group_detail": "Error group detail",
      "settings_body_type": "Body Type",
      "overview_menu_error_code": "Error Code",
      "overview_menu_error_description": "Error-\ndescription",
      "settings_error_group_description": "Description",
      "settings_error_group_name": "Name",
      "settings_error_group_name_min_length": "The Error group name must be at least 5 characters",
      "settings_error_group_save_message": "Are you sure to save the error group?",
      "settings_error_group_save_title": "Confirmation",
      "settings_error_group_enable_title": "Confirmation",
      "settings_error_group_enable_message": "Are you sure to enable the error group?",
      "settings_error_group_disable_title": "Confirmation",
      "settings_error_group_disable_message": "Are you sure to disable the error group?",
      "settings_error_group_public_title" : "Public",
      "settings_error_group_public_message": "Are you sure to update the status of error group?",
      "settings_error_group_private_title" : "Private",
      "settings_error_group_private_message": "Are you sure to update the status of error group?",
      "settings_error_groups_privacy_status" : "Privacy Status",
      "settings_error_group_public" : "public",
      "settings_error_group_private" : "private",
      "error_group_not_accessible": "You do not have access to see error groups",
      "settings_error_tab_send_notifications_on_resovled": "Send Notifications on Resolved",
      "event_info_tour_koco": "Tour Koco",
      "deep_scan_settings_thresholds_tooltip": "Smaller value means higher sensitivity",
      "pagination_go_to_first_page": "Go to first page",
      "pagination_go_to_last_page": "Go to last page",
      "pagination_go_to_next_page": "Go to next page",
      "pagination_go_to_previous_page": "Go to previous page",
      "settings_user_sendlink_title": "Confirmation",
      "settings_user_sendlink_message": "Are you sure you want to resend the link to the user?",
      "st_map_show_markers": "Show tour",
      "st_map_hide_markers": "Hide tour",
      "st_analysis_NotEvaluated_title": "Deepscan not evaluated",
      "st_mapoverview_popup_binId_not_avail": "Identification system not available",
      "st_analysis_NotEvaluated_title": "Deepscan not evaluated",
      "tour_overview_title": "Overview",
      "tour_overview_save_success_notification": "Touroverview saved successfully.",
      "tour_overview_save_failure_notification": "Error in saving data.",
      "tour_overview_save_confirm_title": "Save Confirmation",
      "tour_overview_save_confirm_message": "Are you sure to save the Overivew?",
      "tour_analyse_tour_data_duplicated": "Tour data already exists",
      "tour_analyse_tour_plan_save_confirm_title": "Save Confirmation",
      "tour_analyse_tour_plan_save_confirm_message": "Are you sure to save the Tour Plan?",
      "tour_analyse_tour_delete_confirm_title": "Delete Confirmation",
      "tour_analyse_tour_delete_confirm_message": "Are you sure to delete the Tour?",
      "tour_analyse_tour_delete_success_notification": "Tour successfully deleted.",
      "tour_analyse_tour_delete_failure_notification": "Error while saving Tour",
      "tour_analyse_settings_role_name" : "Name",
      "tour_analyse_tour_plan_actions": "Actions",
      "tour_analyse_tour": "Tour",
      "tour_analyse_tour_plan_save_button": "Save Changes",
      "tour_analyse_tour_save_confirm_message": "Are you sure to save the Tour?",
      "settings_ta_tour_save_success_notification": "Tour successfully saved",
      "settings_ta_tour_title": "Tour",
      "settings_ta_tour_new_title": "New Tour",
      "settings_ta_tour_plan_save_button": "Save Changes",
      "settings_ta_tour_save_confirm_message": "Are you sure to save the Tour?",
      "settings_ta_tour_plan_template": "Template",
      "settings_ta_tour_data_duplicated": "Tour data already exists",
      "tour_analyse_tour_plan_save_confirm_title": "Save Confirmation",
      "tour_analyse_tour_plan_save_confirm_message": "Are you sure to save the Tour Plan?",
      "settings_ta_tour_delete_confirm_title": "Delete Confirmation",
      "settings_ta_tour_delete_confirm_message": "Are you sure to delete the Tour?",
      "settings_ta_tour_is_uplicated_in_db": "duplicated in DB",
      "settings_ta_tour_plan_actions": "Actions",
      "ta_tour_new": "Add Record",
      "settings_ta_tour_plan_import_label": "File to import",
      "ta_tour_details_title": "Address List",
      "ta_tour_details_bin_not_emptied": "Tonne nicht geleert",
      "ta_tour_details_bin_emptied": "Bin emptied",
      "ta_tour_details_date": "Date",
      "ta_tour_details_rfid": "RFID",
      "ta_tour_detail_ident_system_not_available": "Ident system not available",
      "ta_tour_detail_chip_not_detected": "CHIP not detected",
      "ta_tour_details_is_emptied": "Is Emptied",
      "ta_tour_details_lifter_type": "Lifter Type",
      "ta_bin_type_two_wheel_left": "2-wheel left",
      "ta_bin_type_two_wheel_right": "2-wheel right",
      "ta_bin_type_arm": "Arm",
      "ta_bin_type_four_wheel": "4-wheel",
      "ta_bin_type_comb": "Comb",
      "tour_analyse_overview_vehicle" : "Vehicle",
      "tour_analyse_overview_tour" : "Tour",
      "tour_analyse_overview_address" : "Address",
      "ta_tour_details_overview_title": "Overview",
      "ta_tour_details_export_in_progress_message": "Export in progress",
      "ta_tour_select_label" : "Select tour",
      "tour_analyse_filters_icon": "Show less filters",
      "tour_analyse_more_filters_icon": "Show more filters",
      "ta_tour_details_rfid": "RFID",
      "ta_tour_details_report_cannot_be_generated": "Please refine the filters, too much data.",
      "ta_map_fleet_tracking_title": "Map",
      "ta_map_search_address_map": "Search Address",
      "ta_map_mapoverview_emptied": "Emptied",
      "ta_map_not_emptied": "Not emptied",
      "ta_map_tour" : "Tour",
      "ta_map_date" : "Date",
      "ta_map_address" : "Address",
      "ta_map_layout_default_no_options": "no options",
      "ta_map_layout_default_clear": "clear",
      "ta_map_tour_select_label" : "Select tour",
      "ta_map_tour_analyse_truck_selection" : "Select vehicle",
      "ta_map_settings_tour_plan_new_add_record": "Add Record",
      "ta_map_layout_default_search": "Search",
      "settings_subscription_type": "First Pick Body Type, Then Subscription Type",
      "settings_subscription_types": "Subscription Type",
      "settings_subscription_users": "User",
      "settings_errors_tab_body_type": "Body Type",
      "settings_error_group_warning_title" : "WARNING",
      "settings_error_group_warning_message": "A user has subscribed to this Error Group. Disabling it will affect the results of the subscription",
      "errors_tab_default_enabled": "Enabled/Public",
      "ta_map_type_bin": "Bin Type",
      "ta_map_left_bin": "2-Wheel left",
      "ta_map_right_bin": "2-Wheel right",
      "ta_map_4wheel_bin": "4-Wheel",
      "scantec_map_plausibility_check": "Plausibility check failed",
      "scantec_map_no_valid_value": "No valid measured values received",
      "scantec_map_compensation_check": "Compensation2 not performed",
      "scantec_map_few_samples": "Too few measured values",
      "scantec_map_deepscan_off": "DeepScan not available",
      "tour_plan_maxmimum_payload_allowed": "Payload",
      "collection_type_rest": "Rest",
      "collection_type_bio": "Bio",
      "collection_type_glass": "Glass",
      "collection_type_dsd_recycling": "DSD",
      "collection_type_bulky": "Bulky",
      "collection_type_paper": "Paper",
      "collection_type_title": "Waste Type",
      "send_new_request": "Send New Request",
      "ta_map_reset_filter": "Reset Filter",
      "ta_total_bins" : "Total",
      "reports_title": "Performance Reports",
      "reports_date": "Date",
      "reports_vehicle": "Vehicle",
      "reports_type": "Type",
      "reports_actions": "Actions",
      "report_detail_title": "Reprots details",
      "report_type_performance_rcv_diesel": "Perf. RCV Diesel",
      "report_type_performance_rcv_bluepower": "Perf. RCV Bluepower",
      "login_character_match": "Rules: Minimum 8 Characters, 1 Capital letter and at least 1 Number",
      "login_UpperCase_match" : "Password must contain at least one uppercase letter.",
      "login_Number_match" : "Password must contain at least one number.",
      "digital_service_book_body_title": "Body",
      "digital_service_book_lifter_title": "Lifter",
      "digital_service_book_legal_title": "Legal",
      "settings_st_regions_title": "Regions",
      "settings_st_region_new": "New Region",
      "st_regions_search_text": "Enter Region for Search",
      "settings_st_regions_name": "Name",
      "settings_st_regions_description": "Description",
      "settings_st_regions_type": "Type",
      "settings_st_regions_actions": "Action",
      "settings_st_region_title": "Region",
      "settings_st_region_detail": "Region Details",
      "settings_st_region_name": "Name",
      "settings_st_region_type": "Type",
      "login_Number_match" : "Password must contain at least one number.",
      "st_tour_detail_invalid_RFID": "Invalid RFID",
      "ta_tour_detail_invalid_RFID": "Invalid RFID",
      "ta_overview_view_mode": "View Mode",
      "ta_overview_edit_mode": "Edit Mode",
      "st_no_scantec_device_role_assigned": "No SCANTEC-Device roll has been granted. Please contact the administrator.",
      "ta_report_info": "Report Templates",
      "ta_report_info_new": "New Report Template",
      "ta_report_info_search": "Search Report Template",
      "ta_report_info_details": "Report Details",
      "cockpit_tab_default_not_available_abbrevation": "Not Avaliable",
      "reports_fit_to_content": "Fit to content",
      "report_dialog_hint": "Please fill in the form below. The Name field is required.",
      "report_save_new_layout": "Save New Template",
      "report_name": "Name",
      "report_description": "Description",
      "report_save_as_new_template": "Save as new template",
      "report_update_template": "Update template",
      "report_this": "This",
      "report_value": "value",
      "report_indicator": "indicates that it is",
      "report_not_avaliable": "not avaliable",
      "report_truck": "on truck",
      "report_template_name": "Name",
      "report_template_desc": "Description",
      "reports_metrics": "Metrics",      
      "settings_subscription_performance_report": "Performance Reports",
      "performance_report_weekly": "Weekly",
      "performance_report_monthly": "Monthly",
      "performance_report_cycle": "Cycle",
      "performance_report_reports": "Reports",
      "performance_report_sub": "You can only select trucks of the same type",
      "st_deep_scan_settings_current_information_no_deepscan": "This vehicle is not equipped with DeepScan.",
      "performance_report_sub_type": "Ensure all selected vehicles are the same type to enable this option",
      "ta_address_list_address_disabled": "Please select a vehicle first to enable address search",
      "settings_email_administration_title" : "E-Mail Administration",
      "settings_email_subject": "Subject of Email",
      "settings_email_time": "Time",
      "settings_email_recipients" : "Recipients",
      "settings_email_sender" : "Sender",
      "settings_email_new_email" : "New Email",
      "settings_email_new" : "New",
      "settings_email_detail": "E-Mail detail",
      "settings_email_subject": "Subject of Email",
      "settings_email_info": "Email Info",
      "settings_email_data": "Enter you email here.....",
      "settings_send_email": "Send E-Mail",
      "settings_discard_email": "Discard E-Mail",
      "settings_email_discard_message": "Are you sure to discard the email?",
      "settings_email_save_title": "Confirmation",
      "settings_email_save_message": "Are you sure to send the email?",
      "settings_no_such_email": "There is no such email!",
      "settings_email_go_to_management": "To the Email Administration",
      "settings_email_no_data": "There are no emails!",
      "email_not_accessible": "You do not have access to see emails",
      "reports_layout_type": "Reports",
      "layout_default_delete_tour_plan" : "Delete data",
      "layout_default_discard_tour_plan" : "Discard changes",
      "tickets_title": "Service Queries",
      "tickets_new_ticket": "New service query",
      "tickets_from_date": "Date from",
      "tickets_to_date": "Date to",
      "tickets_truck_selection" : "Select vehicle",
      "tickets_ticket_info": "Service query info",
      "tickets_created_by": "Created by",
      "tickets_status": "Status",
      "tickets_creation_date": "Created at",
      "tickets_ticket_number": "Service query number",
      "tickets_ticket_title": "Service query title",
      "tickets_service_center": "Service center",
      "tickets_truck": "Vehicle",
      "tickets_escalation": "code",
      "tickets_last_update": "Last updated",
      "tickets_digital_service_new_btn_tooltip": "This vehicle already has a service query in progress",
      "tickets_digital_service_view_btn_tooltip": "No service query is available for this vehicle",
      "tickets_digital_service_view_ticket": "View Service Query",
      "tickets_digital_service_body_working_hours": "Body Working Hours",
      "tickets_digital_service_body_working_hours_next_service": "Body Hours till Next service",
      "tickets_digital_service_lifter_working_hours": "Lifter Working Hours",
      "tickets_digital_service_lifter_working_hours_next_service": "Lifter Hours till Next service",
      "tickets_digital_service_pick_date": "Pick a date",
      "tickets_digital_service_center_address": "Address of nearest Service Center",
      "tickets_digital_service_orders": "Service Orders History",
      "tickets_digital_service_clear_filters": "Clear filters",
      "tickets_hide_vehicles": "Hide vehicles",
      "tickets_show_vehicles": "Show vehicles",
      "tickets_ticket_details": "Service Query Details",
      "tickets_ticket_enable": "Enable",
      "tickets_ticket_disable": "Disable",
      "tickets_ticket_save_title": "Confirmation",
      "tickets_ticket_save_message": "Are you sure you want to save this service query?",
      "tickets_ticket_enable_title": "Confirmation",
      "tickets_ticket_enable_message": "Are you sure you want to enable this service query?",
      "tickets_ticket_disable_title": "Confirmation",
      "tickets_ticket_disable_message": "Are you sure you want to disable this service query?",
      "tickets_ticket_description": "Description",
      "tickets_ticket_vehicle": "Vehicle",
      "tickets_ticket_service_center_error": "No service center is assigned to this truck. Please assign a service center before proceeding.",
      "tickets_comment_section": "Comments section",
      "tickets_comment_section_save": "Save",
      "tickets_comment_section_cancel": "Cancel",
      "tickets_comment_section_edit": "Edit",
      "tickets_comment_section_delete": "Delete",
      "tickets_comment_section_write_comment": "Write a comment",
      "tickets_comment_section_new_save_title": "Confirmation",
      "tickets_comment_section_new_save_message": "Are you sure you want to save this comment?",
      "tickets_comment_section_edit_save_title": "Confirmation",
      "tickets_comment_section_edit_save_message": "Are you sure you want to save the changes?",
      "tickets_comment_section_delete_save_title": "Confirmation",
      "tickets_comment_section_delete_save_message": "Are you sure you want to delete this comment?",
      "st_tour_details_data": "Data received",
      "performance_report_cycle_validation":"Cycle is required",
      "performance_report_report_validation":"Report selection is required",
      "ta_tour_overview_status_today_less_5_green": "Tour is still ongoing.",
      "tickets_ticket_status_to_do": "To Do",
      "tickets_ticket_status_in_progress": "In Progress",
      "tickets_ticket_status_done": "Done",
      "tickets_ticket_status_customer_called": "Customer called, customer answers back",
      "tickets_ticket_status_customer_tried_to_reach": "Customer tried to reach - Never reached",
      "tickets_ticket_status_service_center_tried_to_reach": "Service center tried to reach - No one reached",
      "tickets_ticket_status_service_center_called": "Service center called - service centre calling back",
      "tickets_ticket_status_appointment_by_phone": "Appointment arranged by phone – Service query done",
      "cockpit_faun_complete_overview": "Overview",
      "cockpit_faun_complete_world": "Digital Services",
      "cockpit_faun_complete_history": "Notifications",
      "cockpit_faun_complete_history_created_at": "Created At",
      "cockpit_faun_complete_history_email_subject": "Email Subject",
      "cockpit_faun_complete_history_users": "Users",
      "cockpit_faun_complete_history_service_centers": "Service Center",
      "cockpit_faun_complete_overview_new_ticket_card": "Service Query",
      "cockpit_faun_complete_overview_new_ticket_card_text": "Create Service Query",
      "cockpit_faun_complete_overview_no_lifter_data": "No data from lifter available",
      "cockpit_faun_complete_overview_no_body_data": "No data from body available",
      "company_admin_module_company_name": "Company",
      "company_admin_module_date": "Date",
      "company_admin_module_vehicle": "Vehicle",

    };
    
    export default locale;
