import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import getChassisManufacturer from 'app/types/chassisManufacturerType';
import ServiceDashboardUtils, { COLUMN_TYPES } from 'app/utils/ServiceDashboardUtils';
import FosService from 'app/services/fosService';

export const getUnits = createAsyncThunk('main/fleetOverview/units/getUnits', async () => {
  const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: params, headers: { 'X-UI-State': 'kocobox.fleetoverview' } });
  const data = await response.data;
  data.units = data.units.map(unit => {
    unit.serviceStatusColor =
    unit.isRcv ? ServiceDashboardUtils.getIndicatorColor(unit, [1, 7, 12, 13, 14, 15])
    : ServiceDashboardUtils.getIndicatorColor(unit, [21, 29, 12, 13]);
    
    unit.lastDateColor = ServiceDashboardUtils.getIndicatorColor(unit, [17]);
    return unit;
  });
  FosService.setLoggedIn(false);
  return data.units;
});

export const getBodyInfos = createAsyncThunk('main/fleetOverview/units/getBodyInfos', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_BODY_INFOS, { type: 9, ids: ids });
  const data = await response.data;

  return data.value.map(val => {
    return {
      id: val.vehicle,
      changes: {
        chassisType: val.chassisType,
        chassisTypeString: getChassisManufacturer(val.chassisType)
      }
    }
  });
});

export const getWorkingHours = createAsyncThunk('main/fleetOverview/units/getWorkingHours', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_SIGNAL_EVENT_DATA, { signalIds: ['20301', 'S02', 'L01'], ids: ids });
  const data = await response.data;

  if (!data)
    return;

  let changeData = data.value.map(val => {
    if (val.doc.signalId == '20301' || val.doc.signalId == 'S02')
      return { id: val.vehicle, changes: { bodyWorkingHours: KocoUtils.numberWithCommas(val.doc.maxv) } }
    else
      return { id: val.vehicle, changes: { lifterWorkingHours: val.doc.maxv != '-' ? KocoUtils.numberWithCommas((parseInt(val.doc.maxv) / 3600).toFixed(0).replace('.', ',')) : '-' } }

  });
  return changeData;
});

// let liveSignalIds = ['20401', '30201'];
// allPromises.push(Restangular.all('unitslivedatas').post({ signalIds: liveSignalIds, ids: unitIds }).then(function (res) {

export const getKilometerStand = createAsyncThunk('main/fleetOverview/units/getKilometerStand', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_LIVE_DATA, { signalIds: ['30201'], ids: ids });
  const data = await response.data;

  if (!data)
    return;

  let changeData = data.value.map(val => {
    return { id: val.vehicle, changes: { kilometerstand: KocoUtils.convertToKm(val.doc.value) } }
  });
  return changeData;
});

export const getActiveErrorsCount = createAsyncThunk('main/fleetOverview/units/getActiveErrorsCount', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_PASSIVE_VEHICLE_ACTIVE_ERRORS, { ids: ids });
  const data = await response.data;

  if (!data)
    return;

  let changeData = data.value.map(val => {
    return {
      id: val.vehicle,
      changes: {
        activeErrorsCount: val.count,
        errorReportColor: ServiceDashboardUtils.getIndicatorColor({ activeErrorsCount: val.count }, [COLUMN_TYPES.FEHLER_MELDUNGEN]),
      }
    }
  });
  return changeData;
});

export const getPassiveVehicleGroups = createAsyncThunk('main/fleetOverview/units/getPassiveVehicleGroups', async () => {
  const response = await axios.get(`api/v1/passivevehiclegroups`, { params: { limit: 0 }});
  const data = await response.data;

  return data.passivevehiclegroups;
});

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById, selectIds: selectUnitIds } =
  unitsAdapter.getSelectors((state) => { return state.main.fleetOverview.units; });

const passiveVehicleGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectPassiveVehicleGroups, selectById: selectPassiveVehicleGroupById } =
  passiveVehicleGroupsAdapter.getSelectors((state) => state.main.fleetOverview.passiveVehicleGroups);

const fleetOverviewSlice = createSlice({
  name: 'main/fleetOverview',
  initialState: {
    searchText: '',
    columns: [],
    csvResult: [],
    loading: false,
    loadingBodyInfos: false,
    loadingWorkingHours: false,
    loadingKilometerstand: false,
    loadingActiveErrorsCount: false,
    units: unitsAdapter.getInitialState({ }),
    passiveVehicleGroups: passiveVehicleGroupsAdapter.getInitialState({ }),
    loadingPassiveVehicleGroups: false,
    selectedVehicleGroups: [],
  },
  reducers: {
    setUnitsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setSelectedVehicleGroups: {
      reducer: (state, action) => {
        state.selectedVehicleGroups = action.payload;   
      },
    },
    setColumns: {
      reducer: (state, action) => {
        state.columns = action.payload;   
      },
    },
    setCsvResult: {
      reducer: (state, action) => {
        state.csvResult = action.payload;
      },
    },
  },
  extraReducers: {
    [getUnits.pending](state, { payload }) {
      state.loading = true;
      unitsAdapter.removeAll(state.units)
    },
    [getUnits.rejected](state, { payload }) {
      state.loading = false;
    },
    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload)
      state.loading = false;
    },

    [getBodyInfos.pending](state, { payload }) {
      state.loadingBodyInfos = true;
    },
    [getBodyInfos.rejected](state, { payload }) {
      state.loadingBodyInfos = false;
    },
    [getBodyInfos.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
      state.loadingBodyInfos = false;
    },

    [getWorkingHours.pending](state, { payload }) {
      state.loadingWorkingHours = true;
    },
    [getWorkingHours.rejected](state, { payload }) {
      state.loadingWorkingHours = false;
    },
    [getWorkingHours.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload)
      state.loadingWorkingHours = false;
    },

    [getKilometerStand.pending](state, { payload }) {
      state.loadingKilometerstand = true;
    },
    [getKilometerStand.rejected](state, { payload }) {
      state.loadingKilometerstand = false;
    },
    [getKilometerStand.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
      state.loadingKilometerstand = false;
    },

    [getActiveErrorsCount.pending](state, { payload }) {
      state.loadingActiveErrorsCount = true;
    },
    [getActiveErrorsCount.rejected](state, { payload }) {
      state.loadingActiveErrorsCount = false;
    },
    [getActiveErrorsCount.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
      state.loadingActiveErrorsCount = false;
    },

    [getPassiveVehicleGroups.fulfilled](state, { payload }) {
      passiveVehicleGroupsAdapter.setAll(state.passiveVehicleGroups, payload);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.pending]: (state) => {
      state.loadingPassiveVehicleGroups = true;
      passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroups.rejected]: (state) => {
      state.loadingPassiveVehicleGroups = false;
    },
  },
});

export const { setUnitsSearchText, setColumns, setCsvResult, setSelectedVehicleGroups } = fleetOverviewSlice.actions;

export default fleetOverviewSlice.reducer;
