import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourPlans = createAsyncThunk('main/settingsStTourPlan/stTourNew/getStTourPlans', async (searchParams) => {
  const response = await axios.get(`api/v1/sttourplans`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return { sttourplans: data.sttourplans, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsStTourPlan/stTourNew/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTourPlanTemplate = createAsyncThunk('main/settingsStTourPlan/stTourNew/getStTourPlanTemplate', async () => {
  const response = await axios.get(`api/v1/sttourplantemplate`);
  const data = await response.data;

  return data.units;
});

export const postBatchStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourNew/postBatchStTourPlan', async (stTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'sttourplan', row)); });

  try {
    const response = await axios.post(`api/v1/newbatchsttourplans`, { st_tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const splitStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourNew/splitStTourPlan', async (stTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'sttourplan', row)); });

  try {
    const response = await axios.post(`api/v1/splitsttourplans`, { st_tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const postBatchStTourPlanCheck = createAsyncThunk('main/settingsStTourPlan/stTourNew/postBatchStTourPlanCheck', async (stTourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlans);
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('post', 'sttourplan', row)); });

  try {
    const response = await axios.post(`api/v1/newbatchsttourplanscheck`, { st_tour_plans: payload });
    const data = await response.data;

    if (data && data.length > 0) {
      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }

    return data;

  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const deleteStTourPlan = createAsyncThunk('main/settingsStTourPlan/stTourNew/deleteStTourPlan', async (stTourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/sttourplans/${stTourPlan.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsStTourPlan/stTourNew/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.st_tours;
});

export const getStClassifiers = createAsyncThunk('main/settingsStTourPlan/stTourNew/getStClassifiers', async () => {
  const response = await axios.get(`api/v1/stclassifiers`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.stclassifiers;
});

const stTourPlansAdapter = createEntityAdapter({});

export const { selectAll: selectStTourPlans, selectById: selectStTourPlanById } =
  stTourPlansAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlanNew.stTourPlans);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlanNew.stTours);

const stClassifiersAdapter = createEntityAdapter({});

export const { selectAll: selectStClassifiers, selectById: selectStClassifierById } =
  stClassifiersAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlanNew.stClassifiers);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsStTourPlan.stTourPlanNew.units);

const stTourPlanNew = createSlice({
  name: 'main/settingsStTourPlan/stTourNew',
  initialState: {
    searchText: '',
    loadingStTourPlans: false,
    loadingUnits: false,
    savingStTourPlan: false,
    loadingStTours: false,
    loadingStClassifiers: false,
    stTourPlans: stTourPlansAdapter.getInitialState({ }),
    stTourPlansCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    stClassifiers: stClassifiersAdapter.getInitialState({ }),
    isDataDirty: false,
    hasError : false,
    isErrorInDirtyData: false,
    dataStTourPlans: [],
    rowModesModel: {},
    errorOnSave: null,

  },
  reducers: {
    setStTourPlansSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setHasError: {
      reducer: (state, action) => {
        state.hasError = action.payload;
      },
    },
    setIsErrorInDirtyData: {
      reducer: (state, action) => {
        state.isErrorInDirtyData = action.payload;
      },
    },    
    setDataStTourPlans: {
      reducer: (state, action) => {
        state.dataStTourPlans = action.payload;
      },
    },
    setRowModesModel: {
      reducer: (state, action) => {
        state.rowModesModel = action.payload;
      },
    },
    resetStTourPlansNew: {
      reducer: (state, action) => {
        state.errorOnSave = null;
        state.isDataDirty = false;
        stTourPlansAdapter.removeAll(state.stTourPlans);
        state.loadingStTourPlans = false;
        state.savingStTourPlan = false;
        state.rowModesModel = {};
        state.isErrorInDirtyData = false;
        state.hasError = false;
        state.dataStTourPlans = [];
      },
    },
  },
  extraReducers: {
    [getStTourPlans.fulfilled](state, { payload }) {
      stTourPlansAdapter.setAll(state.stTourPlans, payload.sttourplans);
      state.stTourPlansCount = payload.totalcount;
      state.loadingStTourPlans = false;
    },
    [getStTourPlans.pending]: (state) => {
      state.loadingStTourPlans = true;
      stTourPlansAdapter.removeAll(state.stTourPlans);
    },
    [getStTourPlans.rejected]: (state) => {
      state.loadingStTourPlans = false;
    },

    [postBatchStTourPlan.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
      state.isDataDirty = false;
      // state.dataStTourPlans = [];
    },
    [postBatchStTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingStTourPlan = true;
    },
    [postBatchStTourPlan.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.savingStTourPlan = false;
    },

    [splitStTourPlan.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
      state.isDataDirty = false;
      // state.dataStTourPlans = [];
    },
    [splitStTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingStTourPlan = true;
    },
    [splitStTourPlan.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.savingStTourPlan = false;
    },

    [postBatchStTourPlanCheck.fulfilled](state, { payload }) {
      state.checkingStTourPlan = false;
    },
    [postBatchStTourPlanCheck.pending](state, { payload }) {
      state.errorOnSave = null;
      state.checkingStTourPlan = true;
    },
    [postBatchStTourPlanCheck.rejected](state, { payload }) {
      console.log('rejected', payload);
      state.errorOnSave = payload?.error;
      state.checkingStTourPlan = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getStClassifiers.fulfilled](state, { payload }) {
      stClassifiersAdapter.setAll(state.stClassifiers, payload);
      state.loadingStClassifiers = false;
    },
    [getStClassifiers.pending]: (state) => {
      state.loadingStClassifiers = true;
      stClassifiersAdapter.removeAll(state.stClassifiers);
    },
    [getStClassifiers.rejected]: (state) => {
      state.loadingStClassifiers = false;
    },


    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getStTourPlanTemplate.fulfilled](state, { payload }) {
      state.downloadingTemplate = false;
    },
    [getStTourPlanTemplate.pending]: (state) => {
      state.downloadingTemplate = true;
    },
    [getStTourPlanTemplate.rejected]: (state) => {
      state.downloadingTemplate = false;
    },
    
  },
});

export const { 
  setStTourPlansSearchText, 
  setHasError, 
  setIsErrorInDirtyData, 
  setIsDataDirty, 
  setDataStTourPlans,
  setRowModesModel,
  resetStTourPlansNew } = stTourPlanNew.actions;

export default stTourPlanNew.reducer;
