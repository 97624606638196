import { combineReducers } from "@reduxjs/toolkit";
import fleetMarkers from "./FleetTrackingMarkerSlice";
import fleetLoadData from "./FleetTrackingLoadData";
import fleetDieselData from "./FleetTrackingDieselData";
import fleetMileageData from "./FleetTrackingMileageData";
import fleetBlueData from "./FleetTrackingBlueData";
import fleetLiveData from "./FleetTrackingLiveData";
import fleetConsumption from "./FleetTrackingConsumptionData";
import fleetLines from "./FleetTrackingLines";
import fleetSignals from "./FleetTrackingGpsSlice";
import unitFleetTracking from "./FleetTrackingSlice";
import fleetCalculation from "./FleetTrackingCalculation";
import fleetTrackingGps from "./FleetTrackingGpsAllSlice";
import fleetTrackingLines from "./FleetTrackingLinesAllSlice";
const reducer = combineReducers({
  fleetMarkers,
  fleetLoadData,
  fleetDieselData,
  fleetBlueData,
  fleetMileageData,
  fleetLiveData,
  fleetConsumption,
  fleetLines,
  fleetSignals,
  unitFleetTracking,
  fleetCalculation,
  fleetTrackingGps,
  fleetTrackingLines,
});

export default reducer;
