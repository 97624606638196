import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "../../fleetTracking/store/FleetTrackingSlice";

const initialState = {
  entities2: [],
  loading: false,
  isMap:false,
  search: '',
  searchEntities:[],
  ids:[],
  markers:[],
  selectedMarker: {},
  selectedUnits : []
}

export const getUnitsStMap = createAsyncThunk('stMap/units', async () => {
  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.units.map(val => {
    val.nameKoco = val.nameKoco ? val.nameKoco: "-";
    val.title= (val.nameKoco ? val.nameKoco: "-");
    val.value = val.id;
    val.isSelected = false;
    return val;
  });
});


export const unitsStMapSlice = createSlice({
  name: 'stMap2/units',
  initialState,
  reducers: {
    setSelectedUnits: {
      reducer: (state, action) => {
        state.selectedUnits = action.payload;
      },
    }
  },
  extraReducers: {
    [getUnitsStMap.pending]: (state) => {
      state.loading = true
    },
    [getUnitsStMap.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.searchEntities = payload
      state.entities2 = payload
      //state.markers = _.map(payload, 'latestPosition');
    },
    [getUnitsStMap.rejected]: (state) => {
      state.loading = false
    },
  },
});
export const {setSelectedUnits} = unitsStMapSlice.actions;

export const postReducer = unitsStMapSlice.reducer;
export default unitsStMapSlice.reducer;