
const ParameterCategoriesLifter = [

    { 'value': 0, 'from': 0, 'to': 99, 'count': 0, 'label': '0 - 99' },
    { 'value': 1, 'from': 100, 'to': 199, 'count': 0, 'label': '100 - 199' },
    { 'value': 2, 'from': 200, 'to': 299, 'count': 0, 'label': '200 - 299' },
    { 'value': 3, 'from': 300, 'to': 399, 'count': 0, 'label': '300 - 399' },
    { 'value': 4, 'from': 400, 'to': 499, 'count': 0, 'label': '400 - 499' },
    { 'value': 5, 'from': 500, 'to': 599, 'count': 0, 'label': '500 - 599' }

];

export default ParameterCategoriesLifter;



