import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {memo, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import _ from '@lodash';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import FuseLoading from "../../../../@fuse/core/FuseLoading";

function TimelineWidget(props) {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const data = _.merge({}, props.data);
  const {load,signals,position,unloading} = useSelector(({ main }) =>  main.fleetTracking.fleetSignals);
  _.setWith(data, 'options.colors', [theme.palette.secondary.main]);
  _.setWith(data, 'options.markers.strokeColor', theme.palette.background.default);
  const { t } = useTranslation('common');

  if (load) return <FuseLoading />;
  return(
    <Card className="w-full rounded-20 shadow">
      <div className="mb-24 flex flex-row items-center justify-center">
      <Typography className="h3 font-medium">{t('fleet_tracking_mileage_date')} :
        {position && position.firstSignal && <Moment unix local={"de"} format="DD.MM.YYYY">
          {position.firstSignal.sec}
        </Moment>
        }</Typography>
      </div>
        {position && position.firstSignal && position.lastSignal && <Timeline>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    <Moment unix local={"de"} format="HH:mm">
                        {position.firstSignal.sec}
                    </Moment>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot/>
                    <TimelineConnector/>
                </TimelineSeparator>
              <TimelineContent>{t('tour_analyse_start_time')}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    <Moment unix local={"de"} format="HH:mm">
                        {position.lastSignal.sec}
                    </Moment>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot/>
                </TimelineSeparator>
                <TimelineContent>{t('tour_analyse_end_time')}</TimelineContent>
            </TimelineItem>
        </Timeline>
        }
    </Card>
  );
}

export default TimelineWidget;
