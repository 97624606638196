import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StTourDetails = lazy(() => import('./StTourDetails'));

const StTourDetailsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'sttourdetails',
      element: <StTourDetails />,
      auth: [authRoles.ROLE_ST_TOUR_DETAIL_READ], 
    },
  ],
};

export default StTourDetailsConfig;
