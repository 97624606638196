import React, {useRef} from "react";
import withReducer from "../../store/withReducer";
import reducer from '../store';
import {styled, ThemeProvider} from "@mui/material/styles";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {Link, Outlet} from "react-router-dom";
import clsx from "clsx";
import FuseNavigation from "../../../@fuse/core/FuseNavigation";
import FuseSuspense from "../../../@fuse/core/FuseSuspense";
import DocumentationPageBreadcrumb from "./DocumentationPageBreadcrumb";
import FusePageSimple from "../../../@fuse/core/FusePageSimple";
import DocumentationNavigation from "./DocumentationNavigation";
import {motion} from "framer-motion";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import Paper from "@mui/material/Paper";
import {setSearchSignals} from "../unit-detail/store/SignalsSlice";
import {TableCell} from "@mui/material";
import {setSearchParse} from "../reports/store/signalsReports";

function ApiContent() {
    const dispatch = useDispatch();
    const Root = styled(FusePageSimple)(({ theme }) => ({
        height: '100%',
        '& .FusePageSimple-header': {
            minHeight: 64,
            height: 64,
        },
        '& .FusePageSimple-wrapper': {
            minHeight: 0,
        },
        '& .FusePageSimple-content': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: 16,
            [theme.breakpoints.up('md')]: {
                padding: 24,
            },
        },
        '& .FusePageSimple-sidebar': {
            width: 288,
            paddingTop: 8,
        },
        '& .description': {
            fontSize: 20,
            marginBottom: 40,
        },
    }));
  //return  const pageLayout = useRef(null);
    const pageLayout = useRef(null);
    const mainTheme = useSelector(selectMainTheme);

    return (
        <Root
            header={
                <div className="flex items-center justify-center px-4 md:px-12 h-full w-full">
                    <Hidden lgUp>
                        <IconButton
                            onClick={(ev) => pageLayout.current.toggleLeftSidebar()}
                            aria-label="open left sidebar"
                            size="large"
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                    </Hidden>
                    <div className="flex flex-1 items-center sm:justify-center px-8 lg:px-12">
                        <Link
                            color="inherit"
                            to="/documentation"
                            className="text-14 md:text-18 font-medium flex items-center"
                            role="button"
                        >
                            <Icon className="mr-8">import_contacts</Icon> <span>Api - Documentation</span>
                        </Link>
                        <div className="flex flex-1 items-center justify-end px-12">
                            <ThemeProvider theme={mainTheme}>
                        <Paper
                            component={motion.div}
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                            className="flex items-end w-full max-w-512 px-8 py-4 rounded-4 shadow"
                        >
                            <Icon color="action">search</Icon>

                            <Input
                                className="flex flex-1 mx-8"
                                disableUnderline
                                fullWidth
                                inputProps={{
                                    'aria-label': 'Signal',
                                }}
                                onChange={(ev) => dispatch(setSearchParse(ev.target.value))}
                            />
                        </Paper></ThemeProvider></div>
                    </div>
                </div>
            }
            content={
                <div className="max-w-2xl min-h-full flex flex-auto flex-col">
                    <DocumentationPageBreadcrumb />
                    <div className="flex flex-col flex-1 relative py-32">
                        <FuseSuspense>
                            <Outlet />
                        </FuseSuspense>
                    </div>
                </div>
            }
            leftSidebarContent={
                <FuseNavigation
                    className={clsx('navigation')}
                    navigation={DocumentationNavigation.children}
                />
            }
            innerScroll
            ref={pageLayout}
        />
    );
}
export default withReducer('main', reducer)(ApiContent);
