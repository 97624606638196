import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getEventInfo = createAsyncThunk('main/settingsEventInfo/EventInfo/getEventInfo', async (id) => {
  const response = await axios.get(`api/v1/eventinfos/${id}`, { headers: { 'X-UI-State': 'eventInfo.settings.eventInfos' }});

  let data = await response.data;
  if(!data || data === undefined)  
    return null;

    return data;
});

export const patchEventInfo = createAsyncThunk('main/settingsEventInfo/EventInfo/patchEventInfo', async (eventInfo, { getState }) => {
  const { main } = getState();

  let clone = _.cloneDeep(eventInfo);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'eventinfo',clone);
  console.log('eventInfo to patch', payload);
  
  const response = await axios.patch(`api/v1/eventinfos/${eventInfo.id}`, payload);

  const data = await response.data;

  return data;
});

export const postEventInfo = createAsyncThunk('main/settingsEventInfo/EventInfo/postEventInfo', async (eventInfo, { getState }) => {
  const { main } = getState();

  let clone = _.cloneDeep(eventInfo);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'eventinfo', clone);

  const response = await axios.post(`api/v1/eventinfos`, payload);
  const data = await response.data;

  return data;
});

export const activateEventInfo = createAsyncThunk('main/settingsEventInfo/EventInfo/activateEventInfo', async (eventInfo, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/eventinfos/${eventInfo.id}/enable`);
  const data = await response.data;

  return data;
});

export const deleteEventInfo = createAsyncThunk('main/settingsEventInfo/EventInfo/deleteEventInfo', async (eventInfo, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/eventinfos/${eventInfo.id}`);
  const data = await response.data;

  return data;
});

const generateNewEventInfo = () => {
  return {
    id: '',
    name: '',
    description: '',
  }
};

const productSlice = createSlice({
  name: 'main/settingsEventInfo/eventInfo',
  initialState: {
    loadingEventInfo: false,
    entity: null,
  },
  reducers: {
    resetEventInfo: {
      reducer: (state, action) => {
        console.log("reset eventInfo called.");
        state.entity = generateNewEventInfo();
        state.tabValue = 0;
        state.loadingCompany = false;
      },
    },
    newEventInfo: {
      reducer: (state, action) => {
        state.entity = generateNewEventInfo();
        state.entity.id = 'new';
      },
    },
  },
  extraReducers: {
    [getEventInfo.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingEventInfo = false;
    },
    [getEventInfo.pending]: (state) => {
      state.loadingEventInfo = true;
      state.entity = null;
    },
    [getEventInfo.rejected]: (state) => {
      state.loadingEventInfo = false;
    },

    [patchEventInfo.fulfilled](state, { payload }) {
      state.savingEventInfo = false;
    },
    [patchEventInfo.pending](state, { payload }) {
      state.savingEventInfo = true;
    },
    [patchEventInfo.rejected](state, { payload }) {
      state.savingEventInfo = false;
    },

    [postEventInfo.fulfilled](state, { payload }) {
      state.savingEventInfo = false;
    },
    [postEventInfo.pending](state, { payload }) {
      state.savingEventInfo = true;
    },
    [postEventInfo.rejected](state, { payload }) {
      state.savingEventInfo = false;
    },

    [activateEventInfo.fulfilled](state, { payload }) {
      state.savingEventInfo = false;
    },
    [activateEventInfo.pending](state, { payload }) {
      state.savingEventInfo = true;
    },
    [activateEventInfo.rejected](state, { payload }) {
      state.savingEventInfo = false;
    },

    [deleteEventInfo.fulfilled](state, { payload }) {
      state.savingEventInfo = false;
    },
    [deleteEventInfo.pending](state, { payload }) {
      state.savingEventInfo = true;
    },
    [deleteEventInfo.rejected](state, { payload }) {
      state.savingEventInfo = false;
    },
  },
});

export const { newEventInfo, resetEventInfo } = productSlice.actions;

export default productSlice.reducer;
