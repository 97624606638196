import { lazy } from 'react';
const TestMap = lazy(() => import('./TestMap'));

const TestMapConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: null,
  routes: [
    {
      path: 'testmap',
        element: <TestMap/>,
    },
  ],
};

export default TestMapConfig;