import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import getChassisManufacturer from 'app/types/chassisManufacturerType';
import apiUrlConfig from 'app/apiUrlConfig';

export const getPassiveVehicleGroup = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/getPassiveVehicleGroup', async (id) => {
  const response = await axios.get(`api/v1/passivevehiclegroups/${id}`, { headers: { 'X-UI-State': 'settings.passiveVehicleGroup.detail' }});
  let data = await response.data;

  if(data === undefined)
    return null;

  if (data && data.passiveVehicles)
    data.passiveVehicles = data.passiveVehicles.map(vehicle => vehicle.id);

  return data;
});

export const removeProduct = createAsyncThunk(
  'eCommerceApp/product/removeProduct',
  async (val, { dispatch, getState }) => {
    const { id } = getState().eCommerceApp.product;
    await axios.post('/api/e-commerce-app/remove-product', { id });

    return id;
  }
);

export const patchPassiveVehicleGroup = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/patchPassiveVehicleGroup', async (passiveVehicleGroup, { getState, rejectWithValue }) => {

  let clone = _.cloneDeep(passiveVehicleGroup);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'passivevehiclegroup',clone);

  try {
    const response = await axios.patch(`api/v1/passivevehiclegroups/${passiveVehicleGroup.id}`, payload);
    const data = await response.data;
    console.log('here');
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

});

export const postPassiveVehicleGroup = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/postPassiveVehicleGroup', async (passiveVehicleGroup, { getState, rejectWithValue }) => {

  let clone = _.cloneDeep(passiveVehicleGroup);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'passivevehiclegroup', clone);

  try {
    const response = await axios.post(`api/v1/passivevehiclegroups`, payload);
    const data = await response.data;
    console.log('here');
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

});

export const getUnitsByCompany = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/getUnitsByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/passivevehicles`, { params: { limit: 0 }, headers: {'X-UI-State': 'kocobox.fleetoverview'} });
  const data = await response.data;

  if (!data || !data.passivevehiclecompanyaccesses)
    return [];

  return data.passivevehiclecompanyaccesses.map(access => access.passive_vehicle);
});

export const getBodyInfos = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/getBodyInfos', async (ids) => {

  const response = await axios.post(apiUrlConfig.POST_BODY_INFOS, { type: 9, ids: ids });
  const data = await response.data;

  return data.value.map(val => {
    return {
      id: val.vehicle,
      changes: {
        chassisType: val.chassisType,
        chassisTypeString: getChassisManufacturer(val.chassisType)
      }
    }
  });
});

export const activatePassiveVehicleGroup = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/activatePassiveVehicleGroup', async (passiveVehicleGroup, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/passivevehiclegroups/${passiveVehicleGroup.id}/enable`);
  const data = await response.data;

  return data;
});

export const deletePassiveVehicleGroup = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/deletePassiveVehicleGroup', async (passiveVehicleGroup, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/passivevehiclegroups/${passiveVehicleGroup.id}`);
  const data = await response.data;

  return data;
});

export const getCompanies = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/getCompanies', async () => {
  const response = await axios.get(`api/v1/companies`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.companies' } });
  const data = await response.data;

  return data.companies;
});

export const patchPassiveVehicleGroupPublic = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/patchPassiveVehicleGroupPublic', async (passiveVehicleGroup, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/passivevehiclegroups/${passiveVehicleGroup.id}/public`);
  const data = await response.data;

  return data;
});

export const patchPassiveVehicleGroupPrivate = createAsyncThunk('main/settingsPassiveVehicleGroup/passiveVehicleGroup/patchPassiveVehicleGroupPrivate', async (passiveVehicleGroup, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/passivevehiclegroups/${passiveVehicleGroup.id}/private`);
  const data = await response.data;

  return data;
});

const CompaniesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectCompanyById } =
  CompaniesAdapter.getSelectors((state) => state.main.settingsPassiveVehicleGroup.passiveVehicleGroup.companies);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById, selectIds: selectUnitIds } =
  unitsAdapter.getSelectors((state) => state.main.settingsPassiveVehicleGroup.passiveVehicleGroup.units);

const generateNewPassiveVehicleGroup = () => {
  return {
    id: 'new',
    company: '',
    name: '',
    description: '',
    passiveVehicles: [],
  };
};

const resetState = (state) => {
  state.entity = null;
  state.loadingPassiveVehicleGroup = false;
  state.tabValue = 0;
  unitsAdapter.removeAll(state.units);
  CompaniesAdapter.removeAll(state.companies);
  state.loadingCompanies = false;
  state.savingPassiveVehicleGroup = false;
  state.unitsSearchText = '';
  state.loadingUnits = false;
  state.error = null;
  state.loadingBodyInfos = false;
}; 

const passiveVehicleGroupSlice = createSlice({
  name: 'main/settingsPassiveVehicleGroup/passiveVehicleGroup',
  initialState: {
    unitsSearchText: '',
    loadingPassiveVehicleGroup: false,
    tabValue: 0, 
    entity: null,
    loadingUnits: false,
    savingPassiveVehicleGroup: false,
    units: unitsAdapter.getInitialState({ }),
    companies: CompaniesAdapter.getInitialState({ }),
    loadingCompanies: false,
    error: null,
    loadingBodyInfos: false,
  },
  reducers: {
    setUnitsSearchText: {
      reducer: (state, action) => {
        state.unitsSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setTabValue: {
      reducer: (state, action) => {
        state.tabValue = action.payload;
      },
    },
    setUnits : {
      reducer: (state, action) => {
        unitsAdapter.setAll(state.units, action.payload);
      },
    },
    resetPassiveVehicleGroup: {
      reducer: (state, action) => {
        resetState(state);
      },
    },
    newPassiveVehicleGroup: {
      reducer: (state, action) => {
        resetState(state);
        state.entity = generateNewPassiveVehicleGroup();
      },
    },
  },
  extraReducers: {
    [getPassiveVehicleGroup.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingPassiveVehicleGroup = false;
    },
    [getPassiveVehicleGroup.pending]: (state) => {
      resetState(state);
      state.loadingPassiveVehicleGroup = true;
    },
    [getPassiveVehicleGroup.rejected]: (state) => {
      state.loadingPassiveVehicleGroup = false;
    },

    [patchPassiveVehicleGroup.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [patchPassiveVehicleGroup.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [patchPassiveVehicleGroup.rejected](state, { payload }) {
      state.error = payload;
      state.savingPassiveVehicleGroup = false;
    },

    [postPassiveVehicleGroup.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [postPassiveVehicleGroup.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [postPassiveVehicleGroup.rejected](state, { payload }) {
      state.error = payload;
      state.savingPassiveVehicleGroup = false;
    },

    [getUnitsByCompany.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnitsByCompany.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnitsByCompany.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [activatePassiveVehicleGroup.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [activatePassiveVehicleGroup.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [activatePassiveVehicleGroup.rejected](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },

    [deletePassiveVehicleGroup.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [deletePassiveVehicleGroup.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [deletePassiveVehicleGroup.rejected](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },

    [getCompanies.fulfilled](state, { payload }) {
      CompaniesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      CompaniesAdapter.removeAll(state.companies);
      state.loadingCompanies = false;
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },

    [patchPassiveVehicleGroupPublic.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [patchPassiveVehicleGroupPublic.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [patchPassiveVehicleGroupPublic.rejected](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },

    [patchPassiveVehicleGroupPrivate.fulfilled](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },
    [patchPassiveVehicleGroupPrivate.pending](state, { payload }) {
      state.savingPassiveVehicleGroup = true;
    },
    [patchPassiveVehicleGroupPrivate.rejected](state, { payload }) {
      state.savingPassiveVehicleGroup = false;
    },

    [getBodyInfos.pending](state, { payload }) {
      state.loadingBodyInfos = true;
    },
    [getBodyInfos.rejected](state, { payload }) {
      state.loadingBodyInfos = false;
    },
    [getBodyInfos.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
      state.loadingBodyInfos = false;
    },
  },
});

export const { setTabValue, resetPassiveVehicleGroup, newPassiveVehicleGroup, setUnits, setUnitsSearchText  } = passiveVehicleGroupSlice.actions;

export default passiveVehicleGroupSlice.reducer;
