import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import MACHINE_TYPE from 'app/types/MachineType';
import REQUEST_PARAMETER_TYPE from 'app/types/RequestParameterType';
import _ from 'lodash';
import { copy } from 'stylis';
import { COMMAND_GATEWAY_STATUS_TYPE } from 'app/types/CommandGatewayStatusType';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getBodyWriteParameterRequests = createAsyncThunk('main/cockpit/bodyParameterUpdate/getBodyWriteParameterRequests', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/types/${REQUEST_PARAMETER_TYPE.WRITE_BODY_PARAMETERS}/parameterrequests`, { params: { inprogress: false } });
  const data = await response.data;
  if (!data)
    return;

  let requests = data.parameterrequests;

  let requestIds = requests.map(function (request) { return request.id; });
  const responseSec = await axios.post(`api/v1/parameterrequestsecondaries`, { ids: requestIds });
  const dataSec = await responseSec.data;

  if (dataSec && dataSec.parameterrequests) {
    _.forEach(requests, function (request) {
      _.forEach(dataSec.parameterrequests, function (secondary) {
        if (request.id == secondary.parentId) {
          if (!request.secondaryRequests)
            request.secondaryRequests = [];
          request.secondaryRequests.push(secondary);
        }
      });
    });
  }

  _.forEach(requests, function (request) {
    if (!request.secondaryRequests)
      request.secondaryRequests = [];
  });

  return requests;
});

export const getBodyWriteRequestParameterInfos = createAsyncThunk('main/cockpit/bodyParameterUpdate/getBodyWriteRequestParameterInfos', async (requestParameter, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;
  let ids = [];
  _.forEach(requestParameter.parameterRequestDetails, function (detail) {
      if (detail.number) {
          ids.push(detail.number);
      }
  });
  const responseParamBatchRead = await axios.post(`api/v1/parameterbatchreads`,
    {
      ids: ids,
      bodytype: KocoUtils.getParameterBodyType(unit),
      vehicletype: KocoUtils.getPassiveVehicleType(unit),
      machinetype: MACHINE_TYPE.BODY,
    }
  );

  const data = await responseParamBatchRead.data;

  if (!data)
    return;

  let parameters = await data.parameters;

  let requestParameterCopy = _.cloneDeep(requestParameter);

  if (requestParameter &&
    requestParameter.parameterRequestDetails &&
    requestParameter.parameterRequestDetails.length > 0 &&
    parameters && parameters.length > 0) {

    requestParameterCopy.parameterRequestDetails = requestParameterCopy.parameterRequestDetails.map(detail => {
      if (parameters) {
        let parameter = parameters.find(param => param.parameterNumber == detail.number);
        detail.parameter = parameter;
      }
      if (!detail.id)
        detail.id = KocoUtils.uuidv4();

        return detail;
    });
  }

  return {
    id: requestParameter.id,
    changes: {
      loadingChanges: false,
      parameterRequestDetails: requestParameterCopy.parameterRequestDetails,
    }
  }
});

export const getBodyParameterInfoByNumber = createAsyncThunk('main/cockpit/bodyParameterUpdate/getBodyParameterInfoByNumber', async (info, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const {detail, request} = info;
  let data;
  if (detail.newNumber && detail.newNumber >= 0) {
    const response = await axios.get(`api/v1/parameterbynumbers/${detail.newNumber}`,
      {
        params:
        {
          bodytype: KocoUtils.getParameterBodyType(unit),
          vehicletype: KocoUtils.getPassiveVehicleType(unit),
          machinetype: MACHINE_TYPE.BODY,
        }
      });
    data = await response.data;
  }

  let copyRequest = _.cloneDeep(request);
  if(copyRequest.parameterRequestDetails && copyRequest.parameterRequestDetails.length > 0){
    copyRequest.parameterRequestDetails.forEach(det => det.isExpanded = false);
    let copyDetail = copyRequest.parameterRequestDetails.find(det => det.number == detail.number);
    copyDetail.number = detail.newNumber;
    copyDetail.isExpanded = true;
    if(data) {
      copyDetail.parameter = data;
    } else {
      copyDetail.parameter = null;
    }
  }

  return {
    id: request.id,
    changes: {
      loadingChanges: false,
      parameterRequestDetails: copyRequest.parameterRequestDetails,
    }
  };
});

export const patchBodyParameterRequest = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchBodyParameterRequest', async (bodyParameterRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(bodyParameterRequest);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'parameterrequests',clone);

  const response = await axios.patch(`api/v1/parameterrequests/${bodyParameterRequest.id}`, payload);

  const data = await response.data;

  return data;
});

export const postBodyParameterRequest = createAsyncThunk('main/cockpit/bodyParameterUpdate/postBodyParameterRequest', async (bodyParameterRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(bodyParameterRequest);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'parameterrequests', clone);

  const response = await axios.post(`api/v1/parameterrequests`, payload);

  const data = await response.data;

  return data;
});

export const changeBodyParameterRequestStatus = createAsyncThunk('main/cockpit/bodyParameterUpdate/changeBodyParameterRequestStatus', async (bodyParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${bodyParameterRequest.id}/changestatus`);
  const data = await response.data;

  return data;
});

export const deleteBodyParameterRequest = createAsyncThunk('main/cockpit/bodyParameterUpdate/deleteBodyParameterRequest', async (bodyParameterRequest, { getState }) => {

  const response = await axios.delete(`api/v1/parameterrequests/${bodyParameterRequest.id}`);
  const data = await response.data;

  return data;
});

export const patchInquiryBodyParameterStatus = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchInquiryBodyParameterStatus', async (bodyParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${bodyParameterRequest.id}/inquirystatus`);
  const data = await response.data;

  return data;
});

export const patchCancelBodyParameterRequest = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchCancelBodyParameterRequest', async (bodyParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${bodyParameterRequest.id}/cancel`);
  const data = await response.data;

  return data;
});

export const postAllExistingBodyParameterRequest = createAsyncThunk('main/cockpit/bodyParameterUpdate/postAllExistingBodyParameterRequest', async (bodyParameterRequest, { getState }) => {

  const response = await axios.post(`api/v1/units/${bodyParameterRequest.id}/machinetypes/${MACHINE_TYPE.BODY}/sendallparameters`);
  const data = await response.data;

  return data;
});

const bodyWriteParameterRequestsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectBodyWriteParameterRequests, selectById: selectBodyWriteParameterRequestById, selectIds: selectBodyWriteParameterRequestIds } =
  bodyWriteParameterRequestsAdapter.getSelectors((state) => { return state.main.cockpit.bodyParameterUpdate.bodyWriteParameterRequests; });

const BodyParameterUpdateSlice = createSlice({
  name: 'main/cockpit/bodyParameterUpdate',
  initialState: {
    searchText: '',
    loadingBodyWriteParameterRequests: false,
    loadingInUseBodyParameterCategories: false,
    sendingBodyReadParameterRequest: false,
    bodyWriteParameterRequests: bodyWriteParameterRequestsAdapter.getInitialState({}),
    currentBodyRequest: null,
  },
  reducers: {
    setReadParameterSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCurrentBodyRequest: {
      reducer: (state, action) => {
        state.currentBodyRequest = action.payload;
      },
    },
    upsertBodyParameterRequest: {
      reducer: (state, action) => {
        bodyWriteParameterRequestsAdapter.upsertOne(state.bodyWriteParameterRequests, action.payload);
      },
    },
  },

  extraReducers: {
    [getBodyWriteParameterRequests.fulfilled](state, { payload }) {
      bodyWriteParameterRequestsAdapter.setAll(state.bodyWriteParameterRequests, payload);
      state.loadingBodyWriteParameterRequests = false;
    },
    [getBodyWriteParameterRequests.pending](state, { payload }) {
      bodyWriteParameterRequestsAdapter.removeAll(state.bodyWriteParameterRequests);
      state.loadingBodyWriteParameterRequests = true;
    },
    [getBodyWriteParameterRequests.rejected](state, { payload }) {
      state.loadingBodyWriteParameterRequests = false;
    },

    [getBodyWriteRequestParameterInfos.fulfilled](state, { meta, payload }) {
      let requestParameter = meta.arg;

      if(requestParameter.commandGatewayStatus == COMMAND_GATEWAY_STATUS_TYPE.NEW_REQUEST){
        state.currentBodyRequest.parameterRequestDetails = payload.changes.parameterRequestDetails;
        state.currentBodyRequest.loadingChanges = payload.changes.loadingChanges;
      }
      else {
        bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, payload);
      }
    },
    [getBodyWriteRequestParameterInfos.pending](state, { meta, payload }) {
      let requestParameter = meta.arg;
      let changes = {
        id: requestParameter.id,
        changes: {
          loadingChanges: true,
          parameterVehicleValues: null,
        }
      }
      bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, changes);
    },
    [getBodyWriteRequestParameterInfos.rejected](state, { meta, payload }) {
      let requestParameter = meta.arg;
      let changes = {
        id: requestParameter.id,
        changes: {
          loadingChanges: false,
        }
      }
      bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, changes);
    },

    [getBodyParameterInfoByNumber.fulfilled](state, { meta, payload }) {
      let info = meta.arg;
      const {detail, request} = info;
      if(request.commandGatewayStatus == COMMAND_GATEWAY_STATUS_TYPE.NEW_REQUEST){
        state.currentBodyRequest.parameterRequestDetails = payload.changes.parameterRequestDetails;
        state.currentBodyRequest.loadingChanges = payload.changes.loadingChanges;
      }
      else {
        bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, payload);
      }
    },
    [getBodyParameterInfoByNumber.pending](state, { meta, payload }) {

      // TODO 

      // let requestParameter = meta.arg;
      // let changes = {
      //   id: requestParameter.id,
      //   changes: {
      //     loadingChanges: true,
      //     parameterVehicleValues: null,
      //   }
      // }
      // bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, changes);
    },
    [getBodyParameterInfoByNumber.rejected](state, { meta, payload }) {


      // TODO

      // let requestParameter = meta.arg;
      // let changes = {
      //   id: requestParameter.id,
      //   changes: {
      //     loadingChanges: false,
      //   }
      // }
      // bodyWriteParameterRequestsAdapter.updateOne(state.bodyWriteParameterRequests, changes);
    },

    [patchBodyParameterRequest.fulfilled](state, { payload }) {
      state.savingCurrentBodyRequest = false;
    },
    [patchBodyParameterRequest.pending](state, { payload }) {
      state.savingCurrentBodyRequest = true;
    },
    [patchBodyParameterRequest.rejected](state, { payload }) {
      state.savingCurrentBodyRequest = false;
    },

    [postBodyParameterRequest.fulfilled](state, { payload }) {
      state.savingCurrentBodyRequest = false;
      state.currentBodyRequest = null;
    },
    [postBodyParameterRequest.pending](state, { payload }) {
      state.savingCurrentBodyRequest = true;
    },
    [postBodyParameterRequest.rejected](state, { payload }) {
      state.savingCurrentBodyRequest = false;
    },

    [changeBodyParameterRequestStatus.fulfilled](state, { payload }) {
      // TODO
      // state.savingCurrentBodyRequest = false;
    },
    [changeBodyParameterRequestStatus.pending](state, { payload }) {
      // TODO
      // state.savingCurrentBodyRequest = true;
    },
    [changeBodyParameterRequestStatus.rejected](state, { payload }) {
      // TODO
      // state.savingCurrentBodyRequest = false;
    },

    [deleteBodyParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = false;
    },
    [deleteBodyParameterRequest.pending](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = true;
    },
    [deleteBodyParameterRequest.rejected](state, { payload }) {
      // TODO
      // state.deletingBodyRequest = false;
    },   
    
    [patchInquiryBodyParameterStatus.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },
    [patchInquiryBodyParameterStatus.pending](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = true;
    },
    [patchInquiryBodyParameterStatus.rejected](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },  
    
    [patchCancelBodyParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },
    [patchCancelBodyParameterRequest.pending](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = true;
    },
    [patchCancelBodyParameterRequest.rejected](state, { payload }) {
      // TODO
      // state.inquiryBodyParameterStatus = false;
    },  

    [postAllExistingBodyParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.sendAllExistingBodyParameters = false;
    },
    [postAllExistingBodyParameterRequest.pending](state, { payload }) {
      // TODO
      // state.sendAllExistingBodyParameters = true;
    },
    [postAllExistingBodyParameterRequest.rejected](state, { payload }) {
      console.log(payload);
      // TODO
      // state.sendAllExistingBodyParameters = false;
    }, 
    
  },
});

export const { setReadParameterSearchText, setCurrentBodyRequest, upsertBodyParameterRequest } = BodyParameterUpdateSlice.actions;

export default BodyParameterUpdateSlice.reducer;
