import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import reducer from '../store';
import ApiContent from "./ApiContent";

const Root = styled(FusePageCarded)(({ theme }) => ({
}));

function Api() {
  return <ApiContent />;
}

export default withReducer('main', reducer)(Api);
