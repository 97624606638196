import { combineReducers } from '@reduxjs/toolkit';
import unitsScantec from './unitsScantec';
import geofencesScantec from "./geofencesScantec";
import positionScantec from "./positionScantec";
import pourcentageScantec from "./graphPositionScantec";
import trandScantec from "./trendPositionScantec";
import areaScantec from "./areasScantec";
const reducer = combineReducers({
    unitsScantec,
    geofencesScantec,
    positionScantec,
    pourcentageScantec,
    trandScantec,
    areaScantec
});

export default reducer;
