import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PassiveVehicleGroup = lazy(() => import('./PassiveVehicleGroup/PassiveVehicleGroup'));
const PassiveVehicleGroups = lazy(() => import('./PassiveVehicleGroups/PassiveVehicleGroups'));

const SettingsPassiveVehicleGroupConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'vehiclegroups',
      element: <PassiveVehicleGroups />,
    },
    {
      path: 'vehiclegroups/:passiveVehicleGroupId/*',
      element: <PassiveVehicleGroup />,
    },
  ],
};

export default SettingsPassiveVehicleGroupConfig;
