import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const User = lazy(() => import('./user/User'));
const Users = lazy(() => import('./users/Users'));

const SettingsUserConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'users/:userId/*',
      element: <User />,
    },
  ],
};

export default SettingsUserConfig;
