import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Role = lazy(() => import('./role/Role'));
const Roles = lazy(() => import('./roles/Roles'));

const SettingsRoleConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'roles',
      element: <Roles />,
    },
    {
      path: 'roles/:roleId/*',
      element: <Role />,
    },
  ],
};

export default SettingsRoleConfig;
