import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSubscriptions = createAsyncThunk('main/settingsSubscription/subscriptions/getSubscriptions', async () => {
  const response = await axios.get(`api/v1/subscriptions`, { params: { limit: 0 }});
  const data = await response.data;

  return data.subscriptions;
});

const subscriptionsAdapter = createEntityAdapter({});

export const { selectAll: selectSubscriptions, selectById: selectSubscriptionById } =
  subscriptionsAdapter.getSelectors((state) => state.main.settingsSubscription.subscriptions.subscriptions);

const subscriptionsSlice = createSlice({
  name: 'main/settingsSubscription/subscriptions',
  initialState: {
    searchText: '',
    loadingSubscriptions: false,
    subscriptions: subscriptionsAdapter.getInitialState({ }),
  },
  reducers: {
    setSubscriptionsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getSubscriptions.fulfilled](state, { payload }) {

      const calculateTotalErrors = (record) => {
        if (record.errorSubscriptions && record.errorSubscriptions.length > 0) {
          return record.errorSubscriptions.reduce((totalErrors, errorSubscription) => {
            if (errorSubscription.errorGroup && Array.isArray(errorSubscription.errorGroup.errors)) {
              return totalErrors + errorSubscription.errorGroup.errors.length;
            }
            return totalErrors;
          }, 0);
        }
        return 0;
      };

      const modifiedPayload = payload.map((record) => { // loping through the payload first, then calcuating individual record no of errors
        const totalErrors = calculateTotalErrors(record);
        return {
          ...record,
          numberOfEvents: totalErrors,
        };
      });

      subscriptionsAdapter.setAll(state.subscriptions, modifiedPayload);
      state.loadingSubscriptions = false;
    },
    [getSubscriptions.pending]: (state) => {
      state.loadingSubscriptions = true;
      subscriptionsAdapter.removeAll(state.subscriptions);
      state.loadingSubscriptions = false;
    },
    [getSubscriptions.rejected]: (state) => {
      state.loadingSubscriptions = false;
    },
  },
});

export const { setSubscriptionsSearchText } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
