import { combineReducers } from '@reduxjs/toolkit';
import role from './RoleSlice';
import roles from './RolesSlice';

const reducer = combineReducers({
  roles,
  role,
});

export default reducer;
