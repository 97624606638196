import { authRoles } from 'app/auth';
import { lazy } from 'react';

const FleetTracking = lazy(() => import('./FleetTracking'));

const FleetTrackingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_FLEET_TRACKING_READ],
  routes: [
    {
      path: 'fleettracking',
      element: <FleetTracking />,
    },
  ],
};

export default FleetTrackingConfig;