import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getRole = createAsyncThunk('main/settingsRole/role/getRole', async (id) => {
  const response = await axios.get(`api/v1/rolekocos/${id}`, { headers: { 'X-UI-State': 'role.settings.users' }});
  const data = await response.data;
  
  return data === undefined ? null : data;
});

export const getCompanies = createAsyncThunk('main/settingsRole/role/getCompanies', async () => {
  const response = await axios.get(`api/v1/companies`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.companies', 'X-UI-Role': 'role.edit' } });
  const data = await response.data;

  return data.companies;
});

export const patchRole = createAsyncThunk('main/settingsRole/role/patchRole', async (role, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(role);
  let selectedRoles = _.cloneDeep(main.settingsRole.role.selectedRoles);
  clone.access = selectedRoles;
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'role',clone);
  console.log('role to patch', payload);
  
  const response = await axios.patch(`api/v1/rolekocos/${role.id}`, payload);

  const data = await response.data;

  return data;
});

export const postRole = createAsyncThunk('main/settingsRole/role/postRole', async (role, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(role);
  let selectedRoles = _.cloneDeep(main.settingsRole.role.selectedRoles);
  clone.access = selectedRoles;
  let payload = AxiosInterceptorUtils.preparePayload('post', 'role', clone);

  const response = await axios.post(`api/v1/rolekocos`, payload);

  const data = await response.data;

  return data;
});

export const activateRole = createAsyncThunk('main/settingsRole/role/activateRole', async (role, { getState }) => {
  const { main } = getState();

  const response = await axios.patch(`api/v1/rolekocos/${role.id}/enable`);
  const data = await response.data;

  return data;
});

export const deleteRole = createAsyncThunk('main/settingsRole/role/deleteRole', async (role, { getState }) => {
  const { main } = getState();

  const response = await axios.delete(`api/v1/rolekocos/${role.id}`);
  const data = await response.data;

  return data;
});

const CompaniesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectCompanyById } =
  CompaniesAdapter.getSelectors((state) => state.main.settingsRole.role.companies);


const generateNewRole = () => {
  return {
    id: '',
    name: '',
    description: '',
    company: '',
    access: [],
  }
};

const RoleSlice = createSlice({
  name: 'main/settingsRole/role',
  initialState: {
    loadingRole: false,
    entity: null,
    selectedRoles: [],
    companies: CompaniesAdapter.getInitialState({ }),
  },
  reducers: {
    resetRole: {
      reducer: (state, action) => {
        console.log("reset role called.");
        state.entity = generateNewRole();
        state.tabValue = 0;
        state.loadingCompany = false;
        state.selectedRoles = [];
      },
    },
    newRole: {
      reducer: (state, action) => {
        state.entity = generateNewRole();
        state.entity.id = 'new';
        state.loadingCompany = false;
        state.selectedRoles = [];
      },
    },
    setSelectedRoles: {
      reducer: (state, action) => {
        state.selectedRoles = action.payload;
      },
    },
  },
  extraReducers: {
    [getRole.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingRole = false;
    },
    [getRole.pending]: (state) => {
      state.loadingRole = true;
      state.entity = null;
      state.selectedRoles = [];
    },
    [getRole.rejected]: (state) => {
      state.loadingRole = false;
    },

    [getCompanies.fulfilled](state, { payload }) {
      CompaniesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      CompaniesAdapter.removeAll(state.companies);
      state.loadingCompanies = false;
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },

    [patchRole.fulfilled](state, { payload }) {
      state.savingRole = false;
    },
    [patchRole.pending](state, { payload }) {
      state.savingRole = true;
    },
    [patchRole.rejected](state, { payload }) {
      state.savingRole = false;
    },

    [postRole.fulfilled](state, { payload }) {
      state.savingRole = false;
    },
    [postRole.pending](state, { payload }) {
      state.savingRole = true;
    },
    [postRole.rejected](state, { payload }) {
      state.savingRole = false;
    },

    [activateRole.fulfilled](state, { payload }) {
      state.savingRole = false;
    },
    [activateRole.pending](state, { payload }) {
      state.savingRole = true;
    },
    [activateRole.rejected](state, { payload }) {
      state.savingRole = false;
    },

    [deleteRole.fulfilled](state, { payload }) {
      state.savingRole = false;
    },
    [deleteRole.pending](state, { payload }) {
      state.savingRole = true;
    },
    [deleteRole.rejected](state, { payload }) {
      state.savingRole = false;
    },
  },
});

export const { newRole, resetRole, setSelectedRoles } = RoleSlice.actions;

export default RoleSlice.reducer;
