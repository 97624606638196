import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Company = lazy(() => import('./Company/Company'));
const Companies = lazy(() => import('./Companies/Companies'));

const SettingsCompanyConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'companies',
      element: <Companies />,
    },
    {
      path: 'companies/:companyId/*',
      element: <Company />,
    },
  ],
};

export default SettingsCompanyConfig;
