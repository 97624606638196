import { authRoles } from 'app/auth';
import { lazy } from 'react';

const FleetOverview = lazy(() => import('./FleetOverview'));

const FleetOverviewConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_FLEET_OVERVIEW_READ],
  routes: [
    {
      path: 'fleetoverview',
      element: <FleetOverview />,
    },
  ],
};

export default FleetOverviewConfig;