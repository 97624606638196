import { lazy } from "react";

const Login3Page = lazy(() => import("./Login3Page"));

const Login3PageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "login", //'pages/auth/login-3',
      element: <Login3Page />,
    },
  ],
};

export default Login3PageConfig;
