import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Reports = lazy(() => import('./reports/Reports'));

const ReportConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'perfreports',
      element: <Reports />,
    }
  ],
};

export default ReportConfig;
