import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";

export const getUser = createAsyncThunk(
  "main/settingsUser/User/getUser",
  async (id) => {
    const response = await axios.get(`api/v1/users/${id}`, {
      headers: { "X-UI-State": "user.settings.users" },
    });
    let data = await response.data;
    if (data) {
      if (!data.roleKocos) data.roleKocos = [];

      if (data.companyAccesses) {
        data.companyAccesses.forEach((access) => {
          if (access.roleKocos) {
            access.roleKocos.forEach((role) => {
              data.roleKocos.push(role);
            });
          }
        });
      }
    }

    return data === undefined ? null : data;
  }
);

export const patchUser = createAsyncThunk(
  "main/settingsUser/User/patchUser",
  async (user, { getState }) => {
    const { main } = getState();
    const unit = main.cockpit.unitDetail.unit;
    let clone = _.cloneDeep(user);
    let payload = AxiosInterceptorUtils.preparePayload("patch", "user", clone);

    // const {
    //   password,
    //   // roleKocos,
    //   profilePicture,
    //   expireTime,
    //   isConfirmed,
    //   createUserToken,
    //   ...newPayload
    // } = payload.user;

    const response = await axios.patch(`api/v1/users/${user.id}`, {
      user: payload.user,
    });

    let image;
    if (user.profilePicture.length > 1000) {
      const imageResponse = await axios.patch(
        `api/v1/users/${user.id}/photo`,
        user.profilePicture
      );
      image = await imageResponse.data;
    } else {
      image = user.profilePicture;
    }
    return image;
  }
);

export const postUser = createAsyncThunk(
  "main/settingsUser/User/postUser",
  async (user, { getState }) => {
    const { main } = getState();
    const unit = main.cockpit.unitDetail.unit;
    let clone = _.cloneDeep(user);

    // extract the image if exist : if(user.image) {....}
    // let image = clone.image;
    let payload = AxiosInterceptorUtils.preparePayload("post", "user", clone);

    // const response = await axios.post(`api/v1/users`, payload);
    // .then -> you have id of the nrewly created user
    // call the UPload image API using id

    const data = await response.data;

    return data;
  }
);

export const activateUser = createAsyncThunk(
  "main/settingsUser/User/activateUser",
  async (user, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(`api/v1/users/${user.id}/enable`);
    const data = await response.data;

    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "main/settingsUser/User/deleteUser",
  async (user, { getState }) => {
    const { main } = getState();

    const response = await axios.delete(`api/v1/users/${user.id}`);
    const data = await response.data;

    return data;
  }
);

export const selectProfilePictureUrl = (state) =>
  state.main.settingsProfile.user.profilePicture;

const generateNewuser = () => {
  return {
    id: "",
    company: "",
    firstName: "",
    lastName: "",
    companyAccesses: [],
    email: "",
    groups: [],
    id: "",
    lastLogin: "",
    locale: "",
    mobileNumber: "",
    roleKocos: [],
    timezone: "",
    userType: "",
    username: "",
    profilePicture: "",
  };
};

const productSlice = createSlice({
  name: "main/settingsUser/user",
  initialState: {
    loadingUser: false,
    entity: null,
    isImageUploaded: false,
  },
  reducers: {
    resetUser: {
      reducer: (state, action) => {
        console.log("reset user called.");
        state.entity = generateNewuser();
        state.tabValue = 0;
        state.loadingCompany = false;
      },
    },
    newUser: {
      reducer: (state, action) => {
        state.entity = generateNewuser();
        state.entity.id = "new";
      },
    },
    setIsImageChanged: {
      reducer: (state, action) => {
        state.isImageUploaded = true;
      },
    },
    setProfilePicture: {
      reducer: (state, action) => {
        state.profilePicture = action.payload;
      },
    },
  },
  extraReducers: {
    [getUser.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingUser = false;
    },
    [getUser.pending]: (state) => {
      state.loadingUser = true;
      state.entity = null;
    },
    [getUser.rejected]: (state) => {
      state.loadingUser = false;
    },

    [patchUser.fulfilled](state, { payload }) {
      state.savingUser = false;
      state.profilePicture = payload;
    },
    [patchUser.pending](state, { payload }) {
      state.savingUser = true;
    },
    [patchUser.rejected](state, { payload }) {
      state.savingUser = false;
    },

    [postUser.fulfilled](state, { payload }) {
      state.savingUser = false;
    },
    [postUser.pending](state, { payload }) {
      state.savingUser = true;
    },
    [postUser.rejected](state, { payload }) {
      state.savingUser = false;
    },

    [activateUser.fulfilled](state, { payload }) {
      state.savingUser = false;
    },
    [activateUser.pending](state, { payload }) {
      state.savingUser = true;
    },
    [activateUser.rejected](state, { payload }) {
      state.savingUser = false;
    },

    [deleteUser.fulfilled](state, { payload }) {
      state.savingUser = false;
    },
    [deleteUser.pending](state, { payload }) {
      state.savingUser = true;
    },
    [deleteUser.rejected](state, { payload }) {
      state.savingUser = false;
    },
  },
});

export const { newUser, resetUser, setIsImageChanged, setProfilePicture } =
  productSlice.actions;

export default productSlice.reducer;
