import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  objects: [],
  filter: [],
  load: false
}

export const getAreas = createAsyncThunk('scantec/areas', async (id) => {
  const response = await axios.get(apiUrlConfig.GET_AREAS+"/"+id, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const areaScantecSlice = createSlice({
  name: 'scantec/geofences',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getAreas.pending]: (state) => {
      state.load = true
    },
    [getAreas.fulfilled]: (state, { payload }) => {
      state.load = false
      state.objects = payload;
      state.filter = payload
    },
    [getAreas.rejected]: (state) => {
      state.load = false
    },
  },
});
export const postReducer = areaScantecSlice.reducer;
export default areaScantecSlice.reducer;