import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Unit = lazy(() => import('./unit/Unit'));
const Units = lazy(() => import('./units/Units'));

const SettingsUnitConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'units',
      element: <Units />,
    },
    {
      path: 'units/:unitId/*',
      element: <Unit />,
    },
  ],
};

export default SettingsUnitConfig;
