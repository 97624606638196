import { combineReducers } from '@reduxjs/toolkit';
import eventInfo from './EventInfoSlice';
import eventInfos from './EventInfosSlice';

const reducer = combineReducers({
  eventInfos,
  eventInfo,
});

export default reducer;
