import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getTourPlans = createAsyncThunk('main/settingsTourPlan/tourPlans/getTourPlans', async (searchParams) => {
  const response = await axios.get(`api/v1/tourplans`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'tourPlan.settings.tourPlans' } });
  let data = await response.data;
  if (data?.tourplans) {
    data.tourplans = data.tourplans.map(stp => ({
      ...stp,
      classifierType: (typeof stp.classifierType !== 'undefined' ? { id: stp.classifierType } : null),
    }));
  }

  return { tourplans: data.tourplans, totalcount: data.totalcount };
});

export const getTourPlanHasOverlap = createAsyncThunk('main/settingsTourPlan/tourPlans/getTourPlanHasOverlap', async (searchParams) => {
  const response = await axios.get(`api/v1/tourplanhasoverap`, {
    params:
    {
      id: searchParams.id,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      tour: searchParams.tour,
    }
  });
  const data = await response.data;

  return { tourplans: data.tourplans, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsTourPlan/tourPlans/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const patchTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlans/patchTourPlan', async (tourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(tourPlan);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'tourplan',clone);
  console.log('tourPlan to patch', payload);

  try {
    const response = await axios.patch(`api/v1/batchtourplans/${tourPlan.id}`, payload);

    const data = await response.data;
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

});

export const patchBatchTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlans/patchBatchTourPlan', async (tourPlans, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(tourPlans.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'tourplan', row)); });

  try {
    const response = await axios.patch(`api/v1/batchtourplan`, { tour_plans: payload });
    const data = await response.data;
    if (data && data.length > 0) {

      return rejectWithValue({ error: { message: 'data overlaps', data: data }});
    }
    return data;
  } catch (err) {
    return rejectWithValue({error: err.response.data})
  }

});

export const deleteTourPlan = createAsyncThunk('main/settingsTourPlan/tourPlans/deleteTourPlan', async (tourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/tourplans/${tourPlan.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsTourPlan/tourPlans/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'tourPlan.settings.tourPlans' } });
  const data = await response.data;

  return data.st_tours;
});

const tourPlansAdapter = createEntityAdapter({});

export const { selectAll: selectTourPlans, selectById: selectTourPlanById } =
  tourPlansAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlans.tourPlans);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlans.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsTourPlan.tourPlans.units);

const tourPlansSlice = createSlice({
  name: 'main/settingsTourPlan/tourPlans',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: 10,
    sortModel: [],
    rangeDate: [null, null],
    selectedUnit: null,
    selectedClassifier: [],
    selectedTour: null,
    loadingTourPlans: false,
    loadingUnits: false,
    savingTourPlan: false,
    deletingTourPlan: false,
    loadingStTours: false,
    tourPlans: tourPlansAdapter.getInitialState({ }),
    dataTourPlans: [],
    tourPlansCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    isDataDirty: false,
    errorOnSave: null,
  },
  reducers: {
    setTourPlansSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setDataTourPlans: {
      reducer: (state, action) => {
        state.dataTourPlans = action.payload;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedClassifier: {
      reducer: (state, action) => {
        state.selectedClassifier = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    resetTourPlans: {
      reducer: (state, action) => {
        state.errorOnSave = null;
        state.isDataDirty = false;
        tourPlansAdapter.removeAll(state.tourPlans);
        state.loadingTourPlans = false;
        state.savingTourPlan = false;
      },
    },
  },
  extraReducers: {
    [getTourPlans.fulfilled](state, { payload }) {
      tourPlansAdapter.setAll(state.tourPlans, payload.tourplans);
      state.tourPlansCount = payload.totalcount;
      state.loadingTourPlans = false;
    },
    [getTourPlans.pending]: (state) => {
      state.errorOnSave = null;
      state.loadingTourPlans = true;
      tourPlansAdapter.removeAll(state.tourPlans);
    },
    [getTourPlans.rejected]: (state) => {
      state.loadingTourPlans = false;
    },

    [patchTourPlan.fulfilled](state, { payload }) {
      state.savingTourPlan = false;
    },
    [patchTourPlan.pending](state, { payload }) {
      state.savingTourPlan = true;
    },
    [patchTourPlan.rejected](state, { payload }) {
      state.savingTourPlan = false;
    },

    [patchBatchTourPlan.fulfilled](state, { payload }) {
      state.savingTourPlan = false;
    },
    [patchBatchTourPlan.pending](state, { payload }) {
      state.errorOnSave = null;
      state.savingTourPlan = true;
    },
    [patchBatchTourPlan.rejected](state, { payload }) {
      state.errorOnSave = payload?.error;
      // if(payload?.error?.data){
      //   console.log('payload to update');
      //   tourPlansAdapter.updateMany(state.tourPlans, payload.error.data);
      // }
      state.savingTourPlan = false;
    },

    [deleteTourPlan.fulfilled](state, { payload }) {
      state.deletingTourPlan = false;
    },
    [deleteTourPlan.pending](state, { payload }) {
      state.deletingTourPlan = true;
    },
    [deleteTourPlan.rejected](state, { payload }) {
      state.deletingTourPlan = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const {
  setTourPlansSearchText,
  setIsDataDirty,
  setDataTourPlans,
  setPage,
  setPageSize,
  setRowsPerPage,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifier,
  setSelectedTour,
  resetTourPlans,
} = tourPlansSlice.actions;

export default tourPlansSlice.reducer;
