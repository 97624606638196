
import withReducer from 'app/store/withReducer';
import {default as React, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reducer from '../store';
import {useTranslation} from "react-i18next";
import HeaderWidget from "./widgets/HeaderWidget";
import {motion} from "framer-motion";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import DatePicker from "@mui/lab/DatePicker";

import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import ScantecMap from "./widgets/Map1Widget";
import ListScanTecWidget from "./widgets/ListWidget";
import {getGeofences} from "./store/geofencesScantec";
import FuseLoading from "../../../@fuse/core/FuseLoading";
import {createFilterOptions} from "@mui/base";
import {getPositions, setClearAll, setColors} from "./store/positionScantec";
import moment from "moment";
import deLocale from "date-fns/locale/de";
import _ from '@lodash';
import SkeltonWidgetLeft from "./widgets/SkeltonWidgetLeft";
import CountBinsWidget from "./widgets/CountBinsWidget";
import CountBinsVehicle from "./widgets/CountBinsVehicleWidget";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import "./css/scantec.css";
import InfoWidget from "./widgets/InfoWidget";
import SensorWidget from "./widgets/SensorWidget";
import CountSensorBin from "./widgets/CountSensorWidget";
import DateRangePicker from "@mui/lab/DateRangePicker";
import InputAdornment from "@mui/material/InputAdornment";
import {getPourcentage, setClearAllGraph} from "./store/graphPositionScantec";
import {showMessage} from "../../store/fuse/messageSlice";
import {setClearAllTrend} from "./store/trendPositionScantec";
import {getAreas} from "./store/areasScantec";
import SkeltonBigWidget from "./widgets/SkeltonBigWidget";
function ScantecContent() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    let { trends, loadTrend} = useSelector(({ main }) => main.scantec.trandScantec);
    const [value, setValue] = React.useState(null);
    const [valueEnd, setValueEnd] = React.useState(null);
    const [lastDate, setLastDate] = React.useState(null);
    const [valueTextYellow, setValueTextYellow] = React.useState(10);
    const [valueTextRed, setValueTextRed] = React.useState(30);
    const [autocompleteValue, setAutocompleteValue] = React.useState(null);
    const [road, setRoad] = React.useState(null);
    const [region, setRegion] = React.useState(null);
    const [details, setDetails] = React.useState(null);
    let [mapEl, setMapEl] = React.useState(null);
    useEffect(() => {
        dispatch(getGeofences()).then(() => {
          dispatch(setClearAll());
            dispatch(setClearAllGraph());
           dispatch(setClearAllTrend());
        });

    }, [dispatch]);
    let defaultProps = {
    };
    let defaultProps2 = {
    };
    let {load2,isFilterSet,isDataSet} = useSelector(({ main }) => main.scantec.positionScantec);
    let { entities, load, filter } = useSelector(({ main }) => main.scantec.geofencesScantec);
    let {  objects, load3 } = useSelector(({ main }) => main.scantec.areaScantec);
    let { dates} = useSelector(({ main }) => main.scantec.pourcentageScantec);
    useEffect(() => {
        if (filter && filter.length > 0) {
            defaultProps = {
                options: filter,
                getOptionLabel: (option) => option.title,
            };
        }
        if (objects && objects.length > 0) {
            defaultProps2 = {
                options: objects,
                getOptionLabel: (option) => option.title,
            };
        }
    }, []);
    const container = {
        show: {
            transition: {
                staggerChildren: 0.06,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };
    useEffect(() =>{
        if (details) {
            window.scroll({
                top: document.body.offsetHeight,
                left: 0,
                behavior: 'smooth',
            });
            setValueTab("2");
        }
    } ,[details]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });

    const [valueTab, setValueTab] = React.useState('1');

    const handleChange = (event,newValue) => {
        setValueTab(newValue);
    };

    function onChangeEvent(newValue) {
        setValue(newValue);
    }
    function handleData() {
        setValueTextRed(30);
        setValueTextYellow(10);
        dispatch(setClearAllTrend());
        if (value) {
        let roadValue = road ? road.value : -1;
        dispatch(getPositions({
            id : autocompleteValue.id,
            date : moment(value).format("YYYY-MM-DD"),
            date2 :valueEnd? moment(valueEnd).format("YYYY-MM-DD") :
                moment(value).format("YYYY-MM-DD"),
        area : roadValue}));
        if (valueEnd) {
            dispatch(getPourcentage({
                id : autocompleteValue.id,
                date : moment(value).format("YYYY-MM-DD"),
                date2 :valueEnd? moment(valueEnd).format("YYYY-MM-DD") :
                    moment(value).format("YYYY-MM-DD"),
                area : roadValue,  yellow:valueTextYellow, red:valueTextRed   }));
        } else {
            dispatch(getPourcentage({
                id: autocompleteValue.id,
                date: moment(value).subtract(4, 'weeks').format("YYYY-MM-DD"),
                date2: moment(value).format("YYYY-MM-DD"),
                area: roadValue,
                yellow: valueTextYellow, red: valueTextRed
            }));
        }
        } else {
            dispatch(showMessage({ message: t('report_please_select') }));
        }
    }

    function updateValues() {
        dispatch(setClearAllTrend());
        let roadValue = road ? road.value : -1;
        if (valueTextRed> 100) {
            dispatch(showMessage({ message:t('value_more_100')}));
        } else if(valueTextYellow> 100) {
            dispatch(showMessage({ message:t('value_more_100')}));
        } else if (valueTextRed < valueTextYellow) {
            dispatch(showMessage({ message: t('bad_should_be_more') }));
        } else {
            dispatch(setColors({
                yellow: valueTextYellow,
                red:valueTextRed
            }));
            if (valueEnd) {
                dispatch(getPourcentage({
                    id : autocompleteValue.id,
                    date : moment(value).format("YYYY-MM-DD"),
                    date2 :valueEnd? moment(valueEnd).format("YYYY-MM-DD") :
                        moment(value).format("YYYY-MM-DD"),
                    area : roadValue, yellow:valueTextYellow, red:valueTextRed }));
            } else {
                dispatch(getPourcentage({
                    id: autocompleteValue.id,
                    date: moment(value).subtract(4, 'weeks').format("YYYY-MM-DD"),
                    date2: moment(value).format("YYYY-MM-DD"),
                    area : roadValue,
                    yellow:valueTextYellow, red:valueTextRed
                }));
            }
        }
     }
    if (load) {
        return <FuseLoading />;
    }
    return (
        <div className="w-full">
            <motion.div variants={item} className="widget w-full pb-32">
                <HeaderWidget/>
            </motion.div>
            <motion.div variants={item} className="p-16 sm:p-24">
                <Typography
                    component={motion.div}
                    variants={item}
                    className="px-16 pb-8 text-18 font-medium"
                    color="textSecondary"
                >
                </Typography>
            <div className="widget w-full pb-32">
                <Stack  direction="row" spacing={2}>
                    {entities && entities.length > 0 && <Autocomplete
                        noOptionsText={t('layout_default_no_options')}
                        clearText={t('layout_default_clear')}
                        id="filter-demo"
                        options={filter}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            setAutocompleteValue(newValue);
                            setRegion(_.find(entities, (element) => {
                                return (element.id == newValue.id) ? true : false;
                            }))
                            dispatch(getAreas(newValue.id));
                        }}
                        filterOptions={filterOptions}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label={t('scan_tec_region')} />}
                    />
                    }
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <DatePicker
                            label={t('scan_tec_date_start')}
                            value={value}
                            placeholder="Select your birthday"
                            onChange={(newValue) => {
                                if (newValue <= valueEnd  || valueEnd == null) {
                                    setValue(newValue);
                                }
                                if (newValue == null) {
                                    setValue(null)
                                }
                                if (valueEnd == null) {
                                    setLastDate(newValue);
                                }
                            }}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            renderInput={(startProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                </React.Fragment>
                            )}
                        />
                        <DatePicker
                            label={t('scan_tec_date_end')}
                            value={valueEnd}
                            placeholderText={'Please select a date'}
                            onChange={(newValue) => {
                                console.log(newValue);
                                if (value <= newValue|| (value == null)){
                                    setValueEnd(newValue);
                                    setLastDate(newValue);
                                }
                                if (newValue == null) {
                                    setValueEnd(null)
                                }
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                </React.Fragment>
                            )}
                        />
                </LocalizationProvider>
                    <Autocomplete
                        noOptionsText={t('layout_default_no_options')}
                        clearText={t('layout_default_clear')}
                        id="filter-demo"
                        options={objects}
                        getOptionLabel={(option) => option.title}
                        filterOptions={filterOptions}
                        sx={{ width: 300 }}
                        onChange={(event, newValue) => {
                            setRoad(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label={t('scan_tec_road')} />}
                    />
                    <Button variant="contained" onClick={()=> handleData()} endIcon={< SendIcon/>}>
                        {t('layout_default_search')}
                    </Button>
                </Stack>
            </div>
            </motion.div>

            {isDataSet && <motion.div variants={item} className="p-16 sm:p-24">
                <Typography
                    component={motion.div}
                    variants={item}
                    className="px-16 pb-8 text-18 font-medium"
                    color="textSecondary"
                >
                    {t('scan_tec_classification_text')}
                </Typography>
                <div className="widget w-full pb-32">
                    <Stack  direction="row" spacing={2}>
                        <TextField
                            label={t('scan_tec_bin_red')}
                            id="outlined-start-adornment"
                            style={{'margin':'8px'}}
                            sx={{ m: 1, width: '25ch' }}
                            type="number"
                            value={valueTextRed}
                            InputProps={{
                                startAdornment: <InputAdornment position="end">{t('scantec_test')}</InputAdornment>,
                            }}
                            onChange={(e) => setValueTextRed(e.target.value)}
                        />
                        <TextField
                            label={t('scan_tec_bin_yellow')}
                            id="outlined-start-adornment"
                            sx={{ m: 1, width: '25ch' }}
                            style={{'margin':'8px'}}
                            type="number"
                            value={valueTextYellow}
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="end">{t('scantec_test')}</InputAdornment>,
                            }}
                            onChange={(e) => setValueTextYellow(e.target.value)}
                        />
                        <Button  style={{'margin':'8px'}} variant="contained" onClick={()=> updateValues()} endIcon={< SendIcon/>}>
                            {t('scan_tec_update')}
                        </Button>
                    </Stack>
                </div>
            </motion.div>}

            <motion.div
                className="flex flex-col md:flex-row sm:p-8 container scantec-list"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <motion.div  className="widget w-full p-16 pb-32">
                        <ScantecMap mapEl={setMapEl} region={region} setDetails={setDetails} date={moment(lastDate).format("YYYY-MM-DD")}/>
                </motion.div>
                <div className="flex flex-wrap w-full md:w-320">
                    <div className="mb-32 w-full sm:w-1/2 md:w-full">
                        <motion.div className="widget w-full p-16 pb-32 ">
                            <ListScanTecWidget  mapEl={mapEl} setDetails={setDetails}  date={moment(lastDate).format("YYYY-MM-DD")}/>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valueTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={t('scantec_overview_tab')} value="1" />
                            <Tab label={t('scantec_overview_bin')} value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div>
                            <Typography
                                component={motion.div}
                                variants={item}
                                className="px-16 pb-8 text-18 font-medium"
                                color="textSecondary"
                            >
                                {t('scantec_overview_text')}
                            </Typography>

                            {dates && <div className="flex flex-col sm:flex sm:flex-row pb-32">
                                {dates && dates.length > 1 &&
                                <motion.div variants={item} className="widget flex w-full sm:w-1/3 p-16">
                                    <CountBinsVehicle/>
                                </motion.div>
                                }
                                {dates && dates.length > 1 &&
                                <motion.div variants={item} className="widget flex w-full sm:w-1/3 p-16">
                                    <CountSensorBin/>
                                </motion.div>
                                }
                                <motion.div variants={item} className="widget flex w-full sm:w-1/3 p-16">
                                    <CountBinsWidget/>
                                </motion.div>
                            </div>
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        {loadTrend && <SkeltonBigWidget />}
                            {loadTrend == false && <div className="mb-32 w-full sm:w-1/2 md:w-full">

                       <Typography
                            component={motion.div}
                            variants={item}
                            className="px-16 pb-8 text-18 font-medium"
                            color="textSecondary"
                        >
                            {t('tour_data')}
                        </Typography>
                        <div className="flex flex-col sm:flex sm:flex-row pb-32">
                            <motion.div variants={item} className="widget flex w-full sm:w-1/3 p-16 card">
                                <InfoWidget />
                            </motion.div>
                            <motion.div variants={item} className="widget flex w-full sm:w-1/4 p-16 card">
                                <SensorWidget />
                            </motion.div>

                            <motion.div varriants={item} className="widget w-full p-16 pb-48">
                                <SkeltonWidgetLeft trends={trends}  date={moment(lastDate).format("YYYY-MM-DD")} yellow={valueTextYellow} red={valueTextRed}/>
                            </motion.div>
                        </div>
                            </div>}
                        </TabPanel>
                </TabContext>
            </Box>
        </div>

    );

}
export default withReducer('main', reducer)(ScantecContent);
