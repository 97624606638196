import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {memo, default as React, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import KocoUtils from "../../../utils/KocoUtils";
import {useSelector} from "react-redux";
import FuseLoading from "../../../../@fuse/core/FuseLoading";
import {floor} from "lodash/math";
import moment from 'moment';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import _ from "lodash";
function CountBinsVehicle(props) {
    const theme = useTheme();
    let [serie, setSerie] = useState({});
    let { load,entities,pourcentageGreen,
        pourcentageYellow,dates,
        pourcentageRed} = useSelector(({ main }) => main.scantec.pourcentageScantec);
    let { entities2, loading,search,ids,searchEntities } = useSelector(({ main }) => main.scantec.unitsScantec);
    const { t } = useTranslation('common');
    let isSet = false;
    let color ="#228B22";

    useEffect(() => {
        if (entities && entities.length > 0) {
            setSerie({
                series: [
                    {
                        name: t('scan_tec_bin_green'),
                        data: pourcentageGreen
                    },
                    {
                        name: t('scan_tec_bin_yellow'),
                        data: pourcentageYellow
                    },
                    {
                        name: t('scan_tec_bin_red'),
                        data: pourcentageRed
                    }
                ], options: {
                    chart: {
                        height: 220,
                        type: 'line',
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#228B22', '#FFD700', '#FF0000'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        padding: {
                            top: 0,
                            right: 30,
                            bottom: 0,
                            left: 30
                        },
                    },
                    xaxis: {
                        type: 'string',
                        categories:dates,
                        labels: {
                            rotate: -90
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                return value + ' %'
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Temperature',
                            show:false
                        },
                        show:false
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: 0,
                        offsetX: 0,
                    }
                    ,padding: {
                        left: 0,
                        right:0
                    },
                }
            });
        }
    }, [entities]);
    if (load) return <FuseLoading />;
    return (<Card className="w-full rounded-20 shadow p-20">
            <div className="pb-24">
            <Typography className="h3 font-medium">{t('scantec_overview_graph_trend')}</Typography>
            </div>
            {entities && entities.length == 0 && <div className="h-256 relative">
                <Typography className="text-16 px-16 font-medium"
                            color="textSecondary">{t('cockpit_tab_default_not_available')}</Typography>
            </div>
            }
            {entities && entities.length > 0 && <div className="h-256 relative">
                {serie && serie.options &&
                <ReactApexChart height={270} options={serie.options} series={serie.series} type="line"/>}
            </div>
            }
        </Card>
        );
}

export default CountBinsVehicle;
