import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StTourOverview = lazy(() => import('./StTourOverview'));

const SettingsStTourOverviewConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'sttouroverview',
      element: <StTourOverview />,
      auth: [authRoles.ROLE_ST_TOUR_OVERVIEW_READ], 
    },
  ],
};

export default SettingsStTourOverviewConfig;
