import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {pourcentageScantecSlice} from "../../scantec/store/graphPositionScantec";

const initialState = {
  loading: false,
  data:[],
}

export const getLoadData = createAsyncThunk('fleetTracking/units/fleettracking', async (id) => {
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+id+ apiUrlConfig.GET_LOAD_DATA, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data.data;
});


export const fleetLoadDataSlice = createSlice({
  name: 'fleetTracking/fleettracking',
  initialState,
  reducers: {
      setClearAll: {
        reducer: (state, action) => {
          state.loading = true;
          state.data = [];
        }
      }
  },
  extraReducers: {
    [getLoadData.pending]: (state) => {
      state.loading = true;
      state.data = [];
    },
    [getLoadData.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.data =payload
    },
    [getLoadData.rejected]: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const postReducer = fleetLoadDataSlice.reducer;
export const {setClearAll} = fleetLoadDataSlice.actions;

export default fleetLoadDataSlice.reducer;