import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";
import {unitFleetTrackingSlice} from "../../fleetTracking/store/FleetTrackingSlice";
import FosService from 'app/services/fosService';


const initialState = {
  entities2: [],
  loading: false
}

export const getUnitsReport = createAsyncThunk('reports/units', async () => {
  const hasJustLoggedIn = FosService.isLoggedIn;
  const params = {
    limit: 0,
  };

  if (hasJustLoggedIn) {
    params.hasJustLoggedIn = 1;
  }else {
    params.hasJustLoggedIn = 0;
  }

  const response = await axios.get(apiUrlConfig.GET_UNITS, { params: params, headers: { 'X-UI-State': 'kocobox.reports' } });
  const data = await response.data;
  FosService.setLoggedIn(false);
  return data.units.map(val => {
    val.nameKoco = val.nameKoco ? val.nameKoco: "-";
    val.title= (val.nameKoco ? val.nameKoco: "-");
    val.value = val.id;
    val.isSelected = false;
    return val;
  });
});


export const unitsReportsSlice = createSlice({
  name: 'scantec/units',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getUnitsReport.pending]: (state) => {
      state.loading = true
    },
    [getUnitsReport.fulfilled]: (state, { payload }) => {
      state.loading = false
      //state.searchEntities = payload
      state.entities2 = payload
      //state.markers = _.map(payload, 'latestPosition');
    },
    [getUnitsReport.rejected]: (state) => {
      state.loading = false
    },
  },
});

export const postReducer = unitsReportsSlice.reducer;
export default unitsReportsSlice.reducer;