
const DocumentationNavigation = {
  id: 'documentation',
  title: 'Documentation',
  type: 'group',
  icon: 'star',
  children: [
  {
        id: 'getting-started',
        title: 'Getting Started',
        type: 'collapse',
        icon: 'play_arrow',
        children: [
          {
            id: 'introduction-doc',
            title: 'Introduction',
            type: 'item',
            url: '/documentation/introduction',
          }
        ],
      },
    {
      id: 'Predective Mainteance',
      title: 'Predective Mainteance',
      type: 'collapse',
      icon: 'play_arrow',
      children: [
        {
          id: 'api call',
          title: 'Api call',
          type: 'item',
          url: '/documentation/apicall',
        },
        {
          id: 'signals',
          title: 'Signals',
          type: 'item',
          url: '/documentation/signals',
        }
      ],
    },
    {
          id: 'Mobile App',
          title: 'Mobile App',
          type: 'collapse',
          icon: 'play_arrow',
          children: [
            {
              id: 'api calls',
              title: 'Api call',
              type: 'item',
              url: '/documentation/introduction3',
            }
          ],
        }
  ],
};

export default DocumentationNavigation;
