import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getRoles = createAsyncThunk('main/settingsRole/roles/getRoles', async () => {
  const response = await axios.get(`api/v1/rolekocos`, { params: { limit: 0 }, headers: { 'X-UI-State': 'role.settings.roles' } });
  const data = await response.data;

  return data.rolekocos;
});

export const getRolesByCompany = createAsyncThunk('main/settingsRole/roles/getRolesByCompany', async (company) => {
  const response = await axios.get(`api/v1/companies/${company.id}/rolekocos`, { params: { limit: 0 }, headers: { 'X-UI-State': 'role.settings.roles' } });
  const data = await response.data;

  return data.rolekocos;
});

const rolesAdapter = createEntityAdapter({});

export const { selectAll: selectRoles, selectById: selectRoleById } =
rolesAdapter.getSelectors((state) => state.main.settingsRole.roles.roles);

const rolesSlice = createSlice({
  name: 'main/settingsRole/roles',
  initialState: {
    searchText: '',
    loadingRoles: false,
    roles: rolesAdapter.getInitialState({ }),
    roles: rolesAdapter.getInitialState({ }),
  },
  reducers: {
    setRolesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getRoles.fulfilled](state, { payload }) {
      rolesAdapter.setAll(state.roles, payload);
      state.loadingRoles = false;
    },
    [getRoles.pending]: (state) => {
      state.loadingRoles = true;
      rolesAdapter.removeAll(state.roles);
    },
    [getRoles.rejected]: (state) => {
      state.loadingRoles = false;
    },

    [getRolesByCompany.fulfilled](state, { payload }) {
      rolesAdapter.setAll(state.roles, payload);
      state.loadingRoles = false;
    },
    [getRolesByCompany.pending]: (state) => {
      state.loadingRoles = true;
      rolesAdapter.removeAll(state.roles);
      state.loadingRoles = false;
    },
    [getRolesByCompany.rejected]: (state) => {
      state.loadingRoles = false;
    },
  },
});

export const { setRolesSearchText } = rolesSlice.actions;

export default rolesSlice.reducer;
