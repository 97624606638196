export const ST_REGION_TYPE = {
    REGION_TOUR: 1,
    REGION_GEOFENCE: 2,
    REGION_BIN: 3,
};

export class StRegionType {

    static label = {
        1: 'TOUR',
        2: 'GEOFENCE',
        3: 'BIN',
    };

    static translationKey = {
        1: 'st_region_type_tour',
        2: 'st_region_type_geofence',
        3: 'st_region_type_bin',
    };
}

export default StRegionType;
