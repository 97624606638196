import { authRoles } from 'app/auth';
import { lazy } from 'react';

const TaTourDetails = lazy(() => import('./TaTourDetails'));

const TaTourDetailsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'tatourdetails',
      element: <TaTourDetails />,
      auth: [authRoles.ROLE_TA_TOUR_DETAIL_READ], 
    },
  ],
};

export default TaTourDetailsConfig;
