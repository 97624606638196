import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from "@lodash";
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  positions: [],
  loadingGps: true,
};

export const getAllGps = createAsyncThunk(
  "fleetTracking/units/gps",
  async (obj) => {
    // const { id, date, date2 } = obj;
    const { id, date, date2, isTodayDate } = obj;

    const params = { date: date, date2: date2 };
    if (isTodayDate) {
      params.isTodayDate = isTodayDate;
    }

    const response = await axios.get(
      "/api/v1/unitdatas/" + id + apiUrlConfig.GET_POSITIONS_ALL,
      {
        params: params,
        headers: { "X-UI-State": "kocobox.overview" },
      }
    );
    const data = await response.data;
    return data;
  }
);

export const fleetTrackingGpsSlice = createSlice({
  name: "fleetTracking/gpsAll",
  initialState,
  reducers: {
    resetPositions: {
      reducer: (state, action) => {
        state.positions = [];
      },
    },
  },
  extraReducers: {
    [getAllGps.pending]: (state) => {
      state.loadingGps = true;
    },
    [getAllGps.fulfilled]: (state, { payload }) => {
      state.loadingGps = false;
      state.positions = payload;
    },
    [getAllGps.rejected]: (state) => {
      state.loadingGps = false;
    },
  },
});
export const postReducer = fleetTrackingGpsSlice.reducer;
export const { resetPositions } = fleetTrackingGpsSlice.actions;
export default fleetTrackingGpsSlice.reducer;
