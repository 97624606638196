import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Subscription = lazy(() => import('./Subscription/Subscription'));
const Subscriptions = lazy(() => import('./Subscriptions/Subscriptions'));

const SettingsSubscriptionConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'subscriptions',
      element: <Subscriptions />,
    },
    {
      path: 'subscriptions/:subscriptionId/*',
      element: <Subscription />,
    },
  ],
};

export default SettingsSubscriptionConfig;
