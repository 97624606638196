import { authRoles } from "app/auth";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const StClassifiers = lazy(() => import("./stClassifiers/StClassifiers"));
const StClassifier = lazy(() => import("./stClassifier/StClassifier"));

const SettingsStClassifiersConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "stclassifiers",
      element: <StClassifiers />,
      auth: [authRoles.ROLE_ST_CLASSIFIER_READ],
    },
    {
      path: "stclassifiers/:classifierId/*",
      element: <StClassifier />,
      auth: [authRoles.ROLE_ST_CLASSIFIER_READ],
    },
  ],
};

export default SettingsStClassifiersConfig;
