import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialogDate from "./customDialogDate";
import { getLoadData } from "../store/FleetTrackingLoadData";
import { getLiveData } from "../store/FleetTrackingLiveData";
import { getConsumption } from "../store/FleetTrackingConsumptionData";
import { GpsFixed, Loupe, ShowChart, Calculate } from "@mui/icons-material";
import History from "@history";

import { useTranslation } from "react-i18next";
import { getSignals } from "../store/FleetTrackingGpsSlice";
import { getCalculation } from "../store/FleetTrackingCalculation";
import moment from "moment";
import { getLines } from "../store/FleetTrackingLines";
import { setIsDriving } from "../store/FleetTrackingSlice";
import { showMessage } from "../../../store/fuse/messageSlice";

function WidgetMultiAction(props) {
  const dispatch = useDispatch();
  //const { selectedContactIds,setState } = props;
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  function openSelectedContactMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  const success = useRef();
  let { load, data } = useSelector(
    ({ main }) => main.fleetTracking.fleetCalculation
  );
  success.current = data;
  function openPopup(item) {
    if (item.isActivToday && item.isStillRunning == false) {
      dispatch(getCalculation({ id: item.id, type: 0 }));
      dispatch(
        showMessage({
          message: t("fleet_tracking_notification_gps_calculation"),
        })
      );
    }
    setAnchorEl(null);
    props.setId(item.id);
    props.setUnit(item);
    props.setDisplayMapFleet(true);
    props.setNewUnit("");
    History.replace("/fleetTracking");
  }

  function handleClick(item) {
    // props.navigate(`/unit/${item.id}`, {unit: item});
    History.push(`/unit/${item.id}`, { unit: item });
  }

  function showDetails(id, item) {
    props.state(true);
    dispatch(getLoadData(id));
    if (item.isRcv) {
      if (item.currentVersion == 2) {
        dispatch(getLiveData({ id: id, type: 12 }));
      } else {
        dispatch(getLiveData({ id: id, type: 1 }));
      }
    } else {
      dispatch(getLiveData({ id: id, type: 5 }));
    }
    dispatch(getSignals(id));
    dispatch(getConsumption(id));
    props.setUnitSelected(item);
    props.setText(item.nameKoco);
    props.setDataFetched(3);
    setAnchorEl(null);
  }

  function closeSelectedContactsMenu() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        aria-label="more"
        size="large"
        aria-owns={anchorEl ? "selectedContactsMenu" : null}
        aria-haspopup="true"
        onClick={openSelectedContactMenu}
        size="large"
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        id="selectedContactsMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeSelectedContactsMenu}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              openPopup(props.item);
            }}
          >
            <ListItemIcon className="min-w-40">
              <GpsFixed>
                {t("fleet_tracking_popup_button_display_route")}
              </GpsFixed>
            </ListItemIcon>
            <ListItemText
              primary={t("fleet_tracking_popup_button_display_route")}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              showDetails(props.id, props.item);
            }}
          >
            <ListItemIcon className="min-w-40">
              <ShowChart>{t("fleet_tracking_popup_button_details")}</ShowChart>
            </ListItemIcon>
            <ListItemText primary={t("fleet_tracking_popup_button_details")} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClick(props.item);
            }}
          >
            <ListItemIcon className="min-w-40">
              <Loupe>{t("cockpit_title_2")}</Loupe>
            </ListItemIcon>
            <ListItemText primary={t("cockpit_title_2")} />
          </MenuItem>
        </MenuList>
      </Menu>
      <CustomDialogDate
        open={open}
        setOpen={setOpen}
        row={props.item}
        id={props.id}
        setAnchorEl={setAnchorEl}
        setDisplayMapFleet={props.setDisplayMapFleet}
      />
    </>
  );
}

export default WidgetMultiAction;
