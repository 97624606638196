import L from "leaflet";
import {default as React, useEffect, useState} from "react";
import "../css/legend.css";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";


function LegendFleet({ map,dates }) {
    const { t } = useTranslation('common');
    if (map && dates && dates.length > 0) {
        removeElementsByClass('legend');
        const legend = L.control({ position: "bottomright" });
        if (document.getElementsByClassName('legend').length == 0) {
            legend.onAdd = function () {
                var div = L.DomUtil.create('div', 'info legend'),
                    grades =  ["blue", "red","yellow","green","black","cyan", "darkorange","gray","indigo","lightcoral","magenta","blue", "red","yellow","green","black","cyan", "darkorange","gray","indigo","lightcoral","magenta","blue", "red","yellow","green","black","cyan", "darkorange","gray","indigo","lightcoral","magenta"],
                labels = dates;
                <svg height="30" width="30">
                    <circle cx="15" cy="15" r="15" fill="red" />
                </svg>

                // loop through our density intervals and generate a label with a colored square for each interval
                for (let i = 0; i < dates.length; i++) {
                    div.innerHTML += ('<svg height="20" width="20"><circle cx="10" cy="10" r="10" fill="'+grades[i]+'"/></svg>'+
                        dates[i] + '<br>');
                }

                return div;
            };
            if (map) {
                legend.addTo(map);
            }
        } else {
            removeElementsByClass('legend');
            legend.onAdd = function () {
                var div = L.DomUtil.create('div', 'info legend'),
                    grades = ["blue", "red","yellow","green","black","cyan",
                        "darkorange","gray","indigo","lightcoral","magenta","blue", "red","yellow",
                        "green","black","cyan", "darkorange","gray","indigo","lightcoral","magenta",
                        "blue", "red","yellow","green","black","cyan", "darkorange","gray","indigo",
                        "lightcoral","magenta"],
                     labels = dates;
                <svg height="30" width="30">
                    <circle cx="15" cy="15" r="15" fill="red" />
                </svg>

                // loop through our density intervals and generate a label with a colored square for each interval
                for (let i = 0; i < dates.length; i++) {
                    div.innerHTML += ('<svg height="30" width="30"><circle cx="15" cy="15" r="15" fill='+grades[i]+'/></svg>'+dates[i]+ '<br>');
                }

                return div;
            };
            if (map) {
                legend.addTo(map);
            }
        }
    }
    return null;

    function removeElementsByClass(className){
        const elements = document.getElementsByClassName(className);
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }
}

export default LegendFleet;