import PASSIVE_VEHICLE_TYPE from "app/types/PassiveVehicleType";
import moment from "moment";

class KocoUtils {
    static numberWithCommas = (x) => {
        if (typeof x === 'undefined' || x == null) {
            return '-';
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    static convertDiesel = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data)).toFixed(1).replace('.', ',');
        return val;
    };
    static roundNumber = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data)).toFixed(1).replace('.', ',');
        return val;
    };
    static convertToKm = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data) / 1000).toFixed(1).replace('.', ',');
        return KocoUtils.numberWithCommas(val);
    };
    static formatDate = function (date, format) {
        if (typeof date === 'undefined' || !date)
            return '-';

        if (typeof format !== 'undefined' && format)
            return moment(date).format(format);

        return moment(date).format('DD.MM.YYYY HH:mm');
    };
    static reorder = function (list, startIndex, endIndex) {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    };
    static hexToSignedInt = function (hex) {
        if (hex.length % 2 != 0) {
            hex = "0" + hex;
        }
        var num = parseInt(hex, 16);
        var maxVal = Math.pow(2, hex.length / 2 * 8);
        if (num > maxVal / 2 - 1) {
            num = num - maxVal
        }
        return num;
    };

    static getPassiveVehicleType = function (unit) {

        if(unit.isRcv)
            return PASSIVE_VEHICLE_TYPE.RCV;
        
        return PASSIVE_VEHICLE_TYPE.SWEEPER;

    };

    static getParameterBodyType = function (unit) {

        if (!unit || !unit.constructionType)
            return null;

        var vehicleType = null;

        // const Variopress = 1;
        // const Powerpress = 2;
        // const Rotopress = 4;
        // const Sidepress = 8;
        // const Frontpress = 16;
        // const MEDIUM X2 = 32;
        // const MEDIUM X4 = 64;

        var constructiontype = unit.constructionType.toLowerCase();

        if (unit.isRcv == true) {
            if (constructiontype.includes("variopress")) {
                vehicleType = 1;
            } else if (constructiontype.includes("rotopress")) {
                vehicleType = 4;
            } else if (constructiontype.includes("powerpress")) {
                vehicleType = 2;
            } else if (constructiontype.includes("sidepress")) {
                vehicleType = 8;
            } else if (constructiontype.includes("frontpress")) {
                vehicleType = 16;
            } else if (constructiontype.includes("medium x2")) {
                vehicleType = 32;
            } else if (constructiontype.includes("medium x4")) {
                vehicleType = 64;
            } else if (constructiontype.includes("micro")) {
                vehicleType = 110;
            } else {
                vehicleType = -1;
            }
        }
        else { // sweeper

            if (constructiontype.includes("viajet5")) {
                vehicleType = 0;
            }
            else if (constructiontype.includes("viajet6")) {
                vehicleType = 1;
            }
            else if (constructiontype.includes("viajet7")) {
                vehicleType = 2;
            }
            else if (constructiontype.includes("viajet8")) {
                vehicleType = 3;
            }
            else if (constructiontype.includes("viajet12")) {
                vehicleType = 4;
            }
            else if (constructiontype.includes("sk500")) {
                vehicleType = 5;
            }
            else if (constructiontype.includes("sk600")) {
                vehicleType = 6;
            }
            else if (constructiontype.includes("sk700")) {
                vehicleType = 7;
            }
            else if (constructiontype.includes("sk800")) {
                vehicleType = 8;
            }
            else if (constructiontype.includes("sk1200")) {
                vehicleType = 9;
            }
            else if (constructiontype.includes("asvac5")) {
                vehicleType = 10;
            }
            else if (constructiontype.includes("asvac6")) {
                vehicleType = 11;
            }
            else if (constructiontype.includes("terrajet")) {
                vehicleType = 12;
            }
            else {
                vehicleType = -1;
            }
        }

        return vehicleType;
    };

    static isSideLoader = function (unit) {
        if (unit.constructionType.toLowerCase() == 'faun sidepress' || unit.constructionType.toLowerCase() == 'zoeller slf xl')
            return true;
        return false;
    };
    static uuidv4 = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    static isValidDate = function (dateTime) {
        if(dateTime instanceof Date){
          return !isNaN(dateTime.getTime());
        } 
        else {
          const d = new Date(dateTime);
          return !isNaN(d.getTime());
        }
    };

    static isToday = (dateTime) => {
   
        const today = new Date();
        const date = new Date(dateTime);

        return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    };

    static startOfDay = (dateTime) => {

        if(!dateTime)
            return null;

        if(!KocoUtils.isValidDate(dateTime))
            return null;
        
        let date = new Date(dateTime);

        date.setHours(0,0,0,0);
        return date;
    };

    static endOfDay = (dateTime) => {

        if(!dateTime)
            return null;

        if(!KocoUtils.isValidDate(dateTime))
            return null;
        
        let date = new Date(dateTime);
         date.setHours(23,59,59,999);
        return date;
    };

    static isObjEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    static calculatePixelResult = (obj) => {

        let pixelRes = [];
        let pixelSum = 0;
        let allowedPixelSum = 0;
        let notAllowedPixelSum = 0;

        obj.pixelCount?.forEach(pixCount => {
            pixelSum += pixCount.value;
        });

        if (obj.stClassifier && obj.stClassifier.allowed?.length > 0) {

            obj.stClassifier.allowed.forEach(group => {
                let groupSum = 0;
                group.classifiers.forEach(item => {
                    obj.pixelCount?.forEach(pixCount => {

                        if (pixCount['key'] === `_${item.classifier}`) {
                            groupSum += pixCount.value;
                        }
                    });
                });
                allowedPixelSum += groupSum;
                if (groupSum !== 0) {
                    pixelRes.push(
                        {
                            name: group.name,
                            allowed: true,
                            sum: groupSum,
                            value: pixelSum !== 0 ? Math.round(groupSum / pixelSum * 100) : 0,
                            color: group.color
                        });
                }
            });
        }

        if (obj.stClassifier && obj.stClassifier.notAllowed?.length > 0) {
            obj.stClassifier.notAllowed.forEach(group => {
                let groupSum = 0;
                group.classifiers.forEach(item => {
                    obj.pixelCount?.forEach(pixCount => {

                        if (pixCount['key'] === `_${item.classifier}`) {
                            groupSum += pixCount.value;
                        }
                    });
                });
                notAllowedPixelSum += groupSum;
                if (groupSum !== 0) {
                    pixelRes.push(
                        {
                            name: group.name,
                            allowed: false,
                            sum: groupSum,
                            value: pixelSum !== 0 ? Math.round(groupSum / pixelSum * 100) : 0,
                            color: group.color
                        });
                }
            });
        }

        return ({
            ...obj,
            utcTimeRecorded: obj.metadata?.utcTimeRecorded,
            lifterId: obj.binCounts && obj.binCounts[0] ? obj.binCounts[0].lifterId : null,
            pixelRes: pixelRes,
            allowedPixelCount: allowedPixelSum,
            allowedPixelValue: pixelSum !== 0 ? Math.round(allowedPixelSum / pixelSum * 100) : 0,
            notAllowedPixelCount: notAllowedPixelSum,
            notAllowedPixelValue: pixelSum !== 0 ? Math.round(notAllowedPixelSum / pixelSum * 100) : 0,
            totalPixelCount: pixelSum,
        })
    };

    static isDateElapsedMoreThan(date, days) {
        let d = null;
        if (date instanceof Date) {
            d = date.getTime();
        } else {
            const _d = new Date(date);
            d = _d.getTime();
        }
    
        let currentDate = new Date();
        let difference = currentDate.getTime() - d;
        let diffInDays = Math.floor(difference / (1000 * 3600 * 24)); 
        return diffInDays > days;
    }

    static getStTourDetailCardColor(row) {

        if (!row || (row.allowedPixelValue === 0 && row.notAllowedPixelValue === 0))
            return { bgColor: 'grey', foreColor: 'white' };

        if (typeof row.stClassifier?.allowedThresholds?.good !== 'undefined' &&
            typeof row.stClassifier?.allowedThresholds?.bad !== 'undefined') {

            if (row.allowedPixelValue >= row.stClassifier.allowedThresholds.good) {
                return { bgColor: 'green', foreColor: 'white' };
            } else if (row.allowedPixelValue >= row.stClassifier.allowedThresholds.bad) {
                return { bgColor: 'yellow', foreColor: 'black' };
            }
            else {
                return { bgColor: 'red', foreColor: 'black' };
            }
        } else {
            return { bgColor: 'grey', foreColor: 'white' };
        }
    }

    static isObjectEqual(oldObj, newObj) {
        if ((!oldObj && newObj) || (oldObj && !newObj))
            return false;

        if ((!oldObj && !newObj) || (oldObj.id === newObj.id)) {
            return true;
        }

        return false;
    }

    static rcvMappings = {
        'VARIOPRESS': 1,
        'Variopress':1,
        'POWERPRESS': 2,
        'ROTOPRESS': 4,
        'SIDEPRESS': 8,
        'FRONTPRESS': 16,
        'MEDIUM_X2': 32,
        'MEDIUM_X4': 64,
        'SIDELOADER': 244
    };

    static sweeperMappings = {
        'VIAJET5': 0,
        'VIAJET6': 1,
        'VIAJET7': 2,
        'VIAJET8': 3,
        'VIAJET12': 4,
        'SK500': 5,
        'SK600': 6,
        'SK700': 7,
        'SK800': 8,
        'SK1200': 9,
        'ASVAC5': 10,
        'ASVAC6': 11,
        'TERRAJET': 12,
        'CITYJET5': 30,
        'CITYJET6': 31
    };

    static getBodyTypeInfo = (bodyType) => {
        if (KocoUtils.rcvMappings[bodyType] !== undefined) {
            return {
                category: 1, // If RCV, category is 1
                bodyTypeNumber: KocoUtils.rcvMappings[bodyType]
            };
        } else if (KocoUtils.sweeperMappings[bodyType] !== undefined) {
            return {
                category: 2, // If Sweeper, category is 2
                bodyTypeNumber: KocoUtils.sweeperMappings[bodyType]
            };
        } else {
            return null;
        }
    };

    static formatBodyType = (bodyType) => {
        // Handle special case for "SIDE LOADER"
        if (bodyType === "SIDE LOADER") {
            return "SIDELOADER";
        }
        // Remove 'FAUN ' or 'ZOELLER ' from the beginning
        let formattedBodyType = bodyType.replace(/^FAUN\s|^ZOELLER\s/, '');
        // if formattedBodyType startng with 'VIAJET'
        if (formattedBodyType.startsWith('VIAJET')) {
            formattedBodyType = formattedBodyType.replace(/\s+/g, ''); //removing and making them ENUM compatinle
        } else {
            // For all other cases, replace spaces with underscores
            formattedBodyType = formattedBodyType.replace(/\s+/g, '_');
        }
        return formattedBodyType;
    };

    static identicalArrays = (arr1, arr2) => {

        if(!arr1 || !arr2) {
            return false;
        }            

        return arr1.length === arr2.length && arr1.some((value, index) => value === arr2[index]);
    };

}

export default KocoUtils;