import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import _ from '@lodash';
import FuseUtils from "../../../../@fuse/utils";

const initialState = {
  load: false,
  liveData:[],
  total:{},
  transport:{},
  collection:{},
  waiting:{},
  sweep:{}
}

export const getLiveData = createAsyncThunk('fleetTracking/units/liveData', async (obj) => {
  const {id,type} = obj;
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+id+ apiUrlConfig.GET_LIVE_DATA+"/"+type, { params: { limit: 0 }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const fleetLiveDataSlice = createSlice({
  name: 'fleetTracking/liveData',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getLiveData.pending]: (state) => {
      state.load = true
    },
    [getLiveData.fulfilled]: (state, { payload }) => {
      state.load = false;
      state.liveData =payload;
      state.total = _.find(payload, (element) => {
        return (element.signalId == '30201' || element.signalId == '20401' || element.signalId == '110202') ? true : false;
      });
      state.transport = _.find(payload, (element) => {
        return (element.signalId == 'g1');
      }); 
      state.collection = _.find(payload, (element) => {
        return (element.signalId == 'g2');
      });
      state.waiting = _.find(payload, (element) => {
        return (element.signalId == 'g3');
      });
      state.sweep = _.find(payload, (element) => {
        return (element.signalId == 'S09');
      });
    },
    [getLiveData.rejected]: (state) => {
      state.load = false
    },
  },
});

export const postReducer = fleetLiveDataSlice.reducer;
export default fleetLiveDataSlice.reducer;