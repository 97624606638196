// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    width: 60%  !important;
    height: 26.8rem !important;
}

.big-graph {
    height: 23.4rem !important;
}

.right{
    margin-left: 1.3rem !important;
}

.first_icon {
    margin-left: 5rem  !important;
}

.bin-ident {
    margin-left: 2rem !important;
}

.new-card-filter {
    padding: 2rem !important;
    padding-top: 0.2rem !important;
    padding-left: 0.2rem !important;
}

.background-color {
    background-color: lightgray !important;
}

.scantec-list .pagination .MuiToolbar-gutters {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
}

@media (min-width: 960px) {
    .scantec-list .flex-wrap  {
        width: 34rem !important;
    }
}
.scantec-list .MuiInputBase-root {
    margin-right: 9px  !important;
}

.muiltr-1354s64-MuiTablePagination-displayedRows {
    font-size: 0.8rem  !important;
}`, "",{"version":3,"sources":["webpack://./src/app/main/scantec/css/scantec.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,+BAA+B;AACnC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".card {\n    width: 60%  !important;\n    height: 26.8rem !important;\n}\n\n.big-graph {\n    height: 23.4rem !important;\n}\n\n.right{\n    margin-left: 1.3rem !important;\n}\n\n.first_icon {\n    margin-left: 5rem  !important;\n}\n\n.bin-ident {\n    margin-left: 2rem !important;\n}\n\n.new-card-filter {\n    padding: 2rem !important;\n    padding-top: 0.2rem !important;\n    padding-left: 0.2rem !important;\n}\n\n.background-color {\n    background-color: lightgray !important;\n}\n\n.scantec-list .pagination .MuiToolbar-gutters {\n    margin: 0 !important;\n    padding: 0 !important;\n    overflow: hidden !important;\n}\n\n@media (min-width: 960px) {\n    .scantec-list .flex-wrap  {\n        width: 34rem !important;\n    }\n}\n.scantec-list .MuiInputBase-root {\n    margin-right: 9px  !important;\n}\n\n.muiltr-1354s64-MuiTablePagination-displayedRows {\n    font-size: 0.8rem  !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
