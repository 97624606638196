import { lazy } from 'react';

const IntroductionDoc = lazy(() => import('./introduction/IntroductionDoc'));
const InstallationDoc = lazy(() => import('./installation/InstallationDoc'));
const GitRepositoryDoc = lazy(() => import('./git-repository/GitRepositoryDoc'));

const GettingStartedDocRoutes = [
  {
    path: 'apicall',
    element: <IntroductionDoc />,
  },
  {
    path: 'signals',
    element: <InstallationDoc />,
  },
  {
    path: 'git-repository',
    element: <GitRepositoryDoc />,
  },
];

export default GettingStartedDocRoutes;
