import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from '@lodash';
import {fleetMarkersSlice} from "../../fleetTracking/store/FleetTrackingMarkerSlice";

export const getHandOvers = createAsyncThunk('main/cockpit/unitDetail/getHandOvers', async (unit) => {
    // const {type,unit,lng} = obj;
    const id = "61409c49ec045b62508b457d";
    const response = await axios.get(`api/v1/units/${id}/handovers`);
    const data = await response.data;
    if (!data)
      return;
    return data.handovers;
});

export const getPdf = createAsyncThunk('main/cockpit/unitDetail/getPdf', async (id) => {

    const id1 = "649158284350484521000000";
    const response = await axios.get(`/api/v1/handovers/${id1}/download`);
    const data = await response.data;
    if (!data)
      return;
});

const HandOversSlice = createSlice({
  name: 'main/cockpit/unitDetail/handOvers',
  initialState: {
    loading: true,
    data : [],
  },
  reducers: {
          setHandOvers: {
              reducer: (state, action) => {
                  state.data = [];
              }
          }
  },

  extraReducers: {
    [getHandOvers.fulfilled](state, { payload }) {
        state.data = payload;
        state.loading = false;
    },
    [getHandOvers.pending](state, { payload }) {
      state.data = [];
      state.loading = true;
    },
    [getHandOvers.rejected](state, { payload }) {
      state.data = [];
      state.loading = false;
    },
  },
});

export const {setHandOvers} = HandOversSlice.actions;

export default HandOversSlice.reducer;