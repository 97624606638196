import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const User = lazy(() => import('./user/User'));

const SettingsProfileConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'profile',
      element: <User />,
    },
  ],
};

export default SettingsProfileConfig;
