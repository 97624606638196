import { authRoles } from 'app/auth';
import { lazy } from 'react';

const ServiceDashboard = lazy(() => import('./ServiceDashboard'));

const ServiceDashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [authRoles.ROLE_SERVICE_DASHBOARD_READ],
  routes: [
    {
      path: 'servicedashboard',
      element: <ServiceDashboard />,
    },
  ],
};

export default ServiceDashboardConfig;