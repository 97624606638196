import { combineReducers } from '@reduxjs/toolkit';
import subscription from './SubscriptionSlice';
import subscriptions from './SubscriptionsSlice';

const reducer = combineReducers({
  subscriptions,
  subscription,
});

export default reducer;
