import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';

export const getFaunErrorCodeVehicles = createAsyncThunk('main/cockpit/unitDetail/getFaunErrorCodeVehicles', async (unit, { getState }) => {
    const response = await axios.get(`api/v1/units/${unit.id}/errorcodes`);
    const data = await response.data;
    if (!data)
      return;

    return data.errorcodes;
  });
  
export const getFaunErrorCodeInfos = createAsyncThunk('main/cockpit/unitDetail/getFaunErrorCodeInfos', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/errorcodeinfos`);
  const data = await response.data;
  if (!data)
    return;

  return data.errorinfos;

});

const errorCodeVehiclesAdapter = createEntityAdapter({ selectId: (model) => model.id });

export const { selectAll: selectFaunErrorReports, selectById: selectFaunErrorReportById, selectIds: selectFaunErrorReportIds } =
  errorCodeVehiclesAdapter.getSelectors((state) => { return state.main.cockpit.faunErrorReport.errorCodeVehicles; });

const errorInfoAdapter = createEntityAdapter({ selectId: (model) => model.id });

export const { selectAll: selectErrorInfos, selectById: selectInfoById, selectIds: selectErrorInfoIds } =
  errorInfoAdapter.getSelectors((state) => { return state.main.cockpit.faunErrorReport.errorInfos; });

const FaunErrorReportSlice = createSlice({
  name: 'main/cockpit/unitDetail/faunErrorCodes',
  initialState: {
    searchText: '',
    loadingErrorCodeVehicles: false,
    loadingErrorCodeInfos: false,
    errorCodeVehicles: errorCodeVehiclesAdapter.getInitialState({ }),
    errorInfos: errorInfoAdapter.getInitialState({ }),
  },
  reducers: {
    setFaunErrorReportSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: {
    [getFaunErrorCodeVehicles.fulfilled](state, { payload }) {
      errorCodeVehiclesAdapter.setAll(state.errorCodeVehicles, payload);
      state.loadingErrorCodeVehicles = false;
    },
    [getFaunErrorCodeVehicles.pending](state, { payload }) {
      errorCodeVehiclesAdapter.removeAll(state.errorCodeVehicles);
      state.loadingErrorCodeVehicles = true;
    },
    [getFaunErrorCodeVehicles.rejected](state, { payload }) {
      state.loadingErrorCodeVehicles = false;
    },

    [getFaunErrorCodeInfos.fulfilled](state, { payload }) {
      errorInfoAdapter.setAll(state.errorInfos, payload);
      state.loadingErrorCodeInfos = false;
    },
    [getFaunErrorCodeInfos.pending](state, { payload }) {
      state.loadingErrorCodeInfos = true;
    },
    [getFaunErrorCodeInfos.rejected](state, { payload }) {
      state.loadingErrorCodeInfos = false;
    },
  },
  reducers: {
    setErrorCodeVehicles : {
      reducer: (state, action) => {
        
        errorInfoAdapter.setAll(state.errorCodeVehicles, action.payload);
      },
    },
  }
});

export const { setFaunErrorReportSearchText, setErrorCodeVehicles } = FaunErrorReportSlice.actions;

export default FaunErrorReportSlice.reducer;
