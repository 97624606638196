import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StTourCollectors = lazy(() => import('./st-tour-collectors/StTourCollectors'));

const SettingsStTourCollectorConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'sttourcollectors',
      element: <StTourCollectors />,
      auth: [authRoles.ROLE_ST_TOUR_COLLECTOR_READ], 
    },
  ],
};

export default SettingsStTourCollectorConfig;
