function getChassisManufacturer(chassisType) { 
    
    switch (chassisType) {
        case 1:
            return 'DAF';
        case 2:
            return 'Iveco';
        case 3:
            return 'MAN';
        case 4:
            return 'Mercedes-Benz';
        case 5:
            return 'Renault';
        case 6:
            return 'Scania';
        case 7:
            return 'Volvo';
        case 8:
            return 'MAZ';
        case 9:
            return 'BLUEPOWER';
        default:
            return ''; //'keine Verbindung';
    };
};

export default getChassisManufacturer;