import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import axios from "axios";
import moment from "moment";

export const getUnits = createAsyncThunk('main/tourMap/Tour/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getTours = createAsyncThunk(
  "main/tourMap/Tour/getTours",
  async (searchParams) => {
    const response = await axios.get(`api/v1/sttour`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "tourPlan.settings.tourPlans" },
    });
    const data = await response.data;

    return data.st_tours;
  }
);

export const getVehicleTour = createAsyncThunk(
  "main/tourMap/Tour/getVehicleTour",
  async (searchParams) => {
    // const response = await axios.get(`api/v1/stvehicletoursummaries`, {
    const response = await axios.get(`/api/v1/vehicletoursummaries`, {
      params: { limit: 10 },
      headers: { "X-UI-State": "tourPlan.settings.tourPlans" },
    });
    const data = await response.data;
    return data.vehicletoursummaries;
  }
);

export const getTourDate = createAsyncThunk(
  "main/tourMap/Tour/getTourDate",
  async (obj) => {
    let { fromDate, toDate, query, index } = obj;
    const response = await axios.get(`/api/v1/touranalyse/toursdate`, {
    // const response = await axios.get(`/api/v1/toursdate`, {
      params: {fromDate: fromDate, toDate: toDate, index: index, query: query },
      headers: { "X-UI-State": "tourPlan.settings.tourPlans" },
    });
    const data = await response.data;

    return data;
  }
);

export const getVehicles = createAsyncThunk(
  "main/tourMap/Tour/getVehicles",
  async (obj) => {
    let { fromDate, toDate, query, index } = obj;
    const response = await axios.get(`/api/v1/touranalyse/vehicledate`, {
    // const response = await axios.get(`/api/v1/vehicledate`, {
      params: {fromDate: fromDate, toDate: toDate, index: index, query: query },
      headers: { "X-UI-State": "tourPlan.settings.tourPlans" },
    });
    const data = await response.data;
    return data;
  }
);

export const getAddress = createAsyncThunk(
  "main/tourMap/Tour/getAddress",
  async (obj) => {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse`,
      { params: obj }
    );
    const data = await response;

    return data;
  }
);

export const postMapFilter = createAsyncThunk(
  "main/tourmap/TourPlans/post",
  async (searchParams) => {
    console.log("search params is ", searchParams);
    const response = await axios.post(
      "/api/v1/touranalyses/bins/searches",
      // "/api/v1/bins/searches",
      searchParams,
      {
        headers: {
          'X-UI-State': 'tourAnalyse.map'
        }
      }
    );
  // const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });

    const data = await response.data;
    return data.st_bin_events;
  }
);

// export const getBinsFilter = createAsyncThunk('main/tourmap/TourPlans/get', async (searchParams) => {
//   const response = await axios.get(  '/api/v1/bins/filter');
//   const data = await response.data;
//   return data;
// });

const TourSlice = createSlice({
  name: "main/tourMap/Tour",
  initialState: {
    selectedRow: 100,
    elements: [
      {
        fromDate: (() => {
          const today = new Date();
          const fromDate = new Date(today);
          fromDate.setHours(0, 0, 0, 0);
          return fromDate;
        })(),
        toDate: (() => {
          const today = new Date();
          const toDate = new Date(today);
          toDate.setHours(23, 59, 0, 0);
          return toDate;
        })(),
        //fromDate: "",
        //toDate: "",
        tours: [],
        vehicles: [],
      },
    ],
    elems2: [],
    address: "",
    tourPlansCount: 0,
    rangeDate: [null, null],
    rangeDateCombo: [],
    loadingUnits: false,
    vehicles: [],
    vehicle: null,
    isUpdated: false,
    toursAdded:[{"id" : 1, "name" : "", "type":1}],
    loadingTours: false,
    isDataDirty: false,
    tours: [],
    tour: null,
    markers: [],
    marker: {},
    bounds2: [],
    red: [],
    green: [],
    yellow: [],
    query: {},
    selectedTours: [],
    hasError: false,
    isDataDirty: false,
    loadingMarkers: false,
    loadingMarkersField: false,
    dates: [],
    binSummary: '',
    isBinUpdated: false,
  },
  reducers: {

    hideLine: {
      reducer: (state, action) => {
        let { index, vehicle, date } = action.payload;
    
        if (state.query && state.query.length > 0) {
          state.query = state.query.filter(
            (que) => !(que.vehicle === vehicle.vehicle.id && que.fromDate === date)
          );
        }
      }
    },

    showLine: {
      reducer: (state, action) => {
        const { index, vehicle } = action.payload;
        state.query.splice(index, 0, vehicle);
      }
  },
    setSelectedTours: {
      reducer: (state, action) => {
        state.selectedTours = action.payload;
      },
    },
    setBinSummary: {
      reducer: (state, action) => {
        state.binSummary = action.payload;
      },
    },

    addTour: {
      reducer: (state, action) => {
        const today = new Date();

        const fromDate = new Date(today);
        fromDate.setHours(0, 0, 0, 0);

        const toDate = new Date(today);
        toDate.setHours(23, 59, 0, 0);

        state.elements.push({
          "fromDate": fromDate,
          "toDate": toDate,
          "tours": state.tours,
          "vehicles": state.vehicles,
        });
        state.isUpdated = false;
      },
    },

    addLine: {
      reducer: (state, action) => {
        // TODO change date according to "fromData" and "toDate"
        let { date, vehicle, tour, selectedRow, tourUpdated, binUpdated } = action.payload;
        let vehicleSelected = null;
        let tourSelected = null;
        state.vehicles.forEach(function (el) {
          if (vehicle && el.id == vehicle.id) {
            vehicleSelected = el;
          }
        });
        state.tours.forEach(function (el) {
          if (tour && el.id == tour.id) {
            tourSelected = el;
          }
        });
        const fromDate = new Date(date);
        const toDate = new Date(fromDate);

        toDate.setHours(23, 59, 0, 0);
        // state.elements.push({
        //   "fromDate": fromDate,
        //   "toDate": toDate,
        //   "vehicle": vehicleSelected,
        //   "tour": tourSelected,
        //   "tours": state.tours,
        //   "vehicles": state.vehicles,
        // });
        state.elements[0] = {
          "fromDate": fromDate,
          "toDate": toDate,
          "vehicle": vehicleSelected,
          "tour": tourSelected,
          "tours": state.tours,
          "vehicles": state.vehicles,
        };
        state.isUpdated = true;
        state.selectedRow = selectedRow;
        state.loadingTours = false;
      },
    },

    deleteLine: {
      reducer: (state, action) => {
        // let tour = action.payload.tour;
        let index = action.payload;
        state.elements.splice(index, 1);
        //update fields for delete line.
        if (state.query && state.query.length > 0) {
          state.query.splice(index, 1);
          let arr = [];
          state.green.map(function (el, ind2) {
            if (el.index == index) {
              // state.green.splice(ind2,1);
            } else {
              arr.push(el);
            }
          });
          state.green = arr;
          arr = [];
          state.red.map(function (el, ind2) {
            if (el.index == index) {
              //state.red.splice(ind2,1);
            } else {
              arr.push(el);
            }
          });
          state.red = arr;
          arr = [];
          state.yellow.map(function (el, ind2) {
            if (el.index == index) {
              //state.yellow.splice(ind2,1);
            } else {
              arr.push(el);
            }
          });
          state.yellow = arr;
        }
      },
    },

    resetFilter: {
      reducer: (state, action) => {
        // let tour = action.payload.tour;
        let index = action.payload;
        let el = state.elements.at(index);
        el.tour = null;
        el.vehicle = null;
        el.fromDate = null;
        el.toDate = null;

      },
    },

    updateFromDate: {
      reducer: (state, action) => {
        let date = action.payload.date;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.fromDate = date;
        if ((el.fromDate && el.fromDate != null) && (el.toDate == "" || el.toDate == null)) {
          const today = new Date();
          const toDate = new Date(el.fromDate);
          toDate.setHours(23, 59, 0, 0);
          el.toDate = toDate;
        }
        state.isDataDirty = false;
      },
    },

    updateToDate: {
      reducer: (state, action) => {
        let date = action.payload.date;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.toDate = date;
        state.isDataDirty = false;
      },
    },

    setQuery: {
        reducer: (state, action) => {
          state.query = action.payload;
        }
    },

    setMarkers: {
        reducer: (state, action) => {
          state.markers = action.payload;
        }
    },

    setMarker: {
        reducer: (state, action) => {
            state.marker = action.payload;
        }
    },

    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },

    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },

    updateTour: {
      reducer: (state, action) => {
        let tour = action.payload.tour;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.tour = tour;
        state.isDataDirty = false;
      },
    },

    updateVehicle: {
      reducer: (state, action) => {
        let vehicle = action.payload.vehicle;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.vehicle = vehicle;
        state.isDataDirty = false;
      },
    },

    deletePreference: {
      reducer: (state, action) => {
        state.vehicle = null;
        state.tour = null;
      },
    },
  },
  extraReducers: {

    [getTours.fulfilled](state, { payload }) {
        // stToursAdapter.setAll(state.stTours, payload.st_tours);
        state.loadingTours = false;
        //state.stToursAdded =  [{"id" : 1, "name" : "", "type":1}]
        state.tourPlansCount = payload.count;
        state.tours = payload;
        state.isDataDirty = false;
        },
        [getTours.pending]: (state) => {
        state.loadingTours = true;
        //toursAdapter.removeAll(state.tours);
          state.toursAdded = [{ id: 1, name: "", type: 1 }];
          state.tourPlansCount = 0;
        //  state.tours = [];
        },
        [getTours.rejected]: (state) => {
        state.loadingTours = false;
        //state.savingtourPlan = false;
        state.tourPlansCount = 0;
        //  state.tours = [];
        },

    [getUnits.fulfilled](state, { payload }) {
        //unitsAdapter.setAll(state.units, payload);
        state.loadingUnits = false;
        },
        [getUnits.pending]: (state) => {
        state.loadingUnits = true;
        //unitsAdapter.removeAll(state.units);
        },
        [getUnits.rejected]: (state) => {
        state.loadingUnits = false;
        },

    [getVehicles.fulfilled](state, { payload }) {
        state.loadingUnits = false;
        if (parseInt(payload.offset) == 100) {
            state.vehicles = payload.units;
            state.elements.at(0).vehicles = payload.units;
            // state.loadingTours = true;
        } else {
            let el = state.elements.at(payload.offset);
            el.vehicles = payload.units;
            el.vehicle = "";
            //el.tour = "";
            state.elements.at[payload.offset] = el;
            state.loadingTours = false;
            if (payload.units && payload.units.length == 1) {
            el.vehicle = payload.units.at(0);
            }
        }
        },
        [getVehicles.pending]: (state) => {
        //    state.loadingTours = true;
        //unitsAdapter.removeAll(state.units);
        },
        [getVehicles.rejected]: (state) => {
        state.loadingUnits = false;
        },

    [getTourDate.fulfilled](state, { payload }) {
        state.loadingUnits = false;
        state.loadingTours = false;
        if (parseInt(payload.offset) == 100) {
            state.tours = payload.st_tours;
            state.elements.at(0).tours = payload.st_tours; 
            // state.loadingTours = true;
        } else {
            state.loadingTours = false;
            let el = state.elements.at(payload.offset);
            el.tours = payload.st_tours;
            el.tour = "";
            state.elements.at[payload.offset] = el;
            if (payload.st_tours && payload.st_tours.length == 1) {
            el.tour = payload.st_tours.at(0);
            }
        }
        },
        [getTourDate.pending]: (state) => {
        state.loadingUnits = true;
        // state.loadingTours = true;
        //unitsAdapter.removeAll(state.units);
        },
        [getTourDate.rejected]: (state) => {
          state.loadingTours = false;
          state.loadingUnits = false;
        },

    [getVehicleTour.fulfilled](state, { payload }) {
      // console.log('payload id ', payload);
      state.loadingTours = false;

      state.dates = payload.map((tourView) => new Date(tourView.dateTime));
    },
    [getVehicleTour.rejected]: (state) => {
      state.address = "";
    },
    [getVehicleTour.pending]: (state) => {
      // state.loadingTours = true;
      state.address = "";
    },
    [postMapFilter.fulfilled](state, { payload }) {
      state.markers = payload;
      state.loadingMarkers = false;
      state.loadingMarkersField = false;
      let array = [];
      state.bounds2 = [];
      // state.red = [];
      // state.yellow = [];
      // state.green = [];
      state.isUpdated = false;
      state.isBinUpdated = false;
      let ind = 0;
      //  bounds2.push(L.marker([]));
    //   payload.map((item) => {
    //     state.bounds2.push([item.position.latitude, item.position.longitude]);
    //   });
      payload.map(function (el) {
        let date = moment(el.date);
        state.query.map(function (queryItem,index) {
          let date2 = moment(queryItem.fromDate);
          let date3 = moment(queryItem.fromDate).add(1, 'day');
          if (date >= date2 && date <= date3 && queryItem.vehicle == el.vehicle.id) {
            // el.index = index;
            const item = {
              ...el,
              index: index
            }
            queryItem.array.push(item);
            state.bounds2.push([item.position.latitude, item.position.longitude]);
            // if (item.result== 0) {
            //   state.green.push(item);
            // }
            // if (item.result== 1) {
            //   state.yellow.push(item);
            // }
            // if (item.result== 2) {
            //   state.red.push(item);
            // }
          }
        });
        ind++;
      });
    },
    [postMapFilter.pending]: (state) => {
      state.markers = [];
      state.loadingMarkers = true;
      state.loadingMarkersField = true;
    },
    [postMapFilter.rejected]: (state) => {
      state.markers = [];
      state.loadingMarkers = true;
      state.loadingMarkersField = false;

    },
    
  },
});

export const {
  updateFromDate,
  updateToDate,
  addTour,
  updateTour,
  setRangeDate,
  setRangeDateCombo,
  updateVehicle,
  deletePreference,
  deleteLine,
  setQuery,
  showLine,
  hideLine,
  resetFilter,
  addLine,
  setMarkers,
  setBinSummary
} = TourSlice.actions;

export default TourSlice.reducer;
