import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import apiUrlConfig from "../../../apiUrlConfig";
import moment from "moment";
import _ from '@lodash';
import {unitsScantecSlice} from "./unitsScantec";
import {pourcentageScantecSlice} from "./graphPositionScantec";

const initialState = {
  trends: [],
  allEntities: [],
  loadTrend: false
}

export const getTrand = createAsyncThunk('scantec/trand', async (obj) => {
  const {binid,date} = obj;
  const response = await axios.get(apiUrlConfig.GET_POSITIONS+"/bin", { params: {  binid:binid, date: date }, headers: { 'X-UI-State': 'kocobox.overview' } });
  const data = await response.data;
  return data;
});


export const trandScantecSlice = createSlice({
  name: 'scantec/trand',
  initialState,
  reducers: {
    setFilterTrend: {
      reducer: (state, action) => {
        state.trends =  state.allEntities.slice(0,parseInt(action.payload) *4);
        state.trends = _.orderBy(state.trends,['date.sec'],['asc']);
      }
    },
    setClearAllTrend: {
      reducer: (state, action) => {
        state.trends=[];
        state.allEntities = [];
      }
    }
  },
  extraReducers: {
    [getTrand.pending]: (state) => {
      state.loadTrend = true;
        state.trends= [];

    },
    [getTrand.fulfilled]: (state, { payload }) => {
          state.loadTrend = false;
          state.allEntities = _.orderBy( payload,['date.sec'],['desc']);
      state.trends = _.orderBy(state.allEntities.slice(0, 4),['date.sec'],['asc']);
    },
    [getTrand.rejected]: (state) => {
      state.loadTrend = false;
      state.trends= [];
      state.allEntities=  [];
    },
  },
});
export const postReducer = trandScantecSlice.reducer;
export const {setClearAllTrend,setFilterTrend} = trandScantecSlice.actions;

export default trandScantecSlice.reducer;